import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
const moment = require('moment');
@Injectable({
  providedIn: 'root'
})
export class FileReaderService {

  constructor() { }

  readXlsxFile(file, id): Promise<any> {
      return new Promise(resolve => {
        let workBook = null;
        let jsonData = null;
        let allJsonData = [];
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary', cellDates: true });
          jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            initial[name].forEach((res) => {
              if (moment(res.start_time).date() < moment(res.end_time).date()) {
                res.is_continued = true;
              } else {
                res.is_continued = false;
              }
              res.start_date = moment(res.start_date).format('YYYY-MM-DD');
              res.start_time = moment(res.start_time).utc();
              res.end_time = moment(res.end_time).utc();
              res.live_channel_id = id;
            });
            return initial;
          }, {});
          for (const json in jsonData) {
            allJsonData = [...allJsonData, ...jsonData[json]];
          }
          resolve(allJsonData);
        };
        reader.readAsBinaryString(file);
      });
  }

   startDateToDateString(date: Date): any {
     return date.getFullYear() +
       '-' + this.pad(date.getMonth() + 1) +
       '-' + this.pad(date.getDate());
  }

  pad(num) {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  }

  toIsoString(date: Date): any {
    return date.getFullYear() +
      '-' + this.pad(date.getMonth() + 1) +
      '-' + this.pad(date.getDate()) +
      'T' + this.pad(date.getHours()) +
      ':' + this.pad(date.getMinutes()) +
      ':' + this.pad(date.getSeconds());
  }
}
