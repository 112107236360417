import {Component, HostListener, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-re-order-screen',
  templateUrl: './re-order-screen.component.html',
  styleUrls: ['./re-order-screen.component.scss']
})
export class ReOrderScreenComponent implements OnInit {

  constructor(private model: MatDialogRef<ReOrderScreenComponent>) { }

  ngOnInit() {
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  closeWindow() {
    this.model.close();
  }


}
