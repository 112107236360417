import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {EventService} from '../../../../../core/service/event.service';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';

@Component({
  selector: 'app-tv-episode-monetization',
  templateUrl: './tv-episode-monetization.component.html',
  styleUrls: ['./tv-episode-monetization.component.scss']
})
export class TvEpisodeMonetizationComponent implements OnInit, OnDestroy {

  availableFreeToWatchValues: any [];
  packages: any [] = [];
  packagesClone = [];
  availablePayToWatchValues: string[] = [];
  freeToWatch: string[];
  payToWatch: string[] = [];
  matSlide;

  FREELKR = 1;
  FREEUSD = 2;
  FREEEDAY = 3;

  // STANDARDLKR = 4;
  // STANDARDUSD = 5;
  // STANDARDDATE = 6;

  PREMIUMLKR = 7;
  PREMIUMUSD = 8;
  PREMIUMDATE = 9;
  PAY_TO_WATCH = 10;
  FREE_TO_WATCH = 11;
  ANNUALPREMIUMLKR = 12;
  ANNUALPREMIUMUSD = 13;
  ANNUALPREMIUMDATE = 14;

  freeLKR: number;
  freeUSD: number;
  freeDays: number;

  // standardLKR: number;
  // standardUSD: number;
  // standardDays: number;

  premiumLKR: number;
  premiumUSD: number;
  premiumDays: number;

  annualPremiumLKR: number;
  annualPremiumUSD: number;
  annualPremiumDays: number;

  isFreeLKRValidate = true;
  isFreeUSDValidate = true;
  isFreeDaysValidate = true;

  // isStandardLKRValidate = true;
  // isStandardUSDValidate = true;
  // isStandardDaysValidate = true;

  isPremiumLKRValidate = true;
  isPremiumUSDValidate = true;
  isPremiumDaysValidate = true;

  isAnnualPremiumLKRValidate = true;
  isAnnualPremiumUSDValidate = true;
  isAnnualPremiumDaysValidate = true;

  freeLKRValidateMessage;
  freeUSDValidateMessage;
  freeDaysValidateMessage;

  /*  standardLKRValidateMessage;
    standardUSDValidateMessage;
    standardDaysValidateMessage;*/

  premiumLKRValidateMessage;
  premiumUSDValidateMessage;
  premiumDaysValidateMessage;

  annualPremiumLKRValidateMessage;
  annualPremiumUSDValidateMessage;
  annualPremiumDaysValidateMessage;

  requestBody: any = {};
  destroy$ = new Subject();

  getContentIdSubscription;
  tvEpisodeId;
  monetizationRuleType = false;
  tvEpisodesIdValidated = false;
  monetizationNotAvailableValidateMessage;
  isFormValid = true;
  tvShowId: number;
  private inheritedMonetizationRules;

  constructor(private service: CommonService, private alert: Alerts, private eventService: EventService,
              private commonService: CommonServiceService) {
  }

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        this.tvEpisodeId = response.id;
        this.tvEpisodesIdValidated = false;
      }
    });
    this.getContentIdSubscription = this.eventService.returnTvShowId.subscribe(response => {
      if (response) {
        this.tvShowId = response.id;
      }
    });
    this.loadPackages();

    this.matSlide = false;
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
  }

  loadPackages() {
    this.service.findAll(Service.PACKAGE_TIER).pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.packages = response;
          this.packagesClone = [...response];
          this.availableFreeToWatchValues = response.filter(v => v.id !== 3).map((pack) => pack.package_type);
          this.freeToWatch = this.availableFreeToWatchValues;
          this.availablePayToWatchValues = this.packages
              .filter(value => !this.freeToWatch.includes(value.package_type) && value.package_type.id === 3)
              .map((pack) => pack.package_type);
        });
  }

  changeItemFromFreeWatch() {
    this.availablePayToWatchValues = this.packages
        .filter(value => !this.freeToWatch.includes(value.package_type) && value.package_type !== 'STANDARD')
        .map((pack) => pack.package_type);
    this.availableFreeToWatchValues = [...this.availableFreeToWatchValues];
  }

  changeItemFromPayWatch() {
    this.availableFreeToWatchValues = this.packages
        .filter(value => !this.payToWatch.includes(value.package_type) && value.package_type !== 'STANDARD')
        .map((pack) => pack.package_type);
    this.availablePayToWatchValues = [...this.availablePayToWatchValues];
  }

  addItemToFreeWatch() {
    this.changeItemFromFreeWatch();
  }

  addItemToPayToWatch() {
    this.changeItemFromPayWatch();
  }

  clearFreeToWatch() {
    this.changeItemFromFreeWatch();
  }

  clearPayToWatch() {
    this.changeItemFromPayWatch();
  }


  validatingRequiredFields() {

    if (!this.payToWatch.includes(environment.packages.premium) &&
        !this.freeToWatch.includes(environment.packages.premium)) {
      this.isFormValid = false;
      this.alert.errorAlert(environment.alerts.error.tv_episode.monetization.title,
          environment.alerts.error.tv_episode.monetization.premiumNotAvailable);
    }
    if (!this.payToWatch.includes(environment.packages.annualPremium) &&
        !this.freeToWatch.includes(environment.packages.annualPremium)) {
      this.isFormValid = false;
      this.alert.errorAlert(environment.alerts.error.tv_episode.monetization.title,
          environment.alerts.error.tv_episode.monetization.annualPremiumNotAvailable);
    }
    if (!((this.payToWatch.includes(environment.packages.premium) && this.payToWatch.includes(environment.packages.annualPremium)) ||
        (this.freeToWatch.includes(environment.packages.premium) && this.freeToWatch.includes(environment.packages.annualPremium)))) {
      this.isFormValid = false;
      this.alert.errorAlert(environment.alerts.error.tv_episode.monetization.title,
          environment.alerts.error.tv_episode.monetization.bothPackagesShouldSame);
    }

    if (this.payToWatch.includes(environment.packages.premium)) {
      if (this.premiumLKR < 0 || isNaN(this.premiumLKR) || this.premiumLKR === null) {
        this.isFormValid = false;
        this.isPremiumLKRValidate = false;
        this.premiumLKRValidateMessage = environment.validations.emptyPremiumLKRValidateMessage;
      }
      if (this.premiumUSD < 0 || isNaN(this.premiumLKR) || this.premiumUSD === null) {
        this.isPremiumUSDValidate = false;
        this.isFormValid = false;
        this.premiumUSDValidateMessage = environment.validations.emptyPremiumUSDValidateMessage;
      }
      if (this.premiumDays < 0 || isNaN(this.premiumLKR) || this.premiumDays === null) {
        this.isPremiumDaysValidate = false;
        this.isFormValid = false;
        this.premiumDaysValidateMessage = environment.validations.emptyPremiumDaysValidateMessage;
      }
    }
    if (this.payToWatch.includes(environment.packages.annualPremium)) {
      if (this.annualPremiumLKR < 0 || isNaN(this.annualPremiumLKR) || this.annualPremiumLKR === null) {
        this.isFormValid = false;
        this.isAnnualPremiumLKRValidate = false;
        this.annualPremiumLKRValidateMessage = environment.validations.emptyPremiumLKRValidateMessage;
      }
      if (this.annualPremiumUSD < 0 || isNaN(this.annualPremiumLKR) || this.annualPremiumUSD === null) {
        this.isAnnualPremiumUSDValidate = false;
        this.isFormValid = false;
        this.annualPremiumUSDValidateMessage = environment.validations.emptyPremiumUSDValidateMessage;
      }
      if (this.annualPremiumDays < 0 || isNaN(this.annualPremiumLKR) || this.annualPremiumDays === null) {
        this.isAnnualPremiumDaysValidate = false;
        this.isFormValid = false;
        this.annualPremiumDaysValidateMessage = environment.validations.emptyPremiumDaysValidateMessage;
      }
    }
    if (this.payToWatch.includes(environment.packages.free)) {
      if (this.freeLKR < 0 || isNaN(this.freeLKR) || this.freeLKR === null) {
        this.isFreeLKRValidate = false;
        this.isFormValid = false;
        this.freeLKRValidateMessage = environment.validations.emptyFreeLKRValidateMessage;
      }
      if (this.freeUSD < 0 || isNaN(this.freeUSD) || this.freeUSD === null) {
        this.isFreeUSDValidate = false;
        this.isFormValid = false;
        this.freeUSDValidateMessage = environment.validations.emptyFreeUSDValidateMessage;
      }
      if (this.freeDays < 0 || isNaN(this.freeDays) || this.freeDays === null) {
        this.isFreeDaysValidate = false;
        this.isFormValid = false;
        this.freeDaysValidateMessage = environment.validations.emptyFreeDaysValidateMessage;
      }
    }
    /*  if (this.payToWatch.includes(environment.packages.standard)) {
        if (this.standardLKR < 0  || isNaN(this.standardLKR) || this.standardLKR === null) {
          this.isStandardLKRValidate = false;
          this.isFormValid = false;
          this.standardLKRValidateMessage = environment.validations.emptyStandardLKRValidateMessage;
        }
        if (this.standardUSD < 0  || isNaN(this.standardUSD) || this.standardUSD === null) {
          this.isStandardUSDValidate = false;
          this.isFormValid = false;
          this.standardUSDValidateMessage = environment.validations.emptyStandardUSDValidateMessage;
        }
        if (this.standardDays < 0  || isNaN(this.standardDays) || this.standardDays === null) {
          this.isStandardDaysValidate = false;
          this.isFormValid = false;
          this.standardDaysValidateMessage = environment.validations.emptyStandardDaysValidateMessage;
        }
      }*/
  }


  resetValidation(field) {
    this.isFormValid = true;
    switch (field) {
      case this.FREELKR:
        this.isFreeLKRValidate = true;
        break;
      case this.FREEUSD:
        this.isFreeUSDValidate = true;
        break;
      case this.FREEEDAY:
        this.isFreeDaysValidate = true;
        break;
        /*case this.STANDARDLKR:
          this.isStandardLKRValidate = true;
          break;
        case this.STANDARDUSD:
          this.isStandardUSDValidate = true;
          break;
        case this.STANDARDDATE:
          this.isStandardDaysValidate = true;
          break;*/
      case this.PREMIUMLKR:
        this.isPremiumLKRValidate = true;
        break;
      case this.PREMIUMUSD:
        this.isPremiumUSDValidate = true;
        break;
      case this.PREMIUMDATE:
        this.isPremiumDaysValidate = true;
        break;
      case this.ANNUALPREMIUMLKR:
        this.isAnnualPremiumLKRValidate = true;
        break;
      case this.ANNUALPREMIUMUSD:
        this.isAnnualPremiumUSDValidate = true;
        break;
      case this.ANNUALPREMIUMDATE:
        this.isAnnualPremiumDaysValidate = true;
        break;
      default:
        return;
    }
  }

  createRequestBody() {
    this.requestBody.content_type = environment.contentType.tvEpisode;
    this.requestBody.monetization = {};
    this.requestBody.monetization.allowed_advertising_free_tier = this.matSlide;
    this.requestBody.monetization.free_to_watch = [];
    this.freeToWatch.forEach((tier, index) => {

      let t;
      if (tier === environment.packages.premium) {
        t = environment.packages.premium;
      }
      if (tier === environment.packages.annualPremium) {
        t = environment.packages.annualPremium;
      }
      if (tier === environment.packages.free) {
        t = environment.packages.free;
      }
      if (tier === environment.packages.standard) {
        t = environment.packages.standard;
      }
      this.requestBody.monetization.free_to_watch.push(t);
    });

    this.requestBody.monetization.plans = [];
    this.requestBody.monetization.plans.prices = [];
    this.requestBody.monetization.inherited_from_content = this.monetizationRuleType;
    this.payToWatch.forEach((tier, index) => {
      this.requestBody.monetization.plans.push(
          {
            package_id: this.packagesClone.filter((pack) => {
                  return pack.package_type === tier;
                }
            ).map((selectedPack) => {
              return selectedPack.id;
            })[0],
            prices: [
              {
                amount: tier === environment.packages.free ? this.freeLKR
                    : (tier === environment.packages.premium ? this.premiumLKR : this.annualPremiumLKR),
                currency: environment.currency.lkr
              },
              {
                amount: tier === environment.packages.free ? this.freeUSD
                    : (tier === environment.packages.premium ? this.premiumUSD : this.annualPremiumUSD),
                currency: environment.currency.usd
              }
            ],
            rental_period: {
              period: tier === environment.packages.free ? this.freeDays
                    : (tier === environment.packages.premium ? this.premiumDays : this.annualPremiumDays),
              unit: environment.packageActivationUnits.days
            },
            package_type: this.payToWatch[index],
          },
      );
    });

  }

  save() {
    this.validatingRequiredFields();
    this.createRequestBody();

    if (!this.isFormValid) {
      return;
    }

    if (this.tvEpisodeId) {
      this.service.update(Service.TV_EPISODE.MONETIZATION, this.tvEpisodeId, this.requestBody)
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.eventService.getContentIdEventEmitter({id: response.id, inherited: this.monetizationRuleType});
        this.alert.successAlert(environment.alerts.success.tv_episode.monetization.title,
            environment.alerts.success.tv_episode.monetization.message);
      });
    } else {
      this.service.save(Service.TV_EPISODE.MONETIZATION, this.requestBody)
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.eventService.getContentIdEventEmitter({id: response.id, inherited: this.monetizationRuleType});
        this.alert.successAlert(environment.alerts.success.tv_episode.monetization.title,
            environment.alerts.success.tv_episode.monetization.message);
      });
    }
  }

  selectMonetizationRule(event) {
    if (event) {
      if (this.tvShowId) {
        this.loadAllMonetization();
        this.freeToWatch = this.inheritedMonetizationRules;
      }
      if (!this.tvEpisodeId) {
        this.tvEpisodesIdValidated = true;
        this.monetizationNotAvailableValidateMessage = environment.validations
            .monetizationNotAvailableValidateMessage;

      } else {
        this.commonService.findAllWizardStatus(this.tvEpisodeId, environment.contentType.tvEpisode)
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

        }, error => {
          if (!error.error.data.metadata_status) {
            this.tvEpisodesIdValidated = true;
            this.monetizationNotAvailableValidateMessage = environment.validations
                .monetizationNotAvailableValidateMessage;
          }
        });
      }

      return;
    }
    this.tvEpisodesIdValidated = false;
    this.loadPackages();
    this.payToWatch = [];
  }

  loadAllMonetization() {
    this.service.findById(Service.TV_SHOW.MONETIZATION, this.tvShowId, environment.contentType.tvShow)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.freeToWatch = result.monetization.free_to_watch;
      this.inheritedMonetizationRules = result.monetization.free_to_watch;
      this.matSlide = result.monetization.allowed_advertising_free_tier;
      this.payToWatch = result.monetization.plans.map((pack) => pack.package_type);
      if (this.payToWatch.includes('FREE')) {
        const freePlanData = result.monetization.plans.filter((pack) => pack.package_type === 'FREE')[0];
        this.freeDays = freePlanData.rental_period.period;
        for (const price of freePlanData.prices) {
          if (price.currency === 'LKR') {
            this.freeLKR = price.amount;
          }
          if (price.currency === 'USD') {
            this.freeUSD = price.amount;
          }
        }
      }
      if (this.payToWatch.includes('PREMIUM')) {
        const premiumPlanData = result.monetization.plans.filter((pack) => pack.package_type === 'PREMIUM')[0];
        this.freeDays = premiumPlanData.rental_period.period;
        for (const price of premiumPlanData.prices) {
          if (price.currency === 'LKR') {
            this.premiumLKR = price.amount;
          }
          if (price.currency === 'USD') {
            this.premiumUSD = price.amount;
          }
        }
      }
      if (this.payToWatch.includes('ANNUAL_PREMIUM')) {
        const premiumPlanData = result.monetization.plans.filter((pack) => pack.package_type === 'ANNUAL_PREMIUM')[0];
        this.freeDays = premiumPlanData.rental_period.period;
        for (const price of premiumPlanData.prices) {
          if (price.currency === 'LKR') {
            this.annualPremiumLKR = price.amount;
          }
          if (price.currency === 'USD') {
            this.annualPremiumUSD = price.amount;
          }
        }
      }
      /* if (this.payToWatch.includes('STANDARD')) {
         const standardPlanData = result.monetization.plans.filter(( pack ) => pack.package_type === 'STANDARD')[0];
         this.freeDays = standardPlanData.rental_period.period;
         for (const  price of standardPlanData.prices) {
           if (price.currency === 'LKR') {
             this.standardLKR = price.amount;
           }
           if (price.currency === 'USD') {
             this.standardUSD = price.amount;
           }
         }
       }
 */
    });
  }

}
