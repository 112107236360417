import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Alerts} from '../../../../../alert/Alert';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-genre-detail-view',
  templateUrl: './genre-detail-view.component.html',
  styleUrls: ['./genre-detail-view.component.scss']
})
export class GenreDetailViewComponent implements OnInit {

  isGenreValidated = true;
  genre: string;
  genreData: any;
  genreValidatedMessage;
  destroy$ = new Subject();
  requestBody: any = {};
  updateSuccess = false;


  constructor(private dialog: MatDialogRef<GenreDetailViewComponent>,
              private alert: Alerts,
              private commonService: CommonService,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.genreData = data.selectedGenre;
    this.genre = this.genreData.name;
  }

  ngOnInit() {

  }


  closeWindow() {
    this.dialog.close(this.updateSuccess);
  }

  save() {
    this.createRequestBody();
    if (!this.genre) {
      this.alert.errorAlert(environment.alerts.error.genresUpdate.title,
          environment.alerts.error.genresUpdate.message);
      return;
    }
    this.commonService.update(Service.GENRE, this.genreData.id, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          this.updateSuccess = true;
          this.alert.successAlert(environment.alerts.success.genresUpdate.title,
          environment.alerts.success.genresUpdate.message);
          this.dialog.close(this.updateSuccess);
    });
  }

  createRequestBody() {
    this.requestBody.name = this.genre;
  }
  cancel() {
    this.closeWindow();
  }
}
