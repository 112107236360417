import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {MatDialogRef} from '@angular/material/dialog';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-add-new-genre',
  templateUrl: './add-new-genre.component.html',
  styleUrls: ['./add-new-genre.component.scss']
})
export class AddNewGenreComponent implements OnInit, OnDestroy {
  genre;
  destroy$ = new Subject();
  env=environment;
  requestBody: any = {};
  savedGenre;
  constructor(private commonService: CommonService, private alert: Alerts,
              private dialog: MatDialogRef<AddNewGenreComponent>) {}

  ngOnInit() {
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  cancel() {
    this.dialog.close();
  }

  save() {
    this.createRequestBody();
    this.commonService.save(Service.GENRE, this.requestBody).pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.savedGenre = response;
          this.alert.successAlert(this.env.alerts.success.genre.title, this.env.alerts.success.genre.message)
              .then(value => {
                this.dialog.close(this.savedGenre);
              });
        }, error => {
          if (error.status === this.env.alerts.error.genre.alreadyExist.errorCode) {
            this.alert.errorAlert(this.env.alerts.error.genre.title,
                this.env.alerts.error.genre.alreadyExist.message);
            return;
          }
          this.alert.errorAlert(this.env.alerts.error.genre.title, this.env.alerts.error.genre.message);
        });
  }

  createRequestBody() {
    this.requestBody.name = this.genre;
  }

  @HostListener('document:keydown.Enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.save();
  }
}
