import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {MediaVideoModelComponent} from './model/media-video-model/media-video-model.component';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';
import {Service} from '../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {CmsUserService} from '../../../core/service/common_service/ http/cms-user/cms-user.service';
import {ContentDetailService} from '../../../core/service/common_service/ http/content-details/content-detail.service';
import {CommonService} from '../../../core/service/common_service/common.service';
import {PackageTierService} from '../../../core/service/common_service/ http/packege-tier/package-tier.service';
import {VideoChannelService} from '../../../core/service/common_service/ http/contents/video-channel/video-channel.service';
import {Alerts} from '../../../alert/Alert';
import {TvShowService} from '../../../core/service/common_service/ http/contents/tv-shows/tv-show.service';



@Component({
  selector: 'app-media-video',
  templateUrl: './media-video.component.html',
  styleUrls: ['./media-video.component.scss']
})
export class MediaVideoComponent implements OnInit, OnDestroy {

  env = environment;
  destroy$ = new Subject();
  selectedPage: number;
  tableData: any[] = [];
  selectedTab;
  loading = false;
  pageCount: any;
  searchTerm = '';
  cmsUsers: any[] = [];
  columnData: any[] = [];
  packages: any[] = [];
  currentVideoChannelPage;
  totalVideoChannels = 0;
  videoChannelsClone: any = [];
  videoChannels: any [] = [];
  selectedVideoChannel;
  playLists: any [] = [];
  playListsClone: any [] = [];
  currentPlayListPage;
  totalPlayLists = 0;
  disableChildFilter = false;
  deleteSubject: Subject<boolean> = new Subject();
  constructor(private matModel: MatDialog, private commonService: CommonService,
              private cmsUserService: CmsUserService, private alert: Alerts,
              private contentDetailService: ContentDetailService,
              private packageService: PackageTierService,
              private videoChannelService: VideoChannelService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.currentVideoChannelPage = this.env.page;
    this.selectedPage = 1;
    this.selectedTab = this.env.contentStatus.published;
    this.currentPlayListPage = this.env.page;
    this.loadAllCmsUsers(this.env.cmsUserAction.create);
    this.loadAllCmsUsers(this.env.cmsUserAction.upload);
    this.getVideoChannels();
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchVideo();
    } else {
      this.findVideos();
    }
    this.loadAllPackages();
  }

  ngOnDestroy() {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  addNewMediaVideo() {
    this.cd.detach();
    const matDialogRef = this.matModel.open(MediaVideoModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: false
      }
    });
    matDialogRef.afterClosed().subscribe(value => {
      this.cd.reattach();
    });
  }

  loadUserFilterData(action) {
    const filterDataArray = [];
    const modifiedUserData = this.cmsUsers.map(val => {
      return {
        id: val.id,
        value: val.name
      };
    });
    let selectedField;

    if (action === this.env.cmsUserAction.create) {
      selectedField = this.env.unPublishedTvEpisodeColumns[8].field;
    } else {
      selectedField = this.env.processedTvEpisodeColumns[5].field;
    }

    filterDataArray.push({
      field: selectedField,
      data: modifiedUserData
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllCmsUsers(action) {
    this.loading = true;
    this.cmsUserService.findAllContentsCmsUsers({status: action})
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.cmsUsers = response;
      this.loadUserFilterData(action);
      this.loading = false;
    });
  }

  loadPackageFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.packages.map(val => {
      return {
        id: val.id,
        value: val.package_type
      };
    });

    modifiedUserData.splice(2, 1);
    const payToWatch = this.env.publishedVideoColumns[9].field;
    const freeToWatch = this.env.publishedVideoColumns[10].field;

    filterDataArray.push({
      field: payToWatch,
      data: modifiedUserData
    });

    filterDataArray.push({
      field: freeToWatch,
      data: modifiedUserData
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  getVideoChannels() {
    const filterDataArray = [];
    this.videoChannelService.findAll({
      page: this.currentVideoChannelPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalVideoChannels = response.total;
      const videoChannelsResponse = response.data;
      this.videoChannels = [...this.videoChannels, ...videoChannelsResponse];

      this.videoChannelsClone = [...this.videoChannels, ...videoChannelsResponse];
      const videoChannel = this.env.processedVideoColumns[7].field;

      filterDataArray.push({
        field: videoChannel,
        data: this.videoChannels.map(val => {
          return {
            id: val.id,
            value: val.title
          };
        })
      });

      this.columnData = [...this.columnData, ...filterDataArray];
    });
  }

  loadAllPackages() {
    this.loading =  true;
    this.packageService.findAllPackageTiers()
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.packages = response;
      this.loadPackageFilterData();
      this.loading = false;
    });
  }

  selectTab(event) {
    let action;
    switch (event.tab.textLabel.toUpperCase()) {
      case this.env.contentStatus.published: {
        action = this.env.cmsUserAction.create;
        action = this.env.cmsUserAction.upload;
        this.loadAllPackages();
        this.selectedTab = this.env.contentStatus.published;
        break;
      }
      case this.env.contentStatus.scheduled: {
        this.selectedTab = this.env.contentStatus.scheduled;
        break;
      }
      case this.env.contentStatus.processed: {
        this.getVideoChannels();
        action = this.env.cmsUserAction.upload;
        this.selectedTab = this.env.contentStatus.processed;
        break;
      }
      case this.env.contentStatus.processing: {
        this.selectedTab = this.env.contentStatus.processing;
        break;
      }
      default: {
        action = this.env.cmsUserAction.create;
        action = this.env.cmsUserAction.upload;
        this.selectedTab = this.env.contentStatus.unpublished;
      }
    }

    this.tableData = [];
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchVideo();
    } else {
      this.findVideos();
    }
    if (action) {
      this.loadAllCmsUsers(action);
    }
  }

  searchVideo() {
    this.loading = true;
    this.commonService.search(Service.VIDEO, this.selectedTab, this.searchTerm,
      this.selectedPage - 1, this.env.tableProperties.size, 'MATURE')
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.processVideoData(response);
      }, error => {
        this.loading = false;
      });
  }

  findVideos() {
    this.loading = true;
    this.commonService.findAll(Service.VIDEO.STATUS, {status: this.selectedTab,
      contentType: environment.contentType.video, page: this.selectedPage - 1, size: this.env.tableProperties.size})
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.processVideoData(response);
        }, error => {
          this.loading =  false;
    });
  }

  processVideoData(response) {
    let arr;
    if (response.content_detail_by_multiple_status) {
      arr = response.content_detail_by_multiple_status;
    } else {
      arr = response.contents_by_status;
    }
    this.pageCount = (response.total) / this.env.tableProperties.size * 10;
    this.tableData = arr.map((content, index) => {

      if (content.title) {
        content.videoTitle = content.title.english;
      }

      if (content.created_by) {
        content.createdBy = content.created_by.name;
      }

      if (content.uploaded_by) {
        content.uploadedBy = content.uploaded_by.name;
      }

      if (content.published_by) {
        if (content.published_by.name) {
          content.publishedBy = content.published_by.name;
        }
      }

      if (content.poster) {
        content.videoPoster = {
          isPoster: true,
          url: content.poster.source_url
        };
      }
      return content;
    });
    this.loading = false;
  }

  setPage(event) {
    this.selectedPage = event;
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchVideo();
    } else {
      this.findVideos();
    }
  }

  openDetailView(event, status) {

    this.cd.detach();
    const matDialogRef = this.matModel.open(MediaVideoModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: true,
        videoId: event.selectedData.id,
        videoTitle: event.selectedData.englishTitle,
        publishStatus: status
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      this.cd.reattach();
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchVideo();
      } else {
        this.findVideos();
      }
    });
  }
  findAllByFilter(filterData) {
    let publishedDateRange = [];
    let releasedDateRange = [];
    const queryObj = {
      createdBy: null,
      uploadedBy: null,
      videoChannel: null,
      playList: null,
      payToWatch: null,
      freeToWatch: null,
      contentStatus: this.selectedTab,
      page: this.selectedPage - 1,
      size: this.env.size,
      lessThanReleasedDateAndTime: null,
      greaterThanReleasedDateAndTime: null,
      lessThanPublishedDateAndTime: null,
      greaterThanPublishedDateAndTime: null
    };

    filterData.forEach(filter => {
      if (filter.field === this.env.unpublishedVideoColumns[5].field) {
        const filteredCmsUsers = this.cmsUsers.filter(val => val.id === filter.value);
        if (filteredCmsUsers.length > 0) {
          queryObj.createdBy = filteredCmsUsers[0].name;
        }
      }

      if (filter.field === this.env.processedVideoColumns[5].field) {
        const filteredCmsUsers = this.cmsUsers.filter(val => val.id === filter.value);
        if (filteredCmsUsers.length > 0) {
          queryObj.uploadedBy = filteredCmsUsers[0].name;
        }
      }

      if (filter.field === this.env.processedVideoColumns[7].field) {
        queryObj.videoChannel = filter.value;
        this.selectedVideoChannel =  filter.value;
        this.getVideoChannelPlayList(this.selectedVideoChannel);
      }

      if (filter.field === this.env.publishedVideoColumns[12].field) {
          queryObj.playList = filter.value;
      }

      if (filter.field === this.env.publishedVideoColumns[9].field) {
        const filteredPackages = this.packages.filter(val => val.id === filter.value);
        if (filteredPackages.length > 0) {
          queryObj.payToWatch = filteredPackages[0].package_type;
        }
      }

      if (filter.field === this.env.publishedVideoColumns[10].field) {
        const filteredPackages = this.packages.filter(val => val.id === filter.value);
        if (filteredPackages.length > 0) {
          queryObj.freeToWatch = filteredPackages[0].package_type;
        }
      }

      if (filter.field === this.env.publishedVideoColumns[7].field) {
        publishedDateRange = filter.value.split(' to ');
        if (publishedDateRange.length > 1) {
          queryObj.greaterThanPublishedDateAndTime = publishedDateRange[0].concat(' ') + '00:00:00';
          queryObj.lessThanPublishedDateAndTime = publishedDateRange[1].concat(' ') + '00:00:00';
        }
      }

      if (filter.field === this.env.publishedVideoColumns[8].field) {
        releasedDateRange = filter.value.split(' to ');
        if (releasedDateRange.length > 1) {
          queryObj.greaterThanReleasedDateAndTime = releasedDateRange[0];
          queryObj.lessThanReleasedDateAndTime = releasedDateRange[1];
        }
      }
    });

    if (!queryObj.videoChannel) {
      this.columnData.map(value => {
        if (value.field === this.env.publishedVideoColumns[12].field) {
          value.data = [];
        }
      });
      this.columnData = [...this.columnData, ...[]];
      this.disableChildFilter = true;
    }

    if (queryObj.createdBy
      || queryObj.greaterThanPublishedDateAndTime
      || queryObj.greaterThanReleasedDateAndTime
      || queryObj.uploadedBy
      || queryObj.freeToWatch
      || queryObj.payToWatch
      || queryObj.videoChannel
      || queryObj.playList) {
      if ((releasedDateRange.length === 1) && (releasedDateRange[0] !== '')) {
        return;
      }

      if ((publishedDateRange.length === 1) && (publishedDateRange[0] !== '')) {
        return;
      }

      this.loadDataByFilters(queryObj);
    } else if (filterData.filter(filter => filter.value).length <= 0) {
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchVideo();
      } else {
        this.findVideos();
      }
    }
  }

  loadDataByFilters(queryObj) {
    this.loading = true;
    this.contentDetailService.contentByFilters(this.env.contentType.video, queryObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.tableData = [];
        this.processVideoData(response);
        this.loading = false;
      }, error => {
        console.log(error);
        this.loading = false;
      });
  }

  videoChannelsOnScroll(col) {
    if (col === this.env.processedVideoColumns[7].field) {
      ++this.currentVideoChannelPage;
      if (this.videoChannelsClone.length < this.totalVideoChannels) {
        this.getVideoChannels();
      }
    }
    if (col === this.env.publishedVideoColumns[12].field) {
      ++this.currentPlayListPage;
      if (this.playListsClone.length < this.totalPlayLists) {
        this.getVideoChannelPlayList(this.selectedVideoChannel);
      }
    }
  }

  getVideoChannelPlayList(selectedVideoChannel) {
    if (!selectedVideoChannel) {
      return;
    }
    const filterDataArray = [];
    this.playLists = [];
    this.videoChannelService.playListsById(selectedVideoChannel,
      {page: this.currentPlayListPage, size: this.env.size})
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      const playListsResponse = response.data;
      this.totalPlayLists = response.total;
      this.playLists = [...this.playLists, ...playListsResponse];

      this.playListsClone = [...this.playListsClone, playListsResponse];

      const playList = this.env.publishedVideoColumns[12].field;

      const index = this.columnData.findIndex(value => value.field === playList);

      if (index > -1) {
        this.columnData[index].data = this.playLists.map(val => {
          return {
            id: val.id,
            value: val.label
          };
        });
      }

      filterDataArray.push({
        field: playList,
        data: this.playLists.map(val => {
          return {
            id: val.id,
            value: val.label
          };
        })
      });

      this.columnData = [...this.columnData, ...filterDataArray];
    });
  }
  deleteContent(event) {
    this.alert.confirm(null, environment.alerts.success.video.deleteVideo.confirmationMessage,
        environment.alerts.success.video.deleteVideo.confirmButton).then(value => {
      if (value.isConfirmed) {
        this.commonService.delete(Service.VIDEO, event.selectedData.id).subscribe(val => {
          this.deleteSubject.next(true);
        });
      }
    });
  }
  onKeyPress() {
    if (!this.searchTerm) {
      this.searchVideo();
    }
  }

}
