import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {TvShowService} from '../../../../../core/service/common_service/ http/contents/tv-shows/tv-show.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {VideoService} from '../../../../../core/service/common_service/ http/contents/video.service';
import {takeUntil} from 'rxjs/operators';
import {TvEpisodeModelComponent} from '../../../tv-episode/model/tv-episode-model/tv-episode-model.component';
import {VideoChannelModelComponent} from '../video-channel-model/video-channel-model.component';

@Component({
  selector: 'app-video-episode-list',
  templateUrl: './video-episode-list.component.html',
  styleUrls: ['./video-episode-list.component.scss']
})
export class VideoEpisodeListComponent implements OnInit, OnDestroy {

  tableData: any[] = [];
  env = environment;
  playlists: any[] = [];
  page= 1;
  size = 5;
  pageSize = 10;
  offset = (this.page - 1) * this.size;
  paginatedItems: any[];
  selectedPage: number;
  destroy$ = new Subject();
  selectedVideoChannel: any;
  private model: any;
  private allVideoList: any[] = [];
  videoChannelTitle;

  constructor(private videoService: VideoService, private matModel: MatDialogRef<VideoEpisodeListComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any, private matVideoModel: MatDialog) {
    this.playlists = this.data.playlists;
    this.selectedVideoChannel = this.data.playlistId;
    this.videoChannelTitle = this.data.videoChannelTitle;
    this.model = matModel; }

  ngOnInit() {
    this.findAllVideosByPlaylist();
    this.selectedPage = 1;
  }

  closeWindow() {
    this.model.close();
  }

  findAllVideosByPlaylist() {
    if (this.playlists.length == 0) {
      this.tableData = [];
      return;
    }
    this.playlists.forEach((playlist) => {
      let videoList = playlist.video_list.map((video) => {

        console.log('here video : ', video );
        video.playlistId = playlist.id;
        video.playlistLable = playlist.label;
        video.title = video.title.english;
        if (video.poster) {
          video.poster = {
            isPoster: true,
            url: video.poster.source_url
          };
        }
        video.rowSpan = 0;
        return video;
      });
      if (videoList.length > 0) {
        videoList[0].rowSpan = playlist.video_list.length;
      }

      this.allVideoList = this.allVideoList.concat(videoList);

    });
    const total_pages = Math.ceil(this.allVideoList.length / this.size);
    this.tableData =   this.allVideoList;
  }

  setPage(event) {
    this.selectedPage = event;
    let that = this;
    const promis = new Promise((resolve, reject) => {
      this.videoService.findAllVideosByPlaylist({
        channelId: that.selectedVideoChannel,
        page: that.selectedPage - 1,
        size: 20
      })
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        that.playlists = response.data;
        that.allVideoList = [];
        that.findAllVideosByPlaylist();
        resolve(true);
      });

    });
  }

  onNoClick() {
    this.matModel.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openDetailView(event, status) {
    let matDialogRef = this.matVideoModel.open(VideoChannelModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: true,
        videoChannelId: event.id,
        videoChannelTitle: event.title,
        publishStatus: status
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
    });
  }
}
