import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-add-new-play-list',
  templateUrl: './add-new-play-list.component.html',
  styleUrls: ['./add-new-play-list.component.scss']
})
export class AddNewPlayListComponent implements OnInit {
  constructor( private model: MatDialogRef<AddNewPlayListComponent>,
               private service: CommonService,
               private alert: Alerts,
               @Inject(MAT_DIALOG_DATA) private data: any) {
    this.playListNumber = this.data.videoChannelId;
    this.playLists = this.data.playlist;
  }
  env = environment;
  playListNumber: number;
  isPlayListNumberValidate = true;
  PlayListNumberValidateMessage;
  playLists = [];
  playListLabel: string;
  isPlayListLabelValidate = true;
  PlayListLabelValidateMessage;
  requestBody: any = {};
  isFormValidate = false;
  destroy$ = new Subject();
  savedPlayList: any;
  isDisable = false;
  videos = [];


  PLAYLIST_NUMBER = 1;
  PLAYLIST_LABEL = 2;
  tableData: any[] = [];
  getContentIdSubscription;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow(this.savedPlayList);
  }

  ngOnInit() {
    this.findTvEpisodeBySeason();
  }

  closeWindow(savedSeason) {
    this.model.close(this.savedPlayList);
  }

  createRequestBody() {
    this.requestBody.id = this.playListNumber;
    this.requestBody.label = this.playListLabel;
  }

  validatingRequiredFields() {
    if (!this.playListNumber) {
      this.isPlayListNumberValidate = false;
      this.PlayListNumberValidateMessage = environment.validations.playListNumberValidateMessage;
    }

    if (!this.playListLabel) {
      this.isPlayListLabelValidate = false;
      this.PlayListLabelValidateMessage = environment.validations.playListValidateMessage;
    }
  }

  findTvEpisodeBySeason() {
    this.tableData = this.playLists.map((playlist) => {
      playlist.video_list = playlist.video_list.map((id) => id.id);
      return playlist;
    });

  }

  resetValidation(field) {
    switch (field) {
      case this.PLAYLIST_NUMBER:
        this.isPlayListNumberValidate = true;
        break;
      case this.PLAYLIST_LABEL:
        this.isPlayListLabelValidate = true;
        break;
    }
  }
  formValidation() {
    if (this.isPlayListLabelValidate && this.isPlayListNumberValidate) {
      this.isFormValidate = true;
    } else {
      this.isFormValidate = false;
    }
  }

  save() {
    this.isDisable = true;
    this.validatingRequiredFields();
    this.formValidation();
    this.createRequestBody();
    if (this.isFormValidate) {
      this.service.save(Service.VIDEO.PLAY_LIST, this.requestBody).pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        this.savedPlayList = result;
        this.alert.successAlert(environment.alerts.success.season.title, environment.alerts.success.season.message);
      }, error => {
        this.alert.errorAlert(environment.alerts.error.season.title, environment.alerts.error.season.message);
      });
    }
    this.isDisable = false;
  }



}
