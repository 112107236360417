import { Injectable } from '@angular/core';
import {CuepointService} from './ http/contents/cuepoint.service';
import {ContentRulesService} from './ http/contents/content-rules.service';
import {MetadataService} from './ http/contents/metadata.service';
import {MonetizationService} from './ http/contents/monetization.service';
import {TailerBonusContentService} from './ http/contents/tailer-bonus-content.service';
import {VideoService} from './ http/contents/video.service';
import {PosterService} from './ http/contents/poster.service';
import {Service} from './services';
import {CastMemberService} from './ http/cast-member/cast-member.service';
import {GenreService} from './ http/genre/genre.service';
import {KeyWordService} from './ http/keyword/key-word.service';
import {ContentService} from './ http/contents/content.service';
import {CountryService} from './ http/country/country.service';
import {PackageTierService} from './ http/packege-tier/package-tier.service';
import {MediaVideoService} from './ http/video/media-video.service';
import {PublishService} from './ http/contents/publish.service';
import {TvEpisodePosterService} from './ http/tv-episode/tv-episode-poster.service';
import {VideoChannelMetadataService} from './ http/video-channel/video-channel-metadata.service';
import {VideoChannelContentRuleService} from './ http/video-channel/video-channel-content-rule.service';
import {VideoChannelMonetizationService} from './ http/video-channel/video-channel-monetization.service';
import {VideoChannelPosterService} from './ http/video-channel/video-channel-poster.service';
import {VideoChannelTrailersBonusService} from './ http/video-channel/video-channel-trailers-bonus.service';
import {SliderGraphicService} from './ http/slider-graphic/slider-graphic.service';
import {CategoryService} from './ http/category/category.service';
import {ScreenService} from './ http/screen/screen.service';
import {SeasonService} from './ http/season/season.service';
import {ContentDetailService} from './ http/content-details/content-detail.service';
import {ContentDetailMetadataService} from './ http/content-details/content-detail-metadata.service';
import {ContentDetailVideoService} from './ http/content-details/content-detail-video.service';
import {ContentDetailContentRuleService} from './ http/content-details/content-detail-content-rule.service';
import {ContentDetailMonetizationService} from './ http/content-details/content-detail-monetization.service';
import {ContentDetailsPublishService} from './ http/content-details/content-details-publish.service';
import {MovieService} from './ http/contents/movies/movie.service';
import {TvShowService} from './ http/contents/tv-shows/tv-show.service';
import {TvEpisodeService} from './ http/content-details/tv-episodes/tv-episode.service';
import {PlayListService} from './ http/play-list/play-list.service';
import {VideoChannelService} from './ http/contents/video-channel/video-channel.service';
import {NotificationTemplateService} from './ http/notification-template/notification-template.service';
import {LiveChannelService} from './ http/contents/live-channel/live-channel.service';
import {PaymentService} from './ http/payment/payment.service';
import {SubscribersService} from './ http/subscribers/subscribers.service';
import {CmsUserService} from './ http/cms-user/cms-user.service';
import {ProgrammeService} from './ http/programme/programme.service';
import {ProgrammeScheduleService} from './ http/programme-schedule/programme-schedule.service';



@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
      private cuepointService: CuepointService,
      private contentRulesService: ContentRulesService,
      private metadataService: MetadataService,
      private monetizationService: MonetizationService,
      private tailerBonusContentService: TailerBonusContentService,
      private videoService: VideoService,
      private posterService: PosterService,
      private castMemberService: CastMemberService,
      private genreService: GenreService,
      private keyWordService: KeyWordService,
      private contentService: ContentService,
      private countryService: CountryService,
      private packageTier: PackageTierService,
      private mediaVideo: MediaVideoService,
      private publishService: PublishService,
      private tvEpisodePosterService: TvEpisodePosterService,
      private videoChannelMetadataService: VideoChannelMetadataService,
      private videoChannelContentRuleService: VideoChannelContentRuleService,
      private videoChannelMonetizationService: VideoChannelMonetizationService,
      private videoChannelPosterService: VideoChannelPosterService,
      private videoChannelTrailersBonusService: VideoChannelTrailersBonusService,
      private sliderGraphicService: SliderGraphicService,
      private categoryService: CategoryService,
      private screenService: ScreenService,
      private seasonService: SeasonService,
      private contentDetailService: ContentDetailService,
      private contentDetailMetadataService: ContentDetailMetadataService,
      private contentDetailVideoService: ContentDetailVideoService,
      private contentDetailContentRuleService: ContentDetailContentRuleService,
      private contentDetailMonetizationService: ContentDetailMonetizationService,
      private contentDetailsPublishService: ContentDetailsPublishService,
      private movieService: MovieService,
      private tvShowService: TvShowService,
      private tvEpisodeService: TvEpisodeService,
      private playListService: PlayListService,
      private videoChannelService: VideoChannelService,
      private notificationTemplateService: NotificationTemplateService,
      private liveChannelService: LiveChannelService,
      private mediaVideoService: MediaVideoService,
      private paymentService: PaymentService,
      private subscribersService: SubscribersService,
      private cmsUserService: CmsUserService,
      private programmeService: ProgrammeService,
      private progammeScheduleService: ProgrammeScheduleService
  ) { }

  save(service, body) {
    switch (service) {
      case Service.MOVIE.CONTENT_RULE:
      case Service.TV_SHOW.CONTENT_RULE:
        return this.contentRulesService.saveContentRules(body);

      case Service.MOVIE.CUE_POINT:
      case Service.TV_EPISODE.CUE_POINT:
        return this.cuepointService.saveCuePoint(body);

      case Service.MOVIE.META_DATA:
      case Service.TV_SHOW.META_DATA:
        return this.metadataService.saveMetaData(body);

      case Service.MOVIE.MONETIZATION:
      case Service.TV_SHOW.MONETIZATION:
        return this.monetizationService.saveMonetization(body);

      case Service.MOVIE.TRAILER:
        return this.tailerBonusContentService.save(body);

      // case Service.MOVIE.VIDEO:
      //   return this.videoService.save(body);

      case Service.MOVIE.POSTER:
        return this.posterService.savePoster(body);

      case Service.MOVIE.CONTENT:
      case Service.TV_SHOW.CONTENT:
      case Service.VIDEO_CHANNEL.CONTENT:
      case Service.LIVE_CHANNEL.CONTENT:
        return this.contentService.save(body);

      case Service.TV_EPISODE.CONTENT:
      case Service.VIDEO.CONTENT:
        return this.contentDetailService.save(body);

      case Service.TV_EPISODE.CONTENT_RULE:
      case Service.VIDEO.CONTENT_RULE:
        return this.contentDetailContentRuleService.save(body);

      case Service.TV_EPISODE.MONETIZATION:
      case Service.VIDEO.MONETIZATION:
        return this.contentDetailMonetizationService.save(body);

      case Service.VIDEO.VIDEO:
        return this.mediaVideo.saveVideo(body);

      case Service.VIDEO_CHANNEL.META_DATA:
        return this.videoChannelMetadataService.save(body);

      case Service.VIDEO_CHANNEL.CONTENT_RULE:
        return this.videoChannelContentRuleService.save(body);

      case Service.VIDEO_CHANNEL.MONETIZATION:
        return this.videoChannelMonetizationService.save(body);

      case Service.VIDEO_CHANNEL.POSTER:
        return this.videoChannelPosterService.save(body);

      case Service.VIDEO_CHANNEL.TRAILER:
        return this.videoChannelTrailersBonusService.save(body);

      case Service.VIDEO_CHANNEL.VIDEO:
        return this.videoChannelTrailersBonusService.save(body);

      case Service.KEY_WORD:
        return this.keyWordService.save(body);

      case Service.GENRE:
        return this.genreService.save(body);

      case Service.CAST_MEMBER:
        return this.castMemberService.save(body);

      case Service.SLIDER_GRAPHIC:
        return this.sliderGraphicService.save(body);

      case Service.CATEGORY:
        return this.categoryService.save(body);

      case Service.SCREEN:
        return this.screenService.save(body);

      case Service.TV_EPISODE.SEASON:
        return this.seasonService.save(body);

      case Service.VIDEO.PLAY_LIST:
        return this.playListService.save(body);

      case Service.TV_EPISODE.POSTER:
        return  this.tvEpisodePosterService.savePoster(body);

      case Service.PROGRAMME:
        return  this.programmeService.create(body);

      case Service.PROGRAMME_SCHEDULE:
        return  this.progammeScheduleService.createSingleSchedule();


    }

  }
  update( service, id, body) {
    switch (service) {
      case Service.MOVIE.CONTENT_RULE:
      case Service.TV_SHOW.CONTENT_RULE:
        return this.contentRulesService.updateContentRules(id, body);

      case Service.MOVIE.CUE_POINT:
        return this.cuepointService.updateCuePoint(id, body);

      case Service.VIDEO.CUE_POINT:
      case Service.TV_EPISODE.CUE_POINT:
        return this.cuepointService.updateContentDetailCuePoint(id, body);

      case Service.MOVIE.META_DATA:
        return this.metadataService.updateMetaData(id, body);

      case Service.MOVIE.MONETIZATION:
      case Service.TV_SHOW.MONETIZATION:
        return this.monetizationService.updateMonetization(id, body);

      case Service.MOVIE.TRAILER:
      case Service.TV_SHOW.TRAILER:
        return this.tailerBonusContentService.update(id, body);

      case Service.MOVIE.VIDEO:
        return this.videoService.update(id, body);

      case Service.MOVIE.POSTER:
      case Service.TV_SHOW.POSTER:
      case Service.VIDEO_CHANNEL.POSTER:
      case Service.LIVE_CHANNEL.POSTER:
        return this.posterService.updatePoster(id, body);

      case Service.MOVIE.PUBLISH:
      case Service.TV_SHOW.PUBLISH:
        return this.publishService.publishContent(id, body);

      case Service.TV_EPISODE.MONETIZATION:
      case Service.VIDEO.MONETIZATION:
        return this.contentDetailMonetizationService.update(id, body);

      case Service.TV_EPISODE.CONTENT_RULE:
      case Service.VIDEO.CONTENT_RULE:
        return this.contentDetailContentRuleService.update(id, body);

      case Service.TV_EPISODE.POSTER:
      case Service.VIDEO.POSTER:
        return  this.tvEpisodePosterService.updatePoster(id, body);

      case Service.TV_EPISODE.VIDEO:
      case Service.VIDEO.VIDEO:
        return this.contentDetailVideoService.update(id, body);

      case Service.TV_EPISODE.META_DATA:
      case Service.VIDEO.META_DATA:
        return this.contentDetailMetadataService.update(id, body);

      case Service.VIDEO_CHANNEL.META_DATA:
        return this.videoChannelMetadataService.update(id, body);

      case Service.VIDEO_CHANNEL.CONTENT_RULE:
        return this.videoChannelContentRuleService.update(id, body);

      case Service.VIDEO_CHANNEL.MONETIZATION:
        return this.videoChannelMonetizationService.update(id, body);

      case Service.VIDEO_CHANNEL.TRAILER:
        return this.videoChannelTrailersBonusService.update(id, body);

      case Service.VIDEO_CHANNEL.VIDEO:
        return this.videoChannelTrailersBonusService.update(id, body);

      case Service.VIDEO_CHANNEL.PUBLISH:
        return this.publishService.publishContent(id, body);

      case Service.CAST_MEMBER:
        return this.castMemberService.update(id, body);

      case Service.SLIDER_GRAPHIC:
        return this.sliderGraphicService.update(id, body);

      case Service.VIDEO.POSTER:
        return this.mediaVideo.updatePoster(id, body);

      case Service.TV_EPISODE.PUBLISH:
      case Service.VIDEO.PUBLISH:
        return this.contentDetailsPublishService.publishContent(id, body);

      case Service.SCREEN:
        return this.screenService.publish(id, body);

      case Service.SLIDER_GRAPHIC_PUBLISH:
        return this.sliderGraphicService.publish(id, body);

      case Service.CATEGORY:
        return this.categoryService.update(id, body);

      case Service.NOTIFICATION:
        return this.notificationTemplateService.update(id, body);

      case Service.GENRE:
        return this.genreService.update(id, body);

      case Service.PROGRAMME:
        return this.programmeService.update(id, body);

      case Service.PROGRAMME_SCHEDULE:
        return this.progammeScheduleService.update(id, body);

      case Service.LIVE_CHANNEL.MATADATA:
        return this.liveChannelService.update(body);
    }
  }

  /**
   * Find all common method
   * @param service ----> Specific service you want to use this method
   * @param options ----> Options on your API, Eg: Page, Size... etc....
   */
  findAll( service, options?: any ) {

    switch (service) {
      case Service.MOVIE.CONTENT_RULE:
        return this.contentRulesService.getAllContentRules();

      case Service.MOVIE.CUE_POINT:
        return this.cuepointService.getAllCuePoint();

      case Service.MOVIE.META_DATA:
        return this.metadataService.getAllMetaData();

      case Service.MOVIE.MONETIZATION:
        return this.monetizationService.getAllMonetization();

      case Service.MOVIE.TRAILER:
        return this.tailerBonusContentService.getAllTailerBonusContent();

      case Service.MOVIE.POSTER:
        return this.posterService.getAllPoster();

      case Service.CAST_MEMBER:
        return  this.castMemberService.findAllCastMembers( options.castMemberRole, options.page, options.size );

      case Service.GENRE:
        return this.genreService.findAllGenres(options.page, options.size);

      case Service.KEY_WORD:
        return this.keyWordService.findAllKeyWords(options.page, options.size);

      case Service.COUNTRY:
        return this.countryService.findAllCounties();

      case Service.PACKAGE_TIER:
        return this.packageTier.findAllPackageTiers();

      case Service.MOVIE.STATUS:
      case Service.TV_SHOW.STATUS:
      case Service.TV_EPISODE.STATUS:
      case Service.VIDEO.STATUS:
      case Service.VIDEO_CHANNEL.STATUS:
      case Service.LIVE_CHANNEL:
        return this.contentService.findAll(options);

      case Service.CATEGORY:
        return this.categoryService.getAllCategory(options.status, options.page, options.size);

      case Service.REALITY_CATEGORY:
        return this.categoryService.getAllRealityCategory(options.page, options.size);

      case Service.SLIDER_GRAPHIC:
        return this.sliderGraphicService.getAllPublishedSliderGraphics(options);

      case Service.SLIDER_GRAPHIC_REALITY:
        return this.sliderGraphicService.getRealitySliderGraphic(options.page, options.size);

      case Service.SCREEN:
        return this.screenService.getAllSlidersByStatus(options);

      case Service.NOTIFICATION:
        return this.notificationTemplateService.findByAll(options.page, options.size);

      case Service.PAYMENT:
        return this.paymentService.getAllPayments(options);

      case Service.SUBSCRIBER:
        return this.subscribersService.getAllSubscribers(options);

      case Service.CMS_USER:
        return this.cmsUserService.findAllScreenCmsUsers();
    }
  }
  findById( service, id, contentType ) {
    switch (service) {
      case Service.MOVIE.CONTENT_RULE:
        return this.contentRulesService.getContentRulesById(id, contentType);

      case Service.TV_SHOW.CONTENT_RULE:
        return this.contentRulesService.getContentRulesById(id, contentType);

        // TODO remove these if unneeded
/*      case Service.MOVIE.CUE_POINT:
        return this.cuepointService.getCuePointById(id);*/

      case Service.MOVIE.META_DATA:
      case Service.TV_SHOW.META_DATA:
        return this.metadataService.getMetaDataById(id, contentType);

      case Service.LIVE_CHANNEL.MATADATA:
        return this.liveChannelService.findChannelById(id);

      case Service.MOVIE.MONETIZATION:
        return this.monetizationService.findById(id, contentType);

      case Service.MOVIE.TRAILER:
      case Service.TV_SHOW.TRAILER:
      case Service.VIDEO_CHANNEL.TRAILER:
        return this.tailerBonusContentService.getTailerBonusContentById(id, contentType);

      case Service.MOVIE.VIDEO:
        return this.videoService.findById(id, contentType);

      case Service.MOVIE.POSTER:
      case Service.TV_SHOW.POSTER:
      case Service.LIVE_CHANNEL.POSTER:
        return this.posterService.getPosterById(id, contentType);

      case Service.TV_SHOW.MONETIZATION:
      case Service.VIDEO_CHANNEL.MONETIZATION:
        return this.monetizationService.findById(id, contentType);

      case Service.TV_EPISODE.MONETIZATION:
      case Service.VIDEO.MONETIZATION:
        return this.contentDetailMonetizationService.findById(id, contentType);

      case Service.SLIDER_GRAPHIC:
        return this.sliderGraphicService.findById(id);

      case Service.SCREEN:
        return this.screenService.findById(id);

      case Service.TV_EPISODE.META_DATA:
        return this.contentDetailMetadataService.getMetaDataById(id, contentType);

      case  Service.TV_EPISODE.VIDEO:
      case Service.VIDEO.VIDEO:
        return  this.contentDetailVideoService.findById(id, contentType);

      case Service.TV_EPISODE.CONTENT_RULE:
      case Service.VIDEO.CONTENT_RULE:
        return this.contentDetailContentRuleService.getContentRulesById(id, contentType);

      case Service.TV_EPISODE.POSTER:
      case Service.VIDEO.POSTER:
        return this.posterService.getDetailPosterById(id, contentType);

      case Service.CAST_MEMBER:
        return this.castMemberService.getById(id);

      case Service.MOVIE.CUE_POINT:
        return this.cuepointService.getCuePointById(id, contentType);

      case Service.TV_EPISODE.CUE_POINT:
      case Service.VIDEO.CUE_POINT:
        return this.cuepointService.getContentDetailCuePoint(id, contentType);

      case Service.NOTIFICATION:
        return this.notificationTemplateService.findById(id);

    }
  }
  delete( service, id ) {
    switch (service) {
      case Service.MOVIE.CONTENT_RULE:
        return this.contentRulesService.deleteContentRules(id);

      case Service.MOVIE.CUE_POINT:
        return this.cuepointService.deleteCuePoint(id);

      case Service.MOVIE.META_DATA:
        return this.metadataService.deleteMetaData(id);

      case Service.MOVIE.MONETIZATION:
        return this.monetizationService.deleteMonetization(id);

      case Service.MOVIE.TRAILER:
        return this.tailerBonusContentService.deleteTailerBonusContent(id);

/*      case Service.MOVIE.VIDEO:
        return this.videoService.delete(id);*/

      case Service.MOVIE.POSTER:
        return this.posterService.deletePoster(id);

      case Service.MOVIE || Service.TV_SHOW:
        return this.contentService.deleteContentById(id);

      case Service.TV_EPISODE:
        return this.contentDetailService.deleteContentById(id);

      case Service.SLIDER_GRAPHIC:
        return this.sliderGraphicService.deleteSlider(id);

      case Service.VIDEO:
        return this.contentDetailService.deleteContentById(id);

      case Service.VIDEO_CHANNEL:
        return this.contentService.deleteContentById(id);

      case Service.CATEGORY:
        return  this.categoryService.deleteCategory(id);
    }
  }

  findAllByStatus( service, options?: any ) {

    switch (service) {
      case Service.MOVIE.STATUS:
        return this.movieService.findAllByStatus(options);

      case Service.TV_SHOW.STATUS:
        return this.tvShowService.findAllByStatus(options);

      case Service.TV_EPISODE.STATUS:
        return this.tvEpisodeService.findAllByStatus(options);

      case Service.VIDEO_CHANNEL.STATUS:
        return this.videoChannelService.findAllByStatus(options);

      case Service.VIDEO.STATUS:
        return this.videoService.findAllByStatus(options);

      case Service.LIVE_CHANNEL.STATUS:
        return this.liveChannelService.findAllByStatus(options);
    }
  }

  unPublish(service, id, body) {
    switch (service) {

      case Service.CATEGORY:
        return this.categoryService.unPublish(id, body);

      case Service.SLIDER_GRAPHIC:
        return this.sliderGraphicService.unPublish(id, body);

      case Service.SCREEN:
        return this.screenService.unPublish(id, body);

      case Service.TV_SHOW.UNPUBLISH:
      case Service.MOVIE.UNPUBLISH:
      case Service.VIDEO_CHANNEL.UNPUBLISH:
        return this.movieService.unPublish(id, body);

      case Service.TV_EPISODE.UNPUBLISH:
        return this.contentDetailsPublishService.unPublish(id, body);
    }
  }
  search(service, contentStatus, searchTerm, page, size, maturityRating) {
    switch (service) {
      case Service.MOVIE:
        return this.movieService.findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating);
      case Service.TV_SHOW:
        return this.tvShowService.findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating);
      case Service.VIDEO_CHANNEL:
        return this.videoChannelService.findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating);
      case Service.TV_EPISODE:
        return this.tvEpisodeService.findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating);
      case Service.VIDEO:
        return this.mediaVideoService.findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating);
      case Service.SLIDER_GRAPHIC:
        return this.sliderGraphicService.findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating);
    }
  }

  saveAll(service, body) {
    switch (service) {
      case Service.PROGRAMME_SCHEDULE:
        return  this.progammeScheduleService.create(body);
    }
  }
}
