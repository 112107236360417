import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {environment} from '../../../../../../environments/environment';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Subject} from 'rxjs';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AddNewScreenComponent} from '../../../screens/add-new-screen/add-new-screen.component';
import {UpperCasePipe} from '@angular/common';

@Component({
  selector: 'app-cast-model',
  templateUrl: './cast-model.component.html',
  styleUrls: ['./cast-model.component.scss']
})
export class CastModelComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  name;
  file: File[] = [];
  casts: any[] = [{file: this.file}];
  bio;
  isCastMemberImageValidated: any[] = [];
  isCastNameValidated = true;
  isCastMemberRoleValidated =  true;
  castMemberNameValidationMessage;
  castMemberImageValidationMessage;
  castMemberRoleValidationMessage;
  isFormValid = true;
  roles: any[] = environment.castMemberRoles;
  selectedRole;
  NAME = 1;
  IMAGE = 2;
  ROLE = 3;
  requestBody: any = {};
  castMemberId;
  promiseArray: any[] = [];
  saveBtnDisabled = false;
  savedCastMember;
  initialData;
  isRoleDisabled = false;
  constructor(private model: MatDialogRef<CastModelComponent>,
              private commonService: CommonService,
              private s3Bucket: S3BucketService,
              private alert: Alerts,
              private matModel: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private upperCase: UpperCasePipe) {
    this.initialData = data;
  }

  ngOnInit() {
    if (this.initialData) {
      if (this.initialData.role === environment.castMemberRoles[0].role) {
        this.selectedRole = environment.castMemberRoles[0].id;
      } else if (this.initialData.role === environment.castMemberRoles[1].role) {
        this.selectedRole = environment.castMemberRoles[1].id;
      } else {
        this.selectedRole = environment.castMemberRoles[2].id;
      }
      this.isRoleDisabled = true;
    }
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }
  closeWindow() {
    this.model.close(this.savedCastMember);
  }

  addAnotherPhoto() {
    this.file = [];
    this.casts.push({file: this.file});
  }

  onSelect(event, index) {
    this.casts[index].file  = event.addedFiles;
  }

  deleteCastImage(index) {
    this.casts.splice(index, 1);
    this.resetValidation(this.IMAGE, index);
  }

  validateRequiredFields() {

    if (!this.name) {
      this.isCastNameValidated = false;
      this.isFormValid = false;
      this.castMemberNameValidationMessage = environment.validations.castMemberNameValidationMessage;
    }

    let index = 0;
    for (const cast of this.casts) {
     if (!cast.file[0]) {
       this.isCastMemberImageValidated.push(index);
     }
     ++index;
    }

    if (this.isCastMemberImageValidated.length > 0) {
      this.isFormValid = false;
      this.castMemberImageValidationMessage = environment.validations.castMemberImageValidationMessage;
    }

    if (!this.selectedRole) {
      this.isCastMemberRoleValidated =  false;
      this.isFormValid =  false;
      this.castMemberRoleValidationMessage = environment.validations.castMemberRoleValidationMessage;
    }
  }

  resetValidation(field, index?) {
    this.isFormValid = true;
    switch (field) {
      case this.NAME: {
        this.isCastNameValidated = true;
        break;
      }
      case this.IMAGE: {
        this.isCastMemberImageValidated.splice(this.isCastMemberImageValidated
            .findIndex((value, selectedIndex) => {
              selectedIndex === index;
            }), 1);
        break;
      }
      default: {
        this.isCastMemberRoleValidated =  true;
      }
    }
  }

  createRequestBody() {

    this.requestBody.bio = this.bio;
    this.requestBody.image_and_captions = [];

    for (const cast of this.casts) {
      this.requestBody.image_and_captions.push(
              {
                actor_name: this.name,
                alt_tag: cast.actorName,
                cast_member_img_type: environment.imageType.profile,
                device_type: environment.deviceType.mobile,
                image_conversion_status: environment.conversionStatus.pending,
                image_name: cast.file[0].name,
                orientation_type: this.upperCase.transform(environment.orientations.portrait),
                source_url: cast.imageLocation.replace(environment.s3Bucket.actualPath,
                    environment.s3Bucket.actualPathReplaceTo),
                resolution_type: environment.resolutionTypes.fourX,
                image_type: environment.resolutionImageType.imageWithoutTitle
              }
      );
    }
    this.requestBody.name = this.name;
    this.requestBody.role = this.roles.filter(value => value.id === this.selectedRole)[0].role;

  }

  uploadImages() {
    this.validateRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.castMemberId) {
      let rqBody = Object.assign({}, environment.castMemberRqBody);
      rqBody.role = environment.castMemberRoles
          .filter(value => value.id === this.selectedRole)[0].role;
      this.commonService.save(Service.CAST_MEMBER, rqBody)
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.castMemberId = response.id;
        this.s3upload();
      });
    } else {
      this.s3upload();
    }
    this.saveBtnDisabled = true;
  }


  s3upload() {
    let uploadPromise;
    for (const cast of this.casts) {
      uploadPromise = new Promise((resolve, reject) => {
        this.s3Bucket.uploadFile(this.castMemberId, cast.file[0], environment.s3Bucket.path.castMembers,
            environment.s3Bucket.buckets.bucketPath +
            environment.s3Bucket.buckets.destinationFolder +
            environment.s3Bucket.metaDataTypeFolder.castMembers, (error, response, pr) => {
              if (error) {
                reject({status: false, response: error});
              }
              cast.imageLocation = response.Location;
              resolve({status: true, response: response});
            }).on('httpUploadProgress', (progress) => {
          cast.progress = Math.round(progress.loaded / progress.total * 100);
        });
      });
      this.promiseArray.push(uploadPromise);
    }
    Promise.all(this.promiseArray).then(value => {
      if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
        this.save();
        return;
      }
      this.alert.errorAlert(environment.s3Bucket.uploadError.title,
          environment.s3Bucket.uploadError.message);
    });
  }

  save() {
    this.createRequestBody();
    this.commonService.update(Service.CAST_MEMBER, this.castMemberId, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          this.savedCastMember = result;
      this.alert.successAlert(environment.alerts.success.castMember.title,
          environment.alerts.success.castMember.message).then(value => {
            this.model.close(this.savedCastMember);
      });
      this.saveBtnDisabled = false;
    }, error2 => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(environment.alerts.error.castMember.title,
          environment.alerts.error.castMember.message);
    });
  }

  @HostListener('document:keydown.Enter', ['$event']) onKeydownHandlerEnter(event: KeyboardEvent) {
    this.uploadImages();
  }
}
