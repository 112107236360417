import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'app-live-channel-tab',
  templateUrl: './live-channel-tab.component.html',
  styleUrls: ['./live-channel-tab.component.scss']
})
export class LiveChannelTabComponent implements OnInit {
  @Input() template;
  @Input() isDetailView = false;
  env = environment;
  constructor() { }

  ngOnInit() {
  }

}
