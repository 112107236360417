import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {environment} from '../../../../../../environments/environment';
import {EventService} from '../../../../../core/service/event.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {Alerts} from '../../../../../alert/Alert';
import {Subject} from 'rxjs';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {PublishVideoModelComponent} from '../publish-video-model/publish-video-model.component';

@Component({
  selector: 'app-media-video-model',
  templateUrl: './media-video-model.component.html',
  styleUrls: ['./media-video-model.component.scss']
})
export class MediaVideoModelComponent implements OnInit, OnDestroy {

  env = environment;
  videoId;
  title;
  getContentIdSubscription;
  initialData;
  publishStatus;
  publishButton = environment.masterDataStatus.published;
  isEditButtonDisable = false;
  destroy$ = new Subject();
  editToggle = false;

  constructor(private model: MatDialogRef<MediaVideoModelComponent>,
              private eventService: EventService,
              private commonServiceService: CommonServiceService,
              private matModel: MatDialog,
              private alert: Alerts,
              private service: CommonService,
              @Inject(MAT_DIALOG_DATA) private data) {
    this.initialData = data;
  }

  ngOnInit() {
    this.videoId = this.initialData.videoId;
    this.title = this.initialData.videoTitle;
    this.publishStatus = this.initialData.publishStatus;
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        if (response.id) {
          this.videoId = response.id;
        }

        if (response.title) {
          this.title = response.title;
        }
      }
    });
    if (this.videoId) {
      this.sendIdToTab();
    }
    if (this.publishStatus === environment.contentStatus.published) {
      this.publishButton = environment.masterDataStatus.unpublished;
    }

    if (this.publishStatus === environment.contentStatus.unpublished) {
      this.publishButton = environment.masterDataStatus.published;
    }
  }
  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
    this.editToggle = false;
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  closeWindow() {
    this.eventService.sendEditDetails({viewEnable: false});
    this.model.close();
  }

  getEventData() {
    this.eventService.sendContentIdToTab({id: this.videoId});
  }

  publishMovie() {
    if (!this.videoId) {
      return;
    }
    if (this.publishButton === this.env.masterDataStatus.published) {
      this.commonServiceService.findAllWizardStatus(this.videoId, environment.contentType.video)
          .subscribe(response => {

            let matDialogRef = this.matModel.open(PublishVideoModelComponent, {
              disableClose: true,
              autoFocus: false,
              maxWidth: '100vw',
              maxHeight: '100vh',
              height: '90%',
              width: '90%',
              data: {
                id: this.videoId,
                title: this.title
              }
            });
            matDialogRef.afterClosed().subscribe(value => {
              this.closeWindow();
            });
          }, error => {
            if (!error.error.data.metadata_status) {
              this.alert.errorAlert(this.env.publish.error.movie.contentWizadMetaData, '');
            }
            if (!error.error.data.videos_status) {
              this.alert.errorAlert(this.env.publish.error.movie.contentWizadVideo, '');
            }
            // if (!error.error.data.monetization_status) {
            //   this.alert.errorAlert(this.env.publish.error.movie.contentWizadMonetization, '');
            // }
            if (!error.error.data.posters_status) {
              this.alert.errorAlert(this.env.publish.error.movie.contentWizadPoster, '');
            }
          });
    }
    if (this.publishButton === environment.masterDataStatus.unpublished) {
      // TODO Remove above, uncomment these things and complete

      // this.isEditButtonDisable = true;
      // this.eventService.sendEditDetails({viewEnable: true});
      // this.publishButton = this.env.masterDataStatus.published;
      this.service.unPublish(Service.TV_EPISODE.UNPUBLISH, this.videoId, {country_id: 0, un_publish_general_rule: true})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.alert.successAlert(environment.publish.success.movie.unpublishTitle, environment.publish.success.movie.unpublishMessage);
        this.isEditButtonDisable = true;
        this.eventService.sendEditDetails({viewEnable: true});
        this.publishButton = this.env.masterDataStatus.published;
      });
    }

  }


  sendIdToTab() {
    this.eventService.sendContentIdToTab({id: this.videoId});
  }

  editTvShow() {

    if (this.publishButton === this.env.masterDataStatus.unpublished || this.publishStatus === this.env.contentStatus.processed) {
      this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
          environment.alerts.success.movie.unPublish.confirmButton).then(value => {
        if (value.isConfirmed) {
          this.isEditButtonDisable = true;
          this.service.unPublish(Service.TV_EPISODE.UNPUBLISH, this.videoId, {country_id: 0, un_publish_general_rule: true})
              .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            this.eventService.sendEditDetails({viewEnable: true});
            this.publishButton = this.env.masterDataStatus.published;
          });
        }
      });
    }


    if (this.publishButton === this.env.masterDataStatus.published) {
      this.eventService.sendEditDetails({viewEnable: true});
    }
  }
}
