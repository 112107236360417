import { Injectable } from '@angular/core';
import {HttpService} from '../../http.service';
import {environment} from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MovieService {

  constructor(private http: HttpService) { }

  validateSlug(slug) {
    return this.http.get<any>(environment.serverurl + '/movies/slugs?slug=' + slug);
  }

  findAllByStatus(options: any) {
    return this.http.get<any>(environment.serverurl + '/movies/status/?' + options.status + '&page='
        + options.page + '&size=' + options.size + '&maturity-rating=' + options.maturityRating);
  }

  unPublish(id, body) {
    return this.http.put<any>(environment.serverurl + '/contents/un-publish/' + id, body);
  }

  updateProgress(body) {
    return this.http.post<any>(environment.serverurl + '/movies/media-convert/progress', body);
  }

  findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating) {
    return this.http.get<any>(environment.serverurl + '/movies/search?searchTerm=' + searchTerm
         + '&contentType=' + contentStatus + '&page=' + page + '&size=' + size + '&maturity-rating=' + maturityRating);
  }
}
