import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentDetailService {

  constructor(private http: HttpService) { }

  save(body) {
    return  this.http.post<any>(environment.serverurl + '/content-details/', body);
  }

  deleteContentById(id) {
    return this.http.delete<any>(environment.serverurl + '/content-details/' +id);
  }

  contentByFilters(contentType, options) {
    let queryString = '';
    if (options.createdBy) {
      queryString = '&created-by=' + options.createdBy;
    }

    if (options.uploadedBy) {
      queryString = queryString + '&uploaded-by=' + options.uploadedBy;
    }

    if (options.greaterThanReleasedDateAndTime) {
      queryString = queryString + '&greater-than-release-date-time=' + options.greaterThanReleasedDateAndTime +
        '&less-than-release-date-time=' + options.lessThanReleasedDateAndTime;
    }

    if (options.greaterThanPublishedDateAndTime) {
      queryString = queryString + '&greater-than-publish-date-time=' + options.greaterThanPublishedDateAndTime +
        '&less-than-publish-date-time=' + options.lessThanPublishedDateAndTime;
    }

    if (options.videoChannel) {
      queryString = queryString + '&content-id=' + options.videoChannel;
    }

    if (options.tvShow) {
      queryString = queryString + '&content-id=' + options.tvShow;
    }

    if (options.season) {
      queryString = queryString + '&season-id=' + options.season;
    }

    if (options.playList) {
      queryString = queryString + '&playlist-id=' + options.playList;
    }

    if (options.payToWatch) {
      queryString = queryString + '&free-to-watch=' + options.payToWatch;
    }

    if (options.freeToWatch) {
      queryString = queryString + '&pay-to-watch=' + options.freeToWatch;
    }

    if (options.contentStatus) {
      queryString = queryString + '&status=' + options.contentStatus;
    }

    if (options.isCategoryFilter) {
      queryString = queryString + '&is-category-filter=true';
    }

    if (options.keywordId) {
      queryString = queryString + '&keyword=' + options.keywordId;
    }

    if (options.genreId) {
      queryString = queryString + '&genre=' + options.genreId;
    }

    if (options.producerId) {
      queryString = queryString + '&producer=' + options.producerId;
    }

    if (options.directorId) {
      queryString = queryString + '&director=' + options.directorId;
    }

    if (options.actorId) {
      queryString = queryString + '&actor=' + options.actorId;
    }

    if (options.runTime) {
      queryString = queryString + '&run-time=' + options.runTime;
    }

    if (options.multipleContentStatus) {
      queryString = queryString + '&status=' + environment.contentStatus.published +
        '&status=' + environment.contentStatus.scheduled;
    }
    switch (contentType) {
      case environment.contentType.tvEpisode:
        return this.http.get<any>(environment.serverurl + '/tv-episodes/filters?' + 'page='
          + options.page + '&size='  +  options.size + queryString);
      case environment.contentType.video:
        return this.http.get<any>(environment.serverurl + '/videos/filters?' + 'page='
          + options.page + '&size='  +  options.size + queryString);

    }
  }

  findKeywords(contentType, page, size) {
    return this.http.get<any>(environment.serverurl +
      '/content-details/content-detail-keyword/' + contentType + '/keywords/?page=' + page + '&size=' + size);
  }

  findGenres(contentType, page, size) {
    return this.http.get<any>(environment.serverurl +
      '/content-details/content-detail-genres/' + contentType + '/genres/?page=' + page + '&size=' + size);
  }

  findCatMembers(contentType, role, page, size) {
    return this.http.get<any>(environment.serverurl +
      '/content-details/' + contentType + '/cast-members/' + role + '?page=' + page + '&size=' + size);
  }
}
