import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input()
  filters: any [] = [];
  filteredList: any [] = [];
  isVisible = true;
  dateRange;
  selectedDates: any [] = [];
  constructor() { }

  ngOnInit() {
  }

  addSelectFilter( selectedValue, filterName, filterType ) {
    const  selectedFilter: any = this.filteredList.find(( filter ) => filter.filterName === filterName );
    if ( !selectedFilter ) {
      const value: any []  [] = selectedValue;
      this.filteredList.push( {
        selectedValues : [value],
        filterName,
        filterType
      } );
    } else {
      selectedFilter.selectedValues.push( selectedValue );
    }
  }

  removeSelectedFilter( selectedValue, filterName ) {
    const  selectedFilter: any = this.filteredList.find(( filter ) => filter.filterName === filterName );

    const matSnackBarIndex =  selectedFilter.selectedValues.findIndex((value ) => value.id === selectedValue.value.id );

    selectedFilter.selectedValues.splice( matSnackBarIndex, 1);


    if ( selectedFilter.selectedValues.length <= 0 ) {
      this.filteredList.splice(this.filteredList.findIndex(( filter ) => filter.filterName === filterName ), 1)
    }
  }

  removeFilter( removedFilter, filter ) {
    const removedFilters: any =  this.filters.find( ( selectedFilters ) => selectedFilters.filterName === filter.filterName);

    if ( filter.filterType === 'date-range') {
      removedFilters.selectedValues = null;

      const index =   this.filteredList.findIndex(( fil ) => fil.filterName === filter.filterName);

      this.filteredList.splice(index, 1);

      this.refreshFilters();

      return;
   } else {
     const filterIndex = removedFilters.selectedValues.findIndex((value ) => value === removedFilter.id );

     removedFilters.selectedValues.splice( filterIndex, 1);
   }
    const  removeFilterListArray =  this.filteredList.find(( selectedFilters ) => selectedFilters.filterName === filter.filterName );

    const matSnackBarIndex =  removeFilterListArray.selectedValues.findIndex((value ) => value.id === removedFilter.id );

    removeFilterListArray.selectedValues.splice( matSnackBarIndex, 1);

    if ( removeFilterListArray.selectedValues.length <= 0 ) {
      this.filteredList.splice(this.filteredList.findIndex(( filters ) => filters.filterName === filter.filterName ), 1)
    }
    this.refreshFilters();
  }

  selectDateRange( event, filterName, filterType , placeHolder) {
    if ( event.selectedDates.length >= 2 ) {
      this.selectedDates.push( event );
      const dateRange = {
        name: placeHolder + ' in ' + event.dateString,
        id: 0
      };
      const  selectedFilter: any = this.filteredList.find(( filter ) => filter.filterName === filterName );
      if ( !selectedFilter ) {
        this.filteredList.push( {
          selectedValues : [dateRange],
          filterName,
          filterType,
          value: event.selectedDates,
        } );
      } else {
        selectedFilter.selectedValues[0] = dateRange;
      }
    }
  }
  refreshFilters() {
    this.isVisible = false;
    setTimeout(() => {
      this.isVisible = true;
    },  0);
  }
  test() {
  }
  applyGTLTFilter( filterName, filterType , placeHolder, dropDown ) {
    const  selectedFilter: any = this.filters.find(( filter ) => filter.filterName === filterName );
    const  selectedFilterFromMatChips: any = this.filteredList
        .find(( filter ) => filter.filterName === filterName && filter.filterType === filterType );
    if ( selectedFilter.selectedValues.length > 0 ) {
      if ( selectedFilter.selectedValues [0] ) {
        const gtFilter = {
          name: placeHolder + ' > ' + selectedFilter.selectedValues[0],
          id: 0
        };

        if ( !selectedFilterFromMatChips ) {
          this.filteredList.push({
            selectedValues : [gtFilter],
            filterName,
            filterType,
            value: +selectedFilter.selectedValues[0],
          });
        } else {
          selectedFilterFromMatChips.selectedValues[0] = gtFilter;
        }

      }
      if ( selectedFilter.selectedValues [1]) {

        const ltFilter = {
          name: placeHolder + ' < ' + selectedFilter.selectedValues[1],
          id: 1
        };
        if ( !selectedFilterFromMatChips ) {
          this.filteredList.push({
            selectedValues : [ltFilter],
            filterName,
            filterType,
            value: +selectedFilter.selectedValues[1],
          });
        } else {
          selectedFilterFromMatChips.selectedValues[1] = ltFilter;
        }
      }
    }

    // if ( selectedFilterFromMatChips && !selectedFilter.selectedValues [0] ) {
    //   selectedFilterFromMatChips.selectedValues.splice(0, 0);
    // }
    //
    // if ( selectedFilterFromMatChips && !selectedFilter.selectedValues [1]  ) {
    //   selectedFilterFromMatChips.selectedValues.splice(1, 1);
    // }

    dropDown.close();
  }
}
