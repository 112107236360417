import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {UpperCasePipe} from '@angular/common';
import {ProgrammeService} from '../../../../../core/service/common_service/ http/programme/programme.service';
import {ProgrammeScheduleComponent} from '../programme-schedule/programme-schedule.component';
import {LiveChannelService} from '../../../../../core/service/common_service/ http/contents/live-channel/live-channel.service';
const moment = require('moment');
@Component({
  selector: 'app-add-programme-schedule',
  templateUrl: './add-programme-schedule.component.html',
  styleUrls: ['./add-programme-schedule.component.scss']
})
export class AddProgrammeScheduleComponent implements OnInit, OnDestroy {
  env = environment;
  IMAGE = 4;
  file: File[] = [];
  programmeSchedule: any[] = [{file: this.file}];
  programmeScheduleImageValidationMessage;
  saveBtnDisabled = false;
  isImageValidated = true;
  destroy$ = new Subject();
  isFormValid = true;
  requestBody: any = {};
  startTime;
  isStartTimeValidated = true;
  startTimeValidationMessage;
  endTime;
  isEndTimeValidated = true;
  endTimeValidationMessage;
  START_TIME = 5;
  END_TIME = 6;
  PROGRAMME = 7;
  selectedProgramme;
  isProgrammeValidated = true;
  programmeValidationMessage;
  duration;
  DURATION = 8;
  isDurationValidated = true;
  durationValidationMessage;
  title;
  TITLE = 9;
  isTitleValidated = true;
  titleValidationMessage;
  description;
  DESCRIPTION = 10;
  isDescriptionValidated = true;
  descriptionValidationMessage;
  programmes: any[] = [];
  currentProgrammePage = 0;
  totalProgrammes;
  programmesClone;
  programmeScheduleId;
  selectedLiveChannel;
  startDate;
  START_DATE = 11;
  isStartDateValidated = true;
  startDateValidationMessage;
  initialData;
  liveData;
  isScheduleBtnDisable = false;
  constructor(private model: MatDialogRef<AddProgrammeScheduleComponent>,
              private commonService: CommonService,
              private liveChannelService: LiveChannelService,
              private alert: Alerts,
              private s3Bucket: S3BucketService,
              private programmeService: ProgrammeService,
              private upperCase: UpperCasePipe,
              private matModel: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data) {
    this.initialData = data;
    this.selectedLiveChannel = this.initialData.liveChannelId;
  }

  ngOnInit() {
    this.getProgrammes();
    this.isScheduleBtnDisable = true;
    this.getTelecastProgramsByDate(this.formatDate(new Date()));
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  resetValidation(field) {
    this.isFormValid = true;
    switch (field) {
      case this.START_TIME: {
        this.isStartTimeValidated = true;
        break;
      }
      case this.END_TIME: {
        this.isEndTimeValidated = true;
        break;
      }
      case this.PROGRAMME: {
        this.isImageValidated =  true;
        break;
      }
      case this.DURATION: {
        this.isDurationValidated = true;
        break;
      }
      case this.TITLE: {
        this.isTitleValidated = true;
        break;
      }
      case this.DESCRIPTION: {
        this.isDescriptionValidated = true;
        break;
      }
      case this.START_DATE: {
        this.isStartDateValidated = true;
        break;
      }
      case this.IMAGE: {
        this.isImageValidated =  true;
      }
    }
  }

  onSelect(event) {
    this.resetValidation(this.IMAGE);
    this.programmeSchedule[0].file  = event.addedFiles;
  }

  validatingRequiredFields() {

    if (!this.startDate) {
      this.isFormValid = false;
      this.isStartDateValidated = false;
      this.startDateValidationMessage = this.env.validations.startDateValidationMessage;
    }

    if (!this.startTime) {
      this.isFormValid = false;
      this.isStartTimeValidated = false;
      this.startTimeValidationMessage = this.env.validations.startTimeValidationMessage;
    }

    if (!this.endTime) {
      this.isFormValid = false;
      this.isEndTimeValidated = false;
      this.endTimeValidationMessage = this.env.validations.endTimeValidationMessage;
    }

    // if (!this.selectedProgramme) {
    //   this.isFormValid = false;
    //   this.isProgrammeValidated = false;
    //   this.programmeValidationMessage = this.env.validations.liveChannelValidateMessage;
    // }

    if (!this.duration) {
      this.isFormValid = false;
      this.isDurationValidated = false;
      this.durationValidationMessage = this.env.validations.durationValidationMessage;
    }

    if (!this.title) {
      this.isFormValid = false;
      this.isTitleValidated = false;
      this.titleValidationMessage = this.env.validations.titleValidationMessage;
    }

    if (!this.description) {
      this.isFormValid = false;
      this.isDescriptionValidated = false;
      this.descriptionValidationMessage = this.env.validations.descriptionValidationMessage;
    }

    if (!this.selectedProgramme && this.programmeSchedule[0].file.length <= 0) {
      this.isFormValid = false;
      this.isImageValidated = false;
      this.programmeScheduleImageValidationMessage = this.env.validations.programmeScheduleImageValidationMessage;
    }
  }

  closeWindow() {
    this.model.close();
  }

  remove() {
    this.programmeSchedule[0].file = this.file;
  }

  createRequestBody() {
    this.requestBody.title = this.title;
    this.requestBody.description = this.description;
    this.requestBody.duration = this.duration;
    this.requestBody.live_channel_id = this.selectedLiveChannel;
    this.requestBody.posters = [];
    if (this.programmeSchedule[0].file.length > 0) {
      this.requestBody.posters.push(
        {
          alt_tag : this.programmeSchedule[0].file[0].name,
          device_type : this.env.deviceType.tablet,
          orientation_type : this.upperCase.transform(this.env.orientations.landscape),
          image_name : this.programmeSchedule[0].file[0].name,
          source_url : this.programmeSchedule[0].imageLocation.replace(this.env.s3Bucket.actualPath,
            this.env.s3Bucket.actualPathReplaceTo),
          image_conversion_status : this.env.conversionStatus.pending,
          image_type: this.env.resolutionImageType.imageWithoutTitle,
          resolution_type: this.env.resolutionTypes.fourX
        }
      );
    }
    if (moment(this.startDate).date() < moment(this.startTime).date()) {
      this.requestBody.is_continued = true;
    } else {
      this.requestBody.is_continued = false;
    }
    this.requestBody.programme_id = this.selectedProgramme;
    this.requestBody.start_date = new Date(this.startDate);
    this.requestBody.start_time = new Date(this.startTime);
    this.requestBody.end_time = new Date(this.endTime);
  }

  uploadImages() {
    this.validatingRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.programmeScheduleId) {
      this.commonService.save(Service.PROGRAMME_SCHEDULE, {})
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.programmeScheduleId = response.id;
        if (this.programmeSchedule[0].file.length > 0) {
          this.s3upload();
        } else {
          this.save();
        }
      });
    } else if (this.programmeSchedule[0].file.length > 0) {
      this.s3upload();
    } else {
      this.save();
    }
    this.saveBtnDisabled = true;
  }


  s3upload() {
    new Promise((resolve, reject) => {
      this.s3Bucket.uploadFile(this.programmeScheduleId, this.programmeSchedule[0].file[0], environment.s3Bucket.path.posters,
        environment.s3Bucket.buckets.bucketPath +
        environment.s3Bucket.buckets.destinationFolder +
        environment.s3Bucket.contentTypeFolder.programmeSchedule, (error, res, pr) => {
          if (error) {
            reject({status: false, response: error});
          }
          this.programmeSchedule[0].imageLocation = res.Location;
          resolve({status: true, response: res});
        }).on('httpUploadProgress', (progress) => {
        this.programmeSchedule[0].progress = Math.round(progress.loaded / progress.total * 100);
      });
    }).then((value: any) => {
      if (value.status) {
        this.save();
        return;
      }
    }).catch(reason => {
      this.alert.errorAlert(environment.s3Bucket.uploadError.title,
        environment.s3Bucket.uploadError.message);
    });
  }

  save() {
    this.createRequestBody();
    this.commonService.update(Service.PROGRAMME_SCHEDULE, this.programmeScheduleId, this.requestBody)
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.alert.successAlert(environment.alerts.success.live_channel.programme.title,
        environment.alerts.success.live_channel.programme.message).then(value => {
      });
      this.saveBtnDisabled = false;
      this.model.close();
    }, error2 => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(environment.alerts.error.live_channel.programme.title,
        environment.alerts.error.live_channel.programme.message);
    });
  }

  getProgrammes() {
    this.programmeService.findAllByLiveChannel(this.selectedLiveChannel, {
      page: this.currentProgrammePage,
      size: this.env.size
    })
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalProgrammes = response.total;
      this.programmes = response.programmes;
      this.programmesClone = this.programmes;
    });
  }

  getTelecastProgramsByDate(date) {

    this.liveChannelService.findAllWithProgrammes( this.selectedLiveChannel, date)
      .subscribe(
        (res) => {
          this.liveData = res;
          this.isScheduleBtnDisable = false;
        }, error => {
          this.isScheduleBtnDisable = false;
        }
      );
  }

  openSchedule() {
    const matDialogRef = this.matModel.open(ProgrammeScheduleComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '50%',
      width: '90%',
      data: {
        liveChannelId: this.initialData.liveChannelId,
        liveData: this.liveData
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
    });
  }

}
