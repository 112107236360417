import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {environment} from '../../../../../../environments/environment';
import {EventService} from '../../../../../core/service/event.service';
import {PublishMovieModelComponent} from '../../../movies/model/publish-movie-model/publish-movie-model.component';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {Alerts} from '../../../../../alert/Alert';
import {PublishTvEpisodeModelComponent} from '../publish-tv-episode-model/publish-tv-episode-model.component';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Service} from "../../../../../core/service/common_service/services";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-tv-episode-model',
  templateUrl: './tv-episode-model.component.html',
  styleUrls: ['./tv-episode-model.component.scss']
})
export class TvEpisodeModelComponent implements OnInit, OnDestroy {

  env = environment;
  tvEpisodeId;
  title;
  getContentIdSubscription;
  initialData;
  publishStatus;
  publishButton = environment.masterDataStatus.published;
  isEditButtonDisable = false;
  destroy$ = new Subject();
  editToggle = false;

  constructor(private model: MatDialogRef<TvEpisodeModelComponent>,
              private eventService: EventService,
              private commonServiceService: CommonServiceService,
              private matModel: MatDialog,
              private alert: Alerts,
              private service: CommonService,
              @Inject(MAT_DIALOG_DATA) private data) {
    this.initialData = data;
  }

  ngOnInit() {
    this.tvEpisodeId = this.initialData.tvEpisodeId;
    this.title = this.initialData.tvEpisodeTitle;
    this.publishStatus = this.initialData.publishStatus;
    console.log('methana ' , this.publishStatus);
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        if (response.id) {
          this.tvEpisodeId = response.id;
        }

        if (response.title) {
          this.title = response.title;
        }
      }
    });
    if (this.tvEpisodeId) {
      this.sendIdToTab();
    }
    if (this.publishStatus === environment.contentStatus.published) {
      this.publishButton = environment.masterDataStatus.unpublished;
    }

    if (this.publishStatus === environment.contentStatus.unpublished) {
      this.publishButton = environment.masterDataStatus.published;
    }
  }
  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
    this.editToggle = false;
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  closeWindow() {
    this.eventService.sendEditDetails({viewEnable: false});
    this.model.close();
  }

  getEventData() {
    console.log('tv show model: ', this.tvEpisodeId);
    this.eventService.sendContentIdToTab({id: this.tvEpisodeId});
  }

  publishMovie() {
    if (!this.tvEpisodeId) {
      return;
    }
    if (this.publishButton === this.env.masterDataStatus.published) {
      this.commonServiceService.findAllWizardStatus(this.tvEpisodeId, environment.contentType.tvEpisode)
          .subscribe(response => {

            let matDialogRef = this.matModel.open(PublishTvEpisodeModelComponent, {
              disableClose: true,
              autoFocus: false,
              maxWidth: '100vw',
              maxHeight: '100vh',
              height: '90%',
              width: '90%',
              data: {
                id: this.tvEpisodeId,
                title: this.title
              }
            });
            matDialogRef.afterClosed().subscribe(value => {
              this.closeWindow();
            });
          }, error => {
            if (!error.error.data.metadata_status) {
              this.alert.errorAlert(this.env.publish.error.movie.contentWizadMetaData, '');
            }
            if (!error.error.data.videos_status) {
              this.alert.errorAlert(this.env.publish.error.movie.contentWizadVideo, '');
            }
            // if (!error.error.data.monetization_status) {
            //   this.alert.errorAlert(this.env.publish.error.movie.contentWizadMonetization, '');
            // }
            if (!error.error.data.posters_status) {
              this.alert.errorAlert(this.env.publish.error.movie.contentWizadPoster, '');
            }
          });
    }
    if (this.publishButton === environment.masterDataStatus.unpublished) {
      // TODO Remove above, uncomment these things and complete

      // this.isEditButtonDisable = true;
      // this.eventService.sendEditDetails({viewEnable: true});
      // this.publishButton = this.env.masterDataStatus.published;
      this.service.unPublish(Service.TV_EPISODE.UNPUBLISH, this.tvEpisodeId, {country_id: 0, un_publish_general_rule: true})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.alert.successAlert(environment.publish.success.movie.unpublishTitle, environment.publish.success.movie.unpublishMessage);
        this.isEditButtonDisable = true;
        this.eventService.sendEditDetails({viewEnable: true});
        this.publishButton = this.env.masterDataStatus.published;
      });
    }

  }


  sendIdToTab() {
    this.eventService.sendContentIdToTab({id: this.tvEpisodeId});
  }

  editTvShow() {

      if (this.publishButton === this.env.masterDataStatus.unpublished || this.publishStatus === this.env.contentStatus.processed) {
        this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
            environment.alerts.success.movie.unPublish.confirmButton).then(value => {
          if (value.isConfirmed) {
            this.isEditButtonDisable = true;
            this.service.unPublish(Service.TV_EPISODE.UNPUBLISH, this.tvEpisodeId, {country_id: 0, un_publish_general_rule: true})
                .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
              this.eventService.sendEditDetails({viewEnable: true});
              this.publishButton = this.env.masterDataStatus.published;
            });
          }
        });
      }


      if (this.publishButton === this.env.masterDataStatus.published) {
        this.eventService.sendEditDetails({viewEnable: true});
      }
    }
}
