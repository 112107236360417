import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TvShowModelComponent} from './model/tv-show-model/tv-show-model.component';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';
import {CommonService} from '../../../core/service/common_service/common.service';
import {Service} from '../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {TitleCasePipe} from '@angular/common';
import {TvEpisodeListComponent} from './model/tv-episode-list/tv-episode-list.component';
import {TvShowService} from '../../../core/service/common_service/ http/contents/tv-shows/tv-show.service';
import {Alerts} from '../../../alert/Alert';
import {CmsUserService} from '../../../core/service/common_service/ http/cms-user/cms-user.service';
import {ContentService} from '../../../core/service/common_service/ http/contents/content.service';
import {PackageTierService} from '../../../core/service/common_service/ http/packege-tier/package-tier.service';

@Component({
  selector: 'app-tv-shows',
  templateUrl: './tv-shows.component.html',
  styleUrls: ['./tv-shows.component.scss']
})
export class TvShowsComponent implements OnInit, OnDestroy {
  env = environment;
  destroy$ = new Subject();
  selectedPage: number;
  tableData: any[] = [];
  selectedTab;
  loading = false;
  seasons: any[] = [];
  selectedTvShow: any;
  isButtonEnable = true;
  searchTerm: any;
  deleteSubject: Subject<boolean> = new Subject();
  searchTv: any;
  pageCount = 1;
  title: any = {};
  cmsUsers: any[] = [];
  columnData: any[] = [];
  packages: any[] = [];
  constructor(private matModel: MatDialog, private commonService: CommonService, private alert: Alerts,
              private cmsUserService: CmsUserService,
              private contentService: ContentService,
              private titleCasePipe: TitleCasePipe, private tvShowService: TvShowService,
              private packageService: PackageTierService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.selectedPage = 1;
    this.selectedTab = this.env.contentStatus.published;
    this.loadAllPackages();
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchTvShow();
    } else {
      this.findTvShows();
    }
    this.loadAllCmsUsers(this.env.cmsUserAction.create);
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadFilterData(action) {
    const filterDataArray = [];
    const modifiedUserData = this.cmsUsers.map(val => {
      return {
        id: val.id,
        value: val.name
      };
    });
    let selectedField;

    if (action === this.env.cmsUserAction.create) {
      selectedField = this.env.unPublishedTvShowColumns[5].field;
    }

    filterDataArray.push({
      field: selectedField,
      data: modifiedUserData
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllCmsUsers(action) {
    this.loading = true;
    this.cmsUserService.findAllContentsCmsUsers({status: action})
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.cmsUsers = response;
      this.loadFilterData(action);
      this.loading = false;
    });
  }

  loadAllPackages() {
    this.loading =  true;
    this.packageService.findAllPackageTiers()
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.packages = response;
      this.loadPackageFilterData();
      this.loading = false;
    });
  }

  loadPackageFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.packages.map(val => {
      return {
        id: val.id,
        value: val.package_type
      };
    });

    modifiedUserData.splice(2, 1);
    const payToWatch = this.env.publishedTvShowColumns[3].field;
    const freeToWatch = this.env.publishedTvShowColumns[4].field;

    filterDataArray.push({
      field: payToWatch,
      data: modifiedUserData
    });

    filterDataArray.push({
      field: freeToWatch,
      data: modifiedUserData
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  addNewTvShow() {
    this.cd.detach();
    const matDialogRef = this.matModel.open(TvShowModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: false
      }
    });

    matDialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.cd.reattach();
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchTvShow();
      } else {
        this.findTvShows();
      }
    });
  }

  selectTab(event) {
    let action;
    switch (event.tab.textLabel.toUpperCase()) {
      case this.env.contentStatus.published: {
        action = this.env.cmsUserAction.create;
        this.loadAllPackages();
        this.selectedTab = this.env.contentStatus.published;
        break;
      }
      case this.env.contentStatus.scheduled: {
        this.selectedTab = this.env.contentStatus.scheduled;
        break;
      }
      default: {
        action = this.env.cmsUserAction.create;
        this.selectedTab = this.env.contentStatus.unpublished;
      }
    }
    this.tableData = [];
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchTvShow();
    } else {
      this.findTvShows();
    }
    if (action) {
      this.loadAllCmsUsers(action);
    }
  }

  findTvShows() {
    this.loading = true;
    this.commonService.findAll(Service.TV_SHOW.STATUS,
        {status: environment.contentStatusString + this.selectedTab,
      contentType: environment.contentType.tvShow,
      page: this.selectedPage - 1,
      size: this.env.tableProperties.size})
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.processTvShowData(response);
        });

  }

  setPage(event) {
    this.selectedPage = event;
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchTvShow();
    } else {
      this.findTvShows();
    }

  }
  openDetailView(event, status) {

    this.title.english = event.selectedData.englishTitle;
    this.cd.detach();
    const matDialogRef = this.matModel.open(TvShowModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: true,
        tvShowId: event.selectedData.id,
        tvShowTitle: this.title,
        publishStatus: status
      }
    });


    matDialogRef.afterClosed().subscribe(value => {
      this.cd.reattach();
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchTvShow();
      } else {
        this.findTvShows();
      }
    });
  }
  getTvShowSeasons() {
    this.tvShowService.seasonsById(this.selectedTvShow)
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.seasons = response.data;
    });

  }

  buttonClickView(event) {
    this.selectedTvShow = event.selectedData.id;
    const promis = new Promise((resolve, reject) => {
      this.tvShowService.seasonsByIdWithoutPagination({
        id: this.selectedTvShow,
        page: 0,
        size: 20
      })
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.seasons = response.data;
        resolve(true);
      });

    });
    promis.then((value) => {
      if (value) {
        this.isButtonEnable = false;
        this.cd.detach();
        const matDialogRef = this.matModel.open(TvEpisodeListComponent, {
          disableClose: true,
          autoFocus: false,
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          data: {
            isDetailView: false,
            tvShowId: event.selectedData.id,
            seasons: this.seasons,
            selectedTvShow: this.selectedTvShow

          }
        });

        matDialogRef.afterClosed().subscribe(value1 => {
          this.cd.reattach();
          this.isButtonEnable = true;
          this.seasons.push(value1);
          this.seasons = [... this.seasons];
        });
      }
    });
  }

  deleteContent(event) {
    this.alert.confirm(null, environment.alerts.success.movie.deleteMovie.confirmationMessage,
        environment.alerts.success.movie.deleteMovie.confirmButton).then(value => {
      if (value.isConfirmed) {
        this.commonService.delete(Service.MOVIE, event.selectedData.id).subscribe(val => {
          this.deleteSubject.next(true);
        });
      }
    });
  }

    searchTvShow() {
      this.loading = true;
      this.commonService.search(Service.TV_SHOW, this.selectedTab, this.searchTerm,
        this.selectedPage - 1, this.env.tableProperties.size, 'MATURE')
            .pipe(takeUntil(this.destroy$))
            .subscribe(response => {
              this.processTvShowData(response);
            }, error => {
              this.loading = false;
            });
    }

    processTvShowData(response) {
    let arr;
    if (response.content_by_multiple_status) {
      arr = response.content_by_multiple_status;
    } else {
      arr = response.contents_by_status;
    }
    this.pageCount = (response.total) / this.env.tableProperties.size * 10;

    this.tableData = arr.map((content) => {

        if (content.title) {
          content.englishTitle = content.title.english;
        }

        if (content.title) {
          content.sinhalaTitle = content.title.sinhala;
        }

        if (content.title) {
          content.tamilTitle = content.title.tamil;
        }

        if (content.created_by) {
          content.createdBy = content.created_by.name;
        }

        if (content.uploaded_by) {
          content.uploadedBy = content.uploaded_by.name;
        }

        if (content.published_by) {
          content.publishedBy = content.uploaded_by.name;
        }

        if (content.poster) {
          content.poster = {
            isPoster: true,
            url: content.poster.source_url
          };
        }

        if (content.monetization && content.monetization.plans.length > 0) {
          let payToWatchPlans;
          payToWatchPlans = content.monetization.plans.map((plan) => {
            return this.titleCasePipe.transform(plan.package_type);
          });

          content.payToWatch = payToWatchPlans.join();
        }

        if (content.monetization && content.monetization.free_to_watch.length > 0) {
          let freeToWatchPlans;
          freeToWatchPlans = content.monetization.free_to_watch.map((plan) => {
            return this.titleCasePipe.transform(plan);
          });

          content.freeToWatch = freeToWatchPlans.join();
        }

        if (content.published_date) {

          let date = new Date(content.published_date),
              month = '' + (date.getMonth() + 1),
              day = '' + date.getDate(),
              year = date.getFullYear();

          if (month.length < 2) { month = '0' + month; }
          if (day.length < 2) { day = '0' + day; }

          content.published_date = [year, month, day].join('-');
        }

        return content;
      });

    this.loading = false;
    }

    findAllByFilter(filterData) {
      let publishedDateRange = [];
      let releasedDateRange = [];
      let createdDateRange = [];
      const queryObj = {
        createdBy: null,
        uploadedBy: null,
        payToWatch: null,
        freeToWatch: null,
        contentStatus: this.selectedTab,
        page: this.selectedPage - 1,
        size: this.env.size,
        lessThanCreatedDateAndTime: null,
        greaterThanCreatedDateAndTime: null,
        lessThanReleasedDateAndTime: null,
        greaterThanReleasedDateAndTime: null,
        lessThanPublishedDateAndTime: null,
        greaterThanPublishedDateAndTime: null
      };

      filterData.forEach(filter => {
        if (filter.field === this.env.unPublishedTvShowColumns[5].field) {
          const filteredCmsUsers = this.cmsUsers.filter(val => val.id === filter.value);
          if (filteredCmsUsers.length > 0) {
            queryObj.createdBy = filteredCmsUsers[0].name;
          }
        }

        if (filter.field === this.env.unPublishedTvShowColumns[6].field) {
          createdDateRange = filter.value.split(' to ');
          if (createdDateRange.length > 1) {
            queryObj.greaterThanCreatedDateAndTime = createdDateRange[0].concat(' ') + '00:00:00';
            queryObj.lessThanCreatedDateAndTime = createdDateRange[1].concat(' ') + '00:00:00';
          }
        }

        if (filter.field === this.env.publishedTvShowColumns[5].field) {
          publishedDateRange = filter.value.split(' to ');
          if (publishedDateRange.length > 1) {
            queryObj.greaterThanPublishedDateAndTime = publishedDateRange[0].concat(' ') + '00:00:00';
            queryObj.lessThanPublishedDateAndTime = publishedDateRange[1].concat(' ') + '00:00:00';
          }
        }

        if (filter.field === this.env.scheduledTvShowColumns[6].field) {
          releasedDateRange = filter.value.split(' to ');
          if (releasedDateRange.length > 1) {
            queryObj.greaterThanReleasedDateAndTime = releasedDateRange[0];
            queryObj.lessThanReleasedDateAndTime = releasedDateRange[1];
          }
        }

        if (filter.field === this.env.publishedMovieColumns[3].field) {
          const filteredPackages = this.packages.filter(val => val.id === filter.value);
          if (filteredPackages.length > 0) {
            queryObj.payToWatch = filteredPackages[0].package_type;
          }
        }

        if (filter.field === this.env.publishedMovieColumns[4].field) {
          const filteredPackages = this.packages.filter(val => val.id === filter.value);
          if (filteredPackages.length > 0) {
            queryObj.freeToWatch = filteredPackages[0].package_type;
          }
        }

      });
      if (queryObj.createdBy
        || queryObj.greaterThanPublishedDateAndTime
        || queryObj.greaterThanCreatedDateAndTime
        || queryObj.freeToWatch
        || queryObj.payToWatch
        || queryObj.greaterThanReleasedDateAndTime) {
        if ((releasedDateRange.length === 1) && (releasedDateRange[0] !== '')) {
          return;
        }

        if ((publishedDateRange.length === 1) && (publishedDateRange[0] !== '')) {
          return;
        }

        if ((createdDateRange.length === 1) && (createdDateRange[0] !== '')) {
          return;
        }

        this.loadDataByFilters(queryObj);
      } else if (filterData.filter(filter => filter.value).length <= 0) {
        if (this.searchTerm && this.searchTerm.length >= 0) {
          this.searchTvShow();
        } else {
          this.findTvShows();
        }
      }
    }

    loadDataByFilters(queryObj) {
      this.loading = true;
      this.contentService.contentByFilters(this.env.contentType.tvShow, queryObj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
        this.tableData = [];
        this.processTvShowData(response);
        this.loading = false;
      }, error => {
        console.log(error);
        this.loading = false;
      });
    }

  onKeyPress() {
    if (!this.searchTerm) {
      this.searchTvShow();
    }
  }
}
