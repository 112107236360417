import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-payment-detail-view',
  templateUrl: './payment-detail-view.component.html',
  styleUrls: ['./payment-detail-view.component.scss']
})
export class PaymentDetailViewComponent implements OnInit {

  paymentDetails: any;
  constructor(private model: MatDialogRef<PaymentDetailViewComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.paymentDetails = data.data;
  }

  ngOnInit() {
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  closeWindow() {
    this.model.close();
  }
}
