import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgApexchartsModule } from 'ng-apexcharts';
import {NgbProgressbarModule, NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { UIModule } from '../ui/ui.module';

import { StatchartComponent } from './statchart/statchart.component';
import { StatprogressComponent } from './statprogress/statprogress.component';
import { StatComponent } from './stat/stat.component';
import { ActivitiesComponent } from './activities/activities.component';
import { TableComponent } from './table/table.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DropDownComponent} from './drop-down/drop-down.component';
import {MatChipsModule, MatExpansionModule, MatIconModule, MatTableModule} from '@angular/material';
import { FilterComponent } from './filter/filter.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FlatpickrModule} from 'angularx-flatpickr';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { PlayerComponent } from './player/player.component';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
    // tslint:disable-next-line: max-line-length
    declarations: [StatchartComponent,
        StatprogressComponent, StatComponent,
         ActivitiesComponent, TableComponent, DropDownComponent,
        FilterComponent,
        PlayerComponent],
  imports: [
    CommonModule,
    NgApexchartsModule,
    UIModule,
    NgbProgressbarModule, NgbDropdownModule,
    FormsModule, ReactiveFormsModule, NgbModule, DragDropModule, MatExpansionModule,
    MatTableModule, MatIconModule, NgSelectModule, MatChipsModule, FlatpickrModule,
    MatCheckboxModule, ScrollingModule, MatButtonModule, MatFormFieldModule, MatDatepickerModule
  ],
    // tslint:disable-next-line: max-line-length
    exports: [StatchartComponent,
         StatprogressComponent, StatComponent,
        ActivitiesComponent, TableComponent, DropDownComponent, FilterComponent, PlayerComponent]
})
export class WidgetModule { }

