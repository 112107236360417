import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {UploadEpgComponent} from '../../model/upload-epg/upload-epg.component';
import {AddProgrammeComponent} from '../../model/add-programme/add-programme.component';
import {environment} from '../../../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {ProgrammeService} from '../../../../../core/service/common_service/ http/programme/programme.service';
import {Subject} from 'rxjs';
import {AddProgrammeScheduleComponent} from '../../model/add-programme-schedule/add-programme-schedule.component';
import {ProgrammeScheduleComponent} from '../../model/programme-schedule/programme-schedule.component';
import {LiveChannelService} from '../../../../../core/service/common_service/ http/contents/live-channel/live-channel.service';
import {UpdateProgrammeComponent} from '../../model/update-programme/update-programme.component';
import {Service} from "../../../../../core/service/common_service/services";
import {CommonService} from "../../../../../core/service/common_service/common.service";

@Component({
  selector: 'app-live-channel-programme',
  templateUrl: './live-channel-programme.component.html',
  styleUrls: ['./live-channel-programme.component.scss']
})
export class LiveChannelProgrammeComponent implements OnInit, OnDestroy {
  pageCount;
  tableData: any[] = [];
  selectedPage;
  loading = false;
  initialData;
  env = environment;
  destroy$ = new Subject();
  liveData;
  isScheduleBtnDisable = false;
  searchTerm = '';
  liveChannelId;

  constructor(private matModel: MatDialog,
              private liveChannelService: LiveChannelService,
              private programmeService: ProgrammeService,
              private commonService: CommonService,
              @Inject(MAT_DIALOG_DATA) private data) {
    this.initialData = data;
    this.liveChannelId = data.liveChannelId;
  }

  ngOnInit() {
    this.selectedPage = 1;
    this.findProgrammes();
    this.isScheduleBtnDisable = true;
    this.getTelecastProgramsByDate(this.formatDate(new Date()));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  getTelecastProgramsByDate(date) {

    this.liveChannelService.findAllWithProgrammes( this.initialData.liveChannelId, date)
      .subscribe(
        (res) => {
          this.liveData = res;
          this.isScheduleBtnDisable = false;
        }, error => {
          this.isScheduleBtnDisable = false;
        }
      );
  }

  uploadEpg() {
    const matDialogRef = this.matModel.open(UploadEpgComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '60%',
      width: '50%'
    });

    matDialogRef.afterClosed().subscribe(value => {
    });
  }

  addProgramme() {
    const matDialogRef = this.matModel.open(AddProgrammeComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '80%',
      width: '90%',
    });

    matDialogRef.afterClosed().subscribe(value => {
    });
  }

  updateProgramme(event) {
    const matDialogRef = this.matModel.open(UpdateProgrammeComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '80%',
      width: '90%',
      data: {
        data : event.selectedData,
        channelId : this.initialData.liveChannelId
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      this.findProgrammes();
    });
  }

  addSchedule() {
    const matDialogRef = this.matModel.open(AddProgrammeScheduleComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '90%',
      width: '90%',
      data: {
        liveChannelId: this.initialData.liveChannelId
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
    });
  }

  setPage(event) {
    this.selectedPage = event;
    this.findProgrammes();
  }



  findProgrammes() {
    this.loading = true;
    this.programmeService.findAllByLiveChannel(this.initialData.liveChannelId,
      {
        page: this.selectedPage - 1,
        size: this.env.tableProperties.size})
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.searchProgrammeProcess(response);
      });

  }

  searchProgrammeProcess(response) {

    this.pageCount = (response.total) / this.env.tableProperties.size * 10;

    this.tableData = response.programmes.map((programme, index) => {

      if (programme.poster) {
        programme.poster = {
          isPoster: true,
          url: programme.poster.source_url
        };
      }

      return programme;
    });

    this.loading = false;
  }

  viewSchedule() {
    const matDialogRef = this.matModel.open(ProgrammeScheduleComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        liveChannelId: this.initialData.liveChannelId,
        liveData: this.liveData
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
    });
  }


  searchProgram() {
    this.loading = true;

    this.programmeService.searchByName(this.liveChannelId, this.searchTerm, environment.page,
        environment.tableProperties.size)
          .pipe(takeUntil(this.destroy$))
          .subscribe(response => {
            this.searchProgrammeProcess(response);
          }, error => {
            this.loading = false;
          });
  }

  onKeyPress() {
    if (!this.searchTerm) {
      this.searchProgram();
    }
  }

}
