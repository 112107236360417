import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {ContentService} from '../../../../../core/service/common_service/ http/contents/content.service';
import {takeUntil} from 'rxjs/operators';
import {EventService} from '../../../../../core/service/event.service';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit, OnDestroy {
  env = environment;
  destroy$ = new Subject();
  selectedPage: number;
  tableData: any[] = [];
  loading = false;
  getContentIdSubscription;
  movieId: any;

  constructor(private contentService: ContentService, private eventService: EventService) { }

  ngOnInit() {
    this.selectedPage = 1;
    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.movieId = response.id;
      }
    });
    this.findErrors();
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  findErrors() {
    this.contentService.findErrorsById(this.movieId, this.selectedPage - 1, 10).pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.tableData = response.content_exceptions.map((exception) => {
          if (exception.created_time) {
            let date = new Date(exception.created_time),
              month = '' + (date.getMonth() + 1),
              day = '' + date.getDate(),
              year = date.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            exception.created_time = [year, month, day].join('-');
          }
          return exception;
        });
      }, error => {
        console.log(error);
      });
  }

  setPage(event) {
    this.selectedPage = event;
  }
}
