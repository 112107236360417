import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class Alerts {
    constructor() {}

    successAlert(title, message) {
        return Swal.fire({
            icon: 'success',
            title,
            text: message,
            timer: 1000,
        });
    }

    errorAlert( title, message ) {
        return Swal.fire({
            icon: 'error',
            title,
            text: message,
        });
    }

    publishedSuccessAlert(title, message) {
        return Swal.fire({
            icon: 'success',
            title,
            text: message
        });
    }

    confirm( title, message, confirmMessage, html?) {
        return Swal.fire({
            title,
            text :  message,
            // html: html,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText : confirmMessage,
            // customClass: 'swal',
        });
    }
}
