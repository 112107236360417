import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CuepointService {

  constructor(private http: HttpService) { }

  saveCuePoint(body) {
   return this.http.post(environment.serverurl + '/contents/cue-points', body);
  }
  getCuePointById(id, contentType) {
    return this.http.get(environment.serverurl + '/contents/' + id + '/cue-points?content=' + contentType);
  }
  getAllCuePoint() {
    return this.http.get(environment.serverurl);
  }
  updateCuePoint(id, body) {
    return this.http.put(environment.serverurl + '/contents/cue-points/' + id, body);
  }
  deleteCuePoint(id) {
    return this.http.delete(environment.serverurl + id);
  }
  updateContentDetailCuePoint(id, body) {
    return this.http.put(environment.serverurl + '/content-details/cue-points/' + id, body);
  }

  getContentDetailCuePoint(id, contentType) {
    return this.http.get(environment.serverurl + '/content-details/' + id + '/cue-points?content=' + contentType);
  }
}


