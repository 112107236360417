import { Injectable } from '@angular/core';
import {environment} from '../../../../../../../environments/environment';
import {HttpService} from '../../http.service';

@Injectable({
  providedIn: 'root'
})
export class VideoChannelService {

  constructor(private http: HttpService) { }


  playListsById(id, options: any) {
    return this.http.get<any>(environment.serverurl + '/playlist/' + id + '/?' + 'page='
      + options.page + '&size=' +  options.size);
  }

  findAllByStatus(options: any) {
    return this.http.get<any>(environment.serverurl + '/video-channel/status?' + options.status + '&page='
        + options.page + '&size=' +  options.size + '&maturity-rating=' + options.maturityRating);
  }

  findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating) {
    return this.http.get<any>(environment.serverurl + '/video-channel/search?searchTerm=' + searchTerm
        + '&contentType=' + contentStatus + '&page=' + page + '&size=' + size + '&maturity-rating=' + maturityRating);
  }

  findAll(options: any) {
    return this.http.get<any>(environment.serverurl + '/video-channel/?' + '&page='
      + options.page + '&size=' + options.size);
  }
}
