import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {CastMemberService} from '../../../../../core/service/common_service/ http/cast-member/cast-member.service';
import {KeyWordService} from '../../../../../core/service/common_service/ http/keyword/key-word.service';
import {DatePipe, TitleCasePipe} from '@angular/common';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {EventService} from '../../../../../core/service/event.service';
import {MatDialog} from '@angular/material/dialog';
import {GenreService} from '../../../../../core/service/common_service/ http/genre/genre.service';
import {environment} from '../../../../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Service} from '../../../../../core/service/common_service/services';
import {CastModelComponent} from '../../../../meta-data/cast/model/cast-model/cast-model.component';
import {AddNewKeywordComponent} from '../../../../meta-data/keyword/model/add-new-keyword/add-new-keyword.component';
import {AddNewGenreComponent} from '../../../../meta-data/genre/model/add-new-genre/add-new-genre.component';

@Component({
  selector: 'app-meta-data-detail-view',
  templateUrl: './meta-data-detail-view.component.html',
  styleUrls: ['./meta-data-detail-view.component.scss']
})
export class MetaDataDetailViewComponent implements OnInit, OnDestroy {
  loading = false;
  env = environment;
  file: File[] = [];
  subtitles: any[] = [];
  movieId: any;
  movieTitleInEnglish: string;
  isMovieTitleInEnglishValidated = true;
  movieTitleInEnglishValidateMessage;
  movieTitleInSinhala: string;
  movieTitleInTamil: string;
  slug: string;
  isSlugValidated = true;
  slugValidateMessage;
  description: string;
  releaseDate: any;
  isReleaseDateValidated = true;
  releaseDateValidateMessage;
  runTime: any;
  isRunTimeValidated = true;
  runTimeValidateMessage;
  selectedDirector: number[] = [];
  isDirectorValidated = true;
  directorValidateMessage;
  selectedProducer: number[] = [];
  selectedCastMembers: number[] = [];
  selectedSpecialCastMembers: number[] = [];
  genres: any [] = [];
  genresClone: any [] = [];
  selectedGenres: any [] = [];
  isSelectedGenresValidated = true;
  genresValidateMessage;
  selectedKeyWords: number[] = [];
  maturityRating: string [];
  selectedMaturityRating: string;
  isMaturityRatingValidated = true;
  maturityRatingValidateMessage;
  TITLE = 1;
  SLUG = 2;
  RELEASE_DATE = 3;
  RUNTIME = 4;
  MATURITY = 5;
  DIRECTOR = 6;
  KEY_WORD = 7;
  GENRE = 8;
  CAST = 9;
  PRODUCER = 10;
  DESCRIPTION = 11;
  SUBTITLE = 12;
  SUBTITLE_LANGUAGE = 13;
  requestBody: any = {};
  directors: any [] = [];
  directorsClone: any = [];
  producers: any [] = [];
  producersClone: any [] = [];
  casts: any [] = [];
  castsClone: any [] = [];
  keywords: any [] = [];
  keywordsClone: any [] = [];
  destroy$ = new Subject();
  // TODO make as camel case
  @ViewChild('director', null) director_reffrence: any;
  @ViewChild('producer', null) producer_reffrence: any;
  @ViewChild('genre', null) genresReffrence: any;
  @ViewChild('cast', null) cast_reffrence: any;
  @ViewChild('specialcast', null) special_cast_reffrence: any;
  @ViewChild('keyword', null) keyword_reffernce: any;
  subLanguages: string[];
  subtitleDescription = [];
  subtitleLanguageValidatedMessage;
  config: {};
  files: File[] = [];
  minDate: Date;
  emptySubFile: number [] = [];
  emptySubLanguage: number [] = [];
  subFileAddedValidationMessage;
  domain;
  getContentIdSubscription;
  isCastValidated = true;
  isSpecialCastValidated = true;
  castValidateMessage;
  isKeyWordsValidated = true;
  keyWordValidateMessage;
  isDescriptionValidated = true;
  descriptionValidateMessage;
  isProducerValidated = true;
  producerValidateMessage;
  isSlugPatternValidated = true;
  slugPatternValidateMessage;
  isFormValid = true;
  saveBtnDisabled = false;
  promiseArray: any[] = [];
  movieStatus;
  toggleLayer = true;
  editViewerEvent;
  @Input() isViewEditable;
  savedIndexes = [];
  currentProducersPage;
  currentDirectorsPage;
  currentActorsPage;
  totalProducers = 0;
  totalDirectors = 0;
  totalActors = 0;
  currentGenresPage;
  currentKeywordsPage;
  totalGenres = 0;
  totalKeywords = 0;
  castSubject: Subject<any> = new Subject<any>();
  producerSubject: Subject<any> = new Subject<any>();
  directorSubject: Subject<any> = new Subject<any>();
  genreSubject: Subject<any> = new Subject<any>();
  keywordsSubject: Subject<any> = new Subject<any>();
  @ViewChild('metaData', null) metaDataFormReference: any;


  constructor(
      private service: CommonService,
      private castMemberService: CastMemberService,
      private keyWordService: KeyWordService,
      private datePipe: DatePipe,
      private s3Bucket: S3BucketService,
      private alert: Alerts,
      private commonService: CommonServiceService,
      private eventService: EventService,
      private matModel: MatDialog,
      private genreService: GenreService,
      private titleCase: TitleCasePipe

  ) {

  }

  ngOnInit() {
    this.currentProducersPage = this.env.page;
    this.currentDirectorsPage = this.env.page;
    this.currentActorsPage = this.env.page;
    this.currentGenresPage = this.env.page;
    this.currentKeywordsPage = this.env.page;
    this.getAllDirectors();
    this.getAllProducers();
    this.getAllCast();
    this.getAllGenre();
    this.getAllKeyWords();
    this.getDomain();
    this.maturityRating = this.env.maturityRating;
    this.subLanguages = [this.titleCase.transform(this.env.metaDataSubLanguages.english),
      this.titleCase.transform(this.env.metaDataSubLanguages.sinhala),
      this.titleCase.transform(this.env.metaDataSubLanguages.tamil)];

    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.movieId = response.id;
        this.movieStatus = response;
        // this.toggleLayer = response.isEditable;
      }
   /*   if (response.isEditEnable) {
          this.toggleLayer = response.isEditEnable;
      }*/
    });

    this.editViewerEvent = this.eventService.contentViewSender.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.toggleLayer = value.viewEnable;
    });


    this.loadAllMetaData();


  }

  /**uploadFile
   * execute when component destroy
   */
  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
    this.editViewerEvent.unsubscribe();
  }

  getDomain() {
    // TODO Get this from services ts file
    this.commonService.findDomain(environment.contentType.movie).
    pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.domain = response.domain;
    });
  }

  addAnotherSub() {
    this.file = [];
    this.subtitles.push({file: this.file});
  }

  deleteSubtitle(index) {
    if (this.subtitles[index].mediaConvertStatus) {
      this.setPendingSubtitles(this.env.mediaConvertStatus.pending);
    }
    this.subtitles.splice(index, 1);
  }

  /**
   * Get all directors
   */
  getAllDirectors() {
    this.service.findAll(Service.CAST_MEMBER, {
      castMemberRole: this.env.castMembers.director,
      page: this.currentDirectorsPage,
      size: this.env.size
    })
    .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalDirectors = response.total;
      const directorsResponse = response.cast_members.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.directors = [...this.directors, ...directorsResponse];

      this.directorsClone = [...this.directorsClone, ...directorsResponse];
    });
    this.directorSubject.next(this.directors);
  }

  runtimeValidate() {
    const time = this.runTime;
    if (time <= 0) {
      this.isRunTimeValidated = false;
      this.runTimeValidateMessage = this.env.validations.invalidRuntime;
    } else {
      this.isRunTimeValidated = true;
    }
  }

  directorsKeyPress() {
    if (!this.director_reffrence.searchTerm) {
      this.directors = this.directorsClone;
      return;
    }
    this.currentDirectorsPage = 0;
    this.directors = [];
    this.searchDirectors();
  }

  searchDirectors() {
    this.castMemberService.searchCastMemberByName(this.director_reffrence.searchTerm,
      this.env.castMembers.director, this.currentDirectorsPage,
      this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.directors = [this.directors, ...response.cast_members];
    });
  }

  clearDirectorsDropDown() {
    this.directors = this.directorsClone;
  }

  getAllProducers() {
    this.service.findAll(Service.CAST_MEMBER, {
      castMemberRole: this.env.castMembers.producer,
      page: this.currentProducersPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalProducers = response.total;
      const producersResponse = response.cast_members.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.producers = [...this.producers, ...producersResponse];

      this.producersClone = [...this.producersClone, ...producersResponse];
      this.producerSubject.next(this.producers);
    });
  }

  producersKeyPress() {
    if (!this.producer_reffrence.searchTerm) {
      this.producers = this.directorsClone;
      return;
    }

    this.currentProducersPage = 0;
    this.producers = [];
    this.searchProducers();
  }

  searchProducers() {
    this.castMemberService.searchCastMemberByName(this.producer_reffrence.searchTerm,
      this.env.castMembers.producer, this.currentProducersPage,
      this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.producers = [this.producers, ...response.cast_members];
    });
  }

  clearProducersDropDown() {
    this.producers = this.producersClone;
  }

  getAllCast(  ) {
    this.service.findAll(Service.CAST_MEMBER, {
      castMemberRole: this.env.castMembers.actor,
      page: this.currentActorsPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalActors = response.total;
      const actorResponse = response.cast_members.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.casts = [...this.casts, ...actorResponse];

      this.castsClone = [...this.castsClone, ...actorResponse];
      this.castSubject.next(this.casts);
    });
  }

  castsKeyPress() {
    if (!this.cast_reffrence.searchTerm) {
      this.casts = this.castsClone;
      return;
    }
    this.currentActorsPage = 0;
    this.casts = [];
    this.searchActors(this.cast_reffrence.searchTerm);
  }

  specialCastsKeyPress() {
    if (!this.special_cast_reffrence.searchTerm) {
      this.casts = this.castsClone;
      return;
    } else {}
    this.currentActorsPage = 0;
    this.casts = [];
    this.searchActors(this.special_cast_reffrence.searchTerm);
  }

  searchActors(searchTerm) {
    this.castMemberService.searchCastMemberByName(searchTerm,
      this.env.castMembers.actor, this.currentActorsPage,
      this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.casts = [this.casts, ...response.cast_members];
    });
  }

  clearCastDropDown() {
    this.casts = this.castsClone;
  }

  getAllGenre() {
    this.service.findAll(Service.GENRE, {
      page: this.currentGenresPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalGenres = response.total;
      const genreResponse = response.genres.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.genres = [...this.genres, ...genreResponse];

      this.genresClone = [...this.genresClone, ...genreResponse];
      this.genreSubject.next(this.genres);
    });
  }
  genresKeyPress() {
    if (!this.genresReffrence.searchTerm) {
      this.genres = this.genresClone;
      return;
    }
    this.currentGenresPage = 0;
    this.genres = [];
    this.searchGenres();
  }

  searchGenres() {
    this.genreService.searchGenresByName(this.genresReffrence.searchTerm,
      this.currentGenresPage, this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.genres = [this.genres, ...response.genres];
    });
  }

  getAllKeyWords() {
    this.service.findAll(Service.KEY_WORD, {
      page: this.currentKeywordsPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalKeywords = response.total;
      const keywordResponse = response.keywords.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.keywords = [...this.keywords, ...keywordResponse];

      this.keywordsClone = [...this.keywordsClone, ...keywordResponse];
      this.keywordsSubject.next(this.keywords);
    });
  }

  keywordsKeyPress() {
    if (!this.keyword_reffernce.searchTerm) {
      this.keywords = this.keywordsClone;
      return;
    }
    this.currentKeywordsPage = 0;
    this.keywords = [];
    this.searchKeywords();
  }

  searchKeywords() {
    this.keyWordService.searchKeyWordMemberByName(this.keyword_reffernce.searchTerm,
      this.env.page, this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.keywords = [this.keywords, ...response.keywords];
    });
  }

  clearKeywordsDropDown() {
    this.keywords = this.keywordsClone;
  }



  validatingRequiredFields() {

    if (this.selectedKeyWords.length <= 0) {
      this.isFormValid = false;
      this.isKeyWordsValidated = false;
      document.getElementById('keyword').focus();
      this.keyWordValidateMessage = this.env.validations.emptyKeyWords;
    }

    if (this.selectedGenres.length <= 0) {
      this.isFormValid = false;
      this.isSelectedGenresValidated = false;
      document.getElementById('genres').focus();
      this.genresValidateMessage = this.env.validations.emptyGenresMessage;
    }
    if (this.selectedCastMembers.length <= 0) {
      this.isFormValid = false;
      this.isCastValidated = false;
      document.getElementById('cast').focus();
      this.castValidateMessage = this.env.validations.emptyCasts;
    }
    if (this.selectedSpecialCastMembers.length <= 0) {
      this.isFormValid = false;
      this.isSpecialCastValidated = false;
      document.getElementById('specialcast').focus();
      this.castValidateMessage = this.env.validations.emptyCasts;
    }

    if (this.selectedProducer.length <= 0) {
      this.isFormValid = false;
      this.isProducerValidated = false;
      document.getElementById('producer').focus();
      this.producerValidateMessage = this.env.validations.emptyProducer;
    }

    if (this.selectedDirector.length <= 0) {
      this.isFormValid = false;
      this.isDirectorValidated = false;
      document.getElementById('director').focus();
      this.directorValidateMessage = this.env.validations.emptyDirector;
    }

    if (! this.selectedMaturityRating) {
      this.isFormValid = false;
      this.isMaturityRatingValidated = false;
      document.getElementById('maturityrating').focus();
      this.maturityRatingValidateMessage = this.env.validations.emptymaturityRating;
    }

    if (! this.runTime) {
      this.isFormValid = false;
      this.isRunTimeValidated = false;
      document.getElementById('timeRun').focus();
      this.runTimeValidateMessage = this.env.validations.emptyRunTime;
    }

    if (! this.releaseDate) {
      this.isFormValid = false;
      this.isReleaseDateValidated = false;
      this.releaseDateValidateMessage = this.env.validations.emptyReleaseDate;
    }

    if (! this.description ) {
      this.isFormValid = false;
      this.isDescriptionValidated = false;
      document.getElementById('des').focus();
      this.descriptionValidateMessage = this.env.validations.emptyDescription;
    }

    if (! this.slug) {
      this.isFormValid = false;
      this.isSlugValidated = false;
      document.getElementById('slug').focus();
      this.slugValidateMessage = this.env.validations.emptySlug;
    }

    if (! this.movieTitleInEnglish) {
      this.isFormValid = false;
      this.isMovieTitleInEnglishValidated = false;
      document.getElementById('titleEnglish').focus();
      this.movieTitleInEnglishValidateMessage = this.env.validations.emptyTitle;
    }

    let index = 0;

    for (const subtitle of this.subtitles) {

      if (!subtitle.subtitleLanguage && (subtitle.file.length <= 0)) {

        if (this.emptySubFile.indexOf(index) !== -1) {
          this.emptySubFile.splice(this.emptySubFile.indexOf(index), 1);
        }

        if (this.emptySubLanguage.indexOf(index) !== -1) {
          this.emptySubLanguage.splice(this.emptySubFile.indexOf(index), 1);
        }
      } else {

        if (subtitle.subtitleLanguage && (subtitle.file.length <= 0) &&
            (this.emptySubFile.indexOf(index) === -1)) {
          this.emptySubFile.push(index);
          this.subFileAddedValidationMessage = this.env.validations.emptySubtitleMessage;
        }

        if ((subtitle.file.length > 0) && !subtitle.subtitleLanguage  &&
            (this.emptySubLanguage.indexOf(index) === -1)) {
          this.emptySubLanguage.push(index);
          this.subtitleLanguageValidatedMessage = this.env.validations.emptySubtileLanugateMessage;
        }

      }

      ++index;
    }

    if (this.emptySubFile.length > 0 || this.emptySubLanguage.length > 0) {
      this.isFormValid = false;
    }

  }

  resetValidation(field, index?) {
    this.isFormValid = true;
    switch (field) {
      case this.MATURITY:
        this.isMaturityRatingValidated = true;
        break;
      case this.TITLE:
        this.isMovieTitleInEnglishValidated = true;
        break;
      case this.RELEASE_DATE:
        this.isReleaseDateValidated = true;
        break;
      case this.DIRECTOR:
        this.isDirectorValidated = true;
        break;
      case this.SLUG:
        this.isSlugValidated = true;
        this.isSlugPatternValidated = true;
        return;
      case this.RUNTIME:
        this.isRunTimeValidated = true;
        return;
      case this.DESCRIPTION:
        this.isDescriptionValidated = true;
        return;
      case this.PRODUCER:
        this.isProducerValidated = true;
        return;
      case this.CAST:
        this.isCastValidated = true;
        this.isSpecialCastValidated = true;
        this.special_cast_reffrence.searchTerm = '';
        this.cast_reffrence.searchTerm = '';
        this.getAllCast();
        return;
      case this.GENRE:
        this.isSelectedGenresValidated = true;
        this.genresReffrence.searchTerm = '';
        return;
      case this.KEY_WORD:
        this.isKeyWordsValidated = true;
        this.keyword_reffernce.searchTerm = '';
        return;
      case this.SUBTITLE: {
        if (this.emptySubFile.indexOf(index) !== -1) {
          this.emptySubFile.splice(this.emptySubFile.indexOf(index), 1);
        }
        return;
      }
      default: {
        if (this.emptySubLanguage.indexOf(index) !== -1) {
          this.emptySubLanguage.splice(this.emptySubLanguage.indexOf(index), 1);
        }
      }
    }
  }
  checkDuplicates() {
    for (let i = 0 ; i < this.selectedCastMembers.length ; i++) {
      if (this.selectedSpecialCastMembers.includes(this.selectedCastMembers[i])) {
        return true;
      }
    }
    return false;
  }

  createRequestBody() {

    this.requestBody.content_type = environment.contentType.movie;
    this.requestBody.special_actor_ids = this.orderCastNumber(this.selectedSpecialCastMembers, 1);
    this.requestBody.actor_ids = this.orderCastNumber(this.selectedCastMembers, this.selectedSpecialCastMembers.length + 1);
    this.requestBody.description = this.description;
    this.requestBody.director_ids = this.selectedDirector;
    this.requestBody.genres_ids = this.selectedGenres;
    this.requestBody.keyword_ids = this.selectedKeyWords;
    this.requestBody.maturity_rating = this.selectedMaturityRating.toUpperCase();
    this.requestBody.producer_ids = this.selectedProducer;
    this.requestBody.releasing_date = this.datePipe.transform(this.releaseDate, this.env.metaDataDateFormat);
    this.requestBody.run_time = this.runTime;
    this.requestBody.slug = this.slug;
    this.requestBody.subtitles = [];
    this.subtitles.forEach((subtitle, index) => {
      let subCode;
      let mediaConvertStatus;
      if (subtitle.subtitleLanguage && subtitle.file) {
        if (subtitle.subtitleLanguage.toUpperCase() === this.env.metaDataSubLanguages.english) {
          subCode = this.env.metaDataSubLanguages.codes.eng;
        }
        if (subtitle.subtitleLanguage.toUpperCase() === this.env.metaDataSubLanguages.sinhala) {
          subCode = this.env.metaDataSubLanguages.codes.sin;
        }
        if (subtitle.subtitleLanguage.toUpperCase() === this.env.metaDataSubLanguages.tamil) {
          subCode = this.env.metaDataSubLanguages.codes.tam;
        }

        if (subtitle.mediaConvertStatus) {
          mediaConvertStatus = subtitle.mediaConvertStatus;
        } else {
          mediaConvertStatus = this.env.mediaConvertStatus.pending;
        }

        this.requestBody.subtitles.push(
            {
              file_name: subtitle.file[0].name,
              language: subCode,
              source_url: subtitle.subTitleLocation,
              converted_url: null,
              description: subtitle.subtitleDescription,
              file_type: subtitle.file[0].name.split('.').pop().toUpperCase(),
              media_convert_status: mediaConvertStatus
            });
      }

    });
    if (this.requestBody.subtitles.length <= 0) {
      delete this.requestBody.subtitles;
    }
    this.requestBody.title = {
      english: this.movieTitleInEnglish,
      sinhala: this.movieTitleInSinhala,
      tamil: this.movieTitleInTamil
    };

  }

  isSlugAvailable(eventData: any) {
    if (eventData.target.value.length !== 0) {
      if (!this.slug.match(this.env.regexValidations.slug)) {
        this.isSlugPatternValidated = false;
        this.slugPatternValidateMessage = this.env.validations.slugRegexValidationMessage;
      } else {
        return this.commonService.validateSlug(eventData.target.value, this.env.contentType.movie)
            .pipe(takeUntil(this.destroy$)).subscribe((response) => {
              if (!response.is_available) {
                this.isSlugValidated = false;
                this.slugValidateMessage = this.env.validations.existingSlugErrorMessage;
              }
            });
      }
    }
  }
  async onSelect(event, index) {

    const updatedFiles: File[] = [];
    for (let index = 0; index < event.addedFiles.length; index++) {
      let originFile = event.addedFiles[index];
      let originText = await originFile.text()
      let splitByEnter = originText.split("\n");
      for (let i = 0; i < splitByEnter.length; i++) {
        let splitByArrow = splitByEnter[i].split("-->");
        if (splitByArrow.length == 2) {
          splitByArrow = splitByArrow.map(
              (time) => {
                let timeSplit = time.split(",");
                return timeSplit.join(".");
              }
          );
        }
        splitByEnter[i] = splitByArrow.join("-->");
      }
      let updatedText = splitByEnter.join("\n");
      let updatedFile: File = new File([updatedText], originFile.name, { type: originFile.type });
      updatedFiles.push(updatedFile);
    }

    this.subtitles[index].file  = updatedFiles;
  }

  uploadSubtitle() {
    this.validatingRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.movieId) {
      this.service.save(Service.MOVIE.CONTENT, {type: environment.contentType.movie})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.movieId = response.id;
        this.s3upload();
      });
    } else {
      this.s3upload();
    }
    // this.saveBtnDisabled = true;
  }


  s3upload() {
    let index = 0;
    let uploadPromise;
    for (const subtitle of this.subtitles) {
      if (subtitle.file && (subtitle.file.length > 0) && (subtitle.file[0].size > 0) && (this.savedIndexes.indexOf(index) === -1)) {
        uploadPromise = new Promise((resolve, reject) => {
          this.s3Bucket.uploadFile(this.movieId, subtitle.file[0], this.env.s3Bucket.path.subs,
              this.env.s3Bucket.buckets.bucketPath +
              this.env.s3Bucket.buckets.destinationFolder +
              this.env.s3Bucket.contentTypeFolder.movies,
              (error, response, pr) => {
                if (error) {
                  reject({status: false, response: error});
                }
                subtitle.subTitleLocation = response.Location;
                resolve({status: true, response});
              }).on('httpUploadProgress', (progress) => {
            subtitle.progress = Math.round(progress.loaded / progress.total * 100);
          });
        });
        this.promiseArray.push(uploadPromise);
      }
      index++;
    }
    if (this.promiseArray.length > 0) {
      Promise.all(this.promiseArray).then(value => {
        this.savedIndexes = value.map((value1, index1) => {
          if (value1) {
            return index1;
          }
        });
        if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
          this.save();
          return;
        }
        this.alert.errorAlert(environment.s3Bucket.uploadError.title,
            environment.s3Bucket.uploadError.message);
      });
    } else {
      this.save();
    }
  }

  save() {
    this.createRequestBody();
    if (this.checkDuplicates()) {
      this.alert.errorAlert(this.env.alerts.error.movie.metaData.title,
          'Duplicate cast and special cast!!');
      console.log(this.saveBtnDisabled);
      this.saveBtnDisabled = false;
      return;
    }
    this.service.update(Service.MOVIE.META_DATA, this.movieId, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.eventService.getContentIdEventEmitter({id: result.id, title: result.title});
      this.alert.successAlert(this.env.alerts.success.movie.metaData.updateTitle,
          this.env.alerts.success.movie.metaData.updateMessage);

      this.metaDataFormReference.form.pristine = true;
      // this.saveBtnDisabled = true;
    }, error2 => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(this.env.alerts.error.movie.metaData.title, error2.error.error);
    });
  }

  addCast(role: string) {
    const matDialogRef = this.matModel.open(CastModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '90%',
      width: '90%',
      data: {
        role
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value) {
        if (value.role === this.env.castMembers.actor) {
          this.castsClone.push(value);
          this.casts = [...this.castsClone];

        }

        if (value.role === this.env.castMembers.director) {
          this.directorsClone.push(value);
          this.directors = [...this.directorsClone];

        }

        if (value.role === this.env.castMembers.producer) {
          this.producersClone.push(value);
          this.producers = [...this.producersClone];

        }
      }
    });
  }

  addDirector() {
    this.addCast(this.env.castMemberRoles[1].role);
  }

  addProducer() {
    this.addCast(this.env.castMemberRoles[2].role);
  }

  addActor() {
    this.addCast(this.env.castMemberRoles[0].role);
  }

  addKeyword() {
    const matDialogRef = this.matModel.open(AddNewKeywordComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '50vh',
      height: '50%',
      width: '50%',
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.keywordsClone.push(value);
        this.keywords = [...this.keywordsClone];
        this.selectedKeyWords.push(value.id);
        this.selectedKeyWords = [...this.selectedKeyWords];
      }

    });
  }

  addGenre() {
    const matDialogRef = this.matModel.open(AddNewGenreComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '50vh',
      height: '50%',
      width: '50%',
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.genresClone.push(value);
        this.genres = [...this.genresClone];
        this.selectedGenres.push(value.id);
        this.selectedGenres = [...this.selectedGenres];
      }

    });
  }


  loadAllMetaData() {
    this.loading = true;

    this.service.findById(Service.MOVIE.META_DATA, this.movieId, environment.contentType.movie)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          if (result.title) {
        if (result.title.english) {
          this.movieTitleInEnglish = result.title.english;
        }

        if (result.title.sinhala) {
          this.movieTitleInSinhala = result.title.sinhala;
        }

        if (result.title.tamil) {
          this.movieTitleInTamil = result.title.tamil;
        }
      }


          if (result.slug) {
        this.slug = result.slug;
      }

          if (result.description) {
        this.description = result.description;
      }

          if (result.run_time) {
        this.runTime = result.run_time;
      }

          if (result.releasing_date) {
        this.releaseDate = result.releasing_date;
      }

          if (result.maturity_rating) {
        this.selectedMaturityRating = result.maturity_rating;
      }

          if (result.directors) {
        this.selectedDirector = result.directors.map((director) => {
          return director.id;
        });
        this.directorSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedDirector.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.directors.push(result.directors.find(val => val.id === value1));
          });
        });
      }

          if (result.genres) {
        this.selectedGenres = result.genres.map((genre) => {
          return genre.id;
        });
        this.genreSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedGenres.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.genres.push(result.genres.find(val => val.id === value1));
          });
        });
      }

          if (result.keywords) {
        this.selectedKeyWords = result.keywords.map((keyword) => {
          return keyword.id;
        });
        this.keywordsSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedKeyWords.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.keywords.push(result.keywords.find(val => val.id === value1));
          });
        });
      }

          if (result.actors) {
        this.selectedCastMembers = result.actors.map((actor) => {
          return actor.id;
        });
        this.castSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedCastMembers.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.casts.push(result.actors.find(val => val.id === value1));
          });
        });
      }

          if (result.special_actors) {
        this.selectedSpecialCastMembers = result.special_actors.map((actor) => {
          return actor.id;
        });
        this.castSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedSpecialCastMembers.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.casts.push(result.special_actors.find(val => val.id === value1));
          });
        });
      }

          if (result.producers) {
        this.selectedProducer = result.producers.map((producer) => {
          return producer.id;
        });
        this.producerSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedProducer.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.producers.push(result.producers.find(val => val.id === value1));
          });
        });
      }

          if (result.subtitles) {
        for (let i = 0; i < result.subtitles.length; i++) {
          this.subtitles.push({file: this.file});
          this.subtitles[i].subtitleDescription = result.subtitles[i].description;

          this.subtitles[i].subTitleLocation = result.subtitles[i].source_url;

          this.subtitles[i].mediaConvertStatus = result.subtitles[i].media_convert_status;

          if (result.subtitles[i].file_type) {
            this.subtitles[i].file = [new File([], '.' + result.subtitles[i].file_type.toLowerCase())];
          }
          let language;
          if (result.subtitles[i].language === 'SIN') {
            language = 'Sinhala';
          }
          if (result.subtitles[i].language === 'ENG') {
            language = 'English';
          }

          if (result.subtitles[i].language === 'TAM') {
            language = 'Tamil';
          }
          this.subtitles[i].subtitleLanguage = language;
        }
      }

          this.loading = false;

    }, error => {

    });
  }

  producersOnScroll() {
    ++this.currentProducersPage;
    if (this.producer_reffrence.searchTerm) {
      this.searchProducers();
    } else {
      if (this.producersClone.length < this.totalProducers) {
        this.getAllProducers();
      }
    }
  }

  directorsOnScroll() {
    ++this.currentDirectorsPage;
    if (this.director_reffrence.searchTerm) {
      this.searchDirectors();
    } else {
      if (this.directorsClone.length < this.totalDirectors) {
        this.getAllDirectors();
      }
    }
  }

  actorsOnScroll() {
    ++this.currentActorsPage;
    if (this.cast_reffrence.searchTerm) {
      this.searchActors(this.cast_reffrence.searchTerm);
    } else {
      if (this.castsClone.length < this.totalActors) {
        this.getAllCast();
      }
    }
  }
  specialActorsOnScroll() {
    ++this.currentActorsPage;
    if (this.special_cast_reffrence.searchTerm) {
      this.searchActors(this.special_cast_reffrence.searchTerm);
    } else {
      if (this.castsClone.length < this.totalActors) {
        this.getAllCast();
      }
    }
  }

  genresOnScroll() {
    ++this.currentGenresPage;
    if (this.genresReffrence.searchTerm) {
      this.searchActors(this.cast_reffrence.searchTerm);
    } else {
      if (this.castsClone.length < this.totalActors) {
        this.getAllGenre();
      }
    }
  }

  keywordsOnScroll() {
    ++this.currentKeywordsPage;
    if (this.keyword_reffernce.searchTerm) {
      this.searchKeywords();
    } else {
      if (this.keywordsClone.length < this.totalKeywords) {
        this.getAllKeyWords();
      }
    }
  }

  setPendingSubtitles(status) {
    this.subtitles = this.subtitles.map(subtitle => {
      subtitle.mediaConvertStatus = status;
      return subtitle;
    });
  }

  orderCastNumber(cast: any[], startIndex: number) {
    const orderedSelectedCastMembers: any[] = [];
    for (let i = 0; i < cast.length; i++) {
      orderedSelectedCastMembers.push({
        id: cast[i],
        seq_no: i + startIndex
      });
    }
    return orderedSelectedCastMembers;
  }

}
