import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {LiveChannelService} from '../../../../../core/service/common_service/ http/contents/live-channel/live-channel.service';
import {TitleCasePipe, UpperCasePipe} from '@angular/common';

@Component({
  selector: 'app-add-programme',
  templateUrl: './add-programme.component.html',
  styleUrls: ['./add-programme.component.scss']
})
export class AddProgrammeComponent implements OnInit, OnDestroy {
  name: string;
  NAME = 1;
  isProgrammeNameValidated = true;
  programmeNameValidationMessage;
  liveChannels: [] = [];
  selectedLiveChannel;
  isChannelDisabled = false;
  CHANNEL = 2;
  isLiveChannelValidated = true;
  liveChannelValidationMessage;
  env = environment;
  selectedProgrammeType;
  PROGRAMME_TYPE = 3;
  isTypeDisabled = false;
  isProgrammeTypeValidated = true;
  programmeTypeValidationMessage;
  IMAGE = 4;
  file: File[] = [];
  programme: any[] = [{file: this.file}];
  programmeImageValidationMessage;
  saveBtnDisabled = false;
  isImageValidated = false;
  destroy$ = new Subject();
  isFormValid = true;
  programmeId;
  requestBody: any = {};
  currentTvShowPage = 0;
  totalLiveChannels;
  liveChannelsClone = [];
  constructor(private model: MatDialogRef<AddProgrammeComponent>,
              private commonService: CommonService,
              private alert: Alerts,
              private s3Bucket: S3BucketService,
              private liveChannelService: LiveChannelService,
              private upperCase: UpperCasePipe) { }

  ngOnInit() {
    this.getLiveChannels();
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetValidation(field) {
    this.isFormValid = true;
    switch (field) {
      case this.NAME: {
        this.isProgrammeNameValidated = true;
        break;
      }
      case this.CHANNEL: {
        this.isLiveChannelValidated = true;
        break;
      }
      case this.PROGRAMME_TYPE: {
        this.isProgrammeTypeValidated = true;
        break;
      }
      default: {
        this.isImageValidated =  true;
      }
    }
  }

  onSelect(event) {
    this.resetValidation(this.IMAGE);
    this.programme[0].file  = event.addedFiles;
  }

  validatingRequiredFields() {

    if (!this.selectedLiveChannel) {
      this.isFormValid = false;
      this.isLiveChannelValidated = false;
      this.liveChannelValidationMessage = this.env.validations.liveChannelValidateMessage;
    }

    if (!this.name) {
      this.isFormValid = false;
      this.isProgrammeNameValidated = false;
      this.programmeNameValidationMessage = this.env.validations.programmeNameValidateMessage;
    }

    if (!this.selectedProgrammeType) {
      this.isFormValid = false;
      this.isProgrammeTypeValidated = false;
      this.programmeTypeValidationMessage = this.env.validations.programmeTypeValidateMessage;
    }

    if (this.programme[0].file.length <= 0) {
      this.isFormValid = false;
      this.isImageValidated = false;
      this.programmeImageValidationMessage = this.env.validations.imageValidateMessage;
    }
  }

  closeWindow() {
    this.model.close();
  }

  remove() {
    this.programme[0].file = this.file;
  }

  createRequestBody() {
    this.requestBody.name = this.name;
    this.requestBody.live_channel_id = this.selectedLiveChannel;
    this.requestBody.posters = [];
    this.requestBody.posters.push(
      {
        alt_tag : this.programme[0].file[0].name,
        device_type : this.env.deviceType.tablet,
        orientation_type : this.upperCase.transform(this.env.orientations.landscape),
        image_name : this.programme[0].file[0].name,
        source_url : this.programme[0].imageLocation.replace(this.env.s3Bucket.actualPath,
          this.env.s3Bucket.actualPathReplaceTo),
        image_conversion_status : this.env.conversionStatus.pending,
        image_type: this.env.resolutionImageType.imageWithoutTitle,
        resolution_type: this.env.resolutionTypes.fourX
      }
    );
  }

  uploadImages() {
    this.validatingRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.programmeId) {
      this.commonService.save(Service.PROGRAMME, {programme_type: this.selectedProgrammeType})
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.programmeId = response.id;
        this.s3upload();
      });
    } else {
      this.s3upload();
    }
    this.saveBtnDisabled = true;
  }


  s3upload() {
      new Promise((resolve, reject) => {
        this.s3Bucket.uploadFile(this.programmeId, this.programme[0].file[0], environment.s3Bucket.path.posters,
          environment.s3Bucket.buckets.bucketPath +
          environment.s3Bucket.buckets.destinationFolder +
          environment.s3Bucket.contentTypeFolder.programme, (error, res, pr) => {
            if (error) {
              reject({status: false, response: error});
            }
            this.programme[0].imageLocation = res.Location;
            resolve({status: true, response: res});
          }).on('httpUploadProgress', (progress) => {
          this.programme[0].progress = Math.round(progress.loaded / progress.total * 100);
        });
      }).then((value: any) => {
          if (value.status) {
            this.save();
            return;
          }
      }).catch(reason => {
        this.alert.errorAlert(environment.s3Bucket.uploadError.title,
          environment.s3Bucket.uploadError.message);
      });
  }

  save() {
    this.createRequestBody();
    this.commonService.update(Service.PROGRAMME, this.programmeId, this.requestBody)
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.alert.successAlert(environment.alerts.success.live_channel.programme.title,
        environment.alerts.success.live_channel.programme.message).then(value => {
      });
      this.saveBtnDisabled = false;
    }, error2 => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(environment.alerts.error.live_channel.programme.title,
        environment.alerts.error.live_channel.programme.message);
    });
  }

  getLiveChannels() {
    this.liveChannelService.findAll({
      page: this.currentTvShowPage,
      size: this.env.size
    })
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalLiveChannels = response.total;
      this.liveChannels = response.data;
      this.liveChannelsClone = this.liveChannels;
    });
  }
}
