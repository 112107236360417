import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenreService {

  constructor(private http: HttpService) { }

  findAllGenres(page, size) {
    return this.http.get<any>(environment.serverurl + '/genres?page=' + page + '&size=' + size);
  }

  searchGenresByName(searchTerm, page, size) {
    return this.http.get<any>(environment.serverurl + '/genres/search/' + searchTerm + '?page=' + page + '&size=' + size);
  }

  save(body) {
    return this.http.post<any>(environment.serverurl + '/genres', body);
  }

  update(id, body) {
    return this.http.put<any>(environment.serverurl + '/genres/' + id, body);
  }
}
