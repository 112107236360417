import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {EventService} from '../../../../../core/service/event.service';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';

@Component({
    selector: 'app-media-monetization',
    templateUrl: './media-monetization.component.html',
    styleUrls: ['./media-monetization.component.scss']
})
export class MediaMonetizationComponent implements OnInit, OnDestroy {

    availableFreeToWatchValues: any [];
    packages: any [] = [];
    packagesClone = [];
    availablePayToWatchValues: string[] = [];
    freeToWatch: string[];
    payToWatch: string[] = [];
    matSlide;
    FREELKR = 1;
    FREEUSD = 2;
    FREEEDAY = 3;
    PREMIUMLKR = 7;
    PREMIUMUSD = 8;
    PREMIUMDATE = 9;
    PAY_TO_WATCH = 10;
    FREE_TO_WATCH = 11;
    ANNUALPREMIUMLKR = 12;
    ANNUALPREMIUMUSD = 13;
    ANNUALPREMIUMDATE = 14;
    freeLKR: number;
    freeUSD: number;
    freeDays: number;
    premiumLKR: number;
    premiumUSD: number;
    premiumDays: number;
    annualPremiumLKR: number;
    annualPremiumUSD: number;
    annualPremiumDays: number;
    freeLKRValidateMessage;
    freeUSDValidateMessage;
    freeDaysValidateMessage;
    premiumLKRValidateMessage;
    premiumUSDValidateMessage;
    premiumDaysValidateMessage;
    isFreeLKRValidate = true;
    isFreeUSDValidate = true;
    isFreeDaysValidate = true;
    isPremiumLKRValidate = true;
    isPremiumUSDValidate = true;
    isPremiumDaysValidate = true;
    isAnnualPremiumLKRValidate = true;
    isAnnualPremiumUSDValidate = true;
    isAnnualPremiumDaysValidate = true;
    annualPremiumLKRValidateMessage;
    annualPremiumUSDValidateMessage;
    annualPremiumDaysValidateMessage;
    requestBody: any = {};
    destroy$ = new Subject();
    getContentIdSubscription;
    videoId;
    monetizationRuleType = false;
    videoIdValidated = false;
    monetizationNotAvailableValidateMessage;
    isFormValid = true;
    videoChannelId: number;

    constructor(private service: CommonService, private alert: Alerts, private eventService: EventService,
                private commonService: CommonServiceService) {
    }

    ngOnInit() {
        this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
            if (response) {
                this.videoId = response.id;
                this.videoIdValidated = false;
            }
        });
        this.getContentIdSubscription = this.eventService.returnTvShowId.subscribe(response => {
            if (response) {
                this.videoChannelId = response.id;
            }
        });
        this.loadPackages();

        this.matSlide = false;
    }

    ngOnDestroy(): void {
        /**
         * Unsubscribe all http request
         */
        this.destroy$.next();
        this.destroy$.complete();
        this.getContentIdSubscription.unsubscribe();
    }

    loadPackages() {
        this.service.findAll(Service.PACKAGE_TIER).pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
                this.packages = response;
                this.packagesClone = [...response];
                this.availableFreeToWatchValues = response.filter(v => v.id !== 3).map((pack) => pack.package_type);
                this.freeToWatch = this.availableFreeToWatchValues;
                this.availablePayToWatchValues = this.packages
                    .filter(value => !this.freeToWatch.includes(value.package_type) && value.package_type.id === 3)
                    .map((pack) => pack.package_type);
            });
    }

    changeItemFromFreeWatch() {
        this.availablePayToWatchValues = this.packages
            .filter(value => !this.freeToWatch.includes(value.package_type) && value.package_type !== 'STANDARD')
            .map((pack) => pack.package_type);
        this.availableFreeToWatchValues = [...this.availableFreeToWatchValues];
    }

    changeItemFromPayWatch() {
        this.availableFreeToWatchValues = this.packages
            .filter(value => !this.payToWatch.includes(value.package_type)  && value.package_type !== 'STANDARD')
            .map((pack) => pack.package_type);
        this.availablePayToWatchValues = [...this.availablePayToWatchValues];
    }

    addItemToFreeWatch() {
        this.changeItemFromFreeWatch();
    }

    addItemToPayToWatch() {
        this.changeItemFromPayWatch();
    }

    clearFreeToWatch() {
        this.changeItemFromFreeWatch();
    }

    clearPayToWatch() {
        this.changeItemFromPayWatch();
    }


    validatingRequiredFields() {

        if (!this.payToWatch.includes(environment.packages.premium) &&
            !this.freeToWatch.includes(environment.packages.premium)) {
            this.isFormValid = false;
            this.alert.errorAlert(environment.alerts.error.video.monetization.title,
                environment.alerts.error.tv_episode.monetization.premiumNotAvailable);  // TODO here
        }
        if (!this.payToWatch.includes(environment.packages.annualPremium) &&
            !this.freeToWatch.includes(environment.packages.annualPremium)) {
            this.isFormValid = false;
            this.alert.errorAlert(environment.alerts.error.video.monetization.title,
                'Annual Premium package should include in Free or Pay to watch');
        }
        if (!((this.payToWatch.includes(environment.packages.premium) && this.payToWatch.includes(environment.packages.annualPremium)) ||
            (this.freeToWatch.includes(environment.packages.premium) && this.freeToWatch.includes(environment.packages.annualPremium)))) {
            this.isFormValid = false;
            this.alert.errorAlert(environment.alerts.error.video.monetization.title,
                'Premium/Annual both packages should include in Free or Pay to watch');
        }

        if (this.payToWatch.includes(environment.packages.premium)) {
            if (this.premiumLKR < 0 || isNaN(this.premiumLKR) || this.premiumLKR === null) {
                this.isFormValid = false;
                this.isPremiumLKRValidate = false;
                this.premiumLKRValidateMessage = environment.validations.emptyPremiumLKRValidateMessage;
            }
            if (this.premiumUSD < 0 || isNaN(this.premiumLKR) || this.premiumUSD === null) {
                this.isPremiumUSDValidate = false;
                this.isFormValid = false;
                this.premiumUSDValidateMessage = environment.validations.emptyPremiumUSDValidateMessage;
            }
            if (this.premiumDays < 0 || isNaN(this.premiumLKR) || this.premiumDays === null) {
                this.isPremiumDaysValidate = false;
                this.isFormValid = false;
                this.premiumDaysValidateMessage = environment.validations.emptyPremiumDaysValidateMessage;
            }
        }
        if (this.payToWatch.includes(environment.packages.annualPremium)) {
            if (this.annualPremiumLKR < 0  || isNaN(this.annualPremiumLKR) || this.annualPremiumLKR === null) {
                this.isAnnualPremiumLKRValidate = false;
                this.annualPremiumLKRValidateMessage = environment.validations.emptyPremiumLKRValidateMessage;
            }
            if (this.annualPremiumUSD < 0  || isNaN(this.annualPremiumLKR) || this.annualPremiumUSD === null) {
                this.isAnnualPremiumUSDValidate = false;
                this.annualPremiumUSDValidateMessage = environment.validations.emptyPremiumUSDValidateMessage;
            }
            if (this.annualPremiumDays < 0  || isNaN(this.annualPremiumLKR) || this.annualPremiumDays === null) {
                this.isAnnualPremiumDaysValidate = false;
                this.annualPremiumDaysValidateMessage = environment.validations.emptyPremiumDaysValidateMessage;
            }
        }
        if (this.payToWatch.includes(environment.packages.free)) {
            if (this.freeLKR < 0 || isNaN(this.freeLKR) || this.freeLKR === null) {
                this.isFreeLKRValidate = false;
                this.isFormValid = false;
                this.freeLKRValidateMessage = environment.validations.emptyFreeLKRValidateMessage;
            }
            if (this.freeUSD < 0 || isNaN(this.freeUSD) || this.freeUSD === null) {
                this.isFreeUSDValidate = false;
                this.isFormValid = false;
                this.freeUSDValidateMessage = environment.validations.emptyFreeUSDValidateMessage;
            }
            if (this.freeDays < 0 || isNaN(this.freeDays) || this.freeDays === null) {
                this.isFreeDaysValidate = false;
                this.isFormValid = false;
                this.freeDaysValidateMessage = environment.validations.emptyFreeDaysValidateMessage;
            }
        }
    }


    resetValidation(field) {
        this.isFormValid = true;
        switch (field) {
            case this.FREELKR:
                this.isFreeLKRValidate = true;
                break;
            case this.FREEUSD:
                this.isFreeUSDValidate = true;
                break;
            case this.FREEEDAY:
                this.isFreeDaysValidate = true;
                break;
            case this.PREMIUMLKR:
                this.isPremiumLKRValidate = true;
                break;
            case this.PREMIUMUSD:
                this.isPremiumUSDValidate = true;
                break;
            case this.PREMIUMDATE:
                this.isPremiumDaysValidate = true;
                break;
            case this.ANNUALPREMIUMLKR:
                this.isAnnualPremiumLKRValidate = true;
                break;
            case this.ANNUALPREMIUMUSD:
                this.isAnnualPremiumUSDValidate = true;
                break;
            case this.ANNUALPREMIUMDATE:
                this.isAnnualPremiumDaysValidate = true;
                break;
            default:
                return;
        }
    }

    createRequestBody() {
        this.requestBody.content_type = environment.contentType.video;
        this.requestBody.monetization = {};
        this.requestBody.monetization.allowed_advertising_free_tier = this.matSlide;
        this.requestBody.monetization.free_to_watch = [];
        this.freeToWatch.forEach((tier, index) => {

            let t;
            if (tier === environment.packages.premium) {
                t = environment.packages.premium;
            }
            if (tier === environment.packages.free) {
                t = environment.packages.free;
            }
            if (tier === environment.packages.standard) {
                t = environment.packages.standard;
            }
            if (tier === environment.packages.annualPremium) {
                t = environment.packages.annualPremium;
            }
            this.requestBody.monetization.free_to_watch.push(t);
        });

        this.requestBody.monetization.plans = [];
        this.requestBody.monetization.plans.prices = [];
        this.requestBody.monetization.inherited_from_content = this.monetizationRuleType;
        this.payToWatch.forEach((tier, index) => {
            this.requestBody.monetization.plans.push(
                {
                    package_id: this.packagesClone.filter((pack) => {
                            return pack.package_type === tier;
                        }
                    ).map((selectedPack) => {
                        return selectedPack.id;
                    })[0],
                    prices: [
                        {
                            amount: tier === environment.packages.free ? this.freeLKR
                                : (tier === environment.packages.premium ? this.premiumLKR : this.annualPremiumLKR),
                            currency: environment.currency.lkr
                        },
                        {
                            amount: tier === environment.packages.free ? this.freeUSD
                                : (tier === environment.packages.premium ? this.premiumUSD : this.annualPremiumUSD),
                            currency: environment.currency.usd
                        }
                    ],
                    rental_period: {
                        period: tier === environment.packages.free ? this.freeDays
                            : (tier === environment.packages.premium ? this.premiumDays : this.annualPremiumDays),
                        unit: environment.packageActivationUnits.days
                    },
                    package_type: this.payToWatch[index],
                },
            );
        });

    }

    save() {
        this.validatingRequiredFields();
        this.createRequestBody();

        if (!this.isFormValid) {
            return;
        }

        if (this.videoId) {
            this.service.update(Service.VIDEO.MONETIZATION, this.videoId, this.requestBody)
                .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
                this.eventService.getContentIdEventEmitter({id: response.id, inherited: this.monetizationRuleType});
                this.alert.successAlert(environment.alerts.success.video.monetization.title,
                    environment.alerts.success.video.monetization.message);
            });
        } else {
            this.service.save(Service.VIDEO.MONETIZATION, this.requestBody)
                .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
                this.eventService.getContentIdEventEmitter({id: response.id, inherited: this.monetizationRuleType});
                this.alert.successAlert(environment.alerts.success.video.monetization.title,
                    environment.alerts.success.video.monetization.message);
            });
        }
    }

    selectMonetizationRule(event) {
        if (event) {
            if (this.videoChannelId) {
                this.loadAllMonetization();
            }

            if (!this.videoChannelId) {
                this.videoIdValidated = true;
                this.monetizationNotAvailableValidateMessage = environment.validations
                    .monetizationNotAvailableValidateMessage;

            } else {
                this.commonService.findAllWizardStatus(this.videoChannelId, environment.contentType.videoChannel)
                    .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

                }, error => {
                    if (!error.error.data.metadata_status) {
                        this.videoIdValidated = true;
                        this.monetizationNotAvailableValidateMessage = environment.validations
                            .monetizationNotAvailableValidateMessage;
                    }
                });
            }
            return;
        }
        this.videoIdValidated = false;
        this.loadPackages();
        this.payToWatch = [];
    }

    loadAllMonetization() {
        this.service.findById(Service.VIDEO_CHANNEL.MONETIZATION, this.videoChannelId, environment.contentType.videoChannel)
            .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
            this.freeToWatch = result.monetization.free_to_watch;
            this.matSlide = result.monetization.allowed_advertising_free_tier;
            this.payToWatch = result.monetization.plans.map((pack) => pack.package_type);
            if (this.payToWatch.includes('FREE')) {
                const freePlanData = result.monetization.plans.filter((pack) => pack.package_type === 'FREE')[0];
                this.freeDays = freePlanData.rental_period.period;
                for (const price of freePlanData.prices) {
                    if (price.currency === 'LKR') {
                        this.freeLKR = price.amount;
                    }
                    if (price.currency === 'USD') {
                        this.freeUSD = price.amount;
                    }
                }
            }
            if (this.payToWatch.includes('PREMIUM')) {
                const premiumPlanData = result.monetization.plans.filter((pack) => pack.package_type === 'PREMIUM')[0];
                this.freeDays = premiumPlanData.rental_period.period;
                for (const price of premiumPlanData.prices) {
                    if (price.currency === 'LKR') {
                        this.premiumLKR = price.amount;
                    }
                    if (price.currency === 'USD') {
                        this.premiumUSD = price.amount;
                    }
                }
            }
            if (this.payToWatch.includes('ANNUAL_PREMIUM')) {
                const premiumPlanData = result.monetization.plans.filter((pack) => pack.package_type === 'ANNUAL_PREMIUM')[0];
                this.freeDays = premiumPlanData.rental_period.period;
                for (const price of premiumPlanData.prices) {
                    if (price.currency === 'LKR') {
                        this.annualPremiumLKR = price.amount;
                    }
                    if (price.currency === 'USD') {
                        this.annualPremiumUSD = price.amount;
                    }
                }
            }
        });
    }

}
