import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KeyWordService {

  constructor(private http: HttpService) { }

  findAllKeyWords(page, size) {
    return this.http.get<any>(environment.serverurl + '/keywords?page=' + page + '&size=' + size);
  }

  searchKeyWordMemberByName(searchTerm, page, size) {
    return this.http.get<any>(environment.serverurl + '/keywords/search/' + searchTerm + '?page=' + page + '&size=' + size);
  }

  save(body) {
    return this.http.post<any>(environment.serverurl + '/keywords', body);
  }
}
