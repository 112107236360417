import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'app-media-video-tab',
  templateUrl: './media-video-tab.component.html',
  styleUrls: ['./media-video-tab.component.scss']
})
export class MediaVideoTabComponent implements OnInit {

  @Input() template;
  env = environment;
  @Input() isDetailView = false;
  constructor() { }

  ngOnInit() {
  }

}
