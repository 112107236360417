import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentRulesService {

  constructor(private http: HttpService) { }

  saveContentRules(body) {
    return this.http.post(environment.serverurl + '/contents/content-rules', body);
  }
  getContentRulesById(id, contentType) {
    return this.http.get<any>(environment.serverurl + '/contents/' + id + '/content-rules?content=' + contentType);
  }
  getAllContentRules() {
    return this.http.get(environment.serverurl);
  }
  updateContentRules(id, body) {
    return this.http.put(environment.serverurl + '/contents/content-rules/' + id, body);
  }
  deleteContentRules(id) {
    return this.http.delete(environment.serverurl + id);
  }
}
