import {Injectable} from '@angular/core';
import {AuthenticationService} from '../service/auth.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
// @ts-ignore
const httpStatus = require('http-status-codes');

@Injectable()
export class HttpRequestInterceptor {

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authenticationService: AuthenticationService) {}

  intercept( request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentUser();
    if ( currentUser && currentUser.token && !this.authenticationService.isRefreshTokenProcessing ) {
      request = this.addToken( request, currentUser.token );
    } else {
        request = this.addXApiVersion( request );
    }
    return next.handle(request).pipe(
      catchError(
        ( err, caught ) => {
                if ( err.status === httpStatus.FORBIDDEN ) {
                this.handUnauthorizedError(request, next);
                }

                throw err;
        }
      )
    );
  }
  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
         Authorization: `Bearer ${token}`,
        'X-Api-Version': environment.apiVersion
      }
    });
  }
  addXApiVersion(request: HttpRequest<any>) {
      return request.clone({
          setHeaders: {
              'X-Api-Version': environment.apiVersion
          }
      });
  }

  handUnauthorizedError( request: HttpRequest<any>, next: HttpHandler) {
    if ( ! this.authenticationService.isRefreshTokenProcessing) {
      this.authenticationService.isRefreshTokenProcessing = true;
      this.refreshTokenSubject.next(null);
      return this.authenticationService.refreshToken().pipe(
        switchMap((token: any) => {
          this.authenticationService.isRefreshTokenProcessing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        })
      );
    } else {
      return  this.refreshTokenSubject.pipe(
        filter( token => token !== null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
