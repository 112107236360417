import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {CastMemberService} from '../../../../../core/service/common_service/ http/cast-member/cast-member.service';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {KeyWordService} from '../../../../../core/service/common_service/ http/keyword/key-word.service';
import {Alerts} from '../../../../../alert/Alert';
import {DatePipe} from '@angular/common';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {EventService} from '../../../../../core/service/event.service';
import {MatDialog} from '@angular/material/dialog';
import {GenreService} from '../../../../../core/service/common_service/ http/genre/genre.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {CastModelComponent} from '../../../../meta-data/cast/model/cast-model/cast-model.component';
import {AddNewKeywordComponent} from '../../../../meta-data/keyword/model/add-new-keyword/add-new-keyword.component';
import {AddNewGenreComponent} from '../../../../meta-data/genre/model/add-new-genre/add-new-genre.component';

@Component({
  selector: 'app-video-chennel-meta-data-detail-view',
  templateUrl: './video-chennel-meta-data-detail-view.component.html',
  styleUrls: ['./video-chennel-meta-data-detail-view.component.scss']
})
export class VideoChennelMetaDataDetailViewComponent implements OnInit, OnDestroy {

  TITLE = 1;
  SLUG = 2;
  MATURITY = 4;
  DIRECTOR = 5;
  DESCRIPTION = 6;
  KEY_WORD = 7;
  GENRE = 8;
  CAST = 9;
  PRODUCER = 10;
  config: {};
  domain;
  videoChannelId;
  loading = false;
  requestBody: any = {};
  directors: any [] = [];
  directorsClone: any = [];
  producers: any [] = [];
  producersClone: any [] = [];
  casts: any [] = [];
  castsClone: any [];
  keywords: any [] = [];
  keywordsClone: any [] = [];
  getContentIdSubscription;
  isSlugPatternValidated = true;
  slugPatternValidateMessage;
  isDescriptionValidated = true;
  isSpecialCastValidated = true;
  descriptionValidateMessage;
  directorValidateMessage;
  env = environment;
  genres: any[] = [];
  genresClone: any[] = [];
  saveBtnDisabled = false;
  isKeyWordsValidated = true;
  keyWordValidateMessage;
  castValidateMessage;
  isCastValidated = true;
  isProducerValidated = true;
  producerValidateMessage;
  isFormValid = true;
  destroy$ = new Subject();
  videoChannelTitleInEnglish: string;
  isVideoChannelTitleInEnglishValidated = true;
  videoChannelTitleInEnglishValidateMessage;
  videoChannelTitleInSinhala: string;
  videoChannelTitleInTamil: string;
  slug: string;
  isSlugValidated = true;
  slugValidateMessage;
  description: string;
  selectedDirector: number[] = [];
  isDirectorValidated = true;
  selectedProducer: number[] = [];
  selectedCastMembers: number[] = [];
  selectedSpecialCastMembers: number[] = [];
  selectedGenres: number[] = [];
  isSelectedGenresValidated = true;
  genresValidateMessage;
  selectedKeyWords: number[] = [];
  maturityRating = environment.maturityRating;
  selectedMaturityRating: string;
  isMaturityRatingValidated = true;
  maturityRatingValidateMessage;
  toggleLayer = true;
  editViewerEvent;
  @ViewChild('director', null) directorReference: any;
  @ViewChild('producer', null) producerReference: any;
  @ViewChild('cast', null) castReference: any;
  @ViewChild('keyword', null) keywordReference: any;
  @ViewChild('genre', null) genresReffrence: any;
  @ViewChild('channelMetaData', null) formReference: any;
  @ViewChild('specialcast', null) specialCastReffrence: any;
  currentProducersPage;
  currentDirectorsPage;
  currentActorsPage;
  totalProducers = 0;
  totalDirectors = 0;
  totalActors = 0;
  currentGenresPage;
  currentKeywordsPage;
  totalGenres = 0;
  totalKeywords = 0;
  castSubject: Subject<any> = new Subject<any>();
  producerSubject: Subject<any> = new Subject<any>();
  directorSubject: Subject<any> = new Subject<any>();
  genreSubject: Subject<any> = new Subject<any>();
  keywordsSubject: Subject<any> = new Subject<any>();

  constructor(private castMemberService: CastMemberService,
              private service: CommonService,
              private keyWordService: KeyWordService,
              private alert: Alerts,
              private datePipe: DatePipe,
              private commonService: CommonServiceService,
              private eventService: EventService,
              private matModel: MatDialog,
              private genreService: GenreService) { }

  ngOnInit() {
    this.currentProducersPage = this.env.page;
    this.currentDirectorsPage = this.env.page;
    this.currentActorsPage = this.env.page;
    this.currentGenresPage = this.env.page;
    this.currentKeywordsPage = this.env.page;
    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.videoChannelId = response.id;
      }
    });

    this.editViewerEvent = this.eventService.contentViewSender.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.toggleLayer = value.viewEnable;
    });


    this.getAllDirectors();
    this.getAllProducers();
    this.getAllCast();
    this.getAllGenre();
    this.getAllKeyWords();
    this.getDomain();
    this.loadAllMetaData();
  }

  /**uploadFile
   * execute when component destroy
   */
  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
  }

  /**
   * Get all directors
   */
  getAllDirectors() {
    this.service.findAll(Service.CAST_MEMBER, {
      castMemberRole: environment.castMembers.director,
      page: this.currentDirectorsPage,
      size: environment.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalDirectors = response.total;
      const directorsResponse = response.cast_members.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.directors = [...this.directors, ...directorsResponse];

      this.directorsClone = [...this.directorsClone, ...directorsResponse];
      this.directorSubject.next(this.directors);
    });
  }

  getAllProducers() {
    this.service.findAll(Service.CAST_MEMBER, {
      castMemberRole: environment.castMembers.producer,
      page: this.currentProducersPage,
      size: environment.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalProducers = response.total;
      const producersResponse = response.cast_members.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.producers = [...this.producers, ...producersResponse];

      this.producersClone = [...this.producersClone, ...producersResponse];
      this.producerSubject.next(this.producers);
    });
  }

  getAllGenre() {
    this.service.findAll(Service.GENRE, {
      page: this.currentGenresPage,
      size: environment.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalGenres = response.total;
      const genreResponse = response.genres.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.genres = [...this.genres, ...genreResponse];

      this.genresClone = [...this.genresClone, ...genreResponse];
      this.genreSubject.next(this.genres);
    });
  }

  getAllKeyWords() {
    this.service.findAll(Service.KEY_WORD, {
      page: this.currentKeywordsPage,
      size: environment.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalKeywords = response.total;
      const keywordResponse = response.keywords.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.keywords = [...this.keywords, ...keywordResponse];

      this.keywordsClone = [...this.keywordsClone, ...keywordResponse];
      this.keywordsSubject.next(this.keywords);
    });
  }

  resetValidation(field) {
    this.isFormValid = true;
    switch (field) {
      case this.MATURITY:
        this.isMaturityRatingValidated = true;
        break;
      case this.TITLE:
        this.isVideoChannelTitleInEnglishValidated = true;
        break;
      case this.DIRECTOR:
        this.isDirectorValidated = true;
        break;
      case this.SLUG:
        this.isSlugValidated = true;
        this.isSlugPatternValidated = true;
        return;
      case this.DESCRIPTION:
        this.isDescriptionValidated = true;
        return;
      case this.PRODUCER:
        this.isProducerValidated = true;
        return;
      case this.CAST:
        this.isCastValidated = true;
        this.isSpecialCastValidated = true;
        this.specialCastReffrence.searchTerm = '';
        this.castReference.searchTerm = '';
        this.getAllCast();
        return;
      case this.GENRE:
        this.genresReffrence.searchTerm = '';
        this.isSelectedGenresValidated = true;
        return;
      default:
        this.isKeyWordsValidated = true;
        this.keywordReference.searchTerm = '';
    }
  }

  isSlugAvailable(eventData: any) {
    if (eventData.target.value.length !== 0) {
      if (!this.slug.match(this.env.regexValidations.slug)) {
        this.isSlugPatternValidated = false;
        this.slugPatternValidateMessage = this.env.validations.slugRegexValidationMessage;
      } else {
        return this.commonService.validateSlug(eventData.target.value, this.env.contentType.videoChannel)
            .pipe(takeUntil(this.destroy$)).subscribe((response) => {
              if (!response.is_available) {
                this.isSlugValidated = false;
                this.slugValidateMessage = this.env.validations.existingSlugErrorMessage;
              }
            });
      }
    }
  }

  directorsKeyPress() {
    if (!this.directorReference.searchTerm) {
      this.directors = this.directorsClone;
      return;
    }
    this.currentDirectorsPage = 0;
    this.directors = [];
    this.searchDirectors();
  }

  searchDirectors() {
    this.castMemberService.searchCastMemberByName(this.directorReference.searchTerm,
      this.env.castMembers.director, this.currentDirectorsPage,
      this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.directors = [this.directors, ...response.cast_members];
    });
  }

  clearDirectorsDropDown() {
    this.directors = this.directorsClone;
  }

  clearProducersDropDown() {
    this.producers = this.producersClone;
  }

  producersKeyPress() {
    if (!this.producerReference.searchTerm) {
      this.producers = this.directorsClone;
      return;
    }
    this.currentProducersPage = 0;
    this.producers = [];
    this.searchProducers();
  }

  searchProducers() {
    this.castMemberService.searchCastMemberByName(this.producerReference.searchTerm,
      this.env.castMembers.producer, this.currentProducersPage,
      this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.producers = [this.producers, ...response.cast_members];
    });
  }

  getAllCast() {
    this.service.findAll(Service.CAST_MEMBER, {
      castMemberRole: environment.castMembers.actor,
      page: this.currentActorsPage,
      size: environment.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalActors = response.total;
      const actorResponse = response.cast_members.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.casts = [...this.casts, ...actorResponse];

      this.castSubject.next(this.casts);
      this.castsClone = [...this.castsClone, ...actorResponse];
    });
  }

  castsKeyPress() {
    if (!this.castReference.searchTerm) {
      this.casts = this.castsClone;
      return;
    }
    this.currentActorsPage = 0;
    this.casts = [];
    this.searchActors(this.castReference.searchTerm);
  }

  searchActors(searchTerm) {
    this.castMemberService.searchCastMemberByName(searchTerm,
      this.env.castMembers.actor, this.currentActorsPage,
      this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.casts = [this.casts, ...response.cast_members];
    });
  }

  clearCastDropDown() {
    this.casts = this.castsClone;
  }

  keywordsKeyPress() {
    if (!this.keywordReference.searchTerm) {
      this.keywords = this.keywordsClone;
      return;
    }
    this.currentKeywordsPage = 0;
    this.keywords = [];
    this.searchKeywords();
  }

  searchKeywords() {
    this.keyWordService.searchKeyWordMemberByName(this.keywordReference.searchTerm,
      this.env.page, this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.keywords = [this.keywords, ...response.keywords];
    });
  }

  clearKeywordsDropDown() {
    this.keywords = this.keywordsClone;
  }

  genresKeyPress() {
    if (!this.genresReffrence.searchTerm) {
      this.genres = this.genresClone;
      return;
    }
    this.currentGenresPage = 0;
    this.genres = [];
    this.searchGenres();
  }

  searchGenres() {
    this.genreService.searchGenresByName(this.genresReffrence.searchTerm,
      this.currentGenresPage, this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.genres = [this.genres, ...response.genres];
    });
  }

  save() {
    if (this.checkDuplicates()) {
      this.alert.errorAlert(this.env.alerts.error.video.metaData.title,
          'Duplicate cast and special cast!!');
      this.saveBtnDisabled = false;
      return;
    }
    this.validatingRequiredFields();
    this.createRequestBody();

    if (this.isVideoChannelTitleInEnglishValidated && this.isSlugValidated) {
      if (this.videoChannelId) {
        this.service.update( Service.MOVIE.META_DATA, this.videoChannelId, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.formReference.form.pristine = true;
          this.alert.successAlert(environment.alerts.success.video_channel.metaData.title,
              environment.alerts.success.video_channel.metaData.message);
        }, error => {
          this.alert.errorAlert(environment.alerts.error.video_channel.metaData.title,
              error.error.error);
        });
      } else {
        this.service.save( Service.VIDEO_CHANNEL.META_DATA, this.requestBody).pipe(takeUntil(this.destroy$))
            .subscribe((response: any) => {
              this.eventService.getContentIdEventEmitter({id: response.id, title: response.title});
              this.alert.successAlert(environment.alerts.success.video_channel.metaData.title,
                  environment.alerts.success.video_channel.metaData.message);
            }, error => {
              this.alert.errorAlert(environment.alerts.error.video_channel.metaData.title,
                  error.error.error);
            });
      }
    }
  }

  validatingRequiredFields() {
    // if (this.selectedKeyWords.length <= 0) {
    //   this.isFormValid = false;
    //   this.isKeyWordsValidated = false;
    //   document.getElementById('keyword').focus();
    //   this.keyWordValidateMessage = this.env.validations.emptyKeyWords;
    // }

    // if (this.selectedGenres.length <= 0) {
    //   this.isFormValid = false;
    //   this.isSelectedGenresValidated = false;
    //   document.getElementById('genre').focus();
    //   this.genresValidateMessage = this.env.validations.emptyGenresMessage;
    // }
    if (! this.selectedMaturityRating) {
      this.isMaturityRatingValidated = false;
      document.getElementById('maturity').focus();
      this.maturityRatingValidateMessage = environment.validations.emptymaturityRating;
    }

    if (! this.description ) {
      this.isFormValid = false;
      this.isDescriptionValidated = false;
      document.getElementById('desc').focus();
      this.descriptionValidateMessage = this.env.validations.emptyDescription;
    }

    if (! this.slug) {
      this.isSlugValidated = false;
      document.getElementById('slug').focus();
      this.slugValidateMessage = environment.validations.emptySlug;
    }

    if (! this.videoChannelTitleInEnglish) {
      this.isVideoChannelTitleInEnglishValidated = false;
      document.getElementById('englishTitle').focus();
      this.videoChannelTitleInEnglishValidateMessage = environment.validations.emptyTitle;
    }
  }

  createRequestBody() {
    this.requestBody.content_type = environment.contentType.videoChannel;
    this.requestBody.special_actor_ids = this.orderCastNumber(this.selectedSpecialCastMembers, 1);
    this.requestBody.actor_ids = this.orderCastNumber(this.selectedCastMembers, this.selectedSpecialCastMembers.length);
    this.requestBody.description = this.description;
    this.requestBody.director_ids = this.selectedDirector;
    this.requestBody.genres_ids = this.selectedGenres;
    this.requestBody.keyword_ids = this.selectedKeyWords;
    this.requestBody.maturity_rating = this.selectedMaturityRating.toUpperCase();
    // TODO remove relase date from BACK END
    this.requestBody.releasing_date = '2020-05-12';
    this.requestBody.producer_ids = this.selectedProducer;
    this.requestBody.slug = this.slug;
    this.requestBody.title = {
      english: this.videoChannelTitleInEnglish,
      sinhala: this.videoChannelTitleInSinhala,
      tamil: this.videoChannelTitleInTamil
    };
  }

  getDomain() {
    this.commonService.findDomain(environment.contentType.videoChannel).pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.domain = response.domain;
        });
  }

  addCast(role: string) {
    const matDialogRef = this.matModel.open(CastModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '90%',
      width: '90%',
      data: {
        role
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value) {
        if (value.role === this.env.castMembers.actor) {
          this.castsClone.push(value);
          this.casts = [...this.castsClone];

        }

        if (value.role === this.env.castMembers.director) {
          this.directorsClone.push(value);
          this.directors = [...this.directorsClone];

        }

        if (value.role === this.env.castMembers.producer) {
          this.producersClone.push(value);
          this.producers = [...this.producersClone];

        }
      }

    });
  }

  addDirector() {
    this.addCast(this.env.castMemberRoles[1].role);
  }

  addProducer() {
    this.addCast(this.env.castMemberRoles[2].role);
  }

  addActor() {
    this.addCast(this.env.castMemberRoles[0].role);
  }

  addKeyword() {
    const matDialogRef = this.matModel.open(AddNewKeywordComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '50vh',
      height: '50%',
      width: '50%',
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.keywordsClone.push(value);
        this.keywords = [...this.keywordsClone];
        this.selectedKeyWords.push(value.id);
        this.selectedKeyWords = [...this.selectedKeyWords];
      }

    });
  }

  addGenre() {
    const matDialogRef = this.matModel.open(AddNewGenreComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '50vh',
      height: '50%',
      width: '50%',
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.genresClone.push(value);
        this.genres = [...this.genresClone];
        this.selectedGenres.push(value.id);
        this.selectedGenres = [...this.selectedGenres];
      }

    });
  }

  loadAllMetaData() {


    this.loading = true;

    this.service.findById( Service.TV_SHOW.META_DATA, this.videoChannelId, environment.contentType.videoChannel)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {

      if (result.title) {
        this.videoChannelTitleInEnglish = result.title.english;
      }

      if (result.title) {
        this.videoChannelTitleInSinhala = result.title.sinhala;
      }

      if (result.title) {
        this.videoChannelTitleInTamil = result.title.tamil;
      }

      if (result.slug) {
        this.slug = result.slug;
      }

      if (result.description) {
        this.description = result.description;
      }

      if (result.maturity_rating) {
        this.selectedMaturityRating = result.maturity_rating;
      }

      if (result.directors) {
        this.selectedDirector = result.directors.map((director) => {
          return director.id;
        });
        this.directorSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedDirector.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.directors.push(result.directors.find(val => val.id === value1));
          });
        });
      }

      if (result.genres) {
        this.selectedGenres = result.genres.map((genre) => {
          return genre.id;
        });
        this.genreSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedGenres.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.genres.push(result.genres.find(val => val.id === value1));
          });
        });
      }

      if (result.keywords) {
        this.selectedKeyWords = result.keywords.map((keyword) => {
          return keyword.id;
        });
        this.keywordsSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedKeyWords.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.keywords.push(result.keywords.find(val => val.id === value1));
          });
        });
      }

      if (result.actors) {
        this.selectedCastMembers = result.actors.map((actor) => {
          return actor.id;
        });
        this.castSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedCastMembers.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.casts.push(result.actors.find(val => val.id === value1));
          });
        });
      }

      if (result.special_actors) {
        this.selectedSpecialCastMembers = result.special_actors.map((actor) => {
          return actor.id;
        });
        this.castSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedSpecialCastMembers.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.casts.push(result.special_actors.find(val => val.id === value1));
          });
        });
      }

      if (result.producers) {
        this.selectedProducer = result.producers.map((producer) => {
          return producer.id;
        });
        this.producerSubject.asObservable().subscribe((value: []) => {
          const castIds = value.map((value1: any) => value1.id);
          const notFetchedIds = this.selectedProducer.filter(value2 => !castIds.find(value1 => value2 === value1));
          notFetchedIds.forEach(value1 => {
            this.producers.push(result.producers.find(val => val.id === value1));
          });
        });
      }


      this.loading = false;

    }, error => {

    });
  }

  producersOnScroll() {
    ++this.currentProducersPage;
    if (this.producerReference.searchTerm) {
      this.searchProducers();
    } else {
      if (this.producersClone.length < this.totalProducers) {
        this.getAllProducers();
      }
    }
  }

  directorsOnScroll() {
    ++this.currentDirectorsPage;
    if (this.directorReference.searchTerm) {
      this.searchDirectors();
    } else {
      if (this.directorsClone.length < this.totalDirectors) {
        this.getAllDirectors();
      }
    }
  }

  actorsOnScroll() {
    ++this.currentActorsPage;
    if (this.castReference.searchTerm) {
      this.searchActors(this.castReference.searchTerm);
    } else {
      if (this.castsClone.length < this.totalActors) {
        this.getAllCast();
      }
    }
  }

  genresOnScroll() {
    ++this.currentGenresPage;
    if (this.genresReffrence.searchTerm) {
      this.searchActors(this.genresReffrence.searchTerm);
    } else {
      if (this.castsClone.length < this.totalActors) {
        this.getAllGenre();
      }
    }
  }

  keywordsOnScroll() {
    ++this.currentKeywordsPage;
    if (this.keywordReference.searchTerm) {
      this.searchKeywords();
    } else {
      if (this.keywordsClone.length < this.totalKeywords) {
        this.getAllKeyWords();
      }
    }
  }

  specialCastsKeyPress() {
    if (!this.specialCastReffrence.searchTerm) {
      this.casts = this.castsClone;
      return;
    } else {}
    this.currentActorsPage = 0;
    this.casts = [];
    this.searchActors(this.specialCastReffrence.searchTerm);
  }

  specialActorsOnScroll() {
    ++this.currentActorsPage;
    if (this.specialCastReffrence.searchTerm) {
      this.searchActors(this.specialCastReffrence.searchTerm);
    } else {
      if (this.castsClone.length < this.totalActors) {
        this.getAllCast();
      }
    }
  }
  checkDuplicates() {
    for (let i = 0 ; i < this.selectedCastMembers.length ; i++) {
      if (this.selectedSpecialCastMembers.includes(this.selectedCastMembers[i])) {
        return true;
      }
    }
    return false;
  }
  orderCastNumber(cast: any[], startIndex: number) {
    const orderedSelectedCastMembers: any[] = [];
    for (let i = 0; i < cast.length; i++) {
      orderedSelectedCastMembers.push({
        id: cast[i],
        seq_no: i + startIndex
      });
    }
    return orderedSelectedCastMembers;
  }
}
