import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeasonService {

  constructor(private  http: HttpService) { }

  save(body) {
    return this.http.post<any>(environment.serverurl + '/tv-shows/season', body);
  }
}
