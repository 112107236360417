import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpBackend, HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SliderGraphicService {

  constructor(private  http: HttpService, private httpBackend: HttpBackend) { }

  save(body) {
    return this.http.post<any>(environment.serverurl + '/slider-graphics', body);
  }

  update(id, body) {
    return this.http.put<any>(environment.serverurl + '/slider-graphics/' + id, body);
  }

  getAllPublishedSliderGraphics(options: any) {
    return this.http.get(environment.serverurl + '/slider-graphics' + '?page=' + options.page + '&size=' +  options.size +
        '&sliderGraphicStatus=' + options.status);
  }
  getRealitySliderGraphic(page: number, size: number): Observable<any> {
    const newHttpClient = new HttpClient(this.httpBackend);
    return newHttpClient.get<any>(environment.realityCmsServiceApi + `slider-graphic?page=${page}&size=${size}`);
  }
  publish(id, body) {
    return this.http.put<any>(environment.serverurl + '/slider-graphics/publish/' + id , body);
  }

  findById(id) {
    return this.http.get<any>(environment.serverurl + '/slider-graphics/' + id);
  }

  unPublish(id, body) {
    return this.http.put<any>(environment.serverurl + '/slider-graphics/un-publish/' + id, body);
  }

  deleteSlider(id) {
    return this.http.delete<any>(environment.serverurl + '/slider-graphics/' + id);
  }


  slidersByFilters(options) {
    let queryString = '';
    if (options.createdBy) {
      queryString = 'created-by=' + options.createdBy;
    }

    if (options.screen) {
      queryString = queryString + '&screen-id=' + options.screen;
    }

    if (options.greaterThanCreatedDateAndTime) {
      queryString = queryString + 'greater-than-date-time=' + options.greaterThanCreatedDateAndTime +
          '&less-than-date-time=' + options.lessThanCreatedDateAndTime;
    }

    return this.http.get<any>(environment.serverurl + '/slider-graphics/filters?' + queryString + '&page='
        + options.page + '&size='  +  options.size + '&status=' + options.contentStatus);
  }

  findByTitleAndStatus(searchTerm, sliderStatus, page, size, maturityRating) {
    return this.http.get<any>(environment.serverurl + '/slider-graphics/search/' + searchTerm
        + '?contentType=' + sliderStatus + '&page=' + page + '&size=' + size + '&maturity-rating=' + maturityRating);
  }
}
