import { BrowserModule } from '@angular/platform-browser';
import {NgModule, Pipe} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpRequestInterceptor} from './core/helpers/http.request.interceptor';
import {ErrorInterceptor} from './core/helpers/error.interceptor';
import {LayoutsModule} from './layouts/layouts.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule, MatInputModule,
    MatRadioModule,
    MatTabsModule
} from '@angular/material';
import {FlatpickrModule} from 'angularx-flatpickr';
import {MovieModelComponent} from './pages/media/movies/model/movie-model/movie-model.component';
import {MediaModule} from './pages/media/media.module';
import {CommonModule, DatePipe, TitleCasePipe, UpperCasePipe} from '@angular/common';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {PublishMovieModelComponent} from './pages/media/movies/model/publish-movie-model/publish-movie-model.component';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import {MediaVideoModelComponent} from './pages/media/media-video/model/media-video-model/media-video-model.component';
import {TvEpisodeModelComponent} from './pages/media/tv-episode/model/tv-episode-model/tv-episode-model.component';
import {VideoChannelModelComponent} from './pages/media/video-channel/model/video-channel-model/video-channel-model.component';
import {TvShowModelComponent} from './pages/media/tv-shows/model/tv-show-model/tv-show-model.component';
import {PublishTvShowModelComponent} from './pages/media/tv-shows/model/publish-tv-show-model/publish-tv-show-model.component';
import {CastModelComponent} from './pages/meta-data/cast/model/cast-model/cast-model.component';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {AddNewKeywordComponent} from './pages/meta-data/keyword/model/add-new-keyword/add-new-keyword.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AddNewGenreComponent} from './pages/meta-data/genre/model/add-new-genre/add-new-genre.component';
import {NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {AddNewSliderGraphicComponent} from './pages/meta-data/slider-graphic/add-new-slider-graphic/add-new-slider-graphic.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {AddNewCategoryComponent} from './pages/meta-data/category/model/add-new-category/add-new-category.component';
import {AddNewScreenComponent} from './pages/meta-data/screens/add-new-screen/add-new-screen.component';
import {LiveChannelModelComponent} from './pages/media/live-channel/model/live-channel-model/live-channel-model.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {WidgetModule} from './shared/widgets/widget.module';
import {AddNewSeasonComponent} from './pages/media/tv-episode/tabs/add-new-season/add-new-season.component';
import {AddCategoryContentComponent} from './pages/meta-data/category/model/add-category-content/add-category-content.component';
import {UIModule} from './shared/ui/ui.module';
import {MAT_CHECKBOX_CLICK_ACTION} from '@angular/material/checkbox';
import {PublishTvEpisodeModelComponent} from './pages/media/tv-episode/model/publish-tv-episode-model/publish-tv-episode-model.component';
import {PublishCategoryComponent} from './pages/meta-data/category/model/publish-category/publish-category.component';
import {PublishScreenComponent} from './pages/meta-data/screens/publish-screen/publish-screen.component';
import {PublishSliderGraphicComponent} from './pages/meta-data/slider-graphic/publish-slider-graphic/publish-slider-graphic.component';
import {CategoryDetailViewComponent} from './pages/meta-data/category/model/category-detail-view/category-detail-view.component';
import {SliderGraphicDetailViewComponent} from './pages/meta-data/slider-graphic/slider-graphic-detail-view/slider-graphic-detail-view.component';
import {ScreenDetailViewComponent} from './pages/meta-data/screens/screen-detail-view/screen-detail-view.component';
import {MetaDataDetailViewComponent} from './pages/media/movies/tabs/meta-data-detail-view/meta-data-detail-view.component';
import {TvEpisodeListComponent} from './pages/media/tv-shows/model/tv-episode-list/tv-episode-list.component';
import {CastDetailViewComponent} from './pages/meta-data/cast/model/cast-detail-view/cast-detail-view.component';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {PublishVideoChannelModelComponent} from './pages/media/video-channel/model/publish-video-channel-model/publish-video-channel-model.component';
import {PublishVideoModelComponent} from './pages/media/media-video/model/publish-video-model/publish-video-model.component';
import {AddNewPlayListComponent} from './pages/media/media-video/tabs/add-new-play-list/add-new-play-list.component';
import {NotificationTemplateDetailViewComponent} from './pages/notification/notification-template-detail-view/notification-template-detail-view.component';
import {PreviewScreenComponent} from './pages/meta-data/screens/preview-screen/preview-screen.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {VideoEpisodeListComponent} from './pages/media/video-channel/model/video-episode-list/video-episode-list.component';
import {ReOrderScreenComponent} from './pages/meta-data/screens/re-order-screen/re-order-screen.component';
import {PaymentDetailViewComponent} from './pages/payment/payment/payment-detail-view/payment-detail-view.component';
import {SubscriberDetailViewComponent} from './pages/subscribers/subscriber-detail-view/subscriber-detail-view.component';
import {CarouselModule} from 'primeng/carousel';
import {PreviewSliderGraphicComponent} from './pages/meta-data/slider-graphic/preview-slider-graphic/preview-slider-graphic.component';
import {GenreDetailViewComponent} from './pages/meta-data/genre/model/genre-detail-view/genre-detail-view.component';
import {UploadEpgComponent} from './pages/media/live-channel/model/upload-epg/upload-epg.component';
import {AddProgrammeComponent} from './pages/media/live-channel/model/add-programme/add-programme.component';
import {AddProgrammeScheduleComponent} from './pages/media/live-channel/model/add-programme-schedule/add-programme-schedule.component';
import {ProgrammeScheduleComponent} from './pages/media/live-channel/model/programme-schedule/programme-schedule.component';
import {LiveChannelEditModelComponent} from './pages/media/live-channel/model/live-channel-edit-model/live-channel-edit-model.component';
import {UpdateProgrammeComponent} from './pages/media/live-channel/model/update-programme/update-programme.component';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';
import {ProblemDetailViewComponent} from './pages/problems/problem-detail-view/problem-detail-view.component';
import { HomeAnalyticComponent } from './pages/home-analytic/home-analytic.component';
import { ChartModule } from 'primeng/chart';
import { PrimeNGConfig } from 'primeng/api';





@NgModule({
  declarations: [
    AppComponent, MovieModelComponent,
      CastModelComponent, AddNewKeywordComponent,
      AddNewGenreComponent, AddNewSliderGraphicComponent,
      AddNewCategoryComponent, AddNewScreenComponent,
      AddCategoryContentComponent, PublishTvEpisodeModelComponent,
      PublishCategoryComponent, PublishScreenComponent,
      PublishSliderGraphicComponent, CategoryDetailViewComponent,
      SliderGraphicDetailViewComponent, ScreenDetailViewComponent,
      CastDetailViewComponent, PublishVideoChannelModelComponent,
      PublishVideoModelComponent, AddNewPlayListComponent,
      NotificationTemplateDetailViewComponent, PreviewScreenComponent,
      ReOrderScreenComponent, PaymentDetailViewComponent, ProblemDetailViewComponent,
      SubscriberDetailViewComponent, PreviewSliderGraphicComponent, GenreDetailViewComponent,
      UploadEpgComponent, AddProgrammeComponent, AddProgrammeScheduleComponent, ProgrammeScheduleComponent,
      UpdateProgrammeComponent,
      HomeAnalyticComponent,

  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        LayoutsModule,
        MatDialogModule,
        FlatpickrModule.forRoot(),
        MatTabsModule,
        MediaModule,
        NgxDropzoneModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        NgxMatNativeDateModule,
        FormsModule,
        NgSelectModule,
        NgbProgressbarModule,
        MatRadioModule,
        MatCheckboxModule,
        ColorPickerModule,
        DragDropModule,
        WidgetModule,
        UIModule,
        MatFormFieldModule,
        MatFormFieldModule, MatInputModule,
        NgxSpinnerModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        Ng2GoogleChartsModule,
        ChartModule,

        LoggerModule.forRoot({
            colorScheme: ['purple', 'teal', 'gray', 'gray', 'red', 'red', 'red'],
            level: NgxLoggerLevel.INFO,
        }), MatSlideToggleModule, CarouselModule,
        // MatRadioModule,

    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    {provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check'},
    [DatePipe],
    [TitleCasePipe],
    NgxSpinnerService,
    [UpperCasePipe],
    [HomeAnalyticComponent],
  ],
  bootstrap: [AppComponent],

  entryComponents: [
      MovieModelComponent, PublishMovieModelComponent, MediaVideoModelComponent, TvEpisodeModelComponent,
      VideoChannelModelComponent, TvShowModelComponent, PublishTvShowModelComponent, CastModelComponent,
      AddNewKeywordComponent, AddNewGenreComponent, AddNewSliderGraphicComponent, AddNewCategoryComponent,
      AddNewScreenComponent, LiveChannelModelComponent, AddNewSeasonComponent, AddNewKeywordComponent,
      AddNewGenreComponent, AddNewSliderGraphicComponent, AddNewCategoryComponent, AddNewScreenComponent,
      LiveChannelModelComponent, AddCategoryContentComponent, PublishTvEpisodeModelComponent,
      PublishCategoryComponent, PublishScreenComponent, PublishSliderGraphicComponent,
      CategoryDetailViewComponent, SliderGraphicDetailViewComponent, ScreenDetailViewComponent,
      MetaDataDetailViewComponent, TvEpisodeListComponent, VideoEpisodeListComponent, CastDetailViewComponent,
      PublishVideoChannelModelComponent, PublishVideoModelComponent, AddNewPlayListComponent,
      NotificationTemplateDetailViewComponent, PreviewScreenComponent, ReOrderScreenComponent, ProblemDetailViewComponent,
      PaymentDetailViewComponent, SubscriberDetailViewComponent, PreviewSliderGraphicComponent, GenreDetailViewComponent,
      UploadEpgComponent, AddProgrammeComponent, AddProgrammeScheduleComponent, ProgrammeScheduleComponent,
      LiveChannelEditModelComponent, UpdateProgrammeComponent,HomeAnalyticComponent

]

})
export class AppModule { }
