import {Component, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material';
import {MovieModelComponent} from './model/movie-model/movie-model.component';
import {CommonService} from '../../../core/service/common_service/common.service';
import {Service} from '../../../core/service/common_service/services';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TitleCasePipe} from '@angular/common';
import {Alerts} from '../../../alert/Alert';
import {MovieService} from '../../../core/service/common_service/ http/contents/movies/movie.service';
import {CmsUserService} from '../../../core/service/common_service/ http/cms-user/cms-user.service';
import {ContentService} from '../../../core/service/common_service/ http/contents/content.service';
import {PackageTierService} from '../../../core/service/common_service/ http/packege-tier/package-tier.service';


@Component({
    selector: 'app-movies',
    templateUrl: './movies.component.html',
    styleUrls: ['./movies.component.scss']
})
export class MoviesComponent implements OnInit, OnDestroy {
    env = environment;
    destroy$ = new Subject();
    selectedPage: number;
    tableData: any[] = [];
    selectedTab;
    loading = false;
    deleteSubject: Subject<boolean> = new Subject();
    searchTerm = '';
    pageCount: any;
    cmsUsers: any[] = [];
    columnData: any[] = [];
    packages: any[] = [];
    queryObj;
    constructor(private matModel: MatDialog, private commonService: CommonService,
                private cmsUserService: CmsUserService,
                private contentService: ContentService,
                private titleCasePipe: TitleCasePipe, private alert: Alerts, private movieService: MovieService,
                private packageService: PackageTierService, private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.selectedPage = 1;
        this.selectedTab = this.env.contentStatus.published;
        if (this.searchTerm && this.searchTerm.length >= 0) {
            this.searchMovie();
        } else {
            this.findMovies();
        }
        this.loadAllPackages();
        setInterval(() => {
          if (this.selectedTab === this.env.contentStatus.processing) {
            this.updateProgress();
          }
        }, 30000);
        this.refreshQueryObj();
        this.loadAllCmsUsers(this.env.cmsUserAction.upload);
        this.loadAllCmsUsers(this.env.cmsUserAction.create);
    }

    loadFilterData(action) {
      const filterDataArray = [];
      const modifiedUserData = this.cmsUsers.map(val => {
        return {
          id: val.id,
          value: val.name
        };
      });
      let selectedField;

      if (action === this.env.cmsUserAction.create) {
        selectedField = this.env.unPublishedMovieColumns[3].field;
      } else {
        selectedField = this.env.processedMovieColumns[4].field;
      }

      filterDataArray.push({
        field: selectedField,
        data: modifiedUserData
      });

      this.columnData = [...this.columnData, ...filterDataArray];
    }

  loadAllCmsUsers(action) {
    this.loading = true;
    this.cmsUserService.findAllContentsCmsUsers({status: action})
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.cmsUsers = response;
      this.loadFilterData(action);
      this.loading = false;
    });
  }

  loadAllPackages() {
    this.loading =  true;
    this.packageService.findAllPackageTiers()
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.packages = response;
      this.loadPackageFilterData();
      this.loading = false;
    });
  }

  loadPackageFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.packages.map(val => {
      return {
        id: val.id,
        value: val.package_type
      };
    });

    modifiedUserData.splice(2, 1);
    const payToWatch = this.env.publishedMovieColumns[3].field;
    const freeToWatch = this.env.publishedMovieColumns[4].field;

    filterDataArray.push({
      field: payToWatch,
      data: modifiedUserData
    });

    filterDataArray.push({
      field: freeToWatch,
      data: modifiedUserData
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  ngOnDestroy(): void {
        /**
         * Unsubscribe all http request
         */
        this.destroy$.next();
        this.destroy$.complete();
  }

    addNewMovie() {
        this.cd.detach();
        const matDialogRef = this.matModel.open(MovieModelComponent, {
            disableClose: true,
            autoFocus: false,
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            data: {
                isDetailView: false
            }
        });
        matDialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.cd.reattach();
            if (this.searchTerm && this.searchTerm.length >= 0) {
                this.searchMovie();
            } else {
                this.findMovies();
            }
        });

    }

    selectTab(event) {
        let action;
        switch (event.tab.textLabel.toUpperCase()) {
            case this.env.contentStatus.published: {
                this.loadAllPackages();
                action = this.env.cmsUserAction.upload;
                action = this.env.cmsUserAction.create;
                this.selectedTab = this.env.contentStatus.published;
                break;
            }
            case this.env.contentStatus.scheduled: {
                this.selectedTab = this.env.contentStatus.scheduled;
                break;
            }
            case this.env.contentStatus.processed: {
                action = this.env.cmsUserAction.upload;
                this.selectedTab = this.env.contentStatus.processed;
                break;
            }
            case this.env.contentStatus.processing: {
                this.selectedTab = this.env.contentStatus.processing;
                break;
            }
            default: {
                action = this.env.cmsUserAction.upload;
                action = this.env.cmsUserAction.create;
                this.selectedTab = this.env.contentStatus.unpublished + '&' +
                    environment.contentStatusString + this.env.contentStatus.error;
            }
        }
        this.refreshQueryObj();
        this.tableData = [];
        if (this.searchTerm && this.searchTerm.length >= 0) {
          this.searchMovie();
        } else {
          this.findMovies();
        }
        if (action) {
          this.loadAllCmsUsers(action);
        }
  }

  findMovies() {

    this.loading = true;
    this.commonService.findAll(Service.MOVIE.STATUS,
        {status: environment.contentStatusString + this.selectedTab,
      contentType: environment.contentType.movie,
      page: this.selectedPage - 1,
      size: this.env.tableProperties.size})
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
            this.searchContentProcess(response);
        });

  }

    setPage(event) {
      this.selectedPage = event;
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchMovie();
      } else {
        this.findMovies();
      }
    }

    openDetailView(event, status) {
        this.cd.detach();
        const matDialogRef = this.matModel.open(MovieModelComponent, {
            disableClose: true,
            autoFocus: false,
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            data: {
                isDetailView: true,
                movieId: event.selectedData.id,
                movieTitle: event.selectedData.englishTitle,
                publishStatus: status,
                isError: event.isError
            }
        });

        matDialogRef.afterClosed().subscribe(value => {
            this.cd.reattach();
            if (this.searchTerm && this.searchTerm.length >= 0) {
                this.searchMovie();
            } else {
                this.findMovies();
            }
        });
    }

    deleteContent(event) {
        this.alert.confirm(null, environment.alerts.success.movie.deleteMovie.confirmationMessage,
            environment.alerts.success.movie.deleteMovie.confirmButton).then(value => {
            if (value.isConfirmed) {
                this.commonService.delete(Service.MOVIE, event.selectedData.id).subscribe(val => {
                    this.deleteSubject.next(true);
                });
            }
        });
    }

    updateProgress() {
      const ids = this.tableData.map(value => value.id);
      this.movieService.updateProgress(ids).pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.tableData.forEach((value, index) => {
            value.progress = response.find(obj =>  obj.id === value.id).progress;
          });
        }, error => {
          console.log(error);
        });
    }

    searchMovie() {
        this.loading = true;
        this.commonService.search(Service.MOVIE, this.selectedTab,
          this.searchTerm, this.selectedPage - 1, this.env.tableProperties.size, 'MATURE')
            .pipe(takeUntil(this.destroy$))
            .subscribe(response => {
                this.searchContentProcess(response);
            }, error => {
                this.loading = false;
            });
    }

    searchContentProcess(response) {
        let contentResArray;
        if (response.content_by_multiple_status) {
            contentResArray = response.content_by_multiple_status;
        } else {
            contentResArray = response.contents_by_status;
        }
        this.pageCount = (response.total) / this.env.tableProperties.size * 10;

        this.tableData = contentResArray.map((content, index) => {

            if (content.title) {
                content.englishTitle = content.title.english;
            }

            if (content.title) {
                content.sinhalaTitle = content.title.sinhala;
            }

            if (content.title) {
                content.tamilTitle = content.title.tamil;
            }

            if (content.created_by) {
                content.createdBy = content.created_by.name;
            }

            if (content.uploaded_by) {
                content.uploadedBy = content.uploaded_by.name;
            }

            if (content.published_by) {
                if (content.published_by.name) {
                    content.publishedBy = content.published_by.name;
                }
            }

            if (content.poster) {
                content.poster = {
                    isPoster: true,
                    url: content.poster.source_url
                };
            }

            if (content.monetization && content.monetization.plans.length > 0) {
                let payToWatchPlans;
                payToWatchPlans = content.monetization.plans.map((plan) => {
                    return this.titleCasePipe.transform(plan.package_type);
                });

                content.payToWatch = payToWatchPlans.join();
            }

            if (content.monetization && content.monetization.free_to_watch.length > 0) {
                let freeToWatchPlans;
                freeToWatchPlans = content.monetization.free_to_watch.map((plan) => {
                    return this.titleCasePipe.transform(plan);
                });

                content.freeToWatch = freeToWatchPlans.join();
            }

            if (content.published_date) {

                let date = new Date(content.published_date),
                    month = '' + (date.getMonth() + 1),
                    day = '' + date.getDate(),
                    year = date.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                content.published_date = [year, month, day].join('-');
            }

            if (content.job_type) {
                content.job_type = content.job_type.join(',');
            }

            return content;
        });

        this.loading = false;
    }

    findAllByFilter(filterData) {
      let publishedDateRange = [];
      let releasedDateRange = [];
      let createdDateRange = [];

      filterData.forEach(filter => {
        if (filter.field === this.env.unPublishedMovieColumns[3].field) {
          const filteredCmsUsers = this.cmsUsers.filter(val => val.id === filter.value);
          if (filteredCmsUsers.length > 0) {
            this.queryObj.createdBy = filteredCmsUsers[0].name;
          }
        }

        if (filter.field === this.env.processedMovieColumns[4].field) {
          const filteredCmsUsers = this.cmsUsers.filter(val => val.id === filter.value);
          if (filteredCmsUsers.length > 0) {
            this.queryObj.uploadedBy = filteredCmsUsers[0].name;
          }
        }

        if (filter.field === this.env.unPublishedMovieColumns[4].field) {
          createdDateRange = filter.value.split(' to ');
          if (createdDateRange.length > 1) {
            this.queryObj.greaterThanCreatedDateAndTime = createdDateRange[0].concat(' ') + '00:00:00';
            this.queryObj.lessThanCreatedDateAndTime = createdDateRange[1].concat(' ') + '00:00:00';
          }
        }

        if (filter.field === this.env.publishedMovieColumns[6].field) {
          publishedDateRange = filter.value.split(' to ');
          if (publishedDateRange.length > 1) {
            this.queryObj.greaterThanPublishedDateAndTime = publishedDateRange[0].concat(' ') + '00:00:00';
            this.queryObj.lessThanPublishedDateAndTime = publishedDateRange[1].concat(' ') + '00:00:00';
          }
        }

        if (filter.field === this.env.publishedMovieColumns[7].field) {
          releasedDateRange = filter.value.split(' to ');
          if (releasedDateRange.length > 1) {
            this.queryObj.greaterThanReleasedDateAndTime = releasedDateRange[0];
            this.queryObj.lessThanReleasedDateAndTime = releasedDateRange[1];
          }
        }

        if (filter.field === this.env.publishedMovieColumns[3].field) {
          const filteredPackages = this.packages.filter(val => val.id === filter.value);
          if (filteredPackages.length > 0) {
            this.queryObj.payToWatch = filteredPackages[0].package_type;
          }
        }

        if (filter.field === this.env.publishedMovieColumns[4].field) {
          const filteredPackages = this.packages.filter(val => val.id === filter.value);
          if (filteredPackages.length > 0) {
            this.queryObj.freeToWatch = filteredPackages[0].package_type;
          }
        }
      });
      if (this.queryObj.createdBy
        || this.queryObj.uploadedBy
        || this.queryObj.greaterThanPublishedDateAndTime
        || this.queryObj.greaterThanCreatedDateAndTime
        || this.queryObj.freeToWatch
        || this.queryObj.payToWatch
        || this.queryObj.greaterThanReleasedDateAndTime) {
        if ((releasedDateRange.length === 1) && (releasedDateRange[0] !== '')) {
          return;
        }

        if ((publishedDateRange.length === 1) && (publishedDateRange[0] !== '')) {
          return;
        }

        if ((createdDateRange.length === 1) && (createdDateRange[0] !== '')) {
          return;
        }
        this.loadDataByFilters(this.queryObj);
      } else if (filterData.filter(filter => filter.value).length <= 0) {
          if (this.searchTerm && this.searchTerm.length >= 0) {
              this.searchMovie();
          } else {
              this.findMovies();
          }
      }
    }

    loadDataByFilters(queryObj) {
      this.loading = true;
      this.contentService.contentByFilters(this.env.contentType.movie, queryObj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
        this.tableData = [];
        this.searchContentProcess(response);
        this.refreshQueryObj();
        this.loading = false;
      }, error => {
        console.log(error);
        this.loading = false;
      });
    }

    refreshQueryObj() {
      this.queryObj = {
        createdBy: null,
        uploadedBy: null,
        payToWatch: null,
        freeToWatch: null,
        contentStatus: this.selectedTab,
        page: this.selectedPage - 1,
        size: this.env.size,
        lessThanCreatedDateAndTime: null,
        greaterThanCreatedDateAndTime: null,
        lessThanReleasedDateAndTime: null,
        greaterThanReleasedDateAndTime: null,
        lessThanPublishedDateAndTime: null,
        greaterThanPublishedDateAndTime: null
      };
    }

    onKeyPress() {
        if (!this.searchTerm) {
            this.searchMovie();
        }
    }
}

