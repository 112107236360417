import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {EventService} from '../../../../../core/service/event.service';

@Component({
  selector: 'app-video-channel-trailers-bouns',
  templateUrl: './video-channel-trailers-bouns.component.html',
  styleUrls: ['./video-channel-trailers-bouns.component.scss']
})
export class VideoChannelTrailersBounsComponent implements OnInit, OnDestroy {

  videoChannelId: string;
  trailers: any[] = [{}];
  videoFileLocation: string[] = [];
  thumbnailLocation: string[] = [];
  videoFiles: File[] = [];
  thumbnailFiles: File[] = [];
  titles: string[] = [];
  runTimes: string[] = [];
  previewToggle: boolean[] = [];
  emptyVideoLocations: number[] = [];
  emptyThumbnailLocations: number[] = [];
  emptyTitles: number[] = [];
  videoLocationValidateMessage = environment.validations.emptyVideoLocationValidateMessage;
  thumbnailValidateMessage = environment.validations.emptyThumbnailValidateMessage;
  titleValidateMessage = environment.validations.emptyTitleValidateMessage;
  requestBody: any = {};
  destroy$ = new Subject();
  getContentIdSubscription;
  videoPromiseArray: any[] = [];
  thumbnailPromiseArray: any[] = [];
  saveBtnDisabled = false;
  isFormValid = true;
  progress1;
  progress2;
  constructor(private service: CommonService, private s3Bucket: S3BucketService,
              private alert: Alerts, private eventService: EventService) {
  }

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        this.videoChannelId = response.id;
      }
    });
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
  }

  onSelectOnVideo(event, index) {
    this.trailers.forEach(() => {
      for (const file of event.addedFiles) {
        this.videoFiles[index] = file;
        const reader =   new FileReader();
        reader.addEventListener('load', () => {

          const video = document.createElement('video') as HTMLVideoElement;
          video.onloadeddata = () => {
            this.runTimes[index] =  this.displayTime(video.duration);
            this.trailers[index].runTime = video.duration / 60;
            this.runTimes = [...this.runTimes];
          };
          video.src = reader.result as string;

        }, false );
        reader.readAsDataURL(file);
      }
    });
  }

  upload() {
    this.validatingRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.videoChannelId) {
      this.service.save(Service.VIDEO_CHANNEL.CONTENT, {type: environment.contentType.videoChannel})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.videoChannelId = response.id;
        this.eventService.getContentIdEventEmitter({id: response.id});
        this.videoS3Upload();
      });
    } else {
      this.videoS3Upload();
    }
    this.saveBtnDisabled = true;
  }

  videoS3Upload() {
    let index = 0;
    let videoUploadPromise;
    for (const trailer of this.trailers) {
      videoUploadPromise = new Promise((resolve, reject) => {
        this.s3Bucket.uploadFile(this.videoChannelId, this.videoFiles[index],
            environment.s3VideoBucket.path.trailers,
            environment.s3VideoBucket.buckets.content.bucket +
            environment.s3VideoBucket.buckets.content.destinationFolder +
            environment.s3VideoBucket.contentTypeFolder.videoChannels, (error, response) => {
              if (error) {
                reject({status: false, response: error});
              }
              trailer.videoFileLocation = response.Location;
              resolve({status: true, response});
            }).on('httpUploadProgress', (progress) => {
          this.progress2 = Math.round(progress.loaded / progress.total * 100);
        });
      });
      ++index;
      this.videoPromiseArray.push(videoUploadPromise);
    }
    Promise.all(this.videoPromiseArray).then(value => {
      if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
        this.thumbnailS3Upload();
        return;
      }
      this.alert.errorAlert(environment.s3Bucket.uploadError.title,
          environment.s3Bucket.uploadError.message);
    });
  }

  onSelectOnThumbnail(event, index) {
    this.trailers.forEach(() => {
      for (const file of event.addedFiles) {
        this.thumbnailFiles[index] = file;
      }
    });
  }

  thumbnailS3Upload() {
    let index = 0;
    let thumbnailUploadPromise;
    for (const trailer of this.trailers) {
      thumbnailUploadPromise = new Promise((resolve, reject) => {
        this.s3Bucket.uploadFile(this.videoChannelId, this.thumbnailFiles[index],
            environment.s3Bucket.path.trailers,
            environment.s3Bucket.buckets.bucketPath +
            environment.s3Bucket.buckets.destinationFolder +
            environment.s3Bucket.contentTypeFolder.videoChannels, (error, response) => {
              if (error) {
                reject({status: false, response: error});
              }
              trailer.thumbnailLocation = response.Location.
              replace(environment.s3Bucket.actualPath,
                  environment.s3Bucket.actualPathReplaceTo);
              resolve({status: true, response});
            }).on('httpUploadProgress', (progress) => {
          this.progress1 = Math.round(progress.loaded / progress.total * 100);
        });
      });
      ++index;
      this.thumbnailPromiseArray.push(thumbnailUploadPromise);
    }
    Promise.all(this.thumbnailPromiseArray).then(value => {
      if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
        this.save();
        return;
      }
      this.alert.errorAlert(environment.s3Bucket.uploadError.title,
          environment.s3Bucket.uploadError.message);
    });
  }

  addAnotherTrailer() {
    this.trailers.push({});
    this.initializeToggle(this.trailers.length);
  }

  deleteTrailer(index) {
    this.trailers.splice(index, 1);
    this.thumbnailFiles.splice(index, 1);
    this.videoFiles.splice(index, 1);
    this.titles.splice(index, 1);
    this.runTimes.splice(index, 1);

    this.resetValidations();
  }

  toggleChange(index) {
    this.previewToggle.forEach((value, i, array) => {
      this.previewToggle[i] = index === i;
    });
  }

  save() {
    this.createRequestBody();
    this.service.update(Service.VIDEO_CHANNEL.TRAILER, this.videoChannelId, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.alert.successAlert(environment.alerts.success.video_channel.tailersBouns.title,
          environment.alerts.success.video_channel.tailersBouns.message);
      this.saveBtnDisabled = false;
    }, error => {
      this.alert.errorAlert(environment.alerts.error.video_channel.tailersBouns.title,
          error.error.error);
      this.saveBtnDisabled = false;
    });
  }
  validatingRequiredFields() {
    for (let i = 0; i < this.trailers.length; i++) {
      if (this.previewToggle.includes(true)) {
        this.trailers.forEach((value, index, array) => {
          if (!this.videoFileLocation[index]) {
            this.emptyVideoLocations.push(index);
          }
          if (!this.thumbnailLocation[index]) {
            this.emptyThumbnailLocations.push(index);
          }
          if (!this.titles[index]) {
            this.emptyTitles.push(index);
          }
        });
      } else {
        this.alert.errorAlert(environment.alerts.error.video_channel.tailersBouns.previewToggle.title,
            environment.alerts.error.video_channel.tailersBouns.previewToggle.message);
      }
    }

    if (!this.previewToggle.includes(true)) {
      this.isFormValid = false;
    }

    if (this.emptyVideoLocations.length > 0 ||
        this.emptyThumbnailLocations.length > 0 ||
        this.emptyTitles.length > 0) {
      this.isFormValid = false;
    }
  }

  resetValidations() {
    this.isFormValid = true;
    this.emptyTitles = [];
    this.emptyVideoLocations = [];
    this.emptyThumbnailLocations = [];
  }

  initializeToggle(index) {
    this.previewToggle[index] = false;
  }

  displayTime(seconds) {
    const format = val => `0${Math.floor(val)}`.slice(-2);
    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;
    return [hours, minutes, seconds % 60].map(format).join(':');
  }


  hoursToSeconds(time) {
    const a = time.split(':'); // split it at the colons
    const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    return seconds;
  }


  createRequestBody() {
    this.requestBody.content_type = environment.contentType.videoChannel;
    this.requestBody.bonus_contents = [];
    for (let index = 0; index < this.trailers.length; index++) {

      let runTime;
      let runTimeInSeconds;
      if (this.trailers[index].runTime) {
        runTime = this.runTimes[index];
        runTimeInSeconds = this.hoursToSeconds(runTime);
      }
      if (this.previewToggle[index] === undefined) {
        this.previewToggle[index] = false;
      }
      this.requestBody.bonus_contents.push({
        posters: [{
          alt_tag: this.thumbnailFiles[index].name,
          converted_url: null,
          device_type: environment.deviceType.mobile,
          image_conversion_status: environment.conversionStatus.pending,
          image_name: this.thumbnailFiles[index].name,
          orientation_type: environment.orientations.landscape.toUpperCase(),
          source_url: this.trailers[index].thumbnailLocation.replace(environment.s3Bucket.actualPath,
              environment.s3Bucket.actualPathReplaceTo),
          resolution_type: environment.resolutionTypes.fourX,
          image_type: environment.resolutionImageType.imageWithTitle
        }],
        preview_enabled: this.previewToggle[index],
        run_time: runTimeInSeconds,
        source_url: this.trailers[index].videoFileLocation,
        title: {
          english: this.titles[index],
          sinhala: null,
          tamil: null
        },
        video_conversion_status: environment.mediaConvertStatus.pending
      });
    }

  }

}
