import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../../environments/environment';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {Alerts} from '../../../../../alert/Alert';
import {AddCategoryContentComponent} from '../add-category-content/add-category-content.component';
import {CategoryService} from '../../../../../core/service/common_service/ http/category/category.service';
import {PublishCategoryComponent} from '../publish-category/publish-category.component';

@Component({
    selector: 'app-add-new-category',
    templateUrl: './add-new-category.component.html',
    styleUrls: ['./add-new-category.component.scss']
})
export class AddNewCategoryComponent implements OnInit, OnDestroy {
    name: string;
    categoryNameValidationMessage: string;
    isCategoryNameValidated: boolean = true;
    description: string;
    NAME = 1;
    DESCRIPTION = 2;
    templates: any[] = [];
    selectedTemplate: any;
    TEMPLATE = 3;
    isCategoryTemplateValidated: boolean = true;
    categoryTemplateValidationMessage: string;
    rules: any[] = [];
    selectedRule: any;
    isRulesValidated: boolean = true;
    isCountriesValidated: boolean = true;
    countries: any[] = [];
    selectedCountries: any[] = [];
    rulesValidateMessage: string;
    countriesValidateMessage: string;
    saveBtnDisabled = false;
    env = environment;
    isFormValid: boolean = true;
    RULE = 4;
    COUNTRIES = 5;
    isAddContentDisabled: boolean = false;
    requestBody: any = {};
    isRuleDisabled: boolean = false;
    isCountryDisabled: boolean = false;
    selectedContents: any[] = [];
    isContentsValidated: boolean = true;
    contentsValidateMessage: string;
    destroy$ = new Subject();
    selectedMaturityRating: any;
    MATURITY_RATING = 6;
    isMaturityRatingValidated: boolean = true;
    maturityRatingValidateMessage: string;
    isMaturityRatingDisabled: boolean = false;
    maturityRating: any[] = [];
    isPublishDisabled = true;

    catogeryId;

    constructor(private model: MatDialogRef<AddNewCategoryComponent>,
                private matModel: MatDialog,
                private commonService: CommonService,
                private alert: Alerts,
                private categoryService: CategoryService) {
    }

    ngOnInit() {
        this.templates = this.env.categoryTemplates;
        this.rules = this.env.contentRulesArray;
        this.maturityRating = this.env.maturityRatingType;
        this.loadAllCountries();
    }

    ngOnDestroy(): void {
        /**
         * Unsubscribe all http request
         */
        this.destroy$.next();
        this.destroy$.complete();
    }

    closeWindow() {
        this.model.close();
    }

    resetValidation(field: number) {

        this.isFormValid = true;
        switch (field) {
            case this.NAME:
                this.isCategoryNameValidated = true;
                break;
            case this.TEMPLATE:
                this.isCategoryTemplateValidated = true;
                break;
            case this.RULE:
                this.isRulesValidated = true;
                break;
            case this.MATURITY_RATING:
                this.isMaturityRatingValidated = true;
                break;
            default:
                this.isCountriesValidated = true;
        }
    }

    addContent() {
        const matDialogRef = this.matModel.open(AddCategoryContentComponent, {
            disableClose: true,
            autoFocus: false,
            height: 'auto',
            minWidth: '100%',
            minHeight: '100vh',
            data: {
                data : this.selectedContents,
                rating : this.selectedMaturityRating
            }
        });

        matDialogRef.afterClosed().subscribe(value => {
            if (value) {
              for (const content of this.selectedContents) {
                value = value.filter(selectedValue => selectedValue.id !== content.id);
              }
              this.selectedContents = [...this.selectedContents, ...value];
            }
        });
    }

    save() {
        this.validateRequiredFields();
        this.createRequestBody();

        if (!this.isFormValid) {
            return;
        }
        this.commonService.save(Service.CATEGORY, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
            this.catogeryId = result.id;
            this.isPublishDisabled = false;
            this.saveBtnDisabled = true;
            this.alert.successAlert(environment.alerts.success.category.title,
                environment.alerts.success.category.message);
        }, error => {
            this.alert.errorAlert(environment.alerts.error.category.title,
                environment.alerts.error.category.message);
        });
    }

    validateRequiredFields() {

        if (!this.name) {
            this.isFormValid = false;
            this.isCategoryNameValidated = false;
            this.categoryNameValidationMessage = this.env.validations.categoryNameValidationMessage;
        }

        if (!this.selectedTemplate) {
            this.isFormValid = false;
            this.isCategoryTemplateValidated = false;
            this.categoryTemplateValidationMessage = this.env.validations.categoryTemplateValidationMessage;
        }

        if (this.selectedRule && this.selectedCountries.length <= 0) {
            this.isFormValid = false;
            this.isCountriesValidated = false;
            this.countriesValidateMessage = this.env.validations.countriesValidate;
        }

        if (!this.selectedRule && this.selectedCountries.length > 0) {
            this.isFormValid = false;
            this.isRulesValidated = false;
            this.rulesValidateMessage = this.env.validations.rulesValidateMessage;
        }


        if (this.selectedTemplate !== this.env.categoryTemplates[1].id) {
            if (this.selectedContents.length <= 0) {
                this.isFormValid = false;
                this.isContentsValidated = false;
                this.contentsValidateMessage = this.env.validations.contentsValidateMessage;
            }

            if (!this.selectedMaturityRating) {
                this.isFormValid = false;
                this.isMaturityRatingValidated = false;
                this.maturityRatingValidateMessage = this.env.validations.maturityValidateMessage;
            }
        }
    }

    selectTemplate(selectedTemplate: any) {
        if (selectedTemplate === this.env.categoryTemplates[1].id) {
            this.isAddContentDisabled = true;
            this.isRuleDisabled = true;
            this.isCountryDisabled = true;
            this.isMaturityRatingDisabled = true;
            return;
        }
        this.isAddContentDisabled = false;
        this.isRuleDisabled = false;
        this.isCountryDisabled = false;
    }

    createRequestBody() {
        this.requestBody = {};
        this.requestBody.name = this.name;
        this.requestBody.description = this.description;
        this.requestBody.display_type = this.selectedTemplate;

        if (this.selectedTemplate !== this.env.categoryTemplates[1].id) {
            this.requestBody.category_template = this.env.categoryTemplates[0].id;
            this.requestBody.contents = this.selectedContents.map(value => {
                return {
                    content_type: value.content_type,
                    id: value.id
                };
            });
            this.requestBody.country_ids = this.selectedCountries;
            this.requestBody.rule_type = this.selectedRule;
            this.requestBody.maturity_rating = this.selectedMaturityRating;
        } else {
          this.requestBody.category_template = this.selectedTemplate;
        }
    }

    loadAllCountries() {
        this.commonService.findAll(Service.COUNTRY)
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            this.countries = response;
        });
    }

    updateSelectedContents(event) {
        this.selectedContents = event.allData;
    }

    publishCategory() {
        let matDialogRef = this.matModel.open(PublishCategoryComponent, {
            disableClose: true,
            autoFocus: false,
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '90%',
            width: '70%',
            data: {
                id: this.catogeryId,
                title: this.name
            }
        });

        matDialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(value => {
            if (value) {
                this.closeWindow();
            }
        });
    }
}
