import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'app-video-channel-tab',
  templateUrl: './video-channel-tab.component.html',
  styleUrls: ['./video-channel-tab.component.scss']
})
export class VideoChannelTabComponent implements OnInit {

  @Input() template;
  @Input() isDetailView = false;
  env = environment;
  constructor() { }

  ngOnInit() {
  }

}
