import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {LiveChannelModelComponent} from './model/live-channel-model/live-channel-model.component';
import {environment} from '../../../../environments/environment';
import {LiveChannelService} from '../../../core/service/common_service/ http/contents/live-channel/live-channel.service';
import {takeUntil} from 'rxjs/operators';
import {LiveChannelEditModelComponent} from './model/live-channel-edit-model/live-channel-edit-model.component';

@Component({
  selector: 'app-live-channel',
  templateUrl: './live-channel.component.html',
  styleUrls: ['./live-channel.component.scss']
})
export class LiveChannelComponent implements OnInit, OnDestroy {

  destroy$ = new Subject();
  env = environment;

  selectedPage: number;
  tableData: any[] = [];
  selectedTab;
  loading = false;
  pageCount: any;

  constructor(private matModel: MatDialog,
              private liveChannelService: LiveChannelService) { }

  ngOnInit() {
    this.selectedPage = 1;
    this.selectedTab = this.env.contentStatus.published;
    this.findLiveChannels();
  }

  ngOnDestroy(): void {

    this.destroy$.next();
    this.destroy$.complete();
  }

  addNewLiveChannel() {
    this.matModel.open(LiveChannelModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
  }

  selectTab(event) {

    switch (event.tab.textLabel.toUpperCase()) {
      case this.env.contentStatus.published: {
        this.selectedTab = this.env.contentStatus.published;
        break;
      }
      case this.env.contentStatus.scheduled: {
        this.selectedTab = this.env.contentStatus.scheduled;
        break;
      }
      case this.env.contentStatus.processed: {
        this.selectedTab = this.env.contentStatus.processed;
        break;
      }
      case this.env.contentStatus.processing: {
        this.selectedTab = this.env.contentStatus.processing;
        break;
      }
      default: {
        this.selectedTab = this.env.contentStatus.unpublished + '&' +
            environment.contentStatusString + this.env.contentStatus.error;
      }
    }
    this.findLiveChannels();
  }


  setPage(event) {
    this.selectedPage = event;
  }

  findLiveChannels() {
    this.tableData = [];
    this.loading = true;
    this.liveChannelService.findAllByStatus(
        {status: environment.contentStatusString + this.selectedTab,
          contentType: environment.contentType.liveChannel,
          page: this.selectedPage - 1,
          size: this.env.tableProperties.size
        })
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

          let contents;
          if (response.contents_by_status) {
            contents = response.contents_by_status;
          }
          if (response.content_by_multiple_status) {
        contents = response.content_by_multiple_status;
      }
          this.tableData = contents.map((content, index) => {

        if (content.title) {
          content.videoTitle = content.title.english;
        }

        if (content.poster) {
          content.videoPoster = {
            isPoster: true,
            url: content.poster.source_url
          };
        }

        if (content.primary_destination) {
          content.primaryDestUrl = content.primary_destination;
        }

        if (content.secondary_destination) {
          content.secondaryDestUrl = content.secondary_destination;
        }

        if (content.live_channel_input_type) {
          content.inputType = content.live_channel_input_type;
        }

        if (content.stream_key) {
          content.streamKey = content.stream_key;
        }

        return content;
      });

    });

  }

  openDetailView(event, status) {
    const matDialogRef = this.matModel.open(LiveChannelEditModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: true,
        liveChannelId: event.selectedData.id,
        liveChannelTitle: event.selectedData.englishTitle,
        publishStatus: status,
        isError: event.isError
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      // this.findMovies();
    });
  }

  editLiveChannelPoster(event, status) {
    const matDialogRef = this.matModel.open(LiveChannelEditModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: true,
        liveChannelId: event.selectedData.id,
        liveChannelTitle: event.selectedData.englishTitle,
        publishStatus: status,
        isError: event.isError
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      // this.findMovies();
    });
  }
}
