import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {DatePipe} from '@angular/common';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class S3BucketService {

  constructor(private datePipe: DatePipe) { }
    env = environment;
    files;
    progressPercentage;

  uploadFile( id, files, path, location, callBack) {

      const currentDateAndTime = this.datePipe.transform(new Date(), 'dd-MMM-yy-h:mm:ss' ).replace(/:/g, '');
      let bucket: S3;
      if (location.includes("dflix-production/")) {
           bucket = new S3(
              {
                  accessKeyId: this.env.s3Bucket.accessKeyId,
                  secretAccessKey: this.env.s3Bucket.secretAccessKey,
                  region: this.env.s3VideoBucket.region
              }
          );
      } else {
           bucket = new S3(
              {
                  accessKeyId: this.env.s3Bucket.accessKeyId,
                  secretAccessKey: this.env.s3Bucket.secretAccessKey,
                  region: this.env.s3Bucket.region
              }
          );
      }
      const type = files.name.substring(files.name.indexOf('.') + 1);
      const params = {
              Bucket: location + id + path ,
              Key: currentDateAndTime + '_' + files.name.replace(/[^A-Z0-9]+/ig, "") + "." + type,
              Body: files,
              ContentType: files.type
          };
      return  bucket.upload(params, callBack);
      }

    uploadSliderGraphic( id, files, path, location, callBack) {

        const currentDateAndTime = this.datePipe.transform(new Date(), 'dd-MMM-yy-h:mm:ss' ).replace(/:/g, '');
        const bucket = new S3(
            {
                accessKeyId: this.env.s3Bucket.accessKeyId,
                secretAccessKey: this.env.s3Bucket.secretAccessKey,
                region: this.env.s3Bucket.region
            }
        );
        const params = {
            Bucket: location + path + id ,
            Key: currentDateAndTime + '_' + files.name.replace(/\s/g, ''),
            Body: files,
            ContentType: files.type

        };
        return  bucket.upload(params, callBack);
    }

    uploadSliderGraphicVideo( files, callBack) {

        const currentDateAndTime = this.datePipe.transform(new Date(), 'dd-MMM-yy-h:mm:ss' ).replace(/:/g, '');
        const bucket = new S3(
            {
                accessKeyId: this.env.s3Bucket.accessKeyId,
                secretAccessKey: this.env.s3Bucket.secretAccessKey,
                region: 'us-east-1'
            }
        );
        const params = {
            Bucket: 'slider-graphic-videos' ,
            Key: currentDateAndTime + '_' + files.name.replace(/\s/g, ''),
            Body: files,
            ContentType: files.type

        };
        return  bucket.upload(params, callBack);
    }

    getProgressPercentage() {
      return this.progressPercentage;
    }




    /*deleteFile(movieId, location: string, callBack) {
        const bucket = new S3(
            {
                accessKeyId: this.env.s3Bucket.accessKeyId,
                secretAccessKey: this.env.s3Bucket.secretAccessKey,
                region: this.env.s3Bucket.region
            }
        );
        const params = {
            Bucket: this.env.s3Bucket.bucket + movieId,
            Key: location,
        };
        bucket.deleteObject(params, callBack);*/

        // /*const params = {
        //     Bucket: this.env.s3Bucket.bucket + movieId,
        //     Key: file.name
        // };*/
        //
        // const params = {
        //     Bucket: this.env.s3Bucket.bucket + movieId,
        //     Key: file.name,
        // };
        // bucket.upload(params, callBack);
        // this.getS3Bucket().deleteObject(params, function (err, data) {
        //     if (err) {
        //         console.log('There was an error deleting your file: ', err.message);
        //         return;
        //     }
        //     console.log('Successfully deleted file.');
        // });
    // }
}
