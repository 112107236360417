import { Injectable } from '@angular/core';
import {HttpService} from '../../http.service';
import {environment} from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TvEpisodeService {

  constructor(private http: HttpService) { }

  validateSlug(slug) {
    return this.http.get<any>(environment.serverurl + '/tv-episodes/slugs?slug=' + slug);
  }

  findAllByStatus(options: any) {
    return this.http.get<any>(environment.serverurl + '/tv-episodes/status?' + options.status + '&page='
        + options.page + '&size=' +  options.size + '&maturity-rating=' + options.maturityRating);
  }

  updateProgress(body) {
    return this.http.post<any>(environment.serverurl + '/tv-episodes/media-convert/progress', body);
  }

  findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating) {
    return this.http.get<any>(environment.serverurl + '/tv-episodes/search?searchTerm=' + searchTerm
        + '&contentType=' + contentStatus + '&page=' + page + '&size=' + size + '&maturity-rating=' + maturityRating);
  }
}
