import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {Service} from '../../../../../core/service/common_service/services';
import {EventService} from '../../../../../core/service/event.service';
import {TitleCasePipe, UpperCasePipe} from '@angular/common';

@Component({
  selector: 'app-tv-episode-poster',
  templateUrl: './tv-episode-poster.component.html',
  styleUrls: ['./tv-episode-poster.component.scss']
})
export class TvEpisodePosterComponent implements OnInit, OnDestroy {

  config: any;
  selectValue: any[] = [];
  file: File[] = [];
  posters: any[] = [];
  files: File[] = [];
  tvEpisodeID: string;
  emptySubs: number[] = [];
  emptyScreens: number[] = [];
  emptyCaptions: number[] = [];
  subValidateMessage = environment.validations.emptySubValidateMessage;
  captionValidateMessage = environment.validations.emptyCaptionValidateMessage;
  screenTypeValidateMessage = environment.validations.emptyScreenTypeValidateMessage;
  requestBody: any = {};
  destroy$ = new Subject();
  isFormValid = true;
  getContentIdSubscription;
  saveBtnDisabled = false;
  promiseArray: any[] = [];
  IMAGE = 1;
  SCREEN = 2;
  CAPTION = 3;
  isPosterSizeValidate = true;
  PosterValidationMessage =  environment.validations.posterValidationMessage;
  constructor(
      private commonService: CommonService,
      private s3Bucket: S3BucketService,
      private alert: Alerts,
      private eventService: EventService,
      private titlePipe: TitleCasePipe,
      private upperCase: UpperCasePipe
  ) { }

  ngOnInit() {
    this.posters = [
      {file: this.file, isOrientationTypeDisable: true, isPosterSizeValidate: true},
      {file: this.file, isOrientationTypeDisable: true, isPosterSizeValidate: true}
    ];
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        this.tvEpisodeID = response.id;
      }
    });

    this.selectValue = environment.contentDetailsOrientationTypes;
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
  }

  onSelect(event, index) {
    this.posters[index].file  = event.addedFiles;
    this.posters[index].caption = this.posters[index].file[0].name;
    this.posters[index].isOrientationTypeDisable = false;
    this.posters[index].isPosterSizeValidate = true;
    this.posters[index].screenType = null;
    this.resetValidations(this.CAPTION, index);
  }
  addAnotherSub() {
    this.file = [];
    this.posters.push({file: this.file, isOrientationTypeDisable: true, isPosterSizeValidate: true});
  }

  deleteSubtitle( index ) {
    this.posters.splice( index, 1);
    this.emptyScreens.splice(this.emptyScreens.indexOf(index), 1);
    this.emptySubs.splice(this.emptySubs.indexOf(index), 1);
    this.emptyCaptions.splice(this.emptyCaptions.indexOf(index), 1);
  }

  createRequestBody() {
    this.requestBody.content_type = environment.contentType.tvEpisode;
    this.requestBody.posters = [];
    let screen = '';
    let orientation = '';
    let imageType = '';
    this.posters.forEach((poster, index) => {

      switch (poster.screenType) {
        case environment.orientationTypes[0].id:
          screen = environment.deviceType.tv;
          orientation = this.upperCase.transform(environment.orientations.landscape);
          imageType = environment.resolutionImageType.imageWithTitle;
          break;
        case environment.orientationTypes[1].id:
          screen = environment.deviceType.tablet;
          orientation = this.upperCase.transform(environment.orientations.portrait);
          imageType = environment.resolutionImageType.imageWithTitle;
          break;
        default:
          screen = environment.deviceType.tablet;
          orientation = this.upperCase.transform(environment.orientations.portrait);
          imageType = environment.resolutionImageType.imageWithTitle;
      }
      this.requestBody.posters.push({
          alt_tag : poster.caption,
          device_type : screen,
          orientation_type : orientation,
          image_name : poster.file[0].name,
          source_url : poster.imageLocation.replace(environment.s3Bucket.actualPath,
            environment.s3Bucket.actualPathReplaceTo),
          image_conversion_status : environment.conversionStatus.pending,
          image_type: imageType,
          resolution_type: environment.resolutionTypes.fourX
        }
      );
    });
  }

  validateRequiredFields() {

    const landscapeWithTitle = this.posters.filter(value => value.screenType === this.selectValue[0].id);

    if (landscapeWithTitle.length <= 0) {
      this.alert.errorAlert(
        environment.alerts.error.tv_episode.poster.screenType.landscapeWithTitle.title,
        environment.alerts.error.tv_episode.poster.screenType.landscapeWithTitle.message );
      this.isFormValid = false;
      return;
    }
    const portraitWithTitle = this.posters.filter(value => value.screenType === this.selectValue[1].id);

    if (portraitWithTitle.length <= 0) {
      this.alert.errorAlert(
        environment.alerts.error.tv_episode.poster.screenType.portraitWithTitle.title,
        environment.alerts.error.tv_episode.poster.screenType.portraitWithTitle.message );
      this.isFormValid = false;
      return;
    }

    let index = 0;
    for (const poster of this.posters) {

      if (!poster.imageLocation && this.emptySubs.indexOf(index) === -1) {
        this.emptySubs.push(index);
      }

      if (!poster.screenType && this.emptyScreens.indexOf(index) === -1) {
        this.emptyScreens.push(index);
      }

      if (!poster.caption && this.emptyCaptions.indexOf(index) === -1) {
        this.emptyCaptions.push(index);
      }

      ++index;
    }

    if (this.emptySubs.length > 0 || this.emptyScreens.length > 0 || this.emptyCaptions.length > 0) {
      this.isFormValid = false;
    }
  }

  resetValidations(field, index) {
    this.isFormValid = true;

    switch (field) {
      case this.SCREEN: {
        if (this.emptyScreens.indexOf(index) !== -1) {
          this.emptyScreens.splice(this.emptyScreens.indexOf(index), 1);
        }
        this.validateImageFormat(index, this.posters[index].screenType);
        break;
      }
      case this.IMAGE: {
        if (this.emptySubs.indexOf(index) !== -1) {
          this.emptySubs.splice(this.emptySubs.indexOf(index), 1);
        }

        break;
      }
      default: {
        if (this.emptyCaptions.indexOf(index) !== -1) {
          this.emptyCaptions.splice(this.emptyCaptions.indexOf(index), 1);
        }

      }
    }
  }

  uploadImages() {
    this.validateRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.tvEpisodeID) {
      this.commonService.save(Service.TV_EPISODE.CONTENT, {type: environment.contentType.tvEpisode})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.tvEpisodeID = response.id;
        this.eventService.getContentIdEventEmitter({id: response.id});
        this.s3upload();
      });
    } else {
      this.s3upload();
    }
    this.saveBtnDisabled = true;
  }


  s3upload() {
    let uploadPromise;
    for (const poster of this.posters) {
      uploadPromise = new Promise((resolve, reject) => {
        this.s3Bucket.uploadFile(this.tvEpisodeID, poster.file[0],
            environment.s3Bucket.path.posters,
            environment.s3Bucket.buckets.bucketPath +
            environment.s3Bucket.buckets.destinationFolder +
            environment.s3Bucket.contentTypeFolder.tvEpisodes,
            (error, response, pr) => {
              if (error) {
                reject({status: false, response: error});
              }
              poster.imageLocation = response.Location;
              resolve({status: true, response: response});
            }).on('httpUploadProgress', (progress) => {
          poster.progress = Math.round(progress.loaded / progress.total * 100);
        });
      });
      this.promiseArray.push(uploadPromise);
    }
    Promise.all(this.promiseArray).then(value => {
      if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
        this.save();
        return;
      }
      this.alert.errorAlert(environment.s3Bucket.uploadError.title,
          environment.s3Bucket.uploadError.message);
    });
  }

  save() {
    this.emptySubs = [];
    this.emptyScreens = [];
    this.emptyCaptions = [];
    this.createRequestBody();
    this.commonService.update(Service.TV_EPISODE.POSTER, this.tvEpisodeID, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.alert.successAlert(environment.alerts.success.movie.poster.title,
          environment.alerts.success.movie.poster.message);
      this.saveBtnDisabled = false;
    }, error2 => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(environment.alerts.error.movie.poster.title,
          error2.error.error);
    });
  }
  validateImageFormat(index, type) {
    let height;
    let width;
    const img = new Image();
    img.onload = () => {
      height = img.naturalHeight;
      width = img.naturalWidth;


      switch (type) {
        case environment.contentDetailsOrientationTypesTitle.landscapeImageWithTitle:
          if (height === environment.contentDetailsOrientationTypesSize.LANDSCAPE_IMAGE_WITH_TITLE.height &&
              width === environment.contentDetailsOrientationTypesSize.LANDSCAPE_IMAGE_WITH_TITLE.width) {
            this.posters[index].isPosterSizeValidate = true;
          } else {
            this.posters[index].isPosterSizeValidate = false;
            this.isFormValid = false;
          }
          break;

        case environment.contentDetailsOrientationTypesTitle.portraitImageWithTitle:
          if (height === environment.contentDetailsOrientationTypesSize.PORTRAIT_IMAGE_WITH_TITLE.height &&
              width === environment.contentDetailsOrientationTypesSize.PORTRAIT_IMAGE_WITH_TITLE.width) {
            this.posters[index].isPosterSizeValidate = true;
          } else {
            this.posters[index].isPosterSizeValidate = false;
            this.isFormValid = false;

          }
          break;






        default:
          this.isPosterSizeValidate = true;
      }
    };
    img.src = URL.createObjectURL(this.posters[index].file[0]);
  }
}
