import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {environment} from '../../../../../../environments/environment';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Alerts} from '../../../../../alert/Alert';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {EventService} from '../../../../../core/service/event.service';

@Component({
  selector: 'app-add-new-season',
  templateUrl: './add-new-season.component.html',
  styleUrls: ['./add-new-season.component.scss']
})
export class AddNewSeasonComponent implements OnInit {

  constructor( private model: MatDialogRef<AddNewSeasonComponent>, private service: CommonService, private alert: Alerts,
               @Inject(MAT_DIALOG_DATA) private data: any) {
    this.seasonNumber = this.data.tvShowID;
    this.seasons = this.data.seasons;
    console.log(this.seasons);
  }
  env = environment;
  seasonNumber: number;
  isSeasonNumberValidate = true;
  seasonNumberValidateMessage;
  seasons= [];
  seasonLabel: string;
  isSeasonLabelValidate = true;
  seasonLabelValidateMessage;
  requestBody: any = {};
  isFormValidate = false;
  destroy$ = new Subject();
  savedSeason: any;
  isDisable = false;
  tvEpisode = [];


  SEASON_NUMBER = 1;
  SEASON_LABEL = 2;
  tableData: any[] = [];
  getContentIdSubscription;
  pageCount: any;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow(this.savedSeason);
  }

  ngOnInit() {
  this.findTvEpisodeBySeason();
  }

  closeWindow(savedSeason) {
    this.model.close(this.savedSeason);
  }

  createRequestBody() {
    this.requestBody.id = this.seasonNumber;
    this.requestBody.label = this.seasonLabel;
  }

  validatingRequiredFields() {
    if (!this.seasonNumber) {
      this.isSeasonNumberValidate = false;
      this.seasonNumberValidateMessage = environment.validations.seasonNumberValidateMessage;
    }

    if (!this.seasonLabel) {
      this.isSeasonLabelValidate = false;
      this.seasonLabelValidateMessage = environment.validations.seasonLabelValidateMessage;
    }
  }

findTvEpisodeBySeason() {
    this.tableData = this.seasons.map((season) => {
      this.pageCount = (season.total) / this.env.tableProperties.size * 10;
      console.log(this.pageCount);
      season.tv_episode_list = season.tv_episode_list.map((id) => id.id);
      return season;
    });

}

  resetValidation(field) {
    switch (field) {
      case this.SEASON_NUMBER:
        this.isSeasonNumberValidate = true;
        break;
      case this.SEASON_LABEL:
        this.isSeasonLabelValidate = true;
        break;
    }
  }
  formValidation() {
    if (this.isSeasonLabelValidate && this.isSeasonNumberValidate) {
      this.isFormValidate = true;
    } else {
      this.isFormValidate = false;
    }
  }

  save() {
    this.isDisable = true;
    this.validatingRequiredFields();
    this.formValidation();
    this.createRequestBody();
    if (this.isFormValidate) {
      this.service.save(Service.TV_EPISODE.SEASON, this.requestBody).pipe(takeUntil(this.destroy$)).subscribe((result: any) => {

        this.savedSeason = result;
        this.alert.successAlert(environment.alerts.success.season.title, environment.alerts.success.season.message);
      }, error => {
          this.alert.errorAlert(environment.alerts.error.season.title, environment.alerts.error.season.message);
      });
    }
    this.isDisable = false;
  }



}
