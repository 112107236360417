import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {TvEpisodeModelComponent} from './model/tv-episode-model/tv-episode-model.component';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TitleCasePipe} from '@angular/common';
import {TvEpisodeService} from '../../../core/service/common_service/ http/content-details/tv-episodes/tv-episode.service';
import {Service} from '../../../core/service/common_service/services';
import {Alerts} from '../../../alert/Alert';
import {CommonService} from '../../../core/service/common_service/common.service';
import {CmsUserService} from '../../../core/service/common_service/ http/cms-user/cms-user.service';
import {ContentDetailService} from '../../../core/service/common_service/ http/content-details/content-detail.service';
import {TvShowService} from '../../../core/service/common_service/ http/contents/tv-shows/tv-show.service';
import {PackageTierService} from '../../../core/service/common_service/ http/packege-tier/package-tier.service';

@Component({
  selector: 'app-tv-episode',
  templateUrl: './tv-episode.component.html',
  styleUrls: ['./tv-episode.component.scss']
})
export class TvEpisodeComponent implements OnInit, OnDestroy {

  env = environment;
  destroy$ = new Subject();
  selectedPage: number;
  tableData: any[] = [];
  selectedTab;
  loading = false;
  deleteSubject: Subject<boolean> = new Subject();
  searchTerm: any;
  pageCount: any;
  cmsUsers: any[] = [];
  columnData: any[] = [];
  currentTvShowPage;
  totalTvShows = 0;
  tvShowsClone: any = [];
  tvShows: any [] = [];
  currentSeasonPage;
  totalSeasons = 0;
  seasonsClone: any = [];
  seasons: any [] = [];
  selectedTvShow;
  constructor(private matModel: MatDialog, private tvEpisodeService: TvEpisodeService,
              private cmsUserService: CmsUserService,
              private contentDetailService: ContentDetailService,
              private titleCasePipe: TitleCasePipe, private alert: Alerts, private commonService: CommonService,
              private tvShowService: TvShowService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.currentTvShowPage = this.env.page;
    this.selectedPage = 1;
    this.selectedTab = this.env.contentStatus.published;
    this.getTvShows();
    this.loadAllCmsUsers(this.env.cmsUserAction.create);
    this.loadAllCmsUsers(this.env.cmsUserAction.upload);
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchTvEpisode();
    } else {
      this.findTvEpisodes();
    }
    setInterval(() => {
      if (this.selectedTab === this.env.contentStatus.processing) {
        this.updateProgress();
      }
    }, 30000);
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadFilterData(action) {
    const filterDataArray = [];
    const modifiedUserData = this.cmsUsers.map(val => {
      return {
        id: val.id,
        value: val.name
      };
    });
    let selectedField;

    if (action === this.env.cmsUserAction.create) {
      selectedField = this.env.unPublishedTvEpisodeColumns[8].field;
    } else {
      selectedField = this.env.processedTvEpisodeColumns[5].field;
    }

    filterDataArray.push({
      field: selectedField,
      data: modifiedUserData
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllCmsUsers(action) {
    this.loading = true;
    this.cmsUserService.findAllContentsCmsUsers({status: action})
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.cmsUsers = response;
      this.loadFilterData(action);
      this.loading = false;
    });
  }

  addNewTvEpisode() {
    this.cd.detach();
    const matDialogRef = this.matModel.open(TvEpisodeModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: false
      }
    });

    matDialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.cd.reattach();
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchTvEpisode();
      } else {
        this.findTvEpisodes();
      }
    });
  }

  selectTab(event) {
    let action;
    switch (event.tab.textLabel.toUpperCase()) {
      case this.env.contentStatus.published: {
        action = this.env.cmsUserAction.create;
        action = this.env.cmsUserAction.upload;
        this.getTvShows();
        this.selectedTab = this.env.contentStatus.published;
        break;
      }
      case this.env.contentStatus.scheduled: {
        this.selectedTab = this.env.contentStatus.scheduled;
        break;
      }
      case this.env.contentStatus.processed: {
        this.getTvShows();
        action = this.env.cmsUserAction.upload;
        this.selectedTab = this.env.contentStatus.processed;
        break;
      }
      case this.env.contentStatus.processing: {
        this.selectedTab = this.env.contentStatus.processing;
        break;
      }
      default: {
        action = this.env.cmsUserAction.create;
        action = this.env.cmsUserAction.upload;
        this.getTvShows();
        this.selectedTab = this.env.contentStatus.unpublished;
      }
    }

    this.tableData = [];
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchTvEpisode();
    } else {
      this.findTvEpisodes();
    }
    if (action) {
      this.loadAllCmsUsers(action);
    }
  }

  findTvEpisodes() {

    this.loading = true;
    this.tvEpisodeService.findAllByStatus(
        {status: environment.contentStatusString + this.selectedTab,
          contentType: environment.contentType.movie,
          page: this.selectedPage - 1,
          size: this.env.tableProperties.size,
          maturityRating: 'MATURE'})
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.processTvEpisodeData(response);
        }, error => {
          this.loading = false;
        });

  }

  setPage(event) {
    this.selectedPage = event;
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchTvEpisode();
    } else {
      this.findTvEpisodes();
    }
  }
  openDetailView(event, status) {

    this.cd.detach();
    const matDialogRef = this.matModel.open(TvEpisodeModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: true,
        tvEpisodeId: event.selectedData.id,
        tvEpisodeTitle: event.selectedData.englishTitle,
        publishStatus: status
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      this.cd.reattach();
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchTvEpisode();
      } else {
        this.findTvEpisodes();
      }
    });
  }

  deleteContent(event) {
    this.alert.confirm(null, environment.alerts.success.movie.deleteMovie.confirmationMessage,
        environment.alerts.success.movie.deleteMovie.confirmButton).then(value => {
      if (value.isConfirmed) {
        this.commonService.delete(Service.TV_EPISODE, event.selectedData.id).subscribe(val => {
          this.deleteSubject.next(true);
        });
      }
    });
  }

  updateProgress() {
    const ids = this.tableData.map(value => value.id);
    this.tvEpisodeService.updateProgress(ids).pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.tableData.forEach((value, index) => {
          value.progress = response.find(obj =>  obj.id === value.id).progress;
        });
      }, error => {
        console.log(error);
      });
  }

  searchTvEpisode() {
    this.loading = true;
    this.commonService.search(Service.TV_EPISODE, this.selectedTab, this.searchTerm,
      this.selectedPage - 1, this.env.tableProperties.size, 'MATURE')
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          this.processTvEpisodeData(response);
        }, error => {
          this.loading = false;
        });
  }

  processTvEpisodeData(response) {
    let arr;
    if (response.content_detail_by_multiple_status) {
      arr = response.content_detail_by_multiple_status;
    } else {
      arr = response.contents_by_status;
    }
    this.pageCount = (response.total) / this.env.tableProperties.size * 10;
    this.tableData = arr.map((content, index) => {
      if (content.title) {
        content.englishTitle = content.title.english;
      }

      if (content.title) {
        content.sinhalaTitle = content.title.sinhala;
      }

      if (content.title) {
        content.tamilTitle = content.title.tamil;
      }

      if (content.tv_show_title) {
        content.tvShowEnglishTitle = content.tv_show_title.english;
      }

      if (content.tv_show_title) {
        content.tvShowSinhalaTitle = content.title.sinhala;
      }

      if (content.tv_show_title) {
        content.tvShowTamilTitle = content.title.tamil;
      }

      if (content.uploaded_by) {
        content.uploadedBy = content.uploaded_by.name;
      }

      if (content.poster) {
        content.poster = {
          isPoster: true,
          url: content.poster.source_url
        };
      }

      if (content.published_date) {

        let date = new Date(content.published_date),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();

        if (month.length < 2) { month = '0' + month; }
        if (day.length < 2) { day = '0' + day; }

        content.published_date = [year, month, day].join('-');
      }

      if (content.job_type) {
        content.job_type = content.job_type.join(',');
      }

      return content;
    });

    this.loading = false;
  }

  findAllByFilter(filterData) {
    let publishedDateRange = [];
    let releasedDateRange = [];
    const queryObj = {
      createdBy: null,
      uploadedBy: null,
      tvShow: null,
      season: null,
      contentStatus: this.selectedTab,
      page: this.selectedPage - 1,
      size: this.env.size,
      lessThanReleasedDateAndTime: null,
      greaterThanReleasedDateAndTime: null,
      lessThanPublishedDateAndTime: null,
      greaterThanPublishedDateAndTime: null
    };

    filterData.forEach(filter => {
      if (filter.field === this.env.unPublishedTvEpisodeColumns[8].field) {
        const filteredCmsUsers = this.cmsUsers.filter(val => val.id === filter.value);
        if (filteredCmsUsers.length > 0) {
          queryObj.createdBy = filteredCmsUsers[0].name;
        }
      }

      if (filter.field === this.env.processedTvEpisodeColumns[5].field) {
        const filteredCmsUsers = this.cmsUsers.filter(val => val.id === filter.value);
        if (filteredCmsUsers.length > 0) {
          queryObj.uploadedBy = filteredCmsUsers[0].name;
        }
      }

      if (filter.field === this.env.publishedTvEpisodeColumns[6].field) {
        publishedDateRange = filter.value.split(' to ');
        if (publishedDateRange.length > 1) {
          queryObj.greaterThanPublishedDateAndTime = publishedDateRange[0].concat(' ') + '00:00:00';
          queryObj.lessThanPublishedDateAndTime = publishedDateRange[1].concat(' ') + '00:00:00';
        }
      }

      if (filter.field === this.env.publishedTvEpisodeColumns[7].field) {
        releasedDateRange = filter.value.split(' to ');
        if (releasedDateRange.length > 1) {
          queryObj.greaterThanReleasedDateAndTime = releasedDateRange[0];
          queryObj.lessThanReleasedDateAndTime = releasedDateRange[1];
        }
      }

      if (filter.field === this.env.publishedTvEpisodeColumns[8].field) {
        this.selectedTvShow = filter.value;
        this.getTvShowSeasons(this.selectedTvShow);
        queryObj.tvShow = filter.value;
      }

      if (filter.field === this.env.unPublishedTvEpisodeColumns[4].field) {
        queryObj.season = filter.value;
      }
    });
    if (queryObj.uploadedBy
      || queryObj.createdBy
      || queryObj.tvShow
      || queryObj.season
      || queryObj.greaterThanPublishedDateAndTime
      || queryObj.greaterThanReleasedDateAndTime) {
      if ((releasedDateRange.length === 1) && (releasedDateRange[0] !== '')) {
        return;
      }

      if ((publishedDateRange.length === 1) && (publishedDateRange[0] !== '')) {
        return;
      }

      this.loadDataByFilters(queryObj);
    } else if (filterData.filter(filter => filter.value).length <= 0) {
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchTvEpisode();
      } else {
        this.findTvEpisodes();
      }
    }
  }

  loadDataByFilters(queryObj) {
    this.loading = true;
    this.contentDetailService.contentByFilters(this.env.contentType.tvEpisode, queryObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.tableData = [];
        this.processTvEpisodeData(response);
        this.loading = false;
      }, error => {
        console.log(error);
        this.loading = false;
      });
  }

  getTvShows() {
    const filterDataArray = [];
    this.tvShowService.findAll({
      page: this.currentTvShowPage,
      size: this.env.size
    })
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalTvShows = response.total;
      const tvShowsResponse = response.data;
      this.tvShows = [...this.tvShows, ...tvShowsResponse];

      this.tvShowsClone = [...this.tvShowsClone, ...tvShowsResponse];

      const tvShow = this.env.publishedTvEpisodeColumns[8].field;

      filterDataArray.push({
        field: tvShow,
        data: this.tvShows.map(val => {
          return {
            id: val.id,
            value: val.title
          };
        })
      });

      this.columnData = [...this.columnData, ...filterDataArray];
    });
  }

  getTvShowSeasons(selectedTvShow) {
    if (!selectedTvShow) {
      return;
    }
    const filterDataArray = [];
    this.tvShowService.seasonsById(selectedTvShow)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      const seasonsResponse = response.data;
      this.seasons = [...this.seasons, ...seasonsResponse];

      this.seasonsClone = [...this.seasonsClone, ...seasonsResponse];

      const season = this.env.publishedTvEpisodeColumns[4].field;

      filterDataArray.push({
        field: season,
        data: this.seasons.map(val => {
          return {
            id: val.id,
            value: val.label
          };
        })
      });

      this.columnData = [...this.columnData, ...filterDataArray];
    });

  }

  scroll(col) {
    if (col === this.env.unPublishedTvEpisodeColumns[4].field) {
      // ++this.currentSeasonPage;
      // if (this.seasonsClone.length < this.totalSeasons) {
      //   this.getTvShowSeasons(this.selectedTvShow);
      // }
    } else if (col === this.env.publishedTvEpisodeColumns[8].field) {
      ++this.currentTvShowPage;
      if (this.tvShowsClone.length < this.totalTvShows) {
        this.getTvShows();
      }
    }
  }

  onKeyPress() {
    if (!this.searchTerm) {
      this.searchTvEpisode();
    }
  }
}
