import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LiveChannelService} from '../../../../../core/service/common_service/ http/contents/live-channel/live-channel.service';
import {ContentData, programmeDate} from '../../class/Content';
import {environment} from '../../../../../../environments/environment';
import {ProgrammeScheduleService} from '../../../../../core/service/common_service/ http/programme-schedule/programme-schedule.service';
import {AddProgrammeScheduleComponent} from '../add-programme-schedule/add-programme-schedule.component';
import {Alerts} from '../../../../../alert/Alert';
const moment = require('moment');

@Component({
  selector: 'app-programme-schedule',
  templateUrl: './programme-schedule.component.html',
  styleUrls: ['./programme-schedule.component.scss']
})
export class ProgrammeScheduleComponent implements OnInit {

  liveData: ContentData;
  dates: programmeDate[] = [];
  programmes: any[] = [];
  liveChannelId;
  loading = false;
  initialData;
  env = environment;
  selected: any = {};
  selectedRowIds;
  constructor(private matModel: MatDialog, private liveChannelService: LiveChannelService,
              private programmeScheduleService: ProgrammeScheduleService,
              private alert: Alerts,
              private model: MatDialogRef<ProgrammeScheduleComponent>, @Inject(MAT_DIALOG_DATA) private data) {
    this.initialData = data;
    this.liveChannelId = this.initialData.liveChannelId;
    this.liveData = this.initialData.liveData;
  }

  ngOnInit() {
    this.liveData.programme_date.current_days.display_name = 'TODAY';
    this.dates = [...this.liveData.programme_date.previous_days.reverse(), this.liveData.programme_date.current_days,
      ...this.liveData.programme_date.next_days];
    this.getTelecastProgramsByDate(this.liveData.programme_date.current_days.date);
    this.selected.index = 7;
  }

  closeWindow() {
    this.model.close();
  }

  getTelecastProgramsByDate(date) {
    this.loading = true;
    this.liveChannelService.findAllWithProgrammes( this.liveChannelId, date)
      .subscribe(
        (res) => {
          this.programmes = [];
          const totalProgrammes = res.programmes.map((programme) => {
            return {
              duration: programme.duration,
              end_time: moment(programme.end_date).local().add(moment().utcOffset(),
                'minutes').format('YYYY-MMM-DD h:mm A'),
              id: programme.id,
              poster: {
                isPoster: true,
                url: this.getPoster(programme.posters)
              },
              start_time: moment(programme.start_date).local().add(moment().utcOffset(),
                'minutes').format('YYYY-MMM-DD h:mm A'),
              name: programme.title
            };
          });
          this.programmes = [...this.programmes, ...totalProgrammes];
          this.loading = false;
        }
      );
  }

  getPoster(Posters) {
    const poster = Posters.filter(pos => pos.orientation_type === 'LANDSCAPE' && pos.source_url)[0];
    return (!!poster) ? poster.source_url : 'assets/images-bundle/not-found.png';
  }
  delete(data) {
    this.alert.confirm(null, environment.alerts.success.movie.deleteMovie.confirmationMessage,
      environment.alerts.success.movie.deleteMovie.confirmButton).then(value => {
        // console.log(this.selected);
        if (value.isConfirmed) {
        this.programmeScheduleService.delete(data.selectedData.id).subscribe(response => {
          console.log(this.dates[this.selected.index].date);
          this.getTelecastProgramsByDate(this.dates[this.selected.index].date);
        });
      }
    });
  }

  selectDate(selected) {
    this.selected = selected;
    this.getTelecastProgramsByDate(this.dates[selected.index].date);
  }

  addSchedule() {
    const matDialogRef = this.matModel.open(AddProgrammeScheduleComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '90%',
      width: '90%',
      data: {
        liveChannelId: this.initialData.liveChannelId
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
    });
  }

  deleteMultiple(event) {
    console.log(event);
    this.selectedRowIds = event.map(data => {
      return data.id;
    });
  }

  deleteMultipleBtnClick() {
    console.log(this.selectedRowIds);
    if (this.selectedRowIds.length > 0) {
      this.alert.confirm(null, environment.alerts.success.movie.deleteMovie.confirmationMessage,
        environment.alerts.success.movie.deleteMovie.confirmButton).then(value => {
        if (value.isConfirmed) {
          this.programmeScheduleService.deleteMultiple({ids: this.selectedRowIds}).subscribe(response => {
            console.log(this.dates[this.selected.index].date);
            this.getTelecastProgramsByDate(this.dates[this.selected.index].date);
          });
        }
      });
    }
  }
}
