import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CommonServiceService {

  constructor(private http: HttpService) { }

  findDomain(contentType) {
    return this.http.get<any>(environment.serverurl + '/commons/contents/' + contentType + '/domain');
  }

  findAllWizardStatus(id, contentType) {
    return this.http.get<any>(environment.serverurl + '/commons/contents/' + id + '/types/' +
        contentType + '/publishes');
  }

  validateSlug(slug, contentType) {
    if (contentType === environment.contentType.movie) {
      return this.http.get<any>(environment.serverurl + '/movies/slugs?slug=' + slug);
    } else if (contentType === environment.contentType.tvShow) {
      return this.http.get<any>(environment.serverurl + '/tv-shows/slugs?slug=' + slug);
    } else if (contentType === environment.contentType.tvEpisode) {
      return this.http.get<any>(environment.serverurl + '/tv-episodes/slugs?slug=' + slug);
    } else if (contentType === environment.contentType.videoChannel) {
      return this.http.get<any>(environment.serverurl + '/video-channel/slugs?slug=' + slug);
    } else if (contentType === environment.contentType.video) {
      return this.http.get<any>(environment.serverurl + '/videos/slugs?slug=' + slug);
    }

  }

  findBySearchTerm(searchTerm, contentStatus, contentType, page, size) {
    if ((contentType !== environment.contentType.tvEpisode) && (contentType !== environment.contentType.video)) {
      return this.http.get<any>(environment.elasticSearchUrl + '/contents/' + contentType +
        '/' + contentStatus + '/' + searchTerm + '?page=' + page + '&limit=' + size);
    } else {
      return this.http.get<any>(environment.elasticSearchUrl + '/content-details/' + contentType +
        '/' + contentStatus + '/' + searchTerm + '?page=' + page + '&limit=' + size);
    }
  }

}
