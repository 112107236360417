import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publish-live-channel-model',
  templateUrl: './publish-live-channel-model.component.html',
  styleUrls: ['./publish-live-channel-model.component.scss']
})
export class PublishLiveChannelModelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
