import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {EventService} from '../../../../../core/service/event.service';
import {Alerts} from '../../../../../alert/Alert';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-live-channel-edit-model',
  templateUrl: './live-channel-edit-model.component.html',
  styleUrls: ['./live-channel-edit-model.component.scss']
})
export class LiveChannelEditModelComponent implements OnInit, OnDestroy {
  env = environment;
  getContentIdSubscription;
  destroy$ = new Subject();
  liveChannelId;
  title;
  initialData;
  publishStatus;
  publishButton = environment.masterDataStatus.published;

  editToggle = false;
  isEditButtonDisable = false;

  constructor( private model: MatDialogRef<LiveChannelEditModelComponent>, private matModel: MatDialog,
               private commonServiceService: CommonServiceService,
               private eventService: EventService,
               private alert: Alerts,
               @Inject(MAT_DIALOG_DATA) private data,
               private service: CommonService) {
    this.initialData = data;

  }

  ngOnInit() {
    this.liveChannelId = this.initialData.liveChannelId;
    this.title = this.initialData.liveChannelTitle;
    this.publishStatus = this.initialData.publishStatus;

    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        if (response.id) {
          this.liveChannelId = response.id;
        }
        if (response.title) {
          this.title = response.title;
        }
      }
    });
    if (this.liveChannelId) {
      this.sendIdToTab();
    }

  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.editToggle = false;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }
  closeWindow() {
    this.eventService.sendEditDetails({viewEnable: false});
    this.model.close();
  }
  // publishMovie() {
  //   if (!this.movieId) {
  //     return;
  //   }
  //
  //   if (this.publishButton === this.env.masterDataStatus.published) {
  //     this.commonServiceService.findAllWizardStatus(this.movieId, environment.contentType.movie)
  //         .subscribe(response => {
  //           let matDialogRef = this.matModel.open(PublishMovieModelComponent, {
  //             disableClose: true,
  //             autoFocus: false,
  //             maxWidth: '100vw',
  //             maxHeight: '100vh',
  //             height: '90%',
  //             width: '90%',
  //             data: {
  //               id: this.movieId,
  //               title: this.title
  //             }
  //           });
  //
  //           matDialogRef.afterClosed().subscribe(value => {
  //             if (value) {
  //               this.closeWindow();
  //             }
  //           });
  //         }, error => {
  //           if (!error.error.data.metadata_status) {
  //             this.alert.errorAlert(this.env.publish.error.movie.contentWizadMetaData, '');
  //           }
  //           if (!error.error.data.videos_status) {
  //             this.alert.errorAlert(this.env.publish.error.movie.contentWizadVideo, '');
  //           }
  //           if (!error.error.data.monetization_status) {
  //             this.alert.errorAlert(this.env.publish.error.movie.contentWizadMonetization , '');
  //           }
  //           if (!error.error.data.posters_status ) {
  //             this.alert.errorAlert(this.env.publish.error.movie.contentWizadPoster , '');
  //           }
  //         });
  //   }
  //
  //   if (this.publishButton === environment.masterDataStatus.unpublished) {
  //     this.service.unPublish(Service.MOVIE.UNPUBLISH, this.movieId, {country_id: 0, un_publish_general_rule: true})
  //         .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
  //       this.alert.successAlert(environment.publish.success.movie.unpublishTitle, environment.publish.success.movie.unpublishMessage);
  //       this.isEditButtonDisable = true;
  //       this.eventService.sendEditDetails({viewEnable: true});
  //       this.publishButton = this.env.masterDataStatus.published;
  //     });
  //   }
  //
  //
  //
  //
  //
  // }
  sendIdToTab() {
    this.eventService.sendContentIdToTab({id: this.liveChannelId, publishedStatus: this.publishStatus, isEditEnable: false});
  }

  editLiveChannel() {
    if (this.publishButton === this.env.masterDataStatus.unpublished) {
      this.alert.confirm(null, environment.alerts.success.live_channel.unPublish.message,
          environment.alerts.success.live_channel.unPublish.confirmButton).then(value => {
        if (value.isConfirmed) {
          this.isEditButtonDisable = true;

          this.service.unPublish(Service.LIVE_CHANNEL.UNPUBLISH, this.liveChannelId, {country_id: 0, un_publish_general_rule: true})
              .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            this.eventService.sendEditDetails({viewEnable: true});
            this.publishButton = this.env.masterDataStatus.published;
          });
        }
      });
    }


    if (this.publishButton === this.env.masterDataStatus.published) {
      this.eventService.sendEditDetails({viewEnable: true});
    }
  }

}
