import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PosterService {

  constructor(private http: HttpService) { }

  savePoster(body) {
    return this.http.post(environment.serverurl + '/contents/posters', body);
  }
  getPosterById(id, contentType) {
    return this.http.get(environment.serverurl + '/contents/' + id + '/posters?content=' + contentType);
  }
  getAllPoster() {
    return this.http.get(environment.serverurl);
  }
  updatePoster(id, body) {
    return this.http.put(environment.serverurl + '/contents/posters/' + id, body);
  }
  deletePoster(id) {
    return this.http.delete(environment.serverurl + '' + id);
  }
  getDetailPosterById(id, contentType) {
    return this.http.get(environment.serverurl + '/content-details/' + id + '/posters?content=' + contentType);
  }
}
