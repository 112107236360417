import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpService} from '../http.service';
import {idList} from 'aws-sdk/clients/datapipeline';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeScheduleService {

  constructor(private http: HttpService) { }

  create(body) {
    return this.http.post<any>(environment.serverurl + '/programme-schedules/create-list', body);
  }

  createSingleSchedule() {
    return this.http.post<any>(environment.serverurl + '/programme-schedules/create', {});
  }

  update(id, body) {
    return this.http.put<any>(environment.serverurl + '/programme-schedules/' + id, body);
  }

  delete(id) {
    return this.http.delete<any>(environment.serverurl + '/programme-schedules/' + id);
  }

  deleteMultiple(body: any) {
    return this.http.post<any>(environment.serverurl + '/programme-schedules', body);
  }
}
