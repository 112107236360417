import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PublishTvShowModelComponent} from '../publish-tv-show-model/publish-tv-show-model.component';
import {Subject} from 'rxjs';
import {Alerts} from '../../../../../alert/Alert';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {EventService} from '../../../../../core/service/event.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from '../../../../../core/service/common_service/common.service';


@Component({
  selector: 'app-tv-show-model',
  templateUrl: './tv-show-model.component.html',
  styleUrls: ['./tv-show-model.component.scss']
})
export class TvShowModelComponent implements OnInit, OnDestroy {
  env = environment;
  destroy$ = new Subject();
  tvShowId;
  title;
  getContentIdSubscription;
  initialData;
  isEditButtonDisable = false;
  publishButton = environment.masterDataStatus.published;
  publishStatus;
  editToggle = false;

  constructor(private model: MatDialogRef<TvShowModelComponent>, private matModel: MatDialog,
              private commonServiceService: CommonServiceService, private alert: Alerts,
              private eventService: EventService, private service: CommonService,
              @Inject(MAT_DIALOG_DATA) private data) {
    this.initialData = data;
  }

  ngOnInit() {

    this.tvShowId = this.initialData.tvShowId;
    this.title = this.initialData.tvShowTitle;
    this.publishStatus = this.initialData.publishStatus;

    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        if (response.id) {
          this.tvShowId = response.id;
        }

        if (response.title) {
          this.title = response.title;
        }
      }
    });
    if (this.tvShowId) {
      this.sendIdToTab();
    }

    if (this.publishStatus === environment.contentStatus.published) {
      this.publishButton = environment.masterDataStatus.unpublished;
    }

    if (this.publishStatus === environment.contentStatus.unpublished) {
      this.publishButton = environment.masterDataStatus.published;
    }
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
    this.editToggle = false;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }
  closeWindow() {
    this.eventService.sendEditDetails({viewEnable: false});
    this.model.close();
  }
  publishTvShow() {
    if (!this.tvShowId) {
      return;
    }

    if (this.publishButton === this.env.masterDataStatus.published) {
      this.commonServiceService.findAllWizardStatus(this.tvShowId, environment.contentType.tvShow)
          .subscribe(response => {
            let matDialogRef  = this.matModel.open(PublishTvShowModelComponent, {
              disableClose: true,
              autoFocus: false,
              maxWidth: '100vw',
              maxHeight: '100vh',
              height: '90%',
              width: '90%',
              data: {
                id: this.tvShowId,
                title: this.title.english
              }
            });
            matDialogRef.afterClosed().subscribe(value => {
              if (value) {
                this.closeWindow();
              }
            });
          }, error => {
            if (!error.error.data.monetization_status) {
              this.alert.errorAlert(this.env.publish.error.movie.contentWizadMonetization, '');
            }
            if (!error.error.data.metadata_status) {
              this.alert.errorAlert(this.env.publish.error.movie.contentWizadMetaData, '');
            }
            if (!error.error.data.posters_status) {
              this.alert.errorAlert(this.env.publish.error.movie.contentWizadPoster, '');
            }
          });
    }
    if (this.publishButton === environment.masterDataStatus.unpublished) {
      // TODO Remove above, uncomment these things and complete

      // this.isEditButtonDisable = true;
      // this.eventService.sendEditDetails({viewEnable: true});
      // this.publishButton = this.env.masterDataStatus.published;
      this.service.unPublish(Service.TV_SHOW.UNPUBLISH, this.tvShowId, {country_id: 0, un_publish_general_rule: true})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.alert.successAlert(environment.publish.success.movie.unpublishTitle, environment.publish.success.movie.unpublishMessage);
        this.isEditButtonDisable = true;
        this.eventService.sendEditDetails({viewEnable: true});
        this.publishButton = this.env.masterDataStatus.published;
      });
    }

  }


  getEventData() {
    this.eventService.sendContentIdToTab({id: this.tvShowId});
  }
  sendIdToTab() {
    this.eventService.sendContentIdToTab({id: this.tvShowId, publishedStatus: this.publishStatus, isEditEnable: false});
  }

  editMovie() {

    if (this.publishButton === this.env.masterDataStatus.unpublished) {
      this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
          environment.alerts.success.movie.unPublish.confirmButton).then(value => {
        if (value.isConfirmed) {
          this.isEditButtonDisable = true;

          // // remove these and uncomment followings
          // this.eventService.sendEditDetails({viewEnable: true});
          // this.publishButton = this.env.masterDataStatus.published;


        this.service.unPublish(Service.TV_SHOW.UNPUBLISH, this.tvShowId, {country_id: 0, un_publish_general_rule: true})
              .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            this.eventService.sendEditDetails({viewEnable: true});
            this.publishButton = this.env.masterDataStatus.published;
          });
        }
      });
    }


    if (this.publishButton === this.env.masterDataStatus.published) {
      this.eventService.sendEditDetails({viewEnable: true});
    }
  }


}
