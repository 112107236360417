import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog, MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit, OnChanges {

  @Input()
   dropDownDataSource: MatTableDataSource<any>;
  @Input()
   addNewComponent: any;
  @Input()
   dropDownHeader: string;
  @Output()
   getSelectedData = new EventEmitter<any> ();
   dataColumns = ['name'];
   isExpaned = false;
   lastIndex =  -1;
   lastSelectedValue = undefined;
   dropDownDataClone = [];
   initRemoveData = {};
   initIndex;
  @Input()
   isDisabled = false;
  @Output()
  private resetComponent = new EventEmitter<any>();

  isAddNewModelOpen = false;
  constructor( private dialog: MatDialog ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.dropDownDataSource) {
      this.dropDownDataClone = JSON.parse(JSON.stringify(this.dropDownDataSource.filteredData));
      this.dropDownDataSource.filterPredicate = (data, filter: string): boolean => {
        return data.name.toLowerCase().includes(filter);
      };
    }
    const index =  this.dropDownDataClone.findIndex( data => data.name === this.dropDownHeader);
    if (index >= 0) {
      this.initIndex = index;
      this.initRemoveData = this.dropDownDataClone[index];
      this.dropDownDataClone.splice(index, 1);
      this.dropDownDataSource = new MatTableDataSource(this.dropDownDataClone);
      return;
    }
    this.initRemoveData = undefined;
  }
  categoryFilter( filterValue: string ) {
    this.dropDownDataSource.filter = filterValue.trim().toLowerCase();
  }
  selectData(selectedData, index) {

    if (this.lastIndex === -1 && !this.lastSelectedValue) {
      this.dropDownDataClone.splice(index, 1);
      if ( this.initRemoveData) {
        this.dropDownDataClone.splice(this.initIndex - 1, 0, this.initRemoveData );
        this.initRemoveData = {};
      }
      this.dropDownDataSource = new MatTableDataSource(this.dropDownDataClone);
    } else {
      this.dropDownDataClone.splice(index, 1);
      this.dropDownDataClone.splice(this.lastIndex, 0, this.lastSelectedValue);
      this.dropDownDataSource = new MatTableDataSource(this.dropDownDataClone);
    }
    this.dropDownHeader = selectedData.name;
    this.getSelectedData.emit(selectedData);
    this.isExpaned = false;
    this.lastIndex = index;
    this.lastSelectedValue = selectedData;
  }

  addNew() {
    this.isAddNewModelOpen = true;
    const dialogRef = this.dialog.open(this.addNewComponent, {
      disableClose : true,
      autoFocus: false,
      width: '1000px',
      maxHeight: '70vh',
      panelClass: 'mobile-size'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isAddNewModelOpen = false;
      if (result) {
        if (result.data) {
          this.dropDownDataClone.push(result.data);
          this.dropDownDataSource = new MatTableDataSource(this.dropDownDataClone);
          const index =  this.dropDownDataClone.findIndex( data => data._id === result.data._id);
          this.selectData(result.data, index);
        }
      }
    });
  }

  reset(header, data) {
  this.dropDownHeader = header;
  this.dropDownDataSource = data;
  }

}
