import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProblemService} from '../../../core/service/common_service/ http/problem/problem.service';
import {environment} from '../../../../environments/environment';
import {Alerts} from '../../../alert/Alert';

@Component({
  selector: 'app-problem-detail-view',
  templateUrl: './problem-detail-view.component.html',
  styleUrls: ['./problem-detail-view.component.scss']
})
export class ProblemDetailViewComponent implements OnInit {

  problemDetails: any;
  loading: any = false;
  constructor(private model: MatDialogRef<ProblemDetailViewComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
              private problemService: ProblemService, private alert: Alerts) {
    this.problemDetails = data.data;
  }

  ngOnInit() {
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  closeWindow() {
    this.model.close();
  }

  solveProblem() {
    this.loading = true;
    this.problemService.solveProblem({id: this.problemDetails.id})
        .subscribe(value => {
          this.loading = false;
          this.alert.successAlert(environment.publish.success.problem.title, environment.publish.success.problem.message);
        });
  }
}
