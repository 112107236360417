import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationTemplateService {

  constructor(private http: HttpService) { }

  update(id, body) {
    return this.http.put(environment.serverurl + '/notifications/templates/' + id, body);
  }

  findAllByContentType(contentType, page, size) {
    return this.http.get<any>(environment.serverurl + '/notifications/templates/' + contentType + '?page=' + page +
      '&size=' + size);
  }

  findByAll(page, size) {
    return this.http.get<any>(environment.serverurl + '/notifications/templates' + '?page=' + page + '&size=' + size);
  }

  findById(id) {
    return this.http.get<any>(environment.serverurl + '/notifications/templates/' + id + '/detail');
  }
}
