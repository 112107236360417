import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-statchart',
  templateUrl: './statchart.component.html',
  styleUrls: ['./statchart.component.scss']
})
export class StatchartComponent implements OnInit {

  @Input() mainTitle: string;
  @Input() value: number;
  @Input() subValue: string;


  constructor() {
  }

  ngOnInit() {


  }
}
