import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {EventService} from '../../../../../core/service/event.service';
import {environment} from '../../../../../../environments/environment';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {TitleCasePipe} from '@angular/common';

// @ts-ignore
@Component({
  selector: 'app-video-detail-view',
  templateUrl: './video-detail-view.component.html',
  styleUrls: ['./video-detail-view.component.scss']
})
export class VideoDetailViewComponent implements OnInit, OnDestroy {


  isFieldDisable = true;
  loading = false;
  video: string[] = [];
  files: File[] = [];
  selectValue: string[] = ['240p', '360p', '720p', '1080p'];
  addedFile: File [] = [];
  movieId;
  videoLocation: string;
  requestBody: any = {};
  destroy$ = new Subject();
  progress: number;
  selectedResolutions: string[] = [];
  isVideoFileUploaded = false;
  isSelectedResolutionValidated = true;
  selectedResolutionValidatedMessage;
  getContentIdSubscription;
  saveBtnDisabled = false;
  isFileSelected = true;
  isFormValid = true;
  videoFileUploadValidationMessage;
  videoDetails: any = {};
  toggleLayer = true;
  editViewerEvent;
  isNewVideoFileAdd = false;
  isVideoPlaying = false;
  videoFileName;
  videoUploadedDateTime;
  @ViewChild('video', null) formReference: any;


  constructor(private service: CommonService, private s3Bucket: S3BucketService,
              private alert: Alerts, private eventService: EventService, private spinnerService: NgxSpinnerService,
              private titleCasePipe: TitleCasePipe) { }
  @ViewChild('resolution', null) resolution_reffrence: any;
  resolution: any;

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.movieId = response.id;
      }
    });
    this.editViewerEvent = this.eventService.contentViewSender.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.toggleLayer = value.viewEnable;
    });


    this.loadAllVideo();
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
    this.editViewerEvent.unsubscribe();

  }

  // TODO unnecessary method. Delete if not need in advance.

  /*generateResolutions(width, height) {

    if ((width >= 640 && width <= 853) && (height >= 360 && height <= 479)) {
      this.selectValue = [...this.selectValue, '360p'];
      this.selectedResolutions = [...this.selectValue, '360p'];
    }
    if ((width >= 854 && width <= 1279) && (height >= 480 && height <= 719)) {
      this.selectValue = [...this.selectValue, '360p', '480p'];
      this.selectedResolutions = [...this.selectValue, '360p', '480p'];
    }
    if ((width >= 1280 && width <= 1919) && (height >= 720 && height <= 1079)) {
      this.selectValue = [...this.selectValue,  '360p', '480p', '720p'];
      this.selectedResolutions = [...this.selectValue, '360p', '480p', '720p'];
    }
    if (width >= 1920 && height >= 1080 ) {
      this.selectValue = [...this.selectValue, '360p', '480p', '720p', '1080p'];
      this.selectedResolutions = [...this.selectValue, '360p', '480p', '720p', '1080p'];
    }
  }*/




  onSelect(event) {
    this.progress = 0;
    const file: File = event.addedFiles[0];
    this.addedFile[0] = event.addedFiles[0];


    const reader =   new FileReader();
    reader.addEventListener('load', () => {

      const video = document.createElement('video') as HTMLVideoElement;
      video.onloadeddata = () => {
        /*   this.generateResolutions(video.videoWidth, video.videoHeight);*/
      };
      video.src = reader.result as string;
    }, false );
    reader.readAsDataURL(file);
    this.isFileSelected = true;
    this.isNewVideoFileAdd = true;

    /* if (!this.movieId) {
       console.log(this.movieId);
       this.service.save(Service.MOVIE.CONTENT, {type: 'MOVIE'})
           .pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
         this.movieId = response.id;
       });
     } else {
       console.log(this.movieId);
       this.s3Bucket.uploadFile(this.movieId, this.addedFile[0], environment.s3Bucket.buckets.video, (error, res ) => {
         this.isVideoFileUploaded = true;
         console.log(res);
         this.videoLocation = res.Location;
       });
     }*/
  }


  validatingRequiredFields() {
    if (this.selectedResolutions.length <= 0) {
      this.isFormValid =  false;
      this.isSelectedResolutionValidated = false;
      this.selectedResolutionValidatedMessage = environment.validations.emptyResolutionValidatedMessage;
    }
    if (!this.addedFile[0]) {
      this.isFileSelected = false;
      this.videoFileUploadValidationMessage = environment.validations.emptyVideoFileValidationMessage;
    }
  }

  createRequestBody() {
    this.requestBody.content_type = environment.contentType.movie;
    this.requestBody.content_type = environment.contentType.movie;
    this.requestBody.conversion_resolutions = this.selectedResolutions;
    this.requestBody.video_conversion_status = (this.isNewVideoFileAdd) ?
        environment.conversionStatus.pending : environment.conversionStatus.complete;
    this.requestBody.source_url = this.videoLocation.replace(environment.s3Bucket.actualPath,
        environment.s3Bucket.actualPathReplaceTo);
  }


  uploadVideo() {
    this.toggleLayer = false;
    // this.saveBtnDisabled = true;

    this.validatingRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.movieId) {
      this.service.save(Service.MOVIE.CONTENT, {type: environment.contentType.movie})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.movieId = response.id;
        this.eventService.getContentIdEventEmitter({id: response.id});
        this.s3upload();

      });
    } else {
      this.s3upload();
    }
  }

  s3upload() {
    this.saveBtnDisabled = true;
    if (this.isNewVideoFileAdd) {
      if (this.addedFile[0]) {
        new Promise((resolve, reject) => {

          this.s3Bucket.uploadFile(this.movieId, this.addedFile[0], environment.s3Bucket.path.videos,
              environment.s3Bucket.buckets.bucketPath +
              environment.s3Bucket.buckets.sourceFolder +
              environment.s3Bucket.contentTypeFolder.movies, (error, response, pr) => {
                this.isVideoFileUploaded = true;
                this.videoLocation = response.Location;
                if (error) {
                  reject(false);
                }
                resolve(true);
              }).on('httpUploadProgress', (progress) => {
            this.progress = Math.round(progress.loaded / progress.total * 100);
          });
        }).then(value => {
          if (value) {
            this.saveVideo();
          }
        });
      }
    } else {
      this.saveVideo();
    }
  }

  saveVideo() {
    this.createRequestBody();
    this.service.update(Service.MOVIE.VIDEO, this.movieId, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.formReference.form.pristine = true;
      this.alert.successAlert(environment.alerts.success.movie.video.title,
          environment.alerts.success.movie.video.message);
    }, error2 => {
      this.alert.errorAlert(environment.alerts.error.movie.video.title, error2.error.error);
    });
    this.saveBtnDisabled = false;
    this.toggleLayer = true;
  }
loadAllVideo() {

  this.loading = true;
  this.service.findById(Service.MOVIE.VIDEO, this.movieId, environment.contentType.movie)
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {



    if (result.conversion_resolutions) {
      this.selectedResolutions = result.conversion_resolutions;
    }
    if (result.source_url) {
          this.addedFile[0] = result.source_url;
          this.videoLocation = result.source_url;
          this.videoFileName = result.source_url.split('_').pop();
          this.progress = 100;
          this.videoUploadedDateTime = this.convertDateTimeFromS3Url(this.videoLocation);
        }
    if (result.cdn_url) {
          let subtitlesArray = [];
          if (result.subtitles && result.subtitles.length > 0) {
            subtitlesArray = result.subtitles.map(subtitle => {
              let languageLabel;
              let languageCode;
              let sub;
              if (subtitle.language === environment.subtitleLanguages.code.english) {
                languageLabel = this.titleCasePipe.transform(environment.subtitleLanguages.language.english);
                languageCode = environment.subtitleLanguages.code.english;
                sub = subtitle.source_url;
              } else if (subtitle.language === environment.subtitleLanguages.code.sinhala) {
                languageLabel = this.titleCasePipe.transform(environment.subtitleLanguages.language.sinhala);
                languageCode = environment.subtitleLanguages.code.sinhala;
                sub = subtitle.source_url;
              } else {
                languageLabel = this.titleCasePipe.transform(environment.subtitleLanguages.code.tamil);
                languageCode = environment.subtitleLanguages.code.tamil;
                sub = subtitle.source_url;
              }
              return {
                label: languageLabel,
                language:  languageCode,
                src: sub
              };
            });
          }

          this.videoDetails = {
            manifestUri: result.cdn_url,
            autoplay: false,
            subtitles: subtitlesArray
          };
          // this.spinnerService.show('movie');
          setTimeout(() => {
            this.isVideoPlaying = true;
          }, 2000);
        }
    this.loading = false;

  });
}
  editForm() {
    this.isFieldDisable = false;
  }
  onRemove() {
    this.selectValue = [];
    this.selectedResolutions = [];
  }

  resetValidation() {
    this.isFormValid = true;
    if (this.selectedResolutions.length > 0) {
      this.isSelectedResolutionValidated = true;
      /* if (this.addedFile[0]) {
           this.isFileSelected = true;
       }*/
    }
  }
  afterInitializedPlayer( value ) {
    if ( value ) {
      // this.spinnerService.hide('movie');
    }
  }
  //  display(seconds) {
  //   const format = val => `0${Math.floor(val)}`.slice(-2);
  //   const hours = seconds / 3600;
  //   const minutes = (seconds % 3600) / 60;
  //
  //   return [hours, minutes, seconds % 60].map(format).join(':');
  // }

  convertDateTimeFromS3Url(url: string) {
      const firstStr = url.split('video/')[1];
      const dateWIthTime = firstStr.split('_')[0];
      const  index = dateWIthTime.lastIndexOf('-');
      const time = dateWIthTime.substr(index + 1);
      const  date = dateWIthTime.substring(0, dateWIthTime.lastIndexOf('-'));
      const timeWithSpace = time.replace(/(\d{2})/g, '$1 ').replace(/(^\s+|\s+$)/, '');

      return date + ' : ' + timeWithSpace;

  }
}


// alert(str.substring(0,str.lastIndexOf("_")))

// var index = str.lastIndexOf("_");
// var result = str.substr(index+1);
