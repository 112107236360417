import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {environment} from '../../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {Service} from '../../../../core/service/common_service/services';
import {CommonService} from '../../../../core/service/common_service/common.service';
import {Subject} from 'rxjs';
import {SliderGraphicService} from '../../../../core/service/common_service/ http/slider-graphic/slider-graphic.service';
import {Alerts} from '../../../../alert/Alert';
import {S3BucketService} from '../../../../core/service/s3-bucket.service';
import {MatDialog} from '@angular/material/dialog';
import {PublishSliderGraphicComponent} from '../publish-slider-graphic/publish-slider-graphic.component';
import {ContentService} from '../../../../core/service/common_service/ http/contents/content.service';
import {PreviewScreenComponent} from '../../screens/preview-screen/preview-screen.component';
import {PreviewSliderGraphicComponent} from '../preview-slider-graphic/preview-slider-graphic.component';

@Component({
    selector: 'app-add-new-slider-graphic',
    templateUrl: './add-new-slider-graphic.component.html',
    styleUrls: ['./add-new-slider-graphic.component.scss']
})
export class AddNewSliderGraphicComponent implements OnInit {
    sliderGraphicID;
    ctaCustomEnable = false;
    isAdvertisementActive = false;
    isStandardActive = true;
    isExternalClick = false;
    isLinkToGluuooClick = false;
    sliderName: string;
    sliderReviewVideoUrl: string;
    isSliderNameValidate = true;
    sliderNameValidateMessage;
    landScapePositions: string[] = [];
    portraitPositions: string[] = [];
    isSelectedLandScapeValidate = true;
    isSelectedPortraitValidate = true;
    portraitCaption: string;
    isPortraitCaptionValidate = true;
    portraitCaptionValidateMessage;
    landscapeCaption: string;
    isLandscapeCaptionValidate = true;
    mainImageCaption: string;
    isMainImageValidate = false;
    landscapeCaptionValidateMessage;
    addToWatchList;
    shareButton;
    ctaButton;
    playButton;
    externalLink: string;
    isExternalLinkValidate = true;
    externalLinkValidationMessage;
    selectedContent: string;
    isSelectedContentValidate = true;
    selectedContentValidateMessage;
    selectedCountries: any[] = [];
    rules = [environment.contentRules.bl, environment.contentRules.wl];
    selectedRule = '';
    isSelectedRulesValidate = true;
    rulesValidateMessage;
    countries = [];
    isSelectedCountriesValidate = true;
    countriesValidateMessage;
    maturities = environment.maturityRating;
    selectedMaturity: string;
    isSelectedMaturityValidate = true;
    maturityValidateMessage;
    ctaCustomButtonText: string;
    isCtaCustomTextValidate = true;
    ctaCusButtonTextValidateMessage;
    requestBody: any = {};
    httpDestroy = new Subject();
    contents: any[] = [];
    SLIDER_NAME = 1;
    LANDSCAPE_POSITION = 2;
    PORTRAIT_POSITION = 3;
    PORTRAIT_CAPTION = 6;
    LANDSCAPE_CAPTION = 7;
    CTA_LINK_DROPDOWN = 8;
    CTA_LINK_EXTERNAL_LINK = 9;
    RULES = 10;
    COUNTRIES = 11;
    MATURITY = 12;
    BUTTON_IMAGE = 13;
    PORTRAIT_IMAGE = 14;
    LANDSCPE_IMAGE = 15;
    files: any[] = [];
    buttonFiles: any[] = [];
    hasCustomButton = true;
    customButtonValidateMessage;
    hasPortraitImage = true;
    portraitImageValidateMessage;
    hasLandScapeImage = true;
    landscapeImageValidateMessage;
    promisesArray: any [] = [];
    isFormValid = false;
    imageLocations: string[] = [];
    progressOne;
    progressTwo;
    progressThree;
    destroy$ = new Subject();
    isPublishDisabled = true;
    bonusContent = false;
    contentTypes = [
        environment.contentType.movie,
        environment.contentType.tvShow,
        environment.contentType.tvEpisode,
        environment.contentType.video,
        environment.contentType.liveChannel,
        environment.contentType.videoChannel];
    selectedContentType: string;
    contentsClone: any[] = [];
    @ViewChild('contentReff', null) contentsReference: any;
    currentContentsPage;
    totalContents = 0;
    isPreviewDisable = true;
    voteButton;
    trailers: any[] = [{}];
    videoFileLocation: string[] = [];
    videoFiles: File[] = [];
    runTimes: string[] = [];
    videoFileName: string[] = [];
    isMainVideoValidate = false;
    constructor(
        private model: MatDialogRef<AddNewSliderGraphicComponent>,
        private service: CommonService,
        private sliderGraphicService: SliderGraphicService,
        private alert: Alerts,
        private s3BucketService: S3BucketService,
        private matModel: MatDialog,
        private contentService: ContentService, private s3Bucket: S3BucketService
    ) {
    }

    ngOnInit() {
        this.currentContentsPage = environment.page;
        this.selectedContentType = environment.contentType.movie;
        this.loadAllCountries();
        this.loadAllContent();
        this.enableRadioButtons();
        this.isLinkToGluuooClick = true;
        this.isStandardActive = true;
        this.getAllSliderTextPositions();
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.closeWindow();
    }

    closeWindow() {
        this.model.close();
    }

    radioChangeCta(event) {
        if (Number(event.value) === 2) {
            this.ctaCustomEnable = true;
            this.playButton = false;
            this.voteButton = false;
        }

        if (Number(event.value) === 1) {
            this.playButton = true;
            this.ctaCustomEnable = false;
            this.voteButton = false;
        }

        if (Number(event.value) === 3) {
          this.playButton = false;
          this.ctaCustomEnable = false;
          this.voteButton = true;
        }
    }

    sliderTypeChangeAdvertisement() {
        this.isAdvertisementActive = true;
        this.isStandardActive = false;
    }

    sliderTypeChangeStandard() {
        this.isStandardActive = true;
        this.isAdvertisementActive = false;
    }

    radioChangeCtaLink(event) {
        if (Number(event.value) === 1) {
            this.isLinkToGluuooClick = true;
            this.isExternalClick = false;
        }
        if (Number(event.value) === 2) {
            this.isExternalClick = true;
            this.isLinkToGluuooClick = false;
        }
    }

    loadAllCountries() {
        this.service.findAll(Service.COUNTRY).pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
            this.countries = response;
        });
    }

    changeContentType() {
      this.currentContentsPage = 0;
      this.contents = [];
      this.contentsClone = [];
      this.loadAllContent();
    }

    loadAllContent() {
        this.service.findAll(Service.MOVIE.STATUS,
            {
                status: environment.contentStatusString + environment.contentStatus.published,
                contentType: this.selectedContentType,
                page: this.currentContentsPage,
                size: environment.size
            }).pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
                  this.totalContents = response.total;
                  let contentsResponse;
                  if (response.contents_by_status) {
                      contentsResponse = response.contents_by_status.map((content, index) => {
                          if (content.title) {
                              content.name = content.title.english;
                          }
                          return content;
                      });
                  }
                  if (response.content_by_multiple_status) {
                        contentsResponse = response.content_by_multiple_status.map((content, index) => {
                            if (content.title) {
                                content.name = content.title.english;
                            }
                            return content;
                        });
                    }
                  this.contents = [...this.contents, ...contentsResponse];
                  this.contentsClone = [...this.contentsClone, ...contentsResponse];
        });
    }


    loadAllContentByContentType(contentType, searchTerm, page, size) {
        this.service.findAll(Service.MOVIE.STATUS,
            {
                status: environment.contentStatusString + environment.contentStatus.published,
                contentType: environment.contentType.movie,
                page: environment.page,
                size: environment.size
            }).pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
            this.contents = response.contents_by_status.map((movie, index) => {
                if (movie.title) {
                    movie.name = movie.title.english;
                }
                return movie;
            });
            this.contentsClone = this.contents;

        });
        this.contentsClone = this.contents;
    }


    searchContents(contentType) {

        switch (contentType) {

            case environment.contentType.movie:
                this.contentService.getAllContentBySearch(environment.contentType.movie,
                    this.contentsReference.searchTerm, environment.page, environment.size)
                    .pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {

                   this.contents = response.map((movie, index) => {
                        if (movie.title) {
                            movie.name = movie.title.english;
                        }
                        return movie;
                    });
                   this.contents = [...this.contents];
                }, error => {
                        this.contents = this.contentsClone;
                });
                break;
            case environment.contentType.tvShow:
                this.contentService.getAllContentBySearch(environment.contentType.tvShow,
                    this.contentsReference.searchTerm, environment.page, environment.size)
                    .pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
                    this.contents = response.map((tvEpisode, index) => {
                        if (tvEpisode.title) {
                            tvEpisode.name = tvEpisode.title.english;
                        }
                        return tvEpisode;
                    });
                    this.contents = [...this.contents];
                }, error => {
                    this.contents = this.contentsClone;
                });
                break;
        }
    }
    validateRequiredFields() {
        if (!this.isAdvertisementActive) {
            if (!this.sliderName) {
                this.isFormValid = false;
                this.isSliderNameValidate = false;
                this.sliderNameValidateMessage = environment.validations.sliderNameValidateMessage;
            }

            // TODO Remove these commented Code & Declaration of it's variables
            /*if (!this.mainText) {
                this.isMainTextValidate = false;
                this.mainTextValidateMessage = environment.validations.sliderMainTextValidateMessage;
            }*/

            // if (( this.mainText && !this.selectedLandscape) || (this.subText && !this.selectedLandscape)) {
            //     this.isSelectedLandScapeValidate = false;
            //     this.landscapeValidateMessage = environment.validations.landscapeValidateMessage;
            //   this.isFormValid = false;
            // }
            // if ((this.mainText && !this.selectedPortrait) || (this.subText && !this.selectedPortrait)) {
            //     this.isSelectedPortraitValidate = false;
            //     this.portraitValidateMessage = environment.validations.portraitValidateMessage;
            //   this.isFormValid = false;
            // }
            if (!this.portraitCaption) {
                this.isFormValid = false;
                this.isPortraitCaptionValidate = false;
                this.portraitCaptionValidateMessage = environment.validations.portraitCaptionValidateMessage;
            }
            if (!this.landscapeCaption) {
                this.isFormValid = false;
                this.isLandscapeCaptionValidate = false;
                this.landscapeCaptionValidateMessage = environment.validations.landscapeCaptionCaptionValidateMessage;
            }
            if (this.isExternalClick) {
                if (!this.externalLink) {
                    this.isFormValid = false;
                    this.isExternalLinkValidate = false;
                    this.externalLinkValidationMessage = environment.validations.externalLinkValidateMessage;
                }
            }

            if (this.isLinkToGluuooClick) {
                // if (!this.selectedContent) {
                //     this.isFormValid = false;
                //     this.isSelectedContentValidate = false;
                //     this.selectedContentValidateMessage = environment.validations.selectedContentValidateMessage;
                // }
            }

            if (this.selectedCountries.length > 0) {
                if (!this.selectedRule) {
                    this.isFormValid = false;
                    this.isSelectedRulesValidate = false;
                    this.rulesValidateMessage = environment.validations.rulesValidateMessage;
                }
            }

            if (this.selectedCountries.length === 0) {
                if (!this.selectedRule) {
                    this.isSelectedRulesValidate = true;
                }
            }
            if (!this.selectedMaturity) {
                this.isFormValid = false;
                this.isSelectedMaturityValidate = false;
                this.maturityValidateMessage = environment.validations.maturityValidateMessage;
            }
            if (!this.selectedRule) {
                if (this.selectedCountries.length === 0) {
                    this.isSelectedCountriesValidate = true;
                }
            }
            if (this.ctaCustomEnable) {
                if (!this.ctaCustomButtonText) {
                    this.isFormValid = false;
                    this.isCtaCustomTextValidate = false;
                    this.ctaCusButtonTextValidateMessage = environment.validations.ctaCusButtonTextMessage;
                }
            }
            if (this.ctaCustomEnable) {
                if (!this.buttonFiles[0]) {
                    this.isFormValid = false;
                    this.hasCustomButton = false;
                    this.customButtonValidateMessage = environment.validations.customButtonValidate;
                }
            }
            if (!this.files[0]) {
                this.isFormValid = false;
                this.hasPortraitImage = false;
                this.portraitImageValidateMessage = environment.validations.portraitImageValidate;
            }
            if (!this.files[1]) {
                this.isFormValid = false;
                this.hasLandScapeImage = false;
                this.landscapeImageValidateMessage = environment.validations.landscapeImageValidate;
            }
        }

        if (this.isAdvertisementActive) {
            if (!this.sliderName) {
                this.isFormValid = false;
                this.isSliderNameValidate = false;
                this.sliderNameValidateMessage = environment.validations.sliderNameValidateMessage;
            }
        }
    }

    resetValidation(field) {
        this.isFormValid = true;
        switch (field) {
            case this.SLIDER_NAME:
                this.isSliderNameValidate = true;
                break;
            // TODO Remove these commented Code & Declaration of it's variables
            case this.LANDSCAPE_POSITION:
                this.isSelectedLandScapeValidate = true;
                break;
            case this.PORTRAIT_POSITION:
                this.isSelectedPortraitValidate = true;
                break;
          /*  case this.MAIN_TEXT:
                this.isMainTextValidate = true;
                break;*/
            case this.PORTRAIT_CAPTION:
                this.isPortraitCaptionValidate = true;
                break;
            case this.LANDSCAPE_CAPTION:
                this.isLandscapeCaptionValidate = true;
                break;
            case this.CTA_LINK_DROPDOWN:
                this.isSelectedContentValidate = true;
                break;
            case this.CTA_LINK_EXTERNAL_LINK:
                this.isExternalLinkValidate = true;
                break;
            case this.RULES:
                this.isSelectedRulesValidate = true;
                break;
            case this.COUNTRIES:
                this.isSelectedCountriesValidate = true;
                break;
            case this.MATURITY:
                this.isSelectedMaturityValidate = true;
                break;
            case this.BUTTON_IMAGE:
                this.hasCustomButton = true;
                return;
            case this.LANDSCPE_IMAGE:
                this.hasLandScapeImage = true;
                return;
            case this.PORTRAIT_IMAGE:
                this.hasPortraitImage = true;
                return;
        }
    }

    isFormValidate() {
        if (
            this.isSliderNameValidate && this.hasPortraitImage &&
            this.hasLandScapeImage && this.isPortraitCaptionValidate && this.isLandscapeCaptionValidate &&
            this.isCtaCustomTextValidate && this.hasCustomButton && this.isSelectedContentValidate &&
            this.isExternalLinkValidate && this.isSelectedRulesValidate && this.isSelectedCountriesValidate &&
            this.isSelectedMaturityValidate
        ) {
            this.isFormValid = true;
        } else {
            this.isFormValid = false;
        }
    }

    enableRadioButtons() {
        this.addToWatchList = true;
        this.shareButton = true;
        this.ctaButton = true;
        this.playButton = true;
    }

    getAllSliderTextPositions() {
        this.landScapePositions = [
            environment.sliderTextPositions.tl,
            environment.sliderTextPositions.tm,
            environment.sliderTextPositions.tr,
            environment.sliderTextPositions.bl,
            environment.sliderTextPositions.bm,
            environment.sliderTextPositions.br
        ];

        this.portraitPositions = [
            environment.sliderTextPositions.tl,
            environment.sliderTextPositions.tm,
            environment.sliderTextPositions.tr,
            environment.sliderTextPositions.bl,
            environment.sliderTextPositions.bm,
            environment.sliderTextPositions.br
        ];
    }

    createRequestBody() {
        let type;
        if (this.isStandardActive) {
            type = 'STANDARD';
        }
        if (this.isAdvertisementActive) {
            // TODO change this.
            type = 'ADVERTISEMENT';
        }
        let btnType;
        if (this.playButton) {
            btnType = 'PLAY';
        } else if (this.voteButton) {
            btnType = 'VOTE';
        } else {
            btnType = 'CUSTOM';
        }
        let ruleType;


        if (this.isStandardActive) {
            if (this.selectedRule === environment.contentRules.wl) {
                ruleType = environment.contentRules.upperCaseWL;
            } else {
                ruleType = environment.contentRules.upperCaseBL;
            }

            this.requestBody.slider_type = type;
            const countryArray: Array<any> = new Array<any>();
            for (const country of this.selectedCountries) {
                countryArray.push(this.countries.find(x => x.id === country));
            }
            this.requestBody.countries = countryArray;

            if (this.voteButton) {
              this.requestBody.cta_button = {
                button_type: btnType
              };
            }
            if (this.playButton) {
                this.requestBody.cta_button = {
                    associated_content: this.selectedContent,
                    button_type: btnType,
                    content_type: this.selectedContentType,
                };
            }
            if (this.ctaCustomEnable && this.isLinkToGluuooClick) {
                this.requestBody.cta_button = {
                    associated_content: this.selectedContent,
                    button_external_link: this.externalLink,
                    button_icon_link: this.imageLocations[3].replace(environment.s3Bucket.actualPath,
                        environment.s3Bucket.actualPathReplaceTo),
                    button_text: this.ctaCustomButtonText,
                    button_type: btnType,
                    content_type: this.selectedContentType,
                };
            }
            if (this.ctaCustomEnable && this.isExternalClick) {
                this.requestBody.cta_button = {
                    associated_content: this.selectedContent,
                    button_external_link: this.externalLink,
                    button_icon_link: this.imageLocations[3].replace(environment.s3Bucket.actualPath,
                        environment.s3Bucket.actualPathReplaceTo),
                    button_text: this.ctaCustomButtonText,
                    button_type: btnType,
                };
            }
            this.requestBody.is_ctabutton_enabled = this.ctaButton;
            this.requestBody.is_share_button_enabled = this.shareButton;
            this.requestBody.is_watch_list_button_enabled = this.addToWatchList;
            this.requestBody.link_bonus_content = this.bonusContent;
            if (this.isMainImageValidate) {
              this.requestBody.slider_graphic_text_images = [
                  {
                      alt_tag: this.mainImageCaption,
                      image_name: this.mainImageCaption,
                      caption: this.mainImageCaption,
                      device_type: 'MOBILE',
                      link: null,
                      orientation_type: 'LANDSCAPE',
                      image_conversion_status: 'PENDING',
                      source_url: this.imageLocations[4]
                  }
              ];
            }
            this.requestBody.maturity_rating = this.selectedMaturity.toUpperCase();
            this.requestBody.name = this.sliderName;
            this.requestBody.review_video_url = this.trailers[0].videoFileLocation;
            this.requestBody.rule_type = ruleType;
            this.requestBody.slider_graphic_images = [
                {
                    alt_tag: this.landscapeCaption,
                    image_name: this.landscapeCaption,
                    caption: this.landscapeCaption,
                    device_type: 'MOBILE',
                    link: null,
                    orientation_type: 'LANDSCAPE',
                    image_conversion_status: 'PENDING',
                    source_url: this.imageLocations[1]
                },
                {
                    alt_tag: this.portraitCaption,
                    image_name: this.portraitCaption,
                    caption: this.portraitCaption,
                    device_type: 'MOBILE',
                    link: null,
                    orientation_type: 'PORTRAIT',
                    image_conversion_status: 'PENDING',
                    source_url: this.imageLocations[0].replace(environment.s3Bucket.actualPath, environment.s3Bucket.actualPathReplaceTo)
                }
            ];

            // if (this.subText && this.subText.length > 0) {
            //     this.requestBody.sub_text = {
            //         text_colour: this.subTextColor,
            //         txt: this.subText,
            //         txt_landscape_position: this.selectedLandscape,
            //         txt_portrait_position: this.selectedPortrait
            //     };
            // }
        }
        if (this.isAdvertisementActive) {
            this.requestBody.slider_type = type;
            this.requestBody.name = this.sliderName;
        }
    }

    onSelectPortrait(event) {
        this.files[0] = (event.addedFiles[0]);
        this.portraitCaption = this.files[0].name;
        this.isPortraitCaptionValidate = true;
    }

    onRemovePortrait() {
        this.files[0] = null;
        this.portraitCaption = '';
    }

    onSelectLandScape(event) {
        this.files[1] = (event.addedFiles[0]);
        this.landscapeCaption = this.files[1].name;
        this.isLandscapeCaptionValidate = true;
    }

    onRemoveLandScape() {
        this.files[1] = null;
        this.landscapeCaption = '';
    }

    onSelectMainTextImage(event) {
        this.files[2] = (event.addedFiles[0]);
        this.mainImageCaption = this.files[2].name;
        this.isMainImageValidate = true;
    }

    onRemoveMainTextImage() {
        this.files[2] = null;
        this.mainImageCaption = '';
        this.isMainImageValidate = false;
    }

    onSelectButtonImage(event) {
        this.buttonFiles[0] = (event.addedFiles[0]);
    }

    onRemoveButtonImage() {
        this.buttonFiles[0] = null;
    }

    s3Upload() {
        this.validateRequiredFields();
        if (this.isFormValid) {
          if (!this.sliderGraphicID) {
            this.service.save(Service.SLIDER_GRAPHIC, environment.sliderGraphic)
              .pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
              this.sliderGraphicID = response.id;
              this.save();
            });
          } else {
            this.save();
          }
        }
    }

  save() {
    if (this.isStandardActive) {
      const promise = new Promise((resolve, reject) => {
        this.s3BucketService.uploadSliderGraphic(this.sliderGraphicID, this.files[0], environment.s3Bucket.path.sliderGraphic,
          environment.s3Bucket.buckets.sliderGraphic.bucket,
          (error, response) => {
            this.imageLocations[0] = response.Location.replace(environment.s3Bucket.actualPath,
              environment.s3Bucket.actualPathReplaceTo);
            resolve(true);
          }).on('httpUploadProgress', (progress => {
          this.progressOne = Math.round(progress.loaded / progress.total * 100);
        }));
      });

      const promis2 = new Promise((resolve, reject1) => {
        this.s3BucketService.uploadSliderGraphic(this.sliderGraphicID, this.files[1], environment.s3Bucket.path.sliderGraphic,
          environment.s3Bucket.buckets.sliderGraphic.bucket,
          (error, response) => {
            this.imageLocations[1] = response.Location.replace(environment.s3Bucket.actualPath,
              environment.s3Bucket.actualPathReplaceTo);
            resolve(true);

          }).on('httpUploadProgress', (progress => {
          this.progressTwo = Math.round(progress.loaded / progress.total * 100);
        }));
      });
      this.promisesArray.push(promis2, promise);

      if (this.isMainImageValidate) {
          const promis4 = new Promise((resolve, reject1) => {
              this.s3BucketService.uploadSliderGraphic(this.sliderGraphicID, this.files[2], environment.s3Bucket.path.sliderGraphic,
                  environment.s3Bucket.buckets.sliderGraphic.bucket,
                  (error, response) => {
                      this.imageLocations[4] = response.Location.replace(environment.s3Bucket.actualPath,
                          environment.s3Bucket.actualPathReplaceTo);
                      resolve(true);

                  }).on('httpUploadProgress', (progress => {
                  this.progressTwo = Math.round(progress.loaded / progress.total * 100);
              }));
          });
          this.promisesArray.push(promis4);
      }
      if (this.isMainVideoValidate) {
          this.videoS3Upload();
      }

      if (this.ctaCustomEnable) {
        const promis3 = new Promise((resolve1, reject) => {
          this.s3BucketService.uploadSliderGraphic(this.sliderGraphicID,
            this.buttonFiles[0], environment.s3Bucket.path.sliderGraphic,
            environment.s3Bucket.buckets.sliderGraphic.bucket+"destination/staging/",
            (error, response) => {
              this.imageLocations[3] = response.Location.replace(environment.s3Bucket.actualPath,
                environment.s3Bucket.actualPathReplaceTo);
              resolve1(true);
            }).on('httpUploadProgress', (progress => {
            this.progressThree = Math.round(progress.loaded / progress.total * 100);
          }));
        });
        this.promisesArray.push(promis3);
        Promise.all(this.promisesArray).then(() => {
          this.createRequestBody();

          this.service.update(Service.SLIDER_GRAPHIC, this.sliderGraphicID, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
            this.alert.successAlert(environment.alerts.success.sliderGraphic.title,
              environment.alerts.success.sliderGraphic.message);
            this.isPublishDisabled = false;
          }, error => {
            this.alert.errorAlert(environment.alerts.error.sliderGraphic.title,
              environment.alerts.error.sliderGraphic.message);
          });
        });
      } else {
        Promise.all(this.promisesArray).then(() => {
          this.createRequestBody();

          this.service.update(Service.SLIDER_GRAPHIC, this.sliderGraphicID, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
            this.isPublishDisabled = false;
            this.isPreviewDisable = false;
            this.alert.successAlert(environment.alerts.success.sliderGraphic.title,
              environment.alerts.success.sliderGraphic.message);
          }, error => {
            this.alert.errorAlert(environment.alerts.error.sliderGraphic.title,
              environment.alerts.error.sliderGraphic.message);
          });
        });
      }
    } else {
      this.createRequestBody();
      this.service.save(Service.SLIDER_GRAPHIC, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        this.alert.successAlert(environment.alerts.success.sliderGraphic.title,
          environment.alerts.success.sliderGraphic.message);
      }, error => {
        this.alert.errorAlert(environment.alerts.error.sliderGraphic.title,
          environment.alerts.error.sliderGraphic.message);
      });
    }
  }

  preview() {
      const matDialogRef = this.matModel.open(PreviewSliderGraphicComponent, {
          disableClose: true,
          autoFocus: false,
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '80%',
          width: '80%',
          data: {
              landscapeSlider: this.imageLocations[0],
              sliderTextImg: this.imageLocations[4],
              isShareButtonEnable: this.shareButton,
              isCTAEnable: this.ctaButton,
              isAddToWatchListEnable: this.addToWatchList
          }
      });
  }

    cancel() {
        this.model.close();
    }

    publishSliderGraphic() {
        const matDialogRef = this.matModel.open(PublishSliderGraphicComponent, {
            disableClose: true,
            autoFocus: false,
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '90%',
            width: '70%',
            data: {
                id: this.sliderGraphicID,
                title: this.sliderName
            }
        });

        matDialogRef.afterClosed().subscribe(value => {
            if (value) {
                this.closeWindow();
            }
        });
    }

  // changeOnMainText(event) {
  //
  //   if (event) {
  //     this.isPositionDisable = false;
  //     return;
  //   } else {
  //
  //     if (!this.subText || (this.subText && (this.subText.length <= 0))) {
  //       this.isPositionDisable = true;
  //       this.selectedPortrait = null;
  //       this.selectedLandscape = null;
  //     }
  //   }
  // }

  // changeOnSubText(event) {
  //
  //   if (event) {
  //     this.isPositionDisable = false;
  //     return;
  //   } else {
  //
  //     if (!this.mainText || (this.mainText && (this.mainText.length <= 0))) {
  //       this.isPositionDisable = true;
  //       this.selectedPortrait = null;
  //       this.selectedLandscape = null;
  //     }
  //   }
  // }

    contentsOnScroll() {
      ++this.currentContentsPage;
      if (this.contentsClone.length < this.totalContents) {
        this.loadAllContent();
      }
    }

    onSelectOnVideo(event, index) {
        this.trailers.forEach(() => {
            for (const file of event.addedFiles) {
                this.videoFiles[index] = file;
                this.isMainVideoValidate = true;
                const reader =   new FileReader();
                reader.addEventListener('load', () => {

                    const video = document.createElement('video') as HTMLVideoElement;
                    video.onloadeddata = () => {
                        this.runTimes[index] =  this.displayTime(video.duration);
                        this.trailers[index].runTime = video.duration / 60;
                        this.runTimes = [...this.runTimes];
                    };
                    video.src = reader.result as string;

                }, false );
                reader.readAsDataURL(file);
            }
        });
    }

    displayTime(seconds) {
        const format = val => `0${Math.floor(val)}`.slice(-2);
        const hours = seconds / 3600;
        const minutes = (seconds % 3600) / 60;
        return [hours, minutes, seconds % 60].map(format).join(':');
    }

    videoS3Upload() {
        let index = 0;
        let videoUploadPromise;
        for (const trailer of this.trailers) {
            videoUploadPromise = new Promise((resolve, reject) => {
                this.s3Bucket.uploadSliderGraphicVideo(this.videoFiles[index],
                    (error, response) => {
                        if (error) {
                            reject({status: false, response: error});
                        }
                        trailer.videoFileLocation = response.Location;
                        resolve({status: true, response});
                    }).on('httpUploadProgress', (progress) => {
                    trailer.progressprogress2 = Math.round(progress.loaded / progress.total * 100);
                });
            });
            ++index;
            this.promisesArray.push(videoUploadPromise);
        }
    }
}
