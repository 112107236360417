import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Alerts} from '../../../../../alert/Alert';
import {environment} from '../../../../../../environments/environment';
import {EventService} from '../../../../../core/service/event.service';

@Component({
  selector: 'app-monetization-detail-view',
  templateUrl: './monetization-detail-view.component.html',
  styleUrls: ['./monetization-detail-view.component.scss']
})
export class MonetizationDetailViewComponent implements OnInit, OnDestroy {
  plans: any [] = [];
  loading = false;
  availableFreeToWatchValues: any [];
  packages: any [] = [];
  packagesClone = [ ];
  availablePayToWatchValues: string[] = [];
  freeToWatch: any[] = [];
  payToWatch: string[] = [];
  matSlide;

  FREELKR = 1;
  FREEUSD = 2;
  FREEEDAY = 3;

/*  STANDARDLKR = 4;
  STANDARDUSD = 5;
  STANDARDDATE = 6;*/

  ANNUALPREMIUMLKR = 4;
  ANNUALPREMIUMUSD = 5;
  ANNUALPREMIUMDATE = 6;

  PREMIUMLKR = 7;
  PREMIUMUSD = 8;
  PREMIUMDATE = 9;
  FREE_TO_WATCH = 10;
  PAY_TO_WATCH = 11;

  freeLKR: number;
  freeUSD: number;
  freeDays: number;

/*  standardLKR: number;
  standardUSD: number;
  standardDays: number;*/

  annualPremiumLKR: number;
  annualPremiumUSD: number;
  annualPremiumDays: number;

  premiumLKR: number;
  premiumUSD: number;
  premiumDays: number;

  isFreeLKRValidate = true;
  isFreeUSDValidate = true;
  isFreeDaysValidate = true;

  // isStandardLKRValidate = true;
  // isStandardUSDValidate = true;
  // isStandardDaysValidate = true;

  isAnnualPremiumLKRValidate = true;
  isAnnualPremiumUSDValidate = true;
  isAnnualPremiumDaysValidate = true;

  isPremiumLKRValidate = true;
  isPremiumUSDValidate = true;
  isPremiumDaysValidate = true;

  freeLKRValidateMessage;
  freeUSDValidateMessage;
  freeDaysValidateMessage;

/*  standardLKRValidateMessage;
  standardUSDValidateMessage;
  standardDaysValidateMessage;*/

  annualPremiumLKRValidateMessage;
  annualPremiumUSDValidateMessage;
  annualPremiumDaysValidateMessage;

  premiumLKRValidateMessage;
  premiumUSDValidateMessage;
  premiumDaysValidateMessage;

  requestBody: any = {};
  destroy$ = new Subject();
  getContentIdSubscription;
  movieId;
  isFormValid = true;
  file: any;

  toggleLayer = true;
  editViewerEvent;
  @ViewChild('monetization', null) formReference: any;


  constructor( private service: CommonService, private alert: Alerts, private eventService: EventService) { }

  ngOnInit() {
    this.loadPackages();
    this.matSlide = false;
    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.movieId = response.id;
      }
    });
    this.editViewerEvent = this.eventService.contentViewSender.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.toggleLayer = value.viewEnable;
    });

    this.loadAllMontization();
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
    this.editViewerEvent.unsubscribe();

  }

  loadPackages() {
    this.service.findAll(Service.PACKAGE_TIER).pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.packages = response;
          this.packagesClone = [...response];
          this.availableFreeToWatchValues = response.filter(v => v.id !== 3).map(( pack ) => pack.package_type );
          this.freeToWatch = this.availableFreeToWatchValues;
          this.availablePayToWatchValues = this.packages
              .filter(value => !this.freeToWatch.includes(value.package_type) && value.package_type.id === 3)
              .map(( pack ) => pack.package_type);
        });
  }

  changeItemFromFreeWatch() {
    this.availablePayToWatchValues = this.packages
        .filter(value => !this.freeToWatch.includes(value.package_type) && value.package_type !== 'STANDARD')
        .map(( pack ) => pack.package_type);
    this.availableFreeToWatchValues = [...this.availableFreeToWatchValues];
  }

  changeItemFromPayWatch() {
    this.availableFreeToWatchValues = this.packages
        .filter(value => !this.payToWatch.includes(value.package_type))
        .map(( pack ) => pack.package_type);
    this.availablePayToWatchValues = [...this.availablePayToWatchValues];
  }

  addItemToFreeWatch() {
    this.changeItemFromFreeWatch();
  }

  addItemToPayToWatch() {
    this.changeItemFromPayWatch();
  }

  clearFreeToWatch() {
    this.changeItemFromFreeWatch();
  }

  clearPayToWatch() {
    this.changeItemFromPayWatch();
  }

  validatingRequiredFields() {
console.log(this.payToWatch);
    if (!this.payToWatch.includes(environment.packages.premium) &&
        !this.freeToWatch.includes(environment.packages.premium)) {
      this.isFormValid = false;
      this.alert.errorAlert(environment.alerts.error.movie.monetization.title,
          environment.alerts.error.movie.monetization.premiumNotAvailable);
    }
    if (!this.payToWatch.includes(environment.packages.annualPremium) &&
        !this.freeToWatch.includes(environment.packages.annualPremium)) {
      this.isFormValid = false;
      this.alert.errorAlert(environment.alerts.error.movie.monetization.title,
          environment.alerts.error.movie.monetization.annualPremiumNotAvailable);
    }
    if (!((this.payToWatch.includes(environment.packages.premium) && this.payToWatch.includes(environment.packages.annualPremium)) ||
        (this.freeToWatch.includes(environment.packages.premium) && this.freeToWatch.includes(environment.packages.annualPremium)))) {
      this.isFormValid = false;
      this.alert.errorAlert(environment.alerts.error.movie.monetization.title,
          environment.alerts.error.movie.monetization.bothPackagesShouldSame);
    }

    if (this.payToWatch.includes(environment.packages.premium)) {
      if (this.premiumLKR < 0  || isNaN(this.premiumLKR) || this.premiumLKR === null) {
        this.isPremiumLKRValidate = false;
        this.premiumLKRValidateMessage = environment.validations.emptyPremiumLKRValidateMessage;
      }
      if (this.premiumUSD < 0  || isNaN(this.premiumLKR) || this.premiumUSD === null) {
        this.isPremiumUSDValidate = false;
        this.premiumUSDValidateMessage = environment.validations.emptyPremiumUSDValidateMessage;
      }
      if (this.premiumDays < 0  || isNaN(this.premiumLKR) || this.premiumDays === null) {
        this.isPremiumDaysValidate = false;
        this.premiumDaysValidateMessage = environment.validations.emptyPremiumDaysValidateMessage;
      }
    }
    if (this.payToWatch.includes(environment.packages.annualPremium)) {
      if (this.annualPremiumLKR < 0  || isNaN(this.annualPremiumLKR) || this.annualPremiumLKR === null) {
        this.isAnnualPremiumLKRValidate = false;
        this.annualPremiumLKRValidateMessage = environment.validations.emptyPremiumLKRValidateMessage;
      }
      if (this.annualPremiumUSD < 0  || isNaN(this.annualPremiumLKR) || this.annualPremiumUSD === null) {
        this.isAnnualPremiumUSDValidate = false;
        this.annualPremiumUSDValidateMessage = environment.validations.emptyPremiumUSDValidateMessage;
      }
      if (this.annualPremiumDays < 0  || isNaN(this.annualPremiumLKR) || this.annualPremiumDays === null) {
        this.isAnnualPremiumDaysValidate = false;
        this.annualPremiumDaysValidateMessage = environment.validations.emptyPremiumDaysValidateMessage;
      }
    }
    if (this.payToWatch.includes(environment.packages.free)) {
      if (this.freeLKR < 0  || isNaN(this.freeLKR) || this.freeLKR === null) {
        this.isFreeLKRValidate = false;
        this.freeLKRValidateMessage = environment.validations.emptyFreeLKRValidateMessage;
      }
      if (this.freeUSD < 0  || isNaN(this.freeUSD) || this.freeUSD === null) {
        this.isFreeUSDValidate = false;
        this.freeUSDValidateMessage = environment.validations.emptyFreeUSDValidateMessage;
      }
      if (this.freeDays < 0  || isNaN(this.freeDays) || this.freeDays === null) {
        this.isFreeDaysValidate = false;
        this.freeDaysValidateMessage = environment.validations.emptyFreeDaysValidateMessage;
      }
    }
    /*if (this.payToWatch.includes(environment.packages.standard)) {
      if (this.standardLKR < 0  || isNaN(this.standardLKR) || this.standardLKR === null) {
        this.isStandardLKRValidate = false;
        this.standardLKRValidateMessage = environment.validations.emptyStandardLKRValidateMessage;
      }
      if (this.standardUSD < 0  || isNaN(this.standardUSD) || this.standardUSD === null) {
        this.isStandardUSDValidate = false;
        this.standardUSDValidateMessage = environment.validations.emptyStandardUSDValidateMessage;
      }
      if (this.standardDays < 0  || isNaN(this.standardDays) || this.standardDays === null) {
        this.isStandardDaysValidate = false;
        this.standardDaysValidateMessage = environment.validations.emptyStandardDaysValidateMessage;
      }
    }*/
  }

  resetValidation(field) {
    this.isFormValid = true;
    switch (field) {
      case this.FREELKR:
        this.isFreeLKRValidate = true;
        break;
      case this.FREEUSD:
        this.isFreeUSDValidate = true;
        break;
      case this.FREEEDAY:
        this.isFreeDaysValidate = true;
        break;
      /*case this.STANDARDLKR:
        this.isStandardLKRValidate = true;
        break;
      case this.STANDARDUSD:
        this.isStandardUSDValidate = true;
        break;
      case this.STANDARDDATE:
        this.isStandardDaysValidate = true;
        break;*/
      case this.PREMIUMLKR:
        this.isPremiumLKRValidate = true;
        break;
      case this.PREMIUMUSD:
        this.isPremiumUSDValidate = true;
        break;
      case this.PREMIUMDATE:
        this.isPremiumDaysValidate = true;
        break;
      case this.ANNUALPREMIUMDATE:
        this.isAnnualPremiumDaysValidate = true;
        break;
      case this.ANNUALPREMIUMLKR:
        this.isAnnualPremiumLKRValidate = true;
        break;
      case this.ANNUALPREMIUMUSD:
        this.isAnnualPremiumUSDValidate = true;
        break;
      default:
        return;
    }
  }

  createRequestBody() {
    this.requestBody.content_type = environment.contentType.movie;
    this.requestBody.monetization = {};
    this.requestBody.monetization.allowed_advertising_free_tier = this.matSlide;
    this.requestBody.monetization.free_to_watch = [];
    this.freeToWatch.forEach((tier, index) => {

      let t;
      if (tier === environment.packages.premium) {
        t = environment.packages.premium;
      }
      if (tier === environment.packages.annualPremium) {
        t = environment.packages.annualPremium;
      }
      if (tier === environment.packages.free) {
        t = environment.packages.free;
      }
/*      if (tier === environment.packages.standard) {
        t = environment.packages.standard;
      }*/
      this.requestBody.monetization.free_to_watch.push(t);
    });


    this.requestBody.monetization.plans = [];
    this.requestBody.monetization.plans.prices = [];
    this.payToWatch.forEach((tier, index) => {
      this.requestBody.monetization.plans.push(
          {
            package_id: this.packagesClone.filter(( pack ) => {
                  return pack.package_type === tier;
                }
            ).map(( selectedPack ) => {
              return selectedPack.id;
            })[0],
            prices : [
              {
                amount: tier === environment.packages.free ? this.freeLKR :
                    (tier === environment.packages.annualPremium ? this.annualPremiumLKR : this.premiumLKR),
                currency: environment.currency.lkr
              },
              {
                amount: tier === environment.packages.free ? this.freeUSD :
                    (tier === environment.packages.annualPremium ? this.annualPremiumUSD : this.premiumUSD),
                currency: environment.currency.usd
              }
            ],
            rental_period : {
              period: tier === environment.packages.free ? this.freeDays
                    : (tier === environment.packages.annualPremium ? this.annualPremiumDays : this.premiumDays),
              unit: environment.packageActivationUnits.days
            },
            package_type: this.payToWatch[index],
          },
      );
    });

  }

  save() {
    this.validatingRequiredFields();
    this.createRequestBody();
    if (this.movieId) {
      // validations
      if (this.isFreeLKRValidate && this.isFreeUSDValidate && this.isFreeDaysValidate
         && this.isPremiumLKRValidate &&
          this.isPremiumUSDValidate && this.isPremiumDaysValidate &&
          this.isAnnualPremiumLKRValidate &&
          this.isAnnualPremiumUSDValidate && this.isAnnualPremiumDaysValidate &&
          this.isFormValid) {

        this.service.update(Service.MOVIE.MONETIZATION, this.movieId, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.eventService.getContentIdEventEmitter({id: response.id});
          this.formReference.form.pristine = true;

          this.alert.successAlert(environment.alerts.success.movie.monetization.title,
              environment.alerts.success.movie.monetization.message);
        }, error => {
          this.alert.errorAlert(environment.alerts.error.movie.monetization.title,
              error.error.error);
        });
      }
    } else {
      if (this.isFreeLKRValidate && this.isFreeUSDValidate && this.isFreeDaysValidate
          && this.isPremiumLKRValidate &&
          this.isPremiumUSDValidate && this.isPremiumDaysValidate &&
          this.isAnnualPremiumLKRValidate &&
          this.isAnnualPremiumUSDValidate && this.isAnnualPremiumDaysValidate &&
          this.isFormValid) {
        this.service.save(Service.MOVIE.MONETIZATION, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.eventService.getContentIdEventEmitter({id: response.id});
          this.alert.successAlert(environment.alerts.success.movie.monetization.title,
              environment.alerts.success.movie.monetization.message);
        }, error => {
          this.alert.errorAlert(environment.alerts.error.movie.monetization.title,
              error.error.error);
        });
      }
    }
  }

  loadAllMontization() {
    this.loading = true;

    this.service.findById( Service.MOVIE.MONETIZATION, this.movieId, environment.contentType.movie)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {

        if (result.monetization) {
          this.freeToWatch = result.monetization.free_to_watch;
          this.loading = false;
          this.matSlide = result.monetization.allowed_advertising_free_tier;
          this.payToWatch = result.monetization.plans.map(( pack ) => pack.package_type  );
          if (this.payToWatch.includes('FREE')) {
            const freePlanData = result.monetization.plans.filter(( pack ) => pack.package_type === 'FREE')[0];
            this.freeDays = freePlanData.rental_period.period;
            for (const  price of freePlanData.prices) {
              if (price.currency === 'LKR') {
                this.freeLKR = price.amount;
              }
              if (price.currency === 'USD') {
                this.freeUSD = price.amount;
              }
            }
          }
          if (this.payToWatch.includes('PREMIUM')) {
            const premiumPlanData = result.monetization.plans.filter(( pack ) => pack.package_type === 'PREMIUM')[0];
            this.freeDays = premiumPlanData.rental_period.period;
            for (const  price of premiumPlanData.prices) {
              if (price.currency === 'LKR') {
                this.premiumLKR = price.amount;
              }
              if (price.currency === 'USD') {
                this.premiumUSD = price.amount;
              }
            }
          }
          if (this.payToWatch.includes('ANNUAL_PREMIUM')) {
            const premiumPlanData = result.monetization.plans.filter(( pack ) => pack.package_type === 'ANNUAL_PREMIUM')[0];
            this.freeDays = premiumPlanData.rental_period.period;
            for (const  price of premiumPlanData.prices) {
              if (price.currency === 'LKR') {
                this.annualPremiumLKR = price.amount;
              }
              if (price.currency === 'USD') {
                this.annualPremiumUSD = price.amount;
              }
            }
          }
        }


    });
  }
}
