import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from "rxjs/operators";
import {TitleCasePipe} from "@angular/common";
import {TvShowService} from "../../../../../core/service/common_service/ http/contents/tv-shows/tv-show.service";
import {Subject} from "rxjs";
import {TvEpisodeModelComponent} from "../../../tv-episode/model/tv-episode-model/tv-episode-model.component";

@Component({
  selector: 'app-tv-episode-list',
  templateUrl: './tv-episode-list.component.html',
  styleUrls: ['./tv-episode-list.component.scss']
})
export class TvEpisodeListComponent implements OnInit, OnDestroy {

  tableData: any[] = [];
  env = environment;
  seasons: any[] = [];
  allTvEpisodeList: any[] = [];
   page= 1;
   size = 5;
   pageSize = 10;
  offset = (this.page - 1) * this.size;
  paginatedItems: any[];
  selectedPage: number;
  destroy$ = new Subject();
  selectedTvShow: any;
  private model: any;
  searchTerm: any = '';

  constructor(private tvShowService: TvShowService, private matModel: MatDialogRef<TvEpisodeListComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any, private matTvEpiModel: MatDialog) {
    this.seasons = this.data.seasons;
    this.selectedTvShow = this.data.selectedTvShow;
    this.model = matModel;
  }

  ngOnInit() {
     this.findAllEpisodeBySeason();
    this.selectedPage = 1;
  }

  closeWindow() {
    this.model.close();
  }

  findAllEpisodeBySeason() {
    // console.log(this.seasons);
    if (this.seasons.length == 0) {
      this.tableData = [];
      return;
    }
    this.seasons.forEach((season) => {
      let tvEpisodeList = season.tv_episode_list.map((tvEpisode) => {
        tvEpisode.seasonId = season.id;
        tvEpisode.seasonLable = season.label;
        tvEpisode.episode = tvEpisode.episode;
        tvEpisode.title = tvEpisode.title.english;
        // console.log(tvEpisode.poster);
        if (tvEpisode.poster) {
          tvEpisode.poster = {
            isPoster: true,
            url: tvEpisode.poster.source_url
          };
        }
        tvEpisode.rowSpan = 0;
        return tvEpisode;
      });
      if (tvEpisodeList.length > 0) {
        tvEpisodeList[0].rowSpan = season.tv_episode_list.length;
      }
      // console.log(tvEpisodeList)
      // if(this.allTvEpisodeList.length <= 0) {
      //   this.allTvEpisodeList = tvEpisodeList;
      // }
      this.allTvEpisodeList = this.allTvEpisodeList.concat(tvEpisodeList);
      // console.log(this.allTvEpisodeList);


    });
    // this.paginatedItems = this.allTvEpisodeList.slice(this.offset).slice(0, this.size);
    // console.log( this.paginatedItems);
    // tslint:disable-next-line:variable-name
    const total_pages = Math.ceil(this.allTvEpisodeList.length / this.size);
    // console.log(total_pages);
    this.tableData =   this.allTvEpisodeList;
  }
  setPage(event) {
    this.selectedPage = event;
    this.findSeasonTvEpisodes();
  }

  findSeasonTvEpisodes() {
    let that = this;
    const promis = new Promise((resolve, reject) => {
      this.tvShowService.seasonsByIdWithoutPagination({
        id: this.selectedTvShow,
        page: this.selectedPage - 1,
        size: 20
      })
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        that.seasons = response.data;
        that.allTvEpisodeList = [];
        that.findAllEpisodeBySeason();
        resolve(true);
      });

    });
  }

  onNoClick() {
    this.matModel.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openDetailView(event, status) {
    let matDialogRef = this.matTvEpiModel.open(TvEpisodeModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: true,
        tvEpisodeId: event.id,
        tvEpisodeTitle: event.englishTitle,
        publishStatus: status
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      // this.findAllEpisodeBySeason();
    });
  }


  searchTvEpisode() {
    const that = this;
    if (this.searchTerm) {
      const promis = new Promise((resolve, reject) => {
        this.tvShowService.seasonsByIdWithoutPaginationWithSearch({
          id: this.selectedTvShow,
          page: this.selectedPage - 1,
          size: 20,
          searchTerm: this.searchTerm
        })
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          that.seasons = response.data;
          that.allTvEpisodeList = [];
          that.findAllEpisodeBySeason();
          resolve(true);
        });
      });
    } else {
      this.findSeasonTvEpisodes();
    }
  }
}
