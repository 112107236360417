import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {MatDialog, MatDialogRef} from '@angular/material';
import {SliderGraphicService} from '../../../../core/service/common_service/ http/slider-graphic/slider-graphic.service';
import {Alerts} from '../../../../alert/Alert';
import {CommonService} from '../../../../core/service/common_service/common.service';
import {S3BucketService} from '../../../../core/service/s3-bucket.service';
import {Subject} from 'rxjs';
import {Service} from '../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {PublishSliderGraphicComponent} from '../publish-slider-graphic/publish-slider-graphic.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PreviewSliderGraphicComponent} from '../preview-slider-graphic/preview-slider-graphic.component';

@Component({
  selector: 'app-slider-graphic-detail-view',
  templateUrl: './slider-graphic-detail-view.component.html',
  styleUrls: ['./slider-graphic-detail-view.component.scss']
})
export class SliderGraphicDetailViewComponent implements OnInit {
  sliderGraphicID;
  ctaCustomEnable;
  isAdvertisementActive = false;
  isStandardActive = true;
  isExternalClick;
  isLinkToGluuooClick;
  sliderName: string;
  isSliderNameValidate = true;
  sliderNameValidateMessage;
  landScapePositions: string[] = [];
  portraitPositions: string[] = [];
  isSelectedLandScapeValidate = false;
  isSelectedPortraitValidate = false;
  portraitCaption: string;
  isPortraitCaptionValidate = true;
  portraitCaptionValidateMessage;
  landscapeCaption: string;
  isLandscapeCaptionValidate = true;
  landscapeCaptionValidateMessage;
  mainImageCaption: string;
  isMainImageValidate = false;
  addToWatchList;
  shareButton;
  ctaButton;
  playButton;
  externalLink: string;
  isExternalLinkValidate = true;
  externalLinkValidationMessage;
  selectedContent;
  isSelectedContentValidate = true;
  selectedContentValidateMessage;
  selectedCountries: any[] = [];
  rules = [environment.contentRules.bl, environment.contentRules.wl];
  selectedRule = '';
  isSelectedRulesValidate = true;
  rulesValidateMessage;
  countries = [];
  isSelectedCountriesValidate = true;
  countriesValidateMessage;
  maturities = environment.maturityRating;
  selectedMaturity: string;
  isSelectedMaturityValidate = true;
  maturityValidateMessage;
  ctaCustomButtonText: string;
  isCtaCustomTextValidate = true;
  ctaCusButtonTextValidateMessage;
  requestBody: any = {};
  httpDestroy = new Subject();
  contents: any[] = [];
  SLIDER_NAME = 1;
  PORTRAIT_CAPTION = 6;
  LANDSCAPE_CAPTION = 7;
  CTA_LINK_DROPDOWN = 8;
  CTA_LINK_EXTERNAL_LINK = 9;
  RULES = 10;
  COUNTRIES = 11;
  MATURITY = 12;
  BUTTON_IMAGE = 13;
  PORTRAIT_IMAGE = 14;
  LANDSCPE_IMAGE = 15;
  files: any[] = [];
  buttonFiles: any[] = [];
  hasCustomButton = true;
  customButtonValidateMessage;
  hasPortraitImage = true;
  portraitImageValidateMessage;
  hasLandScapeImage = true;
  landscapeImageValidateMessage;
  isFormValid = true;
  imageLocations: string[] = [];
  progressOne;
  progressTwo;
  progressThree;
  destroy$ = new Subject();
  isStandardDisable = true;
  isAdvertisementDisable = true;
  isSliderNameDisable = true;
  isMainTextDisable = true;
  isMainTextColorDisable = true;
  isSubTextDisable = true;
  isSubTextColorDisable = true;
  isSelectedLandscapeDisable = true;
  isSelectedPortraitDisable = true;
  isLandscapeCaptionDisable = true;
  isPortraitCaptionDisable = true;
  isAddToWatchListDisable = true;
  isSliderTextImage = true;
  isShareButtonDisable = true;
  isCTAEnableDisable = true;
  isCtaCustomButtonTextDisable = true;
  isCTAButtonDisable = true;
  isCustomButtonDisable = true;
  isVoteButtonDisable = true;
  isLinkToGCDisable = true;
  isExternalLinkDisable = true;
  isRulesDisable = true;
  isCountryDisable = true;
  isMaturityDisable = true;
  isSaveDisable = true;
  isPortraitImageDisable = true;
  isLandscapeImageDisable = true;
  isSelectedContentDisable = true;
  selectedSliderGraphic: any[] = [];
  isNewPortraitAdd = false;
  isNewLandscapeAdd = false;
  isNewButtonImageAdd = false;
  selectedSliderData: any;
  isSelectedContentExist = false;
  selectedContentNotExistMessage;
  isCheckOnPlayButton;
  isCheckOnCustomButton;
  isCheckOnVoteButton;
  promisesArray: any [] = [];
  publishBtnDisabled;
  editBtnDisabled = false;
  publishedStatus;
  buttonStatus;
  selectedContentType: any;
  bonusContent = false;
  contentTypes = [
    environment.contentType.movie,
    environment.contentType.tvShow,
    environment.contentType.tvEpisode,
    environment.contentType.video,
    environment.contentType.liveChannel,
    environment.contentType.videoChannel];
  contentsClone: any[] = [];
  currentContentsPage;
  totalContents = 0;
  isPreviewEnable = false;
  voteButton;
  trailers: any[] = [{}];
  videoFileLocation: string[] = [];
  videoFiles: File[] = [];
  runTimes: string[] = [];
  videoFileName: string[] = [];
  isMainVideoValidate = false;
  constructor(
      private model: MatDialogRef<SliderGraphicDetailViewComponent>,
      private service: CommonService,
      private sliderGraphicService: SliderGraphicService,
      private alert: Alerts,
      private s3BucketService: S3BucketService,
      private matModel: MatDialog,
      @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.selectedSliderData = data.sliderGraphicDetails;
    this.sliderGraphicID = data.sliderGraphicID;
    this.publishedStatus = data.publishStatus;

  }

  ngOnInit() {
        this.currentContentsPage = environment.page;
    // this.selectedContentType =   environment.contentType.movie;
        if (this.publishedStatus === environment.masterDataStatus.published) {
          this.buttonStatus = environment.masterDataStatus.unpublished;
        }

        if (this.publishedStatus === environment.masterDataStatus.unpublished) {
          this.buttonStatus = environment.masterDataStatus.published;
        }

        this.loadAllCountries();
        // this.loadAllContent(this.contentTypes[0]);
        this.enableRadioButtons();
        this.isLinkToGluuooClick = true;
        this.isStandardActive = true;
        this.getAllSliderTextPositions();
        this.loadAllData();

  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  editSliderGraphic() {


    // Published Tab Click
    if (this.publishedStatus === environment.masterDataStatus.published) {
      if (this.buttonStatus === environment.masterDataStatus.unpublished) {
        if (this.publishedStatus === environment.masterDataStatus.unpublished) {
          this.enableFields();
          return;
        }
        if (this.publishedStatus === environment.masterDataStatus.published) {
          this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
              environment.alerts.success.movie.unPublish.confirmButton).then(value => {
            if (value.isConfirmed) {
              this.service.unPublish(Service.SLIDER_GRAPHIC, this.sliderGraphicID, {country_id: 0, un_publish_general_rule: true})
                  .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

                this.buttonStatus = environment.masterDataStatus.published;

                this.publishBtnDisabled = true;

                this.enableFields();


                this.editBtnDisabled = true;
              });
            }
          });
        }
      } else {
        if (this.publishedStatus === environment.masterDataStatus.published) {
          this.enableFields();
          this.publishBtnDisabled = true;
          return;
        }
      }
    }

    // Unpublish Tab Click
    if (this.publishedStatus === environment.masterDataStatus.unpublished) {
      if (this.buttonStatus === environment.masterDataStatus.published) {
        if (this.publishedStatus === environment.masterDataStatus.unpublished) {
          this.enableFields();
          this.publishBtnDisabled = true;
          return;
        }
        if (this.publishedStatus === environment.masterDataStatus.published) {
          this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
              environment.alerts.success.movie.unPublish.confirmButton).then(value => {
            if (value.isConfirmed) {
              this.service.unPublish(Service.SLIDER_GRAPHIC, this.sliderGraphicID, {country_id: 0, un_publish_general_rule: true})
                  .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

                this.buttonStatus = environment.masterDataStatus.published;

                this.publishBtnDisabled = true;

                this.enableFields();


                this.editBtnDisabled = true;
              });
            }
          });
        }
      } else {
        if (this.publishedStatus === environment.masterDataStatus.unpublished) {
          this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
              environment.alerts.success.movie.unPublish.confirmButton).then(value => {
            if (value.isConfirmed) {
              this.service.unPublish(Service.SLIDER_GRAPHIC, this.sliderGraphicID, {country_id: 0, un_publish_general_rule: true})
                  .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

                this.buttonStatus = environment.masterDataStatus.published;

                this.publishBtnDisabled = true;

                this.enableFields();


                this.editBtnDisabled = true;
              });
            }
          });
        }
      }
    }


  }

    unPublishedSlider() {
      if (this.buttonStatus === environment.masterDataStatus.unpublished) {
        this.service.unPublish(Service.SLIDER_GRAPHIC, this.sliderGraphicID, {country_id: 0, un_publish_general_rule: true})
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.buttonStatus = environment.masterDataStatus.published;
          this.alert.successAlert(environment.publish.success.sliderGraphic.unpublish,
              environment.publish.success.sliderGraphic.unpublishMessage);
        });
      } else {
        const matDialogRef = this.matModel.open(PublishSliderGraphicComponent, {
          disableClose: true,
          autoFocus: false,
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '90%',
          width: '70%',
          data: {
            id: this.sliderGraphicID,
            title: this.sliderName
          }
        });

        matDialogRef.afterClosed().subscribe(value => {
          if (value) {
            // this.buttonStatus = environment.masterDataStatus  .unpublished;
           this.closeWindow();
          }
        });
      }
    }

  closeWindow() {
    this.model.close();
  }

  radioChangeCta(event) {
    if (Number(event.value) === 2) {
      this.ctaCustomEnable = true;
      this.playButton = false;
      this.voteButton = false;
    }

    if (Number(event.value) === 1) {
      this.playButton = true;
      this.ctaCustomEnable = false;
      this.voteButton = false;
    }

    if (Number(event.value) === 3) {
      this.playButton = false;
      this.ctaCustomEnable = false;
      this.voteButton = true;
    }
  }

  sliderTypeChangeAdvertisement() {
    this.isAdvertisementActive = true;
    this.isStandardActive = false;
  }

  sliderTypeChangeStandard() {
    this.isStandardActive = true;
    this.isAdvertisementActive = false;
  }

  radioChangeCtaLink(event) {
    if (Number(event.value) === 1) {
      this.isLinkToGluuooClick = true;
      this.isExternalClick = false;
    }
    if (Number(event.value) === 2) {
      this.isExternalClick = true;
      this.isLinkToGluuooClick = false;
    }
  }

  loadAllCountries() {
    this.service.findAll(Service.COUNTRY).pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
      this.countries = response;
    });
  }

  changeContentType() {
    this.currentContentsPage = 0;
    this.contents = [];
    this.contentsClone = [];
    this.loadAllContent();
  }

  loadAllContent() {
    this.service.findAll(Service.MOVIE.STATUS,
        {
          status: environment.contentStatusString + environment.contentStatus.published,
          contentType: this.selectedContentType,
          page: this.currentContentsPage,
          size: environment.size
        }).pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
      this.totalContents = response.total;
      let contentsResponse;
      if (response.contents_by_status) {
        contentsResponse = response.contents_by_status.map((content, index) => {
          if (content.title) {
            content.name = content.title.english;
          }
          return content;
        });
      }
      if (response.content_by_multiple_status) {
        contentsResponse = response.content_by_multiple_status.map((content, index) => {
          if (content.title) {
            content.name = content.title.english;
          }
          return content;
        });
      }
      this.contents = [...this.contents, ...contentsResponse];
      this.contentsClone = [...this.contentsClone, ...contentsResponse];
    });
  }

  validateRequiredFields() {
    if (!this.isAdvertisementActive) {
      if (!this.sliderName) {
        this.isFormValid = false;
        this.isSliderNameValidate = false;
        this.sliderNameValidateMessage = environment.validations.sliderNameValidateMessage;
      }

      // if (!this.mainText) {
      //   this.isMainTextValidate = false;
      //   this.mainTextValidateMessage = environment.validations.sliderMainTextValidateMessage;
      // }

      // if (( this.mainText && !this.selectedLandscape) || (this.subText && !this.selectedLandscape)) {
      //   this.isSelectedLandScapeValidate = false;
      //   this.landscapeValidateMessage = environment.validations.landscapeValidateMessage;
      //   this.isFormValid = false;
      // }
      // if ((this.mainText && !this.selectedPortrait) || (this.subText && !this.selectedPortrait)) {
      //   this.isSelectedPortraitValidate = false;
      //   this.portraitValidateMessage = environment.validations.portraitValidateMessage;
      //   this.isFormValid = false;
      // }
      if (!this.portraitCaption) {
        this.isFormValid = false;
        this.isPortraitCaptionValidate = false;
        this.portraitCaptionValidateMessage = environment.validations.portraitCaptionValidateMessage;
      }
      if (!this.landscapeCaption) {
        this.isFormValid = false;
        this.isLandscapeCaptionValidate = false;
        this.landscapeCaptionValidateMessage = environment.validations.landscapeCaptionCaptionValidateMessage;
      }
      if (this.isExternalClick) {
        if (!this.externalLink) {
          this.isFormValid = false;
          this.isExternalLinkValidate = false;
          this.externalLinkValidationMessage = environment.validations.externalLinkValidateMessage;
        }
      }

      if (this.isLinkToGluuooClick) {
        if (!this.selectedContent) {
          this.isFormValid = false;
          this.isSelectedContentValidate = false;
          this.selectedContentValidateMessage = environment.validations.selectedContentValidateMessage;
        }
      }

      if (this.selectedCountries.length > 0) {
        if (!this.selectedRule) {
          this.isFormValid = false;
          this.isSelectedRulesValidate = false;
          this.rulesValidateMessage = environment.validations.rulesValidateMessage;
        }
      }

      if (this.selectedCountries.length === 0) {
        if (!this.selectedRule) {
          this.isSelectedRulesValidate = true;
        }
      }
      if (!this.selectedMaturity) {
        this.isFormValid = false;
        this.isSelectedMaturityValidate = false;
        this.maturityValidateMessage = environment.validations.maturityValidateMessage;
      }
      if (!this.selectedRule) {
        if (this.selectedCountries.length === 0) {
          this.isSelectedCountriesValidate = true;
        }
      }
      if (this.ctaCustomEnable) {
        if (!this.ctaCustomButtonText) {
          this.isFormValid = false;
          this.isCtaCustomTextValidate = false;
          this.ctaCusButtonTextValidateMessage = environment.validations.ctaCusButtonTextMessage;
        }
      }
      if (this.ctaCustomEnable) {
        if (!this.buttonFiles[0]) {
          this.isFormValid = false;
          this.hasCustomButton = false;
          this.customButtonValidateMessage = environment.validations.customButtonValidate;
        }
      }
      if (!this.files[0] && !this.selectedSliderGraphic[1].url) {
        this.isFormValid = false;
        this.hasPortraitImage = false;
        this.portraitImageValidateMessage = environment.validations.portraitImageValidate;
      }
      if (!this.files[1] && !this.selectedSliderGraphic[0].url) {
        this.isFormValid = false;
        this.hasLandScapeImage = false;
        this.landscapeImageValidateMessage = environment.validations.landscapeImageValidate;
      }
    }

    if (this.isAdvertisementActive) {
      if (!this.sliderName) {
        this.isFormValid = false;
        this.isSliderNameValidate = false;
        this.sliderNameValidateMessage = environment.validations.sliderNameValidateMessage;
      }
    }
  }

  resetValidation(field) {
    this.isFormValid = true;
    switch (field) {
      case this.SLIDER_NAME:
        this.isSliderNameValidate = true;
        break;
      // case this.LANDSCAPE_POSITION:
      //   this.isSelectedLandScapeValidate = true;
      //   break;
      // case this.PORTRAIT_POSITION:
      //   this.isSelectedPortraitValidate = true;
      //   break;
      // case this.MAIN_TEXT:
      //   this.isMainTextValidate = true;
      //   break;
      case this.PORTRAIT_CAPTION:
        this.isPortraitCaptionValidate = true;
        break;
      case this.LANDSCAPE_CAPTION:
        this.isLandscapeCaptionValidate = true;
        break;
      case this.CTA_LINK_DROPDOWN:
        this.isSelectedContentValidate = true;
        this.isSelectedContentExist = true;
        break;
      case this.CTA_LINK_EXTERNAL_LINK:
        this.isExternalLinkValidate = true;
        break;
      case this.RULES:
        this.isSelectedRulesValidate = true;
        break;
      case this.COUNTRIES:
        this.isSelectedCountriesValidate = true;
        break;
      case this.MATURITY:
        this.isSelectedMaturityValidate = true;
        break;
      case this.BUTTON_IMAGE:
        this.hasCustomButton = true;
        return;
      case this.LANDSCPE_IMAGE:
        this.hasLandScapeImage = true;
        return;
      case this.PORTRAIT_IMAGE:
        this.hasPortraitImage = true;
        return;
    }
  }

  isFormValidate() {
    if (
        this.isSliderNameValidate &&
        this.isSelectedLandScapeValidate && this.isSelectedPortraitValidate && this.hasPortraitImage &&
        this.hasLandScapeImage && this.isPortraitCaptionValidate && this.isLandscapeCaptionValidate &&
        this.isCtaCustomTextValidate && this.hasCustomButton && this.isSelectedContentValidate &&
        this.isExternalLinkValidate && this.isSelectedRulesValidate && this.isSelectedCountriesValidate &&
        this.isSelectedMaturityValidate
    ) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  enableRadioButtons() {
    this.addToWatchList = true;
    this.shareButton = true;
    this.ctaButton = true;
    this.playButton = true;
  }

  getAllSliderTextPositions() {
    this.landScapePositions = [
      environment.sliderTextPositions.tl,
      environment.sliderTextPositions.tm,
      environment.sliderTextPositions.tr,
      environment.sliderTextPositions.bl,
      environment.sliderTextPositions.bm,
      environment.sliderTextPositions.br
    ];

    this.portraitPositions = [
      environment.sliderTextPositions.tl,
      environment.sliderTextPositions.tm,
      environment.sliderTextPositions.tr,
      environment.sliderTextPositions.bl,
      environment.sliderTextPositions.bm,
      environment.sliderTextPositions.br
    ];
  }

  createRequestBody() {
    let type;
    if (this.isStandardActive) {
      type = 'STANDARD';
    }
    if (this.isAdvertisementActive) {
      // TODO change this.
      type = 'ADVERTISEMENT';
    }
    let btnType;
    if (this.playButton) {
      btnType = 'PLAY';
    } else if (this.voteButton) {
      btnType = 'VOTE';
    } else {
      btnType = 'CUSTOM';
    }
    let ruleType;


    if (this.isStandardActive) {
      if (this.selectedRule === environment.contentRules.wl) {
        ruleType = environment.contentRules.upperCaseWL;
      } else {
        ruleType = environment.contentRules.upperCaseBL;
      }

      this.requestBody.slider_type = type;
      const countryArray: Array<any> = new Array<any>();
      for (const country of this.selectedCountries) {
        countryArray.push(this.countries.find(x => x.id === country));
      }
      this.requestBody.countries = countryArray;

      if (this.voteButton) {
        this.requestBody.cta_button = {
          button_type: btnType
        };
      }
      if (this.playButton) {
        this.requestBody.cta_button = {
          associated_content: this.selectedContent,
          button_type: btnType,
          content_type: this.selectedContentType,
        };
      }
      if (this.ctaCustomEnable && this.isLinkToGluuooClick) {

        let buttonImageLocation;
        if (this.isNewButtonImageAdd) {
          buttonImageLocation = this.imageLocations[3].replace(environment.s3Bucket.actualPath,
              environment.s3Bucket.actualPathReplaceTo);
        } else {
          buttonImageLocation = this.imageLocations[3];
        }


        this.requestBody.cta_button = {
          associated_content: this.selectedContent,
          button_external_link: this.externalLink,
          button_icon_link: buttonImageLocation,
          button_text: this.ctaCustomButtonText,
          button_type: btnType,
          content_type: this.selectedContentType,
        };

      }
      if (this.ctaCustomEnable && this.isExternalClick) {
        let buttonImageLocation;
        if (this.isNewButtonImageAdd) {
          buttonImageLocation = this.imageLocations[3].replace(environment.s3Bucket.actualPath,
              environment.s3Bucket.actualPathReplaceTo);
        } else {
          buttonImageLocation = this.imageLocations[3];
        }
        this.requestBody.cta_button = {
          associated_content: this.selectedContent,
          button_external_link: this.externalLink,
          button_icon_link: buttonImageLocation,
          button_text: this.ctaCustomButtonText,
          button_type: btnType,
        };

      }


      let portraiteImageLocation;
      if (this.isNewPortraitAdd) {
        portraiteImageLocation = this.imageLocations[0].replace(environment.s3Bucket.actualPath, environment.s3Bucket.actualPathReplaceTo);
      } else {
        portraiteImageLocation = this.imageLocations[0];
      }

      let landScapeImageLocation;
      if (this.isNewPortraitAdd) {
        landScapeImageLocation = this.imageLocations[1].replace(environment.s3Bucket.actualPath, environment.s3Bucket.actualPathReplaceTo);
      } else {
        landScapeImageLocation = this.imageLocations[1];
      }

      let textImageLocation;
      let altTag = 'alt Tag';
      let  imageName = 'img_name';
      let img_caption = 'caption';

      if (this.isMainImageValidate) {
        textImageLocation = this.imageLocations[4].replace(environment.s3Bucket.actualPath, environment.s3Bucket.actualPathReplaceTo);
      } else {
        textImageLocation = this.imageLocations[4];
      }

      this.requestBody.is_ctabutton_enabled = this.ctaButton;
      this.requestBody.is_share_button_enabled = this.shareButton;
      this.requestBody.is_watch_list_button_enabled = this.addToWatchList;
      this.requestBody.link_bonus_content = this.bonusContent;
      this.requestBody.review_video_url = this.trailers[0].videoFileLocation;

        this.requestBody.slider_graphic_text_images = [
          {
            alt_tag: altTag,
            image_name: imageName,
            caption: img_caption,
            device_type: 'MOBILE',
            link: null,
            orientation_type: 'LANDSCAPE',
            image_conversion_status: 'PENDING',
            source_url: textImageLocation
          }
        ];

      this.requestBody.maturity_rating = this.selectedMaturity.toUpperCase();
      this.requestBody.name = this.sliderName;
      this.requestBody.rule_type = ruleType;
      this.requestBody.slider_graphic_images = [
        {
          alt_tag: this.landscapeCaption,
          image_name: this.landscapeCaption,
          caption: this.landscapeCaption,
          device_type: 'MOBILE',
          link: null,
          orientation_type: 'LANDSCAPE',
          image_conversion_status: 'PENDING',
          source_url: landScapeImageLocation
        },
        {
          alt_tag: this.portraitCaption,
          image_name: this.portraitCaption,
          caption: this.portraitCaption,
          device_type: 'MOBILE',
          link: null,
          orientation_type: 'PORTRAIT',
          image_conversion_status: 'PENDING',
          source_url: portraiteImageLocation
        }
      ];

      // if (this.subText && this.subText.length > 0) {
      //   this.requestBody.sub_text = {
      //     text_colour: this.subTextColor,
      //     txt: this.subText,
      //     txt_landscape_position: this.selectedLandscape,
      //     txt_portrait_position: this.selectedPortrait
      //   };
      // }
    }
    if (this.isAdvertisementActive) {
      this.requestBody.slider_type = type;
      this.requestBody.name = this.sliderName;
    }
  }

  onSelectPortrait(event) {
    this.isNewPortraitAdd = true;
    this.files[0] = (event.addedFiles[0]);
    this.portraitCaption = this.files[0].name;
  }

  onRemovePortrait() {
    this.isNewPortraitAdd = false;
    this.files[0] = null;
    this.portraitCaption = '';
  }

  onSelectLandScape(event) {

    this.isNewLandscapeAdd = true;
    this.files[1] = (event.addedFiles[0]);
    this.landscapeCaption = this.files[1].name;
  }

  onRemoveLandScape() {
    this.isNewLandscapeAdd = false;
    this.files[1] = null;
    this.landscapeCaption = '';
  }

  onSelectButtonImage(event) {
    this.isNewButtonImageAdd = true;
    this.buttonFiles[0] = (event.addedFiles[0]);
  }

  onRemoveButtonImage() {
    this.isNewButtonImageAdd = false;
    this.buttonFiles[0] = null;
  }

  onSelectMainTextImage(event) {
    this.files[2] = (event.addedFiles[0]);
    this.mainImageCaption = this.files[2].name;
    this.isMainImageValidate = true;
  }

  onRemoveMainTextImage() {
    this.files[2] = null;
    this.mainImageCaption = '';
    this.isMainImageValidate = false;
  }

  s3Upload() {
    this.publishBtnDisabled = true;
    this.disableFields();
    this.editBtnDisabled = false;
    this.validateRequiredFields();
    this.save();
    this.publishBtnDisabled = false;
  }

  save() {
    if (this.isFormValid) {
      if (this.isStandardActive) {
        if (this.isNewPortraitAdd) {
          const promise = new Promise((resolve, reject) => {
            this.s3BucketService.uploadSliderGraphic(this.sliderGraphicID, this.files[0], environment.s3Bucket.path.sliderGraphic,
                environment.s3Bucket.buckets.sliderGraphic.bucket+"destination/staging/",
                (error, response) => {
                  this.imageLocations[0] = response.Location.replace(environment.s3Bucket.actualPath,
                      environment.s3Bucket.actualPathReplaceTo);
                  resolve(true);
                }).on('httpUploadProgress', (progress => {
              this.progressOne = Math.round(progress.loaded / progress.total * 100);
            }));
          });
          this.promisesArray.push(promise);
        }


        if (this.isNewLandscapeAdd) {
          const promis2 = new Promise((resolve, reject1) => {
            this.s3BucketService.uploadSliderGraphic(this.sliderGraphicID, this.files[1], environment.s3Bucket.path.sliderGraphic,
                environment.s3Bucket.buckets.sliderGraphic.bucket,
                (error, response) => {
                  this.imageLocations[1] = response.Location.replace(environment.s3Bucket.actualPath,
                      environment.s3Bucket.actualPathReplaceTo);
                  resolve(true);

                }).on('httpUploadProgress', (progress => {
              this.progressTwo = Math.round(progress.loaded / progress.total * 100);
            }));
          });
          this.promisesArray.push(promis2);
        }

        if (this.isNewButtonImageAdd) {
          const promis3 = new Promise((resolve1, reject) => {
            this.s3BucketService.uploadSliderGraphic(this.sliderGraphicID,
                this.buttonFiles[0], environment.s3Bucket.path.sliderGraphic,
                environment.s3Bucket.buckets.sliderGraphic.bucket,
                (error, response) => {
                  this.imageLocations[3] = response.Location.replace(environment.s3Bucket.actualPath,
                      environment.s3Bucket.actualPathReplaceTo);
                  resolve1(true);
                }).on('httpUploadProgress', (progress => {
              this.progressThree = Math.round(progress.loaded / progress.total * 100);
            }));
          });
          this.promisesArray.push(promis3);
        }

        if (this.isMainImageValidate) {
          const promis4 = new Promise((resolve, reject1) => {
            this.s3BucketService.uploadSliderGraphic(this.sliderGraphicID, this.files[2], environment.s3Bucket.path.sliderGraphic,
                environment.s3Bucket.buckets.sliderGraphic.bucket,
                (error, response) => {
                  this.imageLocations[4] = response.Location.replace(environment.s3Bucket.actualPath,
                      environment.s3Bucket.actualPathReplaceTo);
                  resolve(true);

                }).on('httpUploadProgress', (progress => {
              this.progressTwo = Math.round(progress.loaded / progress.total * 100);
            }));
          });
          this.promisesArray.push(promis4);
        }
        if (this.isMainVideoValidate) {
          this.videoS3Upload();
        }
        Promise.all(this.promisesArray).then(value => {
          this.createRequestBody();

          this.service.update(Service.SLIDER_GRAPHIC, this.sliderGraphicID, this.requestBody)
              .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
            this.alert.successAlert(environment.alerts.success.sliderGraphic.updateTitle,
                environment.alerts.success.sliderGraphic.updateMessage);
          }, error => {
            this.alert.errorAlert(environment.alerts.error.sliderGraphic.title,
                environment.alerts.error.sliderGraphic.message);
          });
        });
      }
    }
  }

  preview() {
    const matDialogRef = this.matModel.open(PreviewSliderGraphicComponent, {
      disableClose: true,
      autoFocus: false,
      /*maxWidth: '100vw',
      maxHeight: '100vh',*/
      height: '80vh',
      // width: '80%',
      data: {
        landscapeSlider: this.imageLocations[0],
        sliderTextImg: this.imageLocations[4],
        isShareButtonEnable: this.shareButton,
        isCTAEnable: this.ctaButton,
        isAddToWatchListEnable: this.addToWatchList
      }
    });
  }

  cancel() {
    this.model.close();
  }


  loadAllData() {

    this.addToWatchList = false;
    this.shareButton = false;
    this.ctaButton = false;

    if (this.sliderGraphicID) {

      console.log('here data : ', this.selectedSliderData);
        this.sliderName = this.selectedSliderData.name;
        // if (this.selectedSliderData.main_text) {
        //   this.mainText = this.selectedSliderData.main_text.txt;
        //   this.mainTextColor =  this.selectedSliderData.main_text.text_colour;
        //   this.selectedPortrait = this.selectedSliderData.main_text.txt_portrait_position;
        //   this.selectedLandscape = this.selectedSliderData.main_text.txt_landscape_position;
        // }
        let firstImageSourceUrl = null;
        let secondImageSourceUrl = null;
        let firstImageSourceAltTag = null;
        let secondImageSourceAltTag = null;

        if ((this.selectedSliderData.slider_graphic_images !== null)
          && (this.selectedSliderData.slider_graphic_images.length !== 0)) {
          firstImageSourceUrl = this.selectedSliderData.slider_graphic_images[0].source_url;
          secondImageSourceUrl = this.selectedSliderData.slider_graphic_images[1].source_url;
          firstImageSourceAltTag = this.selectedSliderData.slider_graphic_images[0].alt_tag;
          secondImageSourceAltTag = this.selectedSliderData.slider_graphic_images[1].alt_tag;
        }
        this.selectedSliderGraphic = [
              {url: firstImageSourceUrl},
              {url: secondImageSourceUrl},
              {url: this.selectedSliderData.cta_button.button_icon_link}
            ];

        this.imageLocations[0] = firstImageSourceUrl;
        this.imageLocations[1] = secondImageSourceUrl;
        this.imageLocations[2] = this.selectedSliderData.cta_button.button_icon_link;
        this.imageLocations[3] = this.selectedSliderData.cta_button.button_icon_link;
        this.imageLocations[4] = null;

        if (this.selectedSliderData.slider_graphic_text_image !== null &&
            this.selectedSliderData.slider_graphic_text_image.length !== 0) {
          this.imageLocations[4] = this.selectedSliderData.slider_graphic_text_image[0].source_url;
        }

        // if (this.selectedSliderData.sub_text) {
        //   this.subText = this.selectedSliderData.sub_text.txt;
        //   this.subTextColor =  this.selectedSliderData.sub_text.text_colour;
        //   this.selectedPortrait = this.selectedSliderData.sub_text.txt_portrait_position;
        //   this.selectedLandscape = this.selectedSliderData.sub_text.txt_landscape_position;
        // }
        this.landscapeCaption = firstImageSourceAltTag;
        this.portraitCaption = secondImageSourceAltTag;

        this.selectedContent = this.selectedSliderData.cta_button.associated_content;
        if (this.selectedSliderData.countries) {
          this.selectedCountries = this.selectedSliderData.countries;
        }

        this.externalLink = this.selectedSliderData.button_external_link;
        this.ctaButton = this.selectedSliderData.is_ctabutton_enabled;

        this.addToWatchList = this.selectedSliderData.is_watch_list_button_enabled;
        this.shareButton = this.selectedSliderData.is_share_button_enabled;

        this.selectedMaturity = this.selectedSliderData.maturity_rating;
        this.ctaCustomButtonText = this.selectedSliderData.cta_button.button_text;

        if (this.selectedSliderData.cta_button.associated_content) {
          this.selectedContentType = this.selectedSliderData.cta_button.content_type;
          this.loadAllContent();
          this.selectedContent = this.selectedSliderData.cta_button.associated_content;
          this.selectedContentNotExistMessage = environment.validations.selectedContentNotExistMessage;
        }

        if ( this.selectedSliderData.cta_button.button_external_link) {
          this.externalLink = this.selectedSliderData.cta_button.button_external_link;
          this.isLinkToGluuooClick = false;
          this.isExternalClick = true;
        }


        if (this.selectedSliderData.cta_button.button_type === 'CUSTOM') {

          this.ctaCustomEnable = true;
          this.playButton = false;
          this.voteButton = false;
          this.isCheckOnPlayButton = false;
          this.isCheckOnCustomButton = true;
          this.isCheckOnVoteButton = false;
        }
        if (this.selectedSliderData.cta_button.button_type === 'PLAY') {

          this.ctaCustomEnable = false;
          this.playButton = true;
          this.voteButton = false;
          this.isCheckOnPlayButton = true;
          this.isCheckOnCustomButton = false;
          this.isCheckOnVoteButton = false;
        }
      if (this.selectedSliderData.cta_button.button_type === 'VOTE') {

        this.ctaCustomEnable = false;
        this.playButton = false;
        this.voteButton = true;
        this.isCheckOnPlayButton = false;
        this.isCheckOnCustomButton = false;
        this.isCheckOnVoteButton = true;
      }
    }
  }

  enableFields() {
    this.isStandardDisable = false;
    this.isAdvertisementDisable = false;
    this.isSliderNameDisable = false;
    this.isMainTextDisable = false;
    this.isMainTextColorDisable = false;
    this.isSubTextDisable = false;
    this.isSubTextColorDisable = false;
    this.isSelectedLandscapeDisable = false;
    this.isSelectedPortraitDisable = false;
    this.isLandscapeCaptionDisable = false;
    this.isPortraitCaptionDisable = false;
    this.isAddToWatchListDisable = false;
    this.isShareButtonDisable = false;
    this.isSliderTextImage = false;
    this.isCTAEnableDisable = false;
    this.isCtaCustomButtonTextDisable = false;
    this.isCTAButtonDisable = false;
    this.isCustomButtonDisable = false;
    this.isVoteButtonDisable = false;
    this.isLinkToGCDisable = false;
    this.isExternalLinkDisable = false;
    this.isRulesDisable = false;
    this.isCountryDisable = false;
    this.isMaturityDisable = false;
    this.isSaveDisable = false;
    this.isPortraitImageDisable = false;
    this.isLandscapeImageDisable = false;
    this.isSelectedContentDisable = false;
  }

  disableFields() {
    this.isStandardDisable = true;
    this.isAdvertisementDisable = true;
    this.isSliderNameDisable = true;
    this.isMainTextDisable = true;
    this.isMainTextColorDisable = true;
    this.isSubTextDisable = true;
    this.isSubTextColorDisable = true;
    this.isSelectedLandscapeDisable = true;
    this.isSelectedPortraitDisable = true;
    this.isLandscapeCaptionDisable = true;
    this.isPortraitCaptionDisable = true;
    this.isAddToWatchListDisable = true;
    this.isShareButtonDisable = true;
    this.isSliderTextImage = true;
    this.isCTAEnableDisable = true;
    this.isCtaCustomButtonTextDisable = true;
    this.isCTAButtonDisable = true;
    this.isCustomButtonDisable = true;
    this.isVoteButtonDisable = true;
    this.isLinkToGCDisable = true;
    this.isExternalLinkDisable = true;
    this.isRulesDisable = true;
    this.isCountryDisable = true;
    this.isMaturityDisable = true;
    this.isSaveDisable = true;
    this.isPortraitImageDisable = true;
    this.isLandscapeImageDisable = true;
    this.isSelectedContentDisable = true;
  }

  // changeOnMainText(event) {
  //
  //   if (event) {
  //     this.isPositionDisable = false;
  //     return;
  //   } else {
  //
  //     if (!this.subText || (this.subText && (this.subText.length <= 0))) {
  //       this.isPositionDisable = true;
  //       this.selectedPortrait = null;
  //       this.selectedLandscape = null;
  //     }
  //   }
  // }

  // changeOnSubText(event) {
  //
  //   if (event) {
  //     this.isPositionDisable = false;
  //     return;
  //   } else {
  //
  //     if (!this.mainText || (this.mainText && (this.mainText.length <= 0))) {
  //       this.isPositionDisable = true;
  //       this.selectedPortrait = null;
  //       this.selectedLandscape = null;
  //     }
  //   }
  // }
  contentsOnScroll() {
    ++this.currentContentsPage;
    if (this.contentsClone.length < this.totalContents) {
      this.loadAllContent();
    }
  }
  onSelectOnVideo(event, index) {
    this.trailers.forEach(() => {
      for (const file of event.addedFiles) {
        this.videoFiles[index] = file;
        this.isMainVideoValidate = true;
        const reader =   new FileReader();
        reader.addEventListener('load', () => {

          const video = document.createElement('video') as HTMLVideoElement;
          video.onloadeddata = () => {
            this.runTimes[index] =  this.displayTime(video.duration);
            this.trailers[index].runTime = video.duration / 60;
            this.runTimes = [...this.runTimes];
          };
          video.src = reader.result as string;

        }, false );
        reader.readAsDataURL(file);
      }
    });
  }
  displayTime(seconds) {
    const format = val => `0${Math.floor(val)}`.slice(-2);
    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;
    return [hours, minutes, seconds % 60].map(format).join(':');
  }
  videoS3Upload() {
    let index = 0;
    let videoUploadPromise;
    for (const trailer of this.trailers) {
      videoUploadPromise = new Promise((resolve, reject) => {
        this.s3BucketService.uploadSliderGraphicVideo(this.videoFiles[index],
            (error, response) => {
              if (error) {
                reject({status: false, response: error});
              }
              trailer.videoFileLocation = response.Location;
              resolve({status: true, response});
            }).on('httpUploadProgress', (progress) => {
          trailer.progressprogress2 = Math.round(progress.loaded / progress.total * 100);
        });
      });
      ++index;
      this.promisesArray.push(videoUploadPromise);
    }
  }

}
