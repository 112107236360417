import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CastMemberService {

  constructor( private  http: HttpService ) { }

  findAllCastMembers( castMemberRole, page, size ) {
    return this.http.get<any>( environment.serverurl + '/cast-members?castMemberRole=' + castMemberRole +
        '&page=' + page + '&size=' + size);
  }

  searchCastMemberByName(searchTerm, castMemberRole, page, size) {
    return this.http.get<any>( environment.serverurl + '/cast-members/search/' + searchTerm +
        '?castMemberRole=' +  castMemberRole + '&page=' + page + '&size=' + size);
  }

  save(body) {
    return this.http.post<any>(environment.serverurl + '/cast-members', body);
  }

  update(id, body) {
    return this.http.put<any>(environment.serverurl + '/cast-members/' + id, body);
  }

  getById(id) {
    return this.http.get<any>(environment.serverurl + '/cast-members/' + id);
  }
}
