import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {UpperCasePipe} from '@angular/common';

@Component({
  selector: 'app-cast-detail-view',
  templateUrl: './cast-detail-view.component.html',
  styleUrls: ['./cast-detail-view.component.scss']
})
export class CastDetailViewComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  name;
  file: File[] = [];
  casts: any[] = [];
  bio;
  isCastMemberImageValidated: any[] = [];
  isCastNameValidated = true;
  isCastMemberRoleValidated =  true;
  castMemberNameValidationMessage;
  castMemberImageValidationMessage;
  castMemberRoleValidationMessage;
  isFormValid = true;
  roles: any[] = environment.castMemberRoles;
  selectedRole;
  NAME = 1;
  IMAGE = 2;
  ROLE = 3;
  requestBody: any = {};
  castMemberId;
  promiseArray: any[] = [];
  saveBtnDisabled = false;
  savedCastMember;
  initialData;
  isRoleDisabled = false;
  castMemberDetails;
  changedFileIndexes = [];
  isEnableEdit = false;
  constructor(private model: MatDialogRef<CastDetailViewComponent>,
              private commonService: CommonService,
              private s3Bucket: S3BucketService,
              private alert: Alerts,
              private matModel: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private upperCase: UpperCasePipe) {
    this.initialData = data;
    this.castMemberId = data.castId;
    this.castMemberDetails = data.castMemberDetail;

  }

  ngOnInit() {
    // console.log('ini data : ' + this.initialData);
    // if (this.initialData) {
    //   if (this.initialData.role === environment.castMemberRoles[0].role) {
    //     this.selectedRole = environment.castMemberRoles[0].id;
    //   } else if (this.initialData.role === environment.castMemberRoles[1].role) {
    //     this.selectedRole = environment.castMemberRoles[1].id;
    //   } else {
    //     this.selectedRole = environment.castMemberRoles[2].id;
    //   }
    //   this.isRoleDisabled = true;
    // }
    this.loadCastMember();
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }
  closeWindow() {
    this.model.close(this.savedCastMember);
  }

  addAnotherPhoto() {
    this.file = [];
    this.casts.push({file: this.file});
  }

  onSelect(event, index) {
    this.casts[index].file  = event.addedFiles;
    this.changedFileIndexes.push(index);
  }

  deleteCastImage(index) {
    this.casts.splice(index, 1);
    this.resetValidation(this.IMAGE, index);
  }

  validateRequiredFields() {

    if (!this.name) {
      this.isCastNameValidated = false;
      this.isFormValid = false;
      this.castMemberNameValidationMessage = environment.validations.castMemberNameValidationMessage;
    }

    /**
     * IF IMAGE VALIDATE NOT WORKING WHEN UPDATING, CHECK FOLLOWING COMMENTED LINES
     */
    /*if (this.changedFileIndexes.length > 0) {
      let index = 0;
      for (const cast of this.casts) {
        if (!cast.file[0]) {
          this.isCastMemberImageValidated.push(index);
        }
        ++index;
      }
      if (this.isCastMemberImageValidated.length > 0) {
        this.isFormValid = false;
        this.castMemberImageValidationMessage = environment.validations.castMemberImageValidationMessage;
      }
    }*/

    if (!this.selectedRole) {
      this.isCastMemberRoleValidated =  false;
      this.isFormValid =  false;
      this.castMemberRoleValidationMessage = environment.validations.castMemberRoleValidationMessage;
    }
  }

  resetValidation(field, index?) {
    this.isFormValid = true;
    switch (field) {
      case this.NAME: {
        this.isCastNameValidated = true;
        break;
      }
      case this.IMAGE: {
        this.isCastMemberImageValidated.splice(this.isCastMemberImageValidated
            .findIndex((value, selectedIndex) => {
              selectedIndex === index;
            }), 1);
        break;
      }
      default: {
        this.isCastMemberRoleValidated =  true;
      }
    }
  }

  createRequestBody() {

    this.requestBody.bio = this.bio;
    this.requestBody.image_and_captions = [];

    for (const cast of this.casts) {
      this.requestBody.image_and_captions.push(
          {
            actor_name: this.name,
            alt_tag: cast.actorName,
            cast_member_img_type: environment.imageType.profile,
            device_type: environment.deviceType.mobile,
            image_conversion_status: environment.conversionStatus.pending,
            image_name: cast.actorName,
            orientation_type: this.upperCase.transform(environment.orientations.portrait),
            source_url: cast.imageLocation.replace(environment.s3Bucket.actualPath,
                environment.s3Bucket.actualPathReplaceTo),
            resolution_type: environment.resolutionTypes.fourX,
            image_type: environment.resolutionImageType.imageWithoutTitle
          }
      );
    }
    this.requestBody.name = this.name;
    this.requestBody.role = this.roles.filter(value => value.id === this.selectedRole)[0].role;

  }

  uploadImages() {
    this.validateRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.castMemberId) {
      let rqBody = Object.assign({}, environment.castMemberRqBody);
      rqBody.role = environment.castMemberRoles
          .filter(value => value.id === this.selectedRole)[0].role;
      this.commonService.save(Service.CAST_MEMBER, rqBody)
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.castMemberId = response.id;
        this.s3upload();
      });
    } else {
      this.s3upload();
    }
    this.saveBtnDisabled = true;
  }


  s3upload() {
    let uploadPromise;
    if (this.changedFileIndexes.length > 0) {
      for (let i = 0; i < this.changedFileIndexes.length; i++) {
        uploadPromise = new Promise((resolve, reject) => {
          this.s3Bucket.uploadFile(this.castMemberId, this.casts[this.changedFileIndexes[i]].file[0], environment.s3Bucket.path.castMembers,
              environment.s3Bucket.buckets.bucketPath +
              environment.s3Bucket.buckets.destinationFolder +
              environment.s3Bucket.metaDataTypeFolder.castMembers, (error, response, pr) => {
                if (error) {
                  reject({status: false, response: error});
                }
                this.casts[this.changedFileIndexes[i]].imageLocation = response.Location;
                resolve({status: true, response});
              }).on('httpUploadProgress', (progress) => {
            this.casts[this.changedFileIndexes[i]].progress = Math.round(progress.loaded / progress.total * 100);
          });
        });
        this.promiseArray.push(uploadPromise);
      }
    }
    Promise.all(this.promiseArray).then(value => {
      if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
        this.save();
        return;
      }
      this.alert.errorAlert(environment.s3Bucket.uploadError.title,
          environment.s3Bucket.uploadError.message);
    });
  }

  save() {
    this.createRequestBody();
    this.commonService.update(Service.CAST_MEMBER, this.castMemberId, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.savedCastMember = result;
      this.alert.successAlert(environment.alerts.success.castMember.title,
          environment.alerts.success.castMember.message).then(value => {
        this.model.close(this.savedCastMember);
      });
      this.saveBtnDisabled = false;
    }, error2 => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(environment.alerts.error.castMember.title,
          environment.alerts.error.castMember.message);
    });
  }

  loadCastMember() {
    if (this.castMemberDetails.name) {
      this.name = this.castMemberDetails.name;
    }

    if (this.castMemberDetails.bio) {
      this.bio = this.castMemberDetails.bio;
    }

    if (this.castMemberDetails.role) {
      this.selectedRole = this.roles[this.roles.findIndex(value => value.role === this.castMemberDetails.role)].id ;
    }

    if (this.castMemberDetails.image_captions) {
      for (let i = 0; i < this.castMemberDetails.image_captions.length; i++) {
        if (this.castMemberDetails.image_captions[i].source_url) {
          this.casts.push({file: this.file});
          this.casts[i].actorName = this.castMemberDetails.image_captions[i].actor_name;
          this.casts[i].imageLocation = this.castMemberDetails.image_captions[i].source_url;
          this.casts[i].name = this.castMemberDetails.image_captions[i].source_url;
          this.casts[i].resolution_type = this.castMemberDetails.image_captions[i].resolution_type;
          this.casts[i].image_type = this.castMemberDetails.image_captions[i].image_type;
        }
      }
    }
  }

  editCastMember() {
    this.isEnableEdit = true;
  }
}
