import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private http: HttpService) { }

  save(body) {
    return this.http.post(environment.serverurl + '/contents/videos', body);
  }
  findById(id, contentType) {
    return this.http.get<any>(environment.serverurl + '/contents/' + id + '/videos?content=' + contentType);
  }
  findAllByStatus(options: any) {
    return this.http.get(environment.serverurl + '/videos/status?' + options.status + '&page='
        + options.page + '&size=' +  options.size + '&maturity-rating=' + options.maturityRating);
  }

  update(id, body) {
    return this.http.put(environment.serverurl + '/contents/videos/' + id, body);
  }
  delete(id) {
    return this.http.delete(environment.serverurl + '' + id);
  }
  findAllVideosByPlaylist(options: any) {
    return this.http.get(environment.serverurl + '/playlist/' + options.channelId + '?&page='
        + options.page + '&size=' + options.size);
  }
  // updatePoster(id, body) {
  //   return this.http.put(environment.serverurl + '/content-details/posters/' + id, body);
  // }
}
