import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MediaRoutingModule } from './media-routing.module';
import { MoviesComponent } from './movies/movies.component';
import {
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule, MatRadioModule,
    MatSlideToggleModule,
    MatTabsModule
} from '@angular/material';
import { MetaDataComponent } from './movies/tabs/meta-data/meta-data.component';
import {FlatpickrModule} from 'angularx-flatpickr';
import {NgSelectModule} from '@ng-select/ng-select';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import { VideoComponent } from './movies/tabs/video/video.component';
import { TabComponent } from './movies/tabs/common/tab/tab.component';
import { PosterComponent } from './movies/tabs/poster/poster.component';
import { MonetizationComponent } from './movies/tabs/monetization/monetization.component';
import { CuePointComponent } from './movies/tabs/cue-point/cue-point.component';
import { ContentRulesComponent } from './movies/tabs/content-rules/content-rules.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDropzoneModule} from 'ngx-dropzone';
import { PublishMovieModelComponent } from './movies/model/publish-movie-model/publish-movie-model.component';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import { TrailerBonusContentComponent } from './movies/tabs/trailer-bonus-content/trailer-bonus-content.component';
import {NgbPaginationModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import { MediaVideoComponent } from './media-video/media-video.component';
import { MediaVideoModelComponent } from './media-video/model/media-video-model/media-video-model.component';
import { VideoMetaDataComponent } from './media-video/tabs/video-meta-data/video-meta-data.component';
import { MediaVideoTabComponent } from './media-video/tabs/common/media-video-tab/media-video-tab.component';
import { VideoMediaComponent } from './media-video/tabs/video-media/video-media.component';
import { TvEpisodeComponent } from './tv-episode/tv-episode.component';
import { TvEpisodeModelComponent } from './tv-episode/model/tv-episode-model/tv-episode-model.component';
import { TvEpisodeTabComponent } from './tv-episode/tabs/common/tv-episode-tab/tv-episode-tab.component';
import { TvEpisodeMetaDataComponent } from './tv-episode/tabs/tv-episode-meta-data/tv-episode-meta-data.component';
import { TvEpisodeVideoComponent } from './tv-episode/tabs/tv-episode-video/tv-episode-video.component';
import { VideoChannelComponent } from './video-channel/video-channel.component';
import { VideoChannelModelComponent } from './video-channel/model/video-channel-model/video-channel-model.component';
import { VideoChannelTabComponent } from './video-channel/tabs/common/video-channel-tab/video-channel-tab.component';
import { VideoChannelMetaDataComponent } from './video-channel/tabs/video-channel-meta-data/video-channel-meta-data.component';
import { TvShowsComponent } from './tv-shows/tv-shows.component';
import { TvShowModelComponent } from './tv-shows/model/tv-show-model/tv-show-model.component';
import { TvShowMetaDataComponent } from './tv-shows/tabs/tv-show-meta-data/tv-show-meta-data.component';
import { TvShowTabComponent } from './tv-shows/tabs/common/tv-show-tab/tv-show-tab.component';
import { PublishTvShowModelComponent } from './tv-shows/model/publish-tv-show-model/publish-tv-show-model.component';
import { TvEpisodePosterComponent } from './tv-episode/tabs/tv-episode-poster/tv-episode-poster.component';
import { TvEpisodeContentRulesComponent } from './tv-episode/tabs/tv-episode-content-rules/tv-episode-content-rules.component';
import { TvEpisodeMonetizationComponent } from './tv-episode/tabs/tv-episode-monetization/tv-episode-monetization.component';
import { VideoChannelPosterComponent } from './video-channel/tabs/video-channel-poster/video-channel-poster.component';
import { VideoChannelTrailersBounsComponent } from './video-channel/tabs/video-channel-trailers-bouns/video-channel-trailers-bouns.component';
import { VideoChannelContentRulesComponent } from './video-channel/tabs/video-channel-content-rules/video-channel-content-rules.component';
import { MediaMonetizationComponent } from './media-video/tabs/media-monetization/media-monetization.component';
import { MediaPosterComponent } from './media-video/tabs/media-poster/media-poster.component';
import { VideoMediaContentRulesComponent } from './media-video/tabs/video-media-content-rules/video-media-content-rules.component';
import { TvShowMonetizationComponent } from './tv-shows/tabs/tv-show-monetization/tv-show-monetization.component';
import { TvShowPostersComponent } from './tv-shows/tabs/tv-show-posters/tv-show-posters.component';
import { TvShowContentRulesComponent } from './tv-shows/tabs/tv-show-content-rules/tv-show-content-rules.component';
import { TvShowTrailerBonusContentComponent } from './tv-shows/tabs/tv-show-trailer-bonus-content/tv-show-trailer-bonus-content.component';
import { VideoChannelMonetizationComponent } from './video-channel/tabs/video-channel-monetization/video-channel-monetization.component';
import {WidgetModule} from '../../shared/widgets/widget.module';
import {UIModule} from '../../shared/ui/ui.module';
import { LiveChannelComponent } from './live-channel/live-channel.component';
import { LiveChannelModelComponent } from './live-channel/model/live-channel-model/live-channel-model.component';
import { LiveChannelTabComponent } from './live-channel/tabs/common/live-channel-tab/live-channel-tab.component';
import { LiveChannelPosterComponent } from './live-channel/tabs/live-channel-poster/live-channel-poster.component';
import { PublishLiveChannelModelComponent } from './live-channel/model/publish-live-channel-model/publish-live-channel-model.component';
import { AddNewSeasonComponent } from './tv-episode/tabs/add-new-season/add-new-season.component';
import { MetaDataDetailViewComponent } from './movies/tabs/meta-data-detail-view/meta-data-detail-view.component';
import {VideoDetailViewComponent} from './movies/tabs/video-detail-view/video-detail-view.component';
import {ContentRulesDetailViewComponent} from './movies/tabs/content-rules-detail-view/content-rules-detail-view.component';
import {MonetizationDetailViewComponent} from './movies/tabs/monetization-detail-view/monetization-detail-view.component';
import {PosterDetailViewComponent} from './movies/tabs/poster-detail-view/poster-detail-view.component';
import {TrailerBonusDetailViewComponent} from './movies/tabs/trailer-bonus-detail-view/trailer-bonus-detail-view.component';
import {TvShowPostersDetailViewComponent} from './tv-shows/tabs/tv-show-posters-detail-view/tv-show-posters-detail-view.component';
import { TvShowMonetizationDetailViewComponent } from './tv-shows/tabs/tv-show-monetization-detail-view/tv-show-monetization-detail-view.component';
import { TvShowMetaDataDetailViewComponent } from './tv-shows/tabs/tv-show-meta-data-detail-view/tv-show-meta-data-detail-view.component';
import { TvShowTrailerBonusContentDetailViewComponent } from './tv-shows/tabs/tv-show-trailer-bonus-content-detail-view/tv-show-trailer-bonus-content-detail-view.component';
import { TvShowContentRulesDetailViewComponent } from './tv-shows/tabs/tv-show-content-rules-detail-view/tv-show-content-rules-detail-view.component';
import { TvEpisodeMetaDataDetailVeiwComponent } from './tv-episode/tabs/tv-episode-meta-data-detail-veiw/tv-episode-meta-data-detail-veiw.component';
import { TvEpisodeVideoDetailViewComponent } from './tv-episode/tabs/tv-episode-video-detail-view/tv-episode-video-detail-view.component';
import { TvEpisodePosterDetailViewComponent } from './tv-episode/tabs/tv-episode-poster-detail-view/tv-episode-poster-detail-view.component';
import { TvEpisodeMonetizationDetailViewComponent } from './tv-episode/tabs/tv-episode-monetization-detail-view/tv-episode-monetization-detail-view.component';
import { TvEpisodeContentRulesDetailViewComponent } from './tv-episode/tabs/tv-episode-content-rules-detail-view/tv-episode-content-rules-detail-view.component';
import { TvEpisodeListComponent } from './tv-shows/model/tv-episode-list/tv-episode-list.component';
import { CuePointDetailViewComponent } from './movies/tabs/cue-point-detail-view/cue-point-detail-view.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import { TvEpisodeCuePointComponent } from './tv-episode/tabs/tv-episode-cue-point/tv-episode-cue-point.component';
import { TvEpisodeCuePointDetailViewComponent } from './tv-episode/tabs/tv-episode-cue-point-detail-view/tv-episode-cue-point-detail-view.component';
import { VideoChennelMetaDataDetailViewComponent } from './video-channel/tabs/video-chennel-meta-data-detail-view/video-chennel-meta-data-detail-view.component';
import { VideoChennelPosterDetailViewComponent } from './video-channel/tabs/video-chennel-poster-detail-view/video-chennel-poster-detail-view.component';
import { VideoChennelMonetizationDetailViewComponent } from './video-channel/tabs/video-chennel-monetization-detail-view/video-chennel-monetization-detail-view.component';
import { VideoChennelTrailersBonusDetailViewComponent } from './video-channel/tabs/video-chennel-trailers-bonus-detail-view/video-chennel-trailers-bonus-detail-view.component';
import { VideoChennelContentRulesDetailViewComponent } from './video-channel/tabs/video-chennel-content-rules-detail-view/video-chennel-content-rules-detail-view.component';
import { MediaCuePointComponent } from './media-video/tabs/media-cue-point/media-cue-point.component';
import { VideoMediaMetaDataDetailViewComponent } from './media-video/tabs/video-media-meta-data-detail-view/video-media-meta-data-detail-view.component';
import { VideoMediaDetailViewComponent } from './media-video/tabs/video-media-detail-view/video-media-detail-view.component';
import { MediaPosterDetailViewComponent } from './media-video/tabs/media-poster-detail-view/media-poster-detail-view.component';
import { MediaMonetizationDetailViewComponent } from './media-video/tabs/media-monetization-detail-view/media-monetization-detail-view.component';
import { MediaCuePointDetailViewComponent } from './media-video/tabs/media-cue-point-detail-view/media-cue-point-detail-view.component';
import { MediaContentRuleDetailViewComponent } from './media-video/tabs/media-content-rule-detail-view/media-content-rule-detail-view.component';
import { ErrorsComponent } from './movies/tabs/errors/errors.component';
import {VideoEpisodeListComponent} from './video-channel/model/video-episode-list/video-episode-list.component';
import { LiveChannelProgrammeComponent } from './live-channel/tabs/live-channel-programme/live-channel-programme.component';
import { LiveChannelEditModelComponent } from './live-channel/model/live-channel-edit-model/live-channel-edit-model.component';
import { SeasonsComponent } from './tv-shows/tabs/seasons/seasons.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { LiveChannelMetadataComponent } from './live-channel/tabs/live-channel-metadata/live-channel-metadata.component';
// import { AddNewPlayListComponent } from './media-video/tabs/add-new-play-list/add-new-play-list.component';







@NgModule({
    declarations: [
        MoviesComponent,
        MetaDataComponent,
        VideoComponent,
        TabComponent,
        PosterComponent,
        MonetizationComponent,
        CuePointComponent,
        ContentRulesComponent,
        TrailerBonusContentComponent,
        PublishMovieModelComponent,
        MediaVideoComponent,
        MediaVideoModelComponent,
        VideoMetaDataComponent,
        MediaVideoTabComponent,
        VideoMediaComponent,
        TvEpisodeComponent,
        TvEpisodeModelComponent,
        TvEpisodeTabComponent,
        TvEpisodeMetaDataComponent,
        TvEpisodeVideoComponent,
        VideoChannelComponent,
        VideoChannelModelComponent,
        VideoChannelTabComponent,
        VideoChannelMetaDataComponent,
        TvEpisodePosterComponent,
        TvEpisodeContentRulesComponent,
        TvEpisodeMonetizationComponent,
        VideoChannelPosterComponent,
        VideoChannelTrailersBounsComponent,
        TvShowsComponent,
        TvShowModelComponent,
        TvShowMetaDataComponent,
        TvShowTabComponent,
        PublishTvShowModelComponent,
        VideoChannelContentRulesComponent,
        MediaMonetizationComponent,
        MediaPosterComponent,
        VideoMediaContentRulesComponent,
        TvShowMonetizationComponent,
        TvShowPostersComponent,
        TvShowContentRulesComponent,
        TvShowTrailerBonusContentComponent,
        VideoChannelMonetizationComponent,
        LiveChannelComponent,
        LiveChannelModelComponent,
        LiveChannelTabComponent,
        LiveChannelPosterComponent,
        PublishLiveChannelModelComponent,
        AddNewSeasonComponent,
        MetaDataDetailViewComponent,
        VideoDetailViewComponent,
        ContentRulesDetailViewComponent,
        MonetizationDetailViewComponent,
        PosterDetailViewComponent,
        TrailerBonusDetailViewComponent,
        TvShowPostersDetailViewComponent,
        TvShowMonetizationDetailViewComponent,
        TvShowMetaDataDetailViewComponent,
        TvShowTrailerBonusContentDetailViewComponent,
        TvShowContentRulesDetailViewComponent,
        TvEpisodeMetaDataDetailVeiwComponent,
        TvEpisodeVideoDetailViewComponent,
        TvEpisodePosterDetailViewComponent,
        TvEpisodeMonetizationDetailViewComponent,
        TvEpisodeContentRulesDetailViewComponent,
        TvEpisodeListComponent,
        CuePointDetailViewComponent,
        TvEpisodeCuePointComponent,
        TvEpisodeCuePointDetailViewComponent,
        VideoChennelMetaDataDetailViewComponent,
        VideoChennelPosterDetailViewComponent,
        VideoChennelMonetizationDetailViewComponent,
        VideoChennelTrailersBonusDetailViewComponent,
        VideoChennelContentRulesDetailViewComponent,
        MediaCuePointComponent,
        VideoMediaMetaDataDetailViewComponent,
        VideoMediaDetailViewComponent,
        MediaPosterDetailViewComponent,
        MediaMonetizationDetailViewComponent,
        MediaCuePointDetailViewComponent,
        MediaContentRuleDetailViewComponent,
        TvEpisodeCuePointDetailViewComponent,
        ErrorsComponent,
        VideoEpisodeListComponent,
        LiveChannelProgrammeComponent,
        LiveChannelEditModelComponent,
        LiveChannelPosterComponent,
        SeasonsComponent,
        LiveChannelMetadataComponent,

    ],
    exports: [
        TabComponent,
        ContentRulesComponent
    ],
    imports: [
        CommonModule,
        MediaRoutingModule,
        MatTabsModule,
        FlatpickrModule,
        NgSelectModule,
        DropzoneModule,
        MatDividerModule,
        MatSlideToggleModule,
        FormsModule,
        NgxDropzoneModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        MatInputModule,
        NgbProgressbarModule,
        MatRadioModule,
        WidgetModule,
        UIModule,
        NgxSpinnerModule,
        NgbPaginationModule,
        DragDropModule,
    ]
})
export class MediaModule { }
