import {Component, HostListener, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {environment} from '../../../../../environments/environment';
import {Service} from '../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from '../../../../core/service/common_service/common.service';
import {Subject} from 'rxjs';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SliderGraphicService} from '../../../../core/service/common_service/ http/slider-graphic/slider-graphic.service';
import {Alerts} from '../../../../alert/Alert';
import {PublishScreenComponent} from '../publish-screen/publish-screen.component';
import {MatDialog} from '@angular/material/dialog';
import {PreviewScreenComponent} from '../preview-screen/preview-screen.component';

@Component({
  selector: 'app-add-new-screen',
  templateUrl: './add-new-screen.component.html',
  styleUrls: ['./add-new-screen.component.scss']
})
export class AddNewScreenComponent implements OnInit {

  constructor(
      private model: MatDialogRef<AddNewScreenComponent>,
      private service: CommonService,
      private sliderGraphicService: SliderGraphicService,
      private alert: Alerts,
      private matModel: MatDialog,
  ) { }

  countries = [];
  selectedRule = null;
  rules = [environment.contentRules.bl, environment.contentRules.wl];
  httpDestroy = new Subject();
  categoryData: any[] = [];
  realityCategoryData: any[] = [];
  categoryDataColone: any[] = [];
  realityCategoryDataColone: any[] = [];
  maturities = environment.maturityRating;
  selectedMaturity = null;
  file: any = {};
  sliders: any[] = [];
  realitySliders: any[] = [];
  categories: any[] = [];
  realityCategories: any[] = [];
  slidersData: any[] = [];
  realitySlidersData: any[] = [];
  slidersDataClone: any[] = [];
  realitySlidersDataClone: any[] = [];
  screenName: string;
  isScreenNameValidate = true;
  screenNameValidateMessage;
  selectedCountries: any[] = [];
  isSelectedCountriesValidate = true;
  selectedCountryValidateMessage;
  isSelectedRulesValidate = true;
  ruleValidateMessage;
  isSelectedMaturity = true;
  maturityValidateMessage;
  destroy$ = new Subject();
  requestBody: any = {};
  SCREEN_NAME = 1;
  MATURITY = 2;
  RULES = 3;
  COUNTRY = 4;
  SLIDER = 5;
  REALITYSLIDER = 7;
  CATEGORY = 6;
  REALITYCATEGORY = 6;
  isFormValidated = false;
  screenId;
  isPublishDisabled = true;
  emptySliders = [];
  emptyRealitySliders = [];
  emptyCategory = [];
  emptyRealityCategory = [];
  currentSlidersPage;
  currentRealitySlidersPage;
  totalSliders = 0;
  realityTotalSliders = 0;
  currentCategoriesPage;
  currentRealityCategoriesPage;
  totalCategories = 0;
  totalRealityCategories = 0;
  isPreviewEnable = false;

  ngOnInit() {
    this.currentSlidersPage = environment.page;
    this.currentCategoriesPage = environment.page;
    this.loadAllCountries();
    this.loadAllSliders();
    this.loadAllRealitySliders();
    this.getAllCategories();
    this.getAllRealityCategories();
    this.sliders.push({});
    this.categories.push({});
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  closeWindow() {
    this.model.close();
  }

  loadAllCountries() {
    this.service.findAll(Service.COUNTRY).pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
      this.countries = response;
    });
  }

  onDropSliders(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sliders, event.previousIndex, event.currentIndex);
  }

  onDropRealitySliders(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.realitySliders, event.previousIndex, event.currentIndex);
  }

  onDropCategory(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
  }

  onDropRealityCategory(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.realityCategories, event.previousIndex, event.currentIndex);
  }

  addAnotherSliderGraphic() {
    this.file = {};
    this.sliders.push(this.file);
  }

  addAnotherRealitySliderGraphic() {
    this.file = {};
    this.realitySliders.push(this.file);
  }

  deleteSlider(index, slider) {
    this.sliders.splice(index, 1);
    this.slidersData.push(this.slidersDataClone[this.slidersDataClone.findIndex(value => value.id === slider.id)]);
  }

  deleteRealitySlider(index, slider) {
    this.realitySliders.splice(index, 1);
    this.realitySlidersData.push(this.realitySlidersDataClone[this.realitySlidersDataClone.findIndex(value => value.id === slider.id)]);
  }

  deleteCategory(index, category) {

    this.categories.splice(index, 1);
    this.categoryData.push(this.categoryDataColone[this.categoryDataColone.findIndex(value => value.id === category.id)]);
  }
  deleteRealityCategory(index, category) {

    this.realityCategories.splice(index, 1);
    this.realityCategoryData
        .push(this.realityCategoryDataColone[this.realityCategoryDataColone.findIndex(value => value.id === category.id)]);
  }
  addAnotherCategory() {
    this.file = {};
    this.categories.push(this.file);
  }
  addAnotherRealityCategory() {
    this.file = {};
    this.realityCategories.push(this.file);
  }

  validatingRequiredFields() {

    if (!this.screenName) {
      this.isScreenNameValidate = false;
      this.screenNameValidateMessage = environment.validations.screenNameValidateMessage;
    }

    if (this.selectedCountries.length > 0 && this.selectedRule === null ) {
      this.isSelectedRulesValidate = false;
      this.ruleValidateMessage = environment.validations.screenRulesValidate;
    } else {
      this.isSelectedRulesValidate = true;
    }

    if (this.selectedRule !== null && this.selectedCountries.length === 0 ) {
      this.isSelectedCountriesValidate = false;
      this.selectedCountryValidateMessage = environment.validations.screenCountryValidate;
    } else {
      this.isSelectedCountriesValidate = true;
    }

    if (this.selectedMaturity === null) {
       this.isSelectedMaturity = false;
       this.maturityValidateMessage = environment.validations.screenMaturityValudate;
     } else {
      this.isSelectedMaturity = true;
    }

    this.sliders.forEach((slider, index) => {
      if (!slider.id) {
        this.emptySliders.push(index);
      }
    });

    this.categories.forEach((c, index) => {
      if (!c.id) {
        this.emptyCategory.push(index);
      }
    });
  }

  resetValidation(field, index) {
   switch (field) {
     case this.SCREEN_NAME:
       this.isScreenNameValidate = true;
       break;
     case this.RULES:
       this.isSelectedRulesValidate = true;
       break;
     case this.MATURITY:
       this.isSelectedMaturity = true;
       break;
     case this.COUNTRY:
       this.isSelectedCountriesValidate = true;
       break;
     case this.CATEGORY:
       this.emptyCategory = this.emptyCategory.filter(c => c !== index );
       break;
     case this.SLIDER:
       this.emptySliders = this.emptySliders.filter(s => s !== index);
       break;
     case this.REALITYSLIDER:
       this.emptyRealitySliders = this.emptyRealitySliders.filter(s => s !== index);
       break;
     case this.REALITYCATEGORY:
       this.emptyRealityCategory = this.emptyRealityCategory.filter(c => c !== index );
   }
  }

  loadAllSliders() {
    this.service.findAll(Service.SLIDER_GRAPHIC, {page: this.currentSlidersPage,
      size: environment.size,
      status: environment.contentStatus.published})
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalSliders = response.total;
      const slidersResponse = response.slider_graphics.map((slider, index) => {
        slider.name = '[' + slider.id + '] ' + slider.name;
        return slider;
      });

      this.slidersData = [...this.slidersData, ...slidersResponse];
      this.slidersDataClone = [...this.slidersDataClone, ...slidersResponse];
    });
  }

  loadAllRealitySliders() {
    this.service.findAll(Service.SLIDER_GRAPHIC_REALITY, {page: this.currentSlidersPage,
      size: environment.size})
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalSliders = response.totalElements;
      const slidersResponse = response.sliderGraphics;

      this.realitySlidersData = [...this.realitySlidersData, ...slidersResponse];
      this.realitySlidersDataClone = [...this.realitySlidersDataClone, ...slidersResponse];
    });
  }

  createRequestBody() {
   const categoryID: number[] = [];
   const realityCategoryID: number[] = [];
   for (const category of this.realityCategories) {

     realityCategoryID.push(category.id);
    }
   for (const category of this.categories) {

      categoryID.push(category.id);
    }
   this.requestBody.category_ids = categoryID;
   this.requestBody.reality_category_ids = realityCategoryID;
   this.requestBody.country_ids = this.selectedCountries;
   this.requestBody.maturity_rating = this.selectedMaturity.toUpperCase();
   this.requestBody.name = this.screenName;

   let ruleType;
   if (this.selectedRule === environment.contentRules.bl ) {
     ruleType = environment.contentRules.upperCaseBL;
   }

   if (this.selectedRule === environment.contentRules.wl ) {
      ruleType = environment.contentRules.upperCaseWL;
    }

   this.requestBody.rule_type = ruleType;
   this.requestBody.screen_type = 'standard';
   this.requestBody.slider_graphic_ids = this.sliders.map(value => value.id);
   this.requestBody.reality_slider_graphic_ids = this.realitySliders.map(value => value.id);
  }
  isFormValidate() {
    if (this.isSelectedCountriesValidate && this.isSelectedRulesValidate && this.isSelectedMaturity && this.isScreenNameValidate
        && this.emptySliders.length === 0 && this.emptyCategory.length === 0) {
        this.isFormValidated = true;
    } else {
      this.isFormValidated = false;
    }
  }

  getAllCategories() {
    this.service.findAll(Service.CATEGORY,  {status: environment.contentStatus.published,
      page: this.currentCategoriesPage, size: environment.size})
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.totalCategories = response.total;
          const categoryResponse = response.categories.map((category, index) => {
              category.name = '[' + category.id + '] ' + category.name;
              return category;
          });
          this.categoryData = [...this.categoryData, ...categoryResponse];
          this.categoryDataColone = [...this.categoryDataColone, ...categoryResponse];
    });
  }

  getAllRealityCategories() {
    this.service.findAll(Service.REALITY_CATEGORY, {page: this.currentCategoriesPage, size: environment.size})
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalRealityCategories = response.totalElements;
      const categoryResponse = response.categories;
      this.realityCategoryData = [...this.realityCategoryData, ...categoryResponse];
      this.realityCategoryDataColone = [...this.realityCategoryDataColone, ...categoryResponse];
    });
  }

  save() {
    this.emptySliders = [];
    this.emptyCategory = [];
    this.validatingRequiredFields();
    this.isFormValidate();
    if (this.isFormValidated) {
      this.createRequestBody();
      this.service.save(Service.SCREEN, this.requestBody).pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
        this.screenId = result.id;
        this.isPublishDisabled = false;
        this.isPreviewEnable = true;
        this.alert.successAlert(environment.alerts.success.screen.title, environment.alerts.success.screen.message);
          }, error => {
          this.alert.errorAlert(environment.alerts.error.screen.title, environment.alerts.error.screen.message);
      });
    }
  }


  onChangeOnCategory(event) {
    if (event) {
      this.categoryData = this.categoryData.filter(value => value.id !== event);
      return;
    }
    this.categoryData = this.categoryDataColone.filter(value =>
        (this.categories.findIndex(value1 => value1.id === value.id) === -1));
  }

  onChangeOnRealityCategory(event) {
    if (event) {
      this.realityCategoryData = this.realityCategoryData.filter(value => value.id !== event);
      return;
    }
    this.realityCategoryData = this.realityCategoryDataColone.filter(value =>
        (this.realityCategories.findIndex(value1 => value1.id === value.id) === -1));
  }

  onChangeOnSliderGraphic(event) {
    if (event) {
      this.slidersData = this.slidersData.filter(value => value.id !== event);
      return;
    }
    this.slidersData = this.slidersDataClone.filter(value =>
        (this.categories.findIndex(value1 => value1.id === value.id) === -1));
  }

  onChangeOnRealitySliderGraphic(event) {
    if (event) {
      this.realitySlidersData = this.realitySlidersData.filter(value => value.id !== event);
      return;
    }
    this.realitySlidersData = this.realitySlidersDataClone.filter(value =>
        (this.realityCategories.findIndex(value1 => value1.id === value.id) === -1));

  }

  publishCategory() {
    let matDialogRef = this.matModel.open(PublishScreenComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '90%',
      width: '70%',
      data: {
        id: this.screenId,
        title: this.screenName
      }
    });

    matDialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value.isPublish) {
        this.closeWindow();
      }
    });
  }

  slidersOnScroll() {
    ++this.currentSlidersPage;
    if (this.slidersDataClone.length < this.totalSliders) {
      this.loadAllSliders();
    }
  }

  realitySlidersOnScroll() {
    ++this.currentRealitySlidersPage;
    if (this.realitySlidersDataClone.length < this.realityTotalSliders) {
      this.loadAllRealitySliders();
    }
  }

  categoriesOnScroll() {
    ++this.currentCategoriesPage;
    if (this.categoryDataColone.length < this.totalCategories) {
      this.getAllCategories();
    }
  }

  realityCategoriesOnScroll() {
    ++this.currentRealityCategoriesPage;
    if (this.realityCategoryDataColone.length < this.totalRealityCategories) {
      this.getAllRealityCategories();
    }
  }


  preview() {
    const matDialogRef = this.matModel.open(PreviewScreenComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '80%',
      data: {
        screenId: this.screenId
      }
    });
  }
}
