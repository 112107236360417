import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscribersService {

  constructor(private http: HttpService) {
  }

  getAllSubscribers(options: any) {
    if (options.searchTerm && options.userStatus && options.packageType && options.startDate==null && options.endDate==null) {
      console.log("1", options);
      if (options.packageType == "FREE") {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&search-term=' + options.searchTerm + '&package=' + '1');
      } else if (options.packageType == "PREMIUM") {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&search-term=' + options.searchTerm + '&package=' + '2');
      } else if (options.packageType == 'ANNUAL PREMIUM') {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&search-term=' + options.searchTerm + '&package=' + '4');
      }
    } else if (options.userStatus && options.packageType && options.startDate==null && options.endDate==null) {
      console.log("no terms", options);
      if (options.packageType == "FREE") {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&package=' + '1');
      } else if (options.packageType == "PREMIUM") {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&package=' + '2');
      } else if (options.packageType == 'ANNUAL PREMIUM') {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&package=' + '4');
      }
    }
    else if (options.searchTerm && options.userStatus && options.packageType && options.startDate!=null && options.endDate!=null) {
      console.log("1", options);
      if (options.packageType == "FREE") {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&search-term=' + options.searchTerm + '&package=' + '1' +
            '&greater-than-date-time=' + options.startDate +' 00:00:00'+ '&less-than-date-time=' + options.endDate+' 00:00:00');
      } else if (options.packageType == "PREMIUM") {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&search-term=' + options.searchTerm + '&package=' + '2' +
            '&greater-than-date-time=' + options.startDate +' 00:00:00'+ '&less-than-date-time=' + options.endDate+' 00:00:00');
      } else if (options.packageType == 'ANNUAL PREMIUM') {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&search-term=' + options.searchTerm + '&package=' + '4' +
            '&greater-than-date-time=' + options.startDate +' 00:00:00'+ '&less-than-date-time=' + options.endDate+' 00:00:00');
      }
    }
    else if (options.userStatus && options.packageType && options.startDate!=null && options.endDate!=null) {
      console.log("no terms", options);
      if (options.packageType == "FREE") {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&package=' + '1' +
            '&greater-than-date-time=' + options.startDate +' 00:00:00'+ '&less-than-date-time=' + options.endDate+' 00:00:00');
      } else if (options.packageType == "PREMIUM") {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&package=' + '2' +
            '&greater-than-date-time=' + options.startDate +' 00:00:00'+ '&less-than-date-time=' + options.endDate+' 00:00:00');
      } else if (options.packageType == 'ANNUAL PREMIUM') {
        return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size + '&status=' + options.userStatus + '&package=' + '4' +
            '&greater-than-date-time=' + options.startDate +' 00:00:00'+ '&less-than-date-time=' + options.endDate+' 00:00:00');
      }

    }
      return this.http.get(environment.paymentUrl + '/subscriptions?page=' + options.page + '&size=' + options.size);

  }
}

