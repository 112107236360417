import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {EventService} from '../../../../../core/service/event.service';

@Component({
  selector: 'app-tv-show-monetization',
  templateUrl: './tv-show-monetization.component.html',
  styleUrls: ['./tv-show-monetization.component.scss']
})
export class TvShowMonetizationComponent implements OnInit, OnDestroy {

  availableFreeToWatchValues: any [];
  packages: any [] = [];
  packagesClone = [ ];
  availablePayToWatchValues: string[] = [];
  freeToWatch: any[] = [];
  payToWatch: string[] = [];
  matSlide;

  FREELKR = 1;
  FREEUSD = 2;
  FREEEDAY = 3;

 /* STANDARDLKR = 4;
  STANDARDUSD = 5;
  STANDARDDATE = 6;*/

  PREMIUMLKR = 7;
  PREMIUMUSD = 8;
  PREMIUMDATE = 9;
  FREE_TO_WATCH = 10;
  PAY_TO_WATCH = 11;
  ANNUALPREMIUMLKR = 12;
  ANNUALPREMIUMUSD = 13;
  ANNUALPREMIUMDATE = 14;

  freeLKR: number;
  freeUSD: number;
  freeDays: number;

 /* standardLKR: number;
  standardUSD: number;
  standardDays: number;*/

  premiumLKR: number;
  premiumUSD: number;
  premiumDays: number;

  annualPremiumLKR: number;
  annualPremiumUSD: number;
  annualPremiumDays: number;

  isFreeLKRValidate = true;
  isFreeUSDValidate = true;
  isFreeDaysValidate = true;
/*
  isStandardLKRValidate = true;
  isStandardUSDValidate = true;
  isStandardDaysValidate = true;*/

  isPremiumLKRValidate = true;
  isPremiumUSDValidate = true;
  isPremiumDaysValidate = true;

  isAnnualPremiumLKRValidate = true;
  isAnnualPremiumUSDValidate = true;
  isAnnualPremiumDaysValidate = true;

  freeLKRValidateMessage;
  freeUSDValidateMessage;
  freeDaysValidateMessage;

  /*standardLKRValidateMessage;
  standardUSDValidateMessage;
  standardDaysValidateMessage;*/

  premiumLKRValidateMessage;
  premiumUSDValidateMessage;
  premiumDaysValidateMessage;

  annualPremiumLKRValidateMessage;
  annualPremiumUSDValidateMessage;
  annualPremiumDaysValidateMessage;

  requestBody: any = {};
  destroy$ = new Subject();
  getContentIdSubscription;
  tvShowId;
  isFormValid = true;

  constructor( private service: CommonService, private alert: Alerts, private eventService: EventService) { }

  ngOnInit() {
    this.loadPackages();
    this.matSlide = false;
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        this.tvShowId = response.id;
      }
    });
    this.matSlide = false;
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
  }

  loadPackages() {
    this.service.findAll(Service.PACKAGE_TIER).pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.packages = response;
          this.packagesClone = [...response];
          this.availableFreeToWatchValues = response.filter(v => v.id !== 3).map(( pack ) => pack.package_type );
          this.freeToWatch = this.availableFreeToWatchValues;
          this.availablePayToWatchValues = this.packages
              .filter(value => !this.freeToWatch.includes(value.package_type)  && value.package_type.id === 3)
              .map(( pack ) => pack.package_type);
        });
  }

  changeItemFromFreeWatch() {
    this.availablePayToWatchValues = this.packages
        .filter(value => !this.freeToWatch.includes(value.package_type) && value.package_type !== 'STANDARD')
        .map(( pack ) => pack.package_type);
    this.availableFreeToWatchValues = [...this.availableFreeToWatchValues];
  }

  changeItemFromPayWatch() {
    this.availableFreeToWatchValues = this.packages
        .filter(value => !this.payToWatch.includes(value.package_type)&& value.package_type !== 'STANDARD')
        .map(( pack ) => pack.package_type);
    this.availablePayToWatchValues = [...this.availablePayToWatchValues];
  }

  addItemToFreeWatch() {
    this.changeItemFromFreeWatch();
  }

  addItemToPayToWatch() {
    this.changeItemFromPayWatch();
  }

  clearFreeToWatch() {
    this.changeItemFromFreeWatch();
  }

  clearPayToWatch() {
    this.changeItemFromPayWatch();
  }


  validatingRequiredFields() {

    if (!this.payToWatch.includes(environment.packages.premium) &&
        !this.freeToWatch.includes(environment.packages.premium)) {
      this.isFormValid = false;
      this.alert.errorAlert(environment.alerts.error.tv_show.monetization.title,
          environment.alerts.error.tv_show.monetization.premiumNotAvailable);
    }
    if (!this.payToWatch.includes(environment.packages.annualPremium) &&
        !this.freeToWatch.includes(environment.packages.annualPremium)) {
      this.isFormValid = false;
      this.alert.errorAlert(environment.alerts.error.tv_show.monetization.title,
          environment.alerts.error.tv_show.monetization.annualPremiumNotAvailable);
    }
    if (!((this.payToWatch.includes(environment.packages.premium) && this.payToWatch.includes(environment.packages.annualPremium)) ||
        (this.freeToWatch.includes(environment.packages.premium) && this.freeToWatch.includes(environment.packages.annualPremium)))) {
      this.isFormValid = false;
      this.alert.errorAlert(environment.alerts.error.tv_show.monetization.title,
          environment.alerts.error.tv_show.monetization.bothPackagesShouldSame);
    }

    if (this.payToWatch.includes(environment.packages.premium)) {
      if (this.premiumLKR < 0  || isNaN(this.premiumLKR) || this.premiumLKR === null) {
        this.isPremiumLKRValidate = false;
        this.premiumLKRValidateMessage = environment.validations.emptyPremiumLKRValidateMessage;
      }
      if (this.premiumUSD < 0  || isNaN(this.premiumLKR) || this.premiumUSD === null) {
        this.isPremiumUSDValidate = false;
        this.premiumUSDValidateMessage = environment.validations.emptyPremiumUSDValidateMessage;
      }
      if (this.premiumDays < 0  || isNaN(this.premiumLKR) || this.premiumDays === null) {
        this.isPremiumDaysValidate = false;
        this.premiumDaysValidateMessage = environment.validations.emptyPremiumDaysValidateMessage;
      }
    }
    if (this.payToWatch.includes(environment.packages.annualPremium)) {
      if (this.annualPremiumLKR < 0  || isNaN(this.annualPremiumLKR) || this.annualPremiumLKR === null) {
        this.isFormValid = false;
        this.isAnnualPremiumLKRValidate = false;
        this.annualPremiumLKRValidateMessage = environment.validations.emptyPremiumLKRValidateMessage;
      }
      if (this.annualPremiumUSD < 0  || isNaN(this.annualPremiumLKR) || this.annualPremiumUSD === null) {
        this.isAnnualPremiumUSDValidate = false;
        this.isFormValid = false;
        this.annualPremiumUSDValidateMessage = environment.validations.emptyPremiumUSDValidateMessage;
      }
      if (this.annualPremiumDays < 0  || isNaN(this.annualPremiumLKR) || this.annualPremiumDays === null) {
        this.isAnnualPremiumDaysValidate = false;
        this.isFormValid = false;
        this.annualPremiumDaysValidateMessage = environment.validations.emptyPremiumDaysValidateMessage;
      }
    }
    if (this.payToWatch.includes(environment.packages.free)) {
      if (this.freeLKR < 0  || isNaN(this.freeLKR) || this.freeLKR === null) {
        this.isFreeLKRValidate = false;
        this.freeLKRValidateMessage = environment.validations.emptyFreeLKRValidateMessage;
      }
      if (this.freeUSD < 0  || isNaN(this.freeUSD) || this.freeUSD === null) {
        this.isFreeUSDValidate = false;
        this.freeUSDValidateMessage = environment.validations.emptyFreeUSDValidateMessage;
      }
      if (this.freeDays < 0  || isNaN(this.freeDays) || this.freeDays === null) {
        this.isFreeDaysValidate = false;
        this.freeDaysValidateMessage = environment.validations.emptyFreeDaysValidateMessage;
      }
    }
    /*if (this.payToWatch.includes(environment.packages.standard)) {
      if (this.standardLKR < 0  || isNaN(this.standardLKR) || this.standardLKR === null) {
        this.isStandardLKRValidate = false;
        this.standardLKRValidateMessage = environment.validations.emptyStandardLKRValidateMessage;
      }
      if (this.standardUSD < 0  || isNaN(this.standardUSD) || this.standardUSD === null) {
        this.isStandardUSDValidate = false;
        this.standardUSDValidateMessage = environment.validations.emptyStandardUSDValidateMessage;
      }
      if (this.standardDays < 0  || isNaN(this.standardDays) || this.standardDays === null) {
        this.isStandardDaysValidate = false;
        this.standardDaysValidateMessage = environment.validations.emptyStandardDaysValidateMessage;
      }
    }*/
  }

  resetValidation(field) {
    this.isFormValid = true;
    switch (field) {
      case this.FREELKR:
        this.isFreeLKRValidate = true;
        break;
      case this.FREEUSD:
        this.isFreeUSDValidate = true;
        break;
      case this.FREEEDAY:
        this.isFreeDaysValidate = true;
        break;
     /* case this.STANDARDLKR:
        this.isStandardLKRValidate = true;
        break;
      case this.STANDARDUSD:
        this.isStandardUSDValidate = true;
        break;
      case this.STANDARDDATE:
        this.isStandardDaysValidate = true;
        break;*/
      case this.PREMIUMLKR:
        this.isPremiumLKRValidate = true;
        break;
      case this.PREMIUMUSD:
        this.isPremiumUSDValidate = true;
        break;
      case this.PREMIUMDATE:
        this.isPremiumDaysValidate = true;
        break;
      case this.ANNUALPREMIUMLKR:
        this.isAnnualPremiumLKRValidate = true;
        break;
      case this.ANNUALPREMIUMUSD:
        this.isAnnualPremiumUSDValidate = true;
        break;
      case this.ANNUALPREMIUMDATE:
        this.isAnnualPremiumDaysValidate = true;
        break;
    }
  }

  createRequestBody() {
    this.requestBody.content_type = environment.contentType.tvShow;
    this.requestBody.monetization = {};
    this.requestBody.monetization.allowed_advertising_free_tier = this.matSlide;
    this.requestBody.monetization.free_to_watch = [];
    this.freeToWatch.forEach((tier, index) => {

      let t;
      if (tier === environment.packages.premium) {
        t = environment.packages.premium;
      }
      if (tier === environment.packages.annualPremium) {
        t = environment.packages.annualPremium;
      }
      if (tier === environment.packages.free) {
        t = environment.packages.free;
      }
    /*  if (tier === environment.packages.standard) {
        t = environment.packages.standard;
      }*/
      this.requestBody.monetization.free_to_watch.push(t);
    });


    this.requestBody.monetization.plans = [];
    this.requestBody.monetization.plans.prices = [];
    // this.payToWatch.forEach((tier, index) => {
    //   this.requestBody.monetization.plans.push(
    //       {
    //         package_id: this.packagesClone.filter(( pack ) => {
    //               return pack.package_type === tier;
    //             }
    //         ).map(( selectedPack ) => {
    //           return selectedPack.id;
    //         })[0],
    //         prices : [
    //           {
    //             amount: tier === environment.packages.free ? this.freeLKR : this.premiumLKR,
    //             currency: environment.currency.lkr
    //           },
    //           {
    //             amount: tier === environment.packages.free ? this.freeUSD : this.premiumUSD,
    //             currency: environment.currency.usd
    //           }
    //         ],
    //         rental_period : {
    //           period: tier === environment.packages.free ? this.freeDays
    //                 : this.premiumDays,
    //           unit: environment.packageActivationUnits.days
    //         },
    //         package_type: this.payToWatch[index],
    //       },
    //   );
    // });
  }

  save() {
    this.validatingRequiredFields();
    this.createRequestBody();
    if (this.tvShowId) {
      if (this.isFreeLKRValidate && this.isFreeUSDValidate && this.isFreeDaysValidate
         && this.isPremiumLKRValidate &&
          this.isPremiumUSDValidate && this.isPremiumDaysValidate && this.isFormValid) {

        this.service.update(Service.TV_SHOW.MONETIZATION, this.tvShowId, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.alert.successAlert(environment.alerts.success.tv_show.monetization.title,
              environment.alerts.success.tv_show.monetization.message);
        }, error => {
          this.alert.errorAlert(environment.alerts.error.tv_show.monetization.title,
              error.error.error);
        });
      }
    } else {
      if (this.isFreeLKRValidate && this.isFreeUSDValidate && this.isFreeDaysValidate
          && this.isPremiumLKRValidate &&
          this.isPremiumUSDValidate && this.isPremiumDaysValidate && this.isFormValid) {
        this.service.save(Service.TV_SHOW.MONETIZATION, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.eventService.getContentIdEventEmitter({id: response.id});
          this.alert.successAlert(environment.alerts.success.tv_show.monetization.title,
              environment.alerts.success.tv_show.monetization.message);
        }, error => {
          this.alert.errorAlert(environment.alerts.error.tv_show.monetization.title,
              error.error.error);
        });
      }
    }
  }
}
