import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";
import {CommonService} from "../../../../../core/service/common_service/common.service";
import {PackageTierService} from "../../../../../core/service/common_service/ http/packege-tier/package-tier.service";
import {Alerts} from "../../../../../alert/Alert";
import {Service} from "../../../../../core/service/common_service/services";
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {NotificationTemplateService} from '../../../../../core/service/common_service/ http/notification-template/notification-template.service';

@Component({
  selector: 'app-publish-tv-show-model',
  templateUrl: './publish-tv-show-model.component.html',
  styleUrls: ['./publish-tv-show-model.component.scss']
})
export class PublishTvShowModelComponent implements OnInit {
  schedule = {};
  schedules: any = [this.schedule];
  initialData;
  countries: any[] = [];
  countriesClone: any[] = [];
  selectedCountryIds: any[] = [];
  generalPublishDateAndTime;
  generalDateAndTimeForAll;
  isGeneralPublishDateAndTimeAvailable = true;
  isGeneralDateAndTimeForAllAvailable = true;
  isSpecialPublishDateAndTimeAvailable: any[] = [];
  isSpecialDateAndTimeForAllAvailable: any[] = [];
  isSpecialCountryAvailable: any[] = [];
  generalPublishDateAndTimeMessage;
  generalDateAndTimeForAllMessage;
  specialCountryMessage;
  specialPublishDateAndTimeMessage;
  specialDateAndTimeForAllMessage;
  GENERAL_PUBLISH_DATE_TIME = 1;
  GENERAL_WATCH_ALL_DATE_TIME = 2;
  SPECIAL_COUNTRY = 3;
  SPECIAL_PUBLISH_DATE_TIME = 4;
  SPECIAL_WATCH_ALL_DATE_TIME = 5;
  tvShowMonetizationData;
  isFreeAllowed = true;
  isStandardAllowed = true;
  isPremiumAllowed = true;
  isAnnualPremiumAllowed = true;
  packages: any[] = [];
  env = environment;
  isFormValid = true;
  requestBody: any = {};
  packageTiers: any[] = [];
  isPublished = false;
  isFireBaseSendNotification = false;
  isEmailSendNotification = false;
  notificationTemplate;
  constructor(private model: MatDialogRef<PublishTvShowModelComponent>, private matModel: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: any, private commonService: CommonService,
              private commonServiceService: CommonServiceService,
              private notificationTemplateService: NotificationTemplateService,
              private packageTierService: PackageTierService, private alert: Alerts) {
    this.initialData = data;
  }

  ngOnInit() {
    const today = new Date();
    this.generalPublishDateAndTime = today;
    this.generalDateAndTimeForAll = today;
    this.getNotificationTemplate();
    this.getAllCountries();
    this.getPackages();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  addAnotherSchedule() {
    this.schedule = {};
    this.schedules.push(this.schedule);
  }

  onNoClick() {
    this.closeWindow();
  }

  closeWindow() {
    this.model.close(this.isPublished);
  }

  getNotificationTemplate() {
    this.notificationTemplateService.findAllByContentType(environment.contentType.tvShow, 0, 10)
      .subscribe(response => {
      this.notificationTemplate = response[0];
    }, error => {
      console.log(error);
    });
  }

  publishSchedule() {
    this.validateRequiredFields();
    if (this.isFormValid) {
      this.createRequestBody();
      this.commonService.update(Service.TV_SHOW.PUBLISH, this.initialData.id, this.requestBody)
          .subscribe(response => {
            this.isPublished = true;
            this.alert.publishedSuccessAlert(this.env.publish.success.tvShow.title, this.env.publish.success.tvShow.message)
                .then(() =>  {
                  this.closeWindow();
                });
          }, error => {
            this.alert.errorAlert(this.env.publish.error.tvShow.title, this.env.publish.error.tvShow.errorSavingMessage);
          });
    }
  }

  getAllCountries() {
    this.commonService.findAll(Service.COUNTRY).subscribe((response: any) => {
      this.countries = response;
      this.countriesClone = [...this.countries];
    });
  }

  changeCountry(event, index) {
    if (event) {
      this.selectedCountryIds.push({index: index, obj: event});
      this.removeFromCountryArray();
    } else {
      this.selectedCountryIds = this.selectedCountryIds.filter(value => value.index !== index);
      this.removeFromCountryArray();
    }
  }

  deleteContentRule(index, schedule) {
    this.schedules.splice(index, 1);
    this.removeSelectedCountry(schedule);
    this.removeFromCountryArray();
  }

  removeSelectedCountry(schedule) {
    this.selectedCountryIds = this.selectedCountryIds
        .filter(value => (value.obj.id !== schedule.selectedCountryId));
  }

  removeFromCountryArray() {
    this.countries = this.countriesClone.filter(country => {
      return this.selectedCountryIds.filter(value =>
          JSON.stringify(value.obj) === JSON.stringify(country)).length === 0;
    });
  }

  validateRequiredFields() {
    if (!this.generalPublishDateAndTime) {
      this.isFormValid = false;
      this.isGeneralPublishDateAndTimeAvailable = false;
      this.generalPublishDateAndTimeMessage = this.env.publish.error.tvShow.publishDateAndTimeNotAvailable;
    }

    if (!this.generalDateAndTimeForAll) {
      this.isFormValid = false;
      this.isGeneralDateAndTimeForAllAvailable = false;
      this.generalDateAndTimeForAllMessage =
          this.env.publish.error.tvShow.watchEnableDateAndTimeAllNotAvailable;
    }

    this.schedules.forEach((schedule, index) => {

      if (!schedule.selectedCountryId) {
        this.isFormValid = false;
        this.isSpecialCountryAvailable.push(index);
      }

      if (!schedule.specialPublishDateAndTime) {
        this.isFormValid = false;
        this.isSpecialPublishDateAndTimeAvailable.push(index);
      }

      if (!schedule.specialDateAndTimeForAll) {
        this.isFormValid = false;
        this.isSpecialDateAndTimeForAllAvailable.push(index);
      }

    });

    this.specialCountryMessage = this.env.publish.error.tvShow.countryNotAvailable;
    this.specialPublishDateAndTimeMessage = this.env.publish.error.tvShow.publishDateAndTimeNotAvailable;
    this.specialDateAndTimeForAllMessage =
        this.env.publish.error.tvShow.watchEnableDateAndTimeAllNotAvailable;
  }

  resetValidation(field, index?) {
    this.isFormValid = true;
    switch (field) {
      case this.GENERAL_PUBLISH_DATE_TIME: {
        this.isGeneralPublishDateAndTimeAvailable = true;
        break;
      }
      case this.GENERAL_WATCH_ALL_DATE_TIME: {
        this.isGeneralDateAndTimeForAllAvailable = true;
        break;
      }
      case this.SPECIAL_COUNTRY: {
        this.isSpecialCountryAvailable.splice(this.isSpecialCountryAvailable.indexOf(index), 1);
        break;
      }
      case this.SPECIAL_PUBLISH_DATE_TIME: {
        this.isSpecialPublishDateAndTimeAvailable
            .splice(this.isSpecialPublishDateAndTimeAvailable.indexOf(index), 1);
        break;
      }
      case this.SPECIAL_WATCH_ALL_DATE_TIME: {
        this.isSpecialDateAndTimeForAllAvailable
            .splice(this.isSpecialDateAndTimeForAllAvailable.indexOf(index), 1);
        break;
      }
    }
  }

  createRequestBody() {
    this.requestBody.title = this.notificationTemplate.name;
    this.requestBody.message = this.notificationTemplate.template.replace('%tv_show_name%', this.initialData.title);
    this.requestBody.is_send_firebase_notification = this.isFireBaseSendNotification;
    this.requestBody.is_send_email_notification = this.isEmailSendNotification;
    this.requestBody.content_type = this.env.contentType.tvShow;
    // todo remove allow early access
    this.requestBody.allow_early_access = true;
    this.requestBody.country_publish_rule_rqs = this.schedules.map((schedule) => {
      return {
        country_id: schedule.selectedCountryId,
        publish_date_and_time: schedule.specialPublishDateAndTime
      };
    });

    this.requestBody.country_watch_enable_rule_rqs = [];

    this.schedules.forEach((schedule) => {

      if (this.isFreeAllowed) {
        this.requestBody.country_watch_enable_rule_rqs.push({
          country_id: schedule.selectedCountryId,
          package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.free)[0].id,
          watch_enable_date_and_time: schedule.specialDateAndTimeForAll
        });
      }

      if (this.isStandardAllowed) {
        this.requestBody.country_watch_enable_rule_rqs.push({
          country_id: schedule.selectedCountryId,
          package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.standard)[0].id,
          watch_enable_date_and_time: schedule.specialDateAndTimeForAll
        });
      }

      if (this.isPremiumAllowed) {
        this.requestBody.country_watch_enable_rule_rqs.push({
          country_id: schedule.selectedCountryId,
          package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.premium)[0].id,
          watch_enable_date_and_time: schedule.specialDateAndTimeForAll
        });
      }

      if (this.isAnnualPremiumAllowed) {
        this.requestBody.country_watch_enable_rule_rqs.push({
          country_id: schedule.selectedCountryId,
          package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.annualPremium)[0].id,
          watch_enable_date_and_time: schedule.specialDateAndTimeForAll
        });
      }
    });

    this.requestBody.general_publish_date_and_time = this.generalPublishDateAndTime;
    this.requestBody.general_watch_enable_rule_rqs = [];

    if (this.isFreeAllowed) {
      this.requestBody.general_watch_enable_rule_rqs.push({
        package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.free)[0].id,
        watch_enable_date_and_time: this.generalDateAndTimeForAll
      });
    }

    if (this.isStandardAllowed) {
      this.requestBody.general_watch_enable_rule_rqs.push({
        package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.standard)[0].id,
        watch_enable_date_and_time: this.generalDateAndTimeForAll
      });
    }

    if (this.isPremiumAllowed) {
      this.requestBody.general_watch_enable_rule_rqs.push({
        package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.premium)[0].id,
        watch_enable_date_and_time: this.generalDateAndTimeForAll
      });
    }

    if (this.isAnnualPremiumAllowed) {
      this.requestBody.general_watch_enable_rule_rqs.push({
        package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.annualPremium)[0].id,
        watch_enable_date_and_time: this.generalDateAndTimeForAll
      });
    }
  }

  getPackages() {
    this.packageTierService.findAllPackageTiers().subscribe((response: any) => {
      this.packageTiers = response;
    }, error => {
      console.log(error.error);
    });
  }
  cancelSchedule() {}
}
