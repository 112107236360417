import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(private http: HttpService) { }


  saveMetaData(body) {
    return this.http.post(environment.serverurl + '/contents/metadata', body);
  }

  getMetaDataById(id, contentType) {
    return this.http.get<any>(environment.serverurl + '/contents/' + id + '/metadata?content=' + contentType);
  }

  getAllMetaData() {
    return this.http.get(environment.serverurl);
  }

  updateMetaData(id, body) {
    return this.http.put(environment.serverurl + '/contents/metadata/' + id, body);
  }

  deleteMetaData(id) {
    return this.http.delete(environment.serverurl + id);
  }
}
