import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../../environments/environment';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TitleCasePipe} from '@angular/common';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {PackageTierService} from '../../../../../core/service/common_service/ http/packege-tier/package-tier.service';
import {ContentService} from '../../../../../core/service/common_service/ http/contents/content.service';
import {ContentDetailService} from '../../../../../core/service/common_service/ http/content-details/content-detail.service';

@Component({
  selector: 'app-add-category-content',
  templateUrl: './add-category-content.component.html',
  styleUrls: ['./add-category-content.component.scss']
})
export class AddCategoryContentComponent implements OnInit, OnDestroy {

  env = environment;
  selectedContentType: string;
  destroy$ = new Subject();
  tableData: any[] = [];
  selectedPage = 1;
  selectedContents: any[] = [];
  loading = false;
  totalData: number;
  formData: any;
  checkedRows: number[] = [1, 2, 4] ;
  contentTypes: any[] = [];
  searchTerm = '';
  isPublishedSelected = true;
  searchContentValidationMessage;
  isSearchContentValidated = true;
  contentStatusText = 'Published';
  contentStatus;
  pageCount: any = 0;
  columnData: any[] = [];
  packages: any[] = [];
  queryObj;
  isFilterEnabled = false;
  contentStatuses: any[] = [];
  isFilterable = false;
  keywords: any[] = [];
  genres: any[] = [];
  actors: any[] = [];
  directors: any[] = [];
  producers: any[] = [];
  isRefreshEnabled = false;
  selectedServiceType;
  isDisableSearch = false;
  selectedRating;
  constructor(private model: MatDialogRef<AddCategoryContentComponent>,
              private commonService: CommonService,
              private titleCasePipe: TitleCasePipe,
              private commonServiceService: CommonServiceService,
              private packageService: PackageTierService,
              private contentService: ContentService,
              private contentDetailService: ContentDetailService,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.formData = data.data;
    this.selectedRating = data.rating;
    this.contentTypes = this.env.categoryContentType;
    this.contentStatus =  this.env.contentStatus.published;
  }

  ngOnInit() {
    this.loadData();
    this.loadAllPackages();
    this.loadContentStatusFilterData();
    this.refreshQueryObj();
    this.isRefreshEnabled = true;
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeWindow() {
    this.model.close();
  }

  selectService() {
    if ((this.selectedContentType !== this.env.contentType.tvEpisode)
      && (this.selectedContentType !== this.env.contentType.video)) {
      this.selectedServiceType = this.contentService;
    } else {
      this.selectedServiceType = this.contentDetailService;
    }
  }

  loadContentStatusFilterData() {
    const filterDataArray = [];
    this.contentStatuses.push({
      id: this.env.contentStatus.published,
      value: this.env.contentStatus.published
    });

    this.contentStatuses.push({
      id: this.env.contentStatus.scheduled,
      value: this.env.contentStatus.scheduled
    });

    filterDataArray.push({
      field: this.env.addCategoryContentsColumns[2].field,
      data: this.contentStatuses
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllPackages() {
    this.loading =  true;
    this.packageService.findAllPackageTiers()
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.packages = response;
      this.loadPackageFilterData();
      this.loading = false;
    });
  }

  loadPackageFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.packages.map(val => {
      return {
        id: val.id,
        value: val.package_type
      };
    });

    modifiedUserData.splice(2, 1);
    const payToWatch = this.env.publishedMovieColumns[3].field;

    filterDataArray.push({
      field: payToWatch,
      data: modifiedUserData
    });

    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllKeywords() {
    this.loading =  true;
    this.selectedServiceType.findKeywords(this.selectedContentType, this.selectedPage - 1, 200)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.keywords = response;
      this.loadKeywordFilterData();
      this.loading = false;
    });
  }

  loadKeywordFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.keywords.map(val => {
      return {
        id: val.id,
        value: val.name
      };
    });

    filterDataArray.push({
      field: this.env.addCategoryContentsColumns[4].field,
      data: modifiedUserData
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllGenres() {
    this.loading =  true;
    this.selectedServiceType.findGenres(this.selectedContentType, this.selectedPage - 1, 200)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.genres = response;
      this.loadGenreFilterData();
      this.loading = false;
    });
  }

  loadGenreFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.genres.map(val => {
      return {
        id: val.id,
        value: val.name
      };
    });

    filterDataArray.push({
      field: this.env.addCategoryContentsColumns[5].field,
      data: modifiedUserData
    });

    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadRuntimeFilterData() {
    const filterDataArray = [];
    const modifiedUserData = ['0-60', '60-120', '120-180', '240-above'];

    filterDataArray.push({
      field: this.env.addCategoryContentsColumns[9].field,
      data: modifiedUserData
    });

    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllActors() {
    this.loading =  true;
    this.selectedServiceType.findCatMembers(this.selectedContentType, this.env.castMemberRoles[0].role,
      this.selectedPage - 1, 200)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.actors = response;
      this.loadActorFilterData();
      this.loading = false;
    });
  }

  loadActorFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.actors.map(val => {
      return {
        id: val.id,
        value: val.name
      };
    });

    filterDataArray.push({
      field: this.env.addCategoryContentsColumns[8].field,
      data: modifiedUserData
    });

    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllDirectors() {
    this.loading =  true;
    this.selectedServiceType.findCatMembers(this.selectedContentType, this.env.castMemberRoles[1].role,
      this.selectedPage - 1, 200)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.directors = response;
      this.loadDirectorFilterData();
      this.loading = false;
    });
  }

  loadDirectorFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.directors.map(val => {
      return {
        id: val.id,
        value: val.name
      };
    });

    filterDataArray.push({
      field: this.env.addCategoryContentsColumns[7].field,
      data: modifiedUserData
    });

    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllProducers() {
    this.loading =  true;
    this.selectedServiceType.findCatMembers(this.selectedContentType, this.env.castMemberRoles[2].role,
      this.selectedPage - 1, 200)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.producers = response;
      this.loadProducerFilterData();
      this.loading = false;
    });
  }

  loadProducerFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.producers.map(val => {
      return {
        id: val.id,
        value: val.name
      };
    });

    filterDataArray.push({
      field: this.env.addCategoryContentsColumns[6].field,
      data: modifiedUserData
    });

    this.columnData = [...this.columnData, ...filterDataArray];
  }

  search() {
    this.isSearchContentValidated =  true;
    if (!this.selectedContentType) {
      this.isSearchContentValidated =  false;
      this.searchContentValidationMessage = environment.validations.contentTypeNotSelectedValidationMessage;
    } else {
      this.tableData = [];
      this.selectedPage = 1;
      this.searchRequest();
    }
  }

  searchRequest() {
    let type;
    switch (this.selectedContentType) {

      case this.env.contentType.movie:
        type = Service.MOVIE;
        break;
      case this.env.contentType.tvShow:
        type = Service.TV_SHOW;
        break;
      case this.env.contentType.tvEpisode:
        type = Service.TV_EPISODE;
        break;
      case this.env.contentType.video:
        type = Service.VIDEO;
        break;
      case this.env.contentType.videoChannel:
        type = Service.VIDEO_CHANNEL;
        break;
      default:
        break;
    }
    this.loading = true;
    this.commonService.search(type, this.contentStatus,
      this.searchTerm, this.selectedPage - 1, 50, this.selectedRating)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.processData(response);
      }, error => {
        this.loading = false;
      });
  }

  findContents() {
    let service;
    switch (this.selectedContentType) {

      case this.env.contentType.movie:
        service = Service.MOVIE.STATUS;
        break;
      case this.env.contentType.tvShow:
        service = Service.TV_SHOW.STATUS;
        break;
      case this.env.contentType.tvEpisode:
        service = Service.TV_EPISODE.STATUS;
        break;
      case this.env.contentType.video:
        service = Service.VIDEO.STATUS;
        break;
      case this.env.contentType.videoChannel:
        service = Service.VIDEO_CHANNEL.STATUS;
        break;
      case this.env.contentType.liveChannel:
        service = Service.LIVE_CHANNEL.STATUS;
        break;
      default:
        break;
    }

    this.loading = true;
    this.commonService.findAllByStatus(service,
        {
          status: environment.contentStatusString + this.env.contentStatus.published + '&' +
              environment.contentStatusString + this.env.contentStatus.scheduled,
          contentType: this.selectedContentType,
          page: this.selectedPage - 1,
          size: 50,
          maturityRating: this.selectedRating
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.processData(response);
        }, error => {
          this.loading = false;
        });
  }

  processData(response: any) {
    this.totalData = response.total;

    if (this.selectedContentType === this.env.contentType.movie || this.selectedContentType === this.env.contentType.videoChannel ||
      this.selectedContentType === this.env.contentType.tvShow || this.selectedContentType === this.env.contentType.liveChannel) {

      const resData = response.content_by_multiple_status !== undefined ? response.content_by_multiple_status
          : response.contents_by_status;
      const mappedData = resData.map((content, index) => {

        if (content.title) {
          content.englishTitle = content.title.english;
        }

        if (content.title) {
          content.sinhalaTitle = content.title.sinhala;
        }

        if (content.title) {
          content.tamilTitle = content.title.tamil;
        }

        if (content.poster) {
          content.poster = {
            isPoster: true,
            url: content.poster.source_url
          };
        }

        if (content.monetization && content.monetization.plans.length > 0) {
          let payToWatchPlans;
          payToWatchPlans = content.monetization.plans.map((plan) => {
            return this.titleCasePipe.transform(plan.package_type);
          });

          content.payToWatch = payToWatchPlans.join();
        }

        if (content.monetization && content.monetization.free_to_watch.length > 0) {
          let freeToWatchPlans;
          freeToWatchPlans = content.monetization.free_to_watch.map((plan) => {
            return this.titleCasePipe.transform(plan);
          });

          content.freeToWatch = freeToWatchPlans.join();
        }

        if (content.actors && content.actors.length > 0) {
          content.actors = content.actors.map((actor) => {
            return this.titleCasePipe.transform(actor.name);
          }).join();
        }

        if (content.directors && content.directors.length > 0) {
          content.directors = content.directors.map((director) => {
            return this.titleCasePipe.transform(director.name);
          }).join();

        }

        if (content.producers && content.producers.length > 0) {
          content.producers = content.producers.map((producer) => {
            return this.titleCasePipe.transform(producer.name);
          }).join();
        }

        if (content.keywords && content.keywords.length > 0) {
          content.keywords = content.keywords.map((keyword) => {
            return this.titleCasePipe.transform(keyword.name);
          }).join();
        }

        if (content.genres && content.genres.length > 0) {
          content.genres = content.genres.map((genre) => {
            return this.titleCasePipe.transform(genre.name);
          }).join();
        }

        content.isSelected = (this.formData.filter(value => value.id === content.id).length > 0)
          || (this.selectedContents.filter(value => value.id === content.id).length > 0);

        return content;
      });

      this.tableData = [...this.tableData, ...mappedData];
    } else {

      const resData = response.content_by_multiple_status !== undefined ? response.content_by_multiple_status
          : response.contents_by_status;
      console.log(resData);
      const mappedData = resData.map((content, index) => {

        if (content.title) {
          content.englishTitle = content.title.english;
        }

        if (content.title) {
          content.sinhalaTitle = content.title.sinhala;
        }

        if (content.title) {
          content.tamilTitle = content.title.tamil;
        }

        if (content.poster) {
          content.poster = {
            isPoster: true,
            url: content.poster.source_url
          };
        }

        if (content.monetization && content.monetization.plans.length > 0) {
          let payToWatchPlans;
          payToWatchPlans = content.monetization.plans.map((plan) => {
            return this.titleCasePipe.transform(plan.package_type);
          });

          content.payToWatch = payToWatchPlans.join();
        }

        if (content.monetization && content.monetization.free_to_watch.length > 0) {
          let freeToWatchPlans;
          freeToWatchPlans = content.monetization.free_to_watch.map((plan) => {
            return this.titleCasePipe.transform(plan);
          });

          content.freeToWatch = freeToWatchPlans.join();
        }

        if (content.actors.length > 0) {
          content.actors = content.actors.map((actor) => {
            return this.titleCasePipe.transform(actor.name);
          }).join();
        }

        if (content.directors.length > 0) {
          content.directors = content.directors.map((director) => {
            return this.titleCasePipe.transform(director.name);
          }).join();

        }

        if (content.producers.length > 0) {
          content.producers = content.producers.map((producer) => {
            return this.titleCasePipe.transform(producer.name);
          }).join();
        }

        if (content.keywords.length > 0) {
          content.keywords = content.keywords.map((keyword) => {
            return this.titleCasePipe.transform(keyword.name);
          }).join();
        }

        if (content.genres.length > 0) {
          content.genres = content.genres.map((genre) => {
            return this.titleCasePipe.transform(genre.name);
          }).join();
        }

        content.isSelected = (this.formData.filter(value => value.id === content.id).length > 0)
          || (this.selectedContents.filter(value => value.id === content.id).length > 0);

        return content;
      });

      this.tableData = [...this.tableData, ...mappedData];
    }
    this.isRefreshEnabled = false;
    this.loading = false;
  }

  setPage(event) {
    this.selectedPage = event;
    if (this.tableData.length < this.totalData) {
      if (this.searchTerm.length > 0) {
        this.searchRequest();
        return;
      }
      if (!this.isFilterable) {
        this.findContents();
      } else {
        this.loadDataByFilters(this.queryObj);
      }
    }
  }

  getSelectedContents(event) {
    this.selectedContents = event;
  }

  addSelected() {
    this.model.close(this.selectedContents);
  }

  setContentType(event) {
    this.isSearchContentValidated =  true;
    this.selectedPage = 1;
    this.tableData = [];
    this.isRefreshEnabled = true;
    if (event) {
      this.selectService();
      this.loadAllKeywords();
      this.loadAllGenres();
      this.loadAllActors();
      this.loadAllDirectors();
      this.loadAllProducers();
      this.loadRuntimeFilterData();
      this.isFilterEnabled = true;
      this.findContents();
    } else {
      this.isFilterEnabled = false;
    }
  }

  loadData() {
    if (this.formData && this.formData.length > 0 ) {
      if ((this.env.contentType.tvEpisode !== this.formData[0].content_type) &&
          (this.env.contentType.video !== this.formData[0].content_type)) {
        this.contentTypes = this.contentTypes.splice(4, 2);
      } else {
        this.contentTypes = this.contentTypes.splice(0, 4);
      }
    }
  }

  changeContentStatus(isPublished) {
    if (isPublished) {
      this.contentStatusText = 'Published';
      this.contentStatus =  this.env.contentStatus.published;
    } else  {
      this.contentStatusText = 'Scheduled';
      this.contentStatus =  this.env.contentStatus.scheduled;
    }
  }

  findAllByFilter(filterData) {

    let releasedDateRange = [];
    if (filterData.filter(filter => filter.value).length <= 0) {
      this.isFilterable = false;
    } else {
      this.isFilterable = true;
    }
    filterData.forEach(filter => {

      if (filter.field === this.env.addCategoryContentsColumns[1].field) {
        releasedDateRange = filter.value.split(' to ');
        if (releasedDateRange.length > 1) {
          this.queryObj.greaterThanReleasedDateAndTime = releasedDateRange[0];
          this.queryObj.lessThanReleasedDateAndTime = releasedDateRange[1];
        }
      }

      if (filter.field === this.env.addCategoryContentsColumns[3].field) {
        const filteredPackages = this.packages.filter(val => val.id === filter.value);
        if (filteredPackages.length > 0) {
          this.queryObj.payToWatch = filteredPackages[0].package_type;
        }
      }

      if (filter.field === this.env.addCategoryContentsColumns[2].field) {
        const filteredStatuses = this.contentStatuses.filter(val => val.id === filter.value);
        if (filteredStatuses.length > 0) {
          this.queryObj.contentStatus = filteredStatuses[0].value;
        }
      }

      if (filter.field === this.env.addCategoryContentsColumns[4].field) {
          this.queryObj.keywordId = filter.value;
      }

      if (filter.field === this.env.addCategoryContentsColumns[5].field) {
        this.queryObj.genreId = filter.value;
      }

      if (filter.field === this.env.addCategoryContentsColumns[6].field) {
        this.queryObj.producerId = filter.value;
      }

      if (filter.field === this.env.addCategoryContentsColumns[7].field) {
        this.queryObj.directorId = filter.value;
      }

      if (filter.field === this.env.addCategoryContentsColumns[8].field) {
        this.queryObj.actorId = filter.value;
      }

      if (filter.field === this.env.addCategoryContentsColumns[9].field) {
        this.queryObj.runTime = filter.value;
      }

    });

    if (this.queryObj.runTime
      || this.queryObj.actorId
      || this.queryObj.directorId
      || this.queryObj.producerId
      || this.queryObj.genreId
      || this.queryObj.keywordId
      || this.queryObj.contentStatus
      || this.queryObj.freeToWatch
      || this.queryObj.payToWatch
      || this.queryObj.greaterThanReleasedDateAndTime) {
      if ((releasedDateRange.length === 1) && (releasedDateRange[0] !== '')) {
        return;
      }
      if (!this.queryObj.contentStatus) {
        this.queryObj.multipleContentStatus = true;
      }
      this.tableData = [];
      this.selectedPage = 1;
      this.isDisableSearch = true;
      this.loadDataByFilters(this.queryObj);
    } else if (!this.isFilterable) {
      this.isDisableSearch = false;
      this.findContents();
    }
  }

  loadDataByFilters(queryObj) {
    this.loading = true;
    this.selectedServiceType.contentByFilters(this.selectedContentType, queryObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.processData(response);
        this.refreshQueryObj();
        this.loading = false;
      }, error => {
        console.log(error);
        this.loading = false;
      });
  }

  refreshQueryObj() {
    this.queryObj = {
      payToWatch: null,
      freeToWatch: null,
      contentStatus: null,
      keywordId: null,
      genreId: null,
      actorId: null,
      directorId: null,
      producerId: null,
      runTime: null,
      page: this.selectedPage - 1,
      size: this.env.size,
      lessThanReleasedDateAndTime: null,
      greaterThanReleasedDateAndTime: null,
      isCategoryFilter: true
    };
  }
}
