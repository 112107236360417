import { Injectable } from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {HttpService} from "../http.service";

@Injectable({
  providedIn: 'root'
})
export class PublishService {

  constructor(private http: HttpService) { }

  publishContent(id, body) {
    return this.http.put(environment.serverurl + '/contents/publish/' + id, body);
  }
}
