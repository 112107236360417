import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashbordService {

  constructor(private http: HttpService) { }


  loadAllSubscriptions() {
    return this.http.get<any>(environment.serverurl + '/user-count');
  }

  loadRevenue(dateRange) {
    return this.http.get<any>(environment.paymentUrl + '/payments/' + dateRange.startDate + '/' + dateRange.endDate);
  }

  loadTraffic(dateRange) {
    return this.http.get<any>(environment.serverurl + '/dashboard/traffic-by-date/' + dateRange.startDate + '/'
      + dateRange.endDate);
  }

  loadDeviceTraffic(dateRange) {
    return this.http.get<any>(environment.serverurl + '/dashboard/traffic-by-device/' + dateRange.startDate + '/'
      + dateRange.endDate);
  }

  loadCountryTraffic(dateRange) {
    return this.http.get<any>(environment.serverurl + '/dashboard/traffic-by-country/' + dateRange.startDate + '/'
      + dateRange.endDate);
  }

  loadMostWatchedContent(dateRange) {
    return this.http.get<any>(environment.serverurl + '/dashboard/most-watch-content/' + dateRange.startDate + '/'
      + dateRange.endDate);
  }

  loadActiveSubscriptions() {
    return this.http.get<any>(environment.serverurl + '/dashboard/active-subscriptions');
  }

  loadTotalWatchHours(dateRange) {
    return this.http.get<any>(environment.serverurl + '/dashboard/total-watch-hours/' + dateRange.startDate + '/'
      + dateRange.endDate);
  }
  getMovieContentDetails(startDate: string, endDate:string) : Observable<any>{
    // @ts-ignore
    return this.http.get<any>('https://cms-stg.dflix.com/dashboard/contentType/MOVIE/'+startDate+'/'+endDate,{headers: this.headersX})
  }

  getTvShowContentDetails(startDate: string, endDate:string) : Observable<any>{
    // @ts-ignore
    return this.http.get<any>('https://cms-stg.dflix.com/dashboard/contentType/TV_SHOW/'+startDate+'/'+endDate,{headers: this.headersX})
  }
  getVidioChanelContentDetails(startDate: string, endDate:string) : Observable<any>{
    // @ts-ignore
    return this.http.get<any>('https://cms-stg.dflix.com/dashboard/contentType/VIDEO_CHANNEL/'+startDate+'/'+endDate,{headers: this.headersX})
  }
  /*---------------GET TOP RATED CONTENT----------------------------*/
  getMovieTopRatedDetails(startDate: string, endDate:string) : Observable<any>{
    // @ts-ignore
    //return this.http.get('https://cms-stg.dflix.com/dashboard/topWatchContent/MOVIE/'+startDate+'/'+endDate,{headers: this.headersX})
    return this.http.get('https://cms-stg.dflix.com/dashboard/topWatchContent/MOVIE/'+startDate+'/'+endDate,{headers: this.headersX})
  }
  getTvShowTopRatedDetails(startDate: string, endDate:string) : Observable<any>{
    // @ts-ignore
    return this.http.get('https://cms-stg.dflix.com/dashboard/topWatchContent/TV_SHOW/'+startDate+'/'+endDate,{headers: this.headersX})
  }
  getVideoChanelTopRatedDetails(startDate: string, endDate:string) : Observable<any>{
    // @ts-ignore
    return this.http.get('https://cms-stg.dflix.com/dashboard/topWatchContent/VIDEO_CHANNEL/'+startDate+'/'+endDate,{headers: this.headersX})
  }
  getSevenDayTopRatedDetails(startDate: string, endDate:string) : Observable<any>{
    // @ts-ignore
    return this.http.get('https://cms-stg.dflix.com/dashboard/topWatchContent/'+startDate+'/'+endDate,{headers: this.headersX})
  }
  getHoursByDate(startDate: string, endDate:string) : Observable<any>{
    // @ts-ignore
    return this.http.get('https://cms-stg.dflix.com/dashboard/content/'+startDate+'/'+endDate,{headers:this.headersX});
  }

}
