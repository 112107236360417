import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {LiveChannelService} from '../../../../../core/service/common_service/ http/contents/live-channel/live-channel.service';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {FileReaderService} from '../../../../../core/service/file-reader.service';
import {Alerts} from '../../../../../alert/Alert';
import {ProgrammeService} from '../../../../../core/service/common_service/ http/programme/programme.service';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Service} from '../../../../../core/service/common_service/services';

@Component({
  selector: 'app-upload-epg',
  templateUrl: './upload-epg.component.html',
  styleUrls: ['./upload-epg.component.scss']
})

export class UploadEpgComponent implements OnInit, OnDestroy {

  liveChannels: any = [];
  isLiveChannelValidated = true;
  isTemplateValidated = true;
  selectedLiveChannel;
  isFormValid = true;
  LIVE_CHANNEL = 1;
  TEMPLATE = 2;
  liveChannelValidateMessage;
  templateValidateMessage;
  currentTvShowPage = 0;
  env = environment;
  destroy$ = new Subject();
  totalLiveChannels;
  liveChannelsClone: any = [];
  file: File[] = [];
  templates: any[] = [{file: this.file}];
  header = false;
  requestBody: any = [];
  saveBtnDisabled = false;
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  public records: any[] = [];
  constructor(private liveChannelService: LiveChannelService,
              private commonService: CommonService,
              private fileReaderService: FileReaderService,
              private model: MatDialogRef<UploadEpgComponent>,
              private alert: Alerts) { }

  ngOnInit() {
    this.getLiveChannels();
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLiveChannels() {
    this.liveChannelService.findAll({
      page: this.currentTvShowPage,
      size: this.env.size
    })
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalLiveChannels = response.total;
      this.liveChannels = response.data;
      this.liveChannelsClone = this.liveChannels;
    });
  }

  resetValidation(field) {
    this.isFormValid = true;
    switch (field) {
      case this.LIVE_CHANNEL:
        this.isLiveChannelValidated = true;
        break;
      default: {
        this.isTemplateValidated = true;
      }
    }
  }

  onSelect(event) {
    this.resetValidation(this.TEMPLATE);
    this.templates[0].file  = event.addedFiles;
    const file = event.addedFiles[0];
    this.fileReaderService.readXlsxFile(file, this.selectedLiveChannel).then(value => {
      this.records = value;
    });
  }

  validatingRequiredFields() {

    if (!this.selectedLiveChannel) {
      this.isFormValid = false;
      this.isLiveChannelValidated = false;
      this.liveChannelValidateMessage = this.env.validations.liveChannelValidateMessage;
    }

    if (this.templates[0].file.length <= 0) {
      this.isFormValid = false;
      this.isTemplateValidated = false;
      this.templateValidateMessage = this.env.validations.templateValidateMessage;
    }

  }

  remove() {
    this.templates[0].file = this.file;
  }

  closeWindow() {
    this.model.close();
  }

  createRequestBody() {
    this.requestBody = this.records;
  }

  save() {
    this.validatingRequiredFields();
    if (this.isFormValid) {
    this.createRequestBody();
    this.commonService.saveAll(Service.PROGRAMME_SCHEDULE, this.requestBody)
      .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.alert.successAlert(this.env.alerts.success.live_channel.programme.title,
        this.env.alerts.success.live_channel.programme.message);
      this.saveBtnDisabled = false;
    }, error => {
      console.log(error);
      this.saveBtnDisabled = false;
      this.alert.errorAlert(this.env.alerts.error.live_channel.programme.title, error.error.error);
    });
    }
  }
}
