import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentDetailsPublishService {

  constructor(private http: HttpService) { }

  publishContent(id, body) {
    return this.http.put(environment.serverurl + '/content-details/publish/' + id, body);
  }


  unPublish(id, body) {
    return this.http.put<any>(environment.serverurl + '/content-details/un-publish/' + id, body);
  }
}
