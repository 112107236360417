import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {EventService} from '../../../../../core/service/event.service';
import {TitleCasePipe, UpperCasePipe} from '@angular/common';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-poster-detail-view',
  templateUrl: './poster-detail-view.component.html',
  styleUrls: ['./poster-detail-view.component.scss']
})
export class PosterDetailViewComponent implements OnInit, OnDestroy {


  config: any;
  selectValue: any[] = [];
  file: File[] = [];
  posters: any[] = [];
  files: File[] = [];
  movieId;
  emptySubs: number[] = [];
  emptyScreens: number[] = [];
  emptyCaptions: number[] = [];
  subValidateMessage = environment.validations.emptySubValidateMessage;
  captionValidateMessage = environment.validations.emptyCaptionValidateMessage;
  screenTypeValidateMessage = environment.validations.emptyScreenTypeValidateMessage;
  requestBody: any = {};
  destroy$ = new Subject();
  isFormValid = true;
  getContentIdSubscription;
  saveBtnDisabled = false;
  promiseArray: any[] = [];
  IMAGE = 1;
  SCREEN = 2;
  CAPTION = 3;
  selectedPosterData: any[] = [];
  firstPoster: string;
  secondPoster: string;

  toggleLayer = true;
  editViewerEvent;
  PosterValidationMessage =  environment.validations.posterValidationMessage;
  isPosterSizeValidate = true;
  @ViewChild('poster', null) formReference: any;



  constructor(
      private commonService: CommonService,
      private s3Bucket: S3BucketService,
      private alert: Alerts,
      private eventService: EventService,
      private titlePipe: TitleCasePipe,
      private upperCase: UpperCasePipe
  ) { }

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.movieId = response.id;
      }
    });

    this.selectValue = environment.orientationTypes;

    this.editViewerEvent = this.eventService.contentViewSender.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.toggleLayer = value.viewEnable;

    });
    this.loadPosterData();
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
    this.editViewerEvent.unsubscribe();

  }

  onSelect(event, index) {
    this.posters[index].file  = event.addedFiles;
    this.posters[index].caption = this.posters[index].file[0].name;
    this.resetValidations(this.CAPTION, index);
    this.posters[index].isOrientationTypeDisable = false;
    this.posters[index].isPosterSizeValidate = true;
    this.posters[index].screenType = null;
  }
  addAnotherSub() {
    this.file = [];
    this.posters.push({file: this.file, isOrientationTypeDisable: true, isPosterSizeValidate: true});
  }

  deleteSubtitle( index ) {
    this.posters.splice( index, 1);
    this.emptyScreens.splice(this.emptyScreens.indexOf(index), 1);
    this.emptySubs.splice(this.emptySubs.indexOf(index), 1);
    this.emptyCaptions.splice(this.emptyCaptions.indexOf(index), 1);
  }

  createRequestBody() {
    this.requestBody.content_type = environment.contentType.movie;
    this.requestBody.posters = [];
    let screen = '';
    let orientation = '';
    let imageType = '';
    this.posters.forEach((poster, index) => {

      switch (poster.screenType) {
        case environment.orientationTypes[0].id:
          screen = environment.deviceType.tv;
          orientation = this.upperCase.transform(environment.orientations.landscape);
          imageType = environment.resolutionImageType.imageWithTitle;
          break;
        case environment.orientationTypes[1].id:
          screen = environment.deviceType.tv;
          orientation = this.upperCase.transform(environment.orientations.landscape);
          imageType = environment.resolutionImageType.imageWithoutTitle;
          break;
       /* case environment.orientationTypes[2].id:
          screen = environment.deviceType.tv;
          orientation = this.upperCase.transform(environment.orientations.landscape);
          imageType = environment.resolutionImageType.imageWithoutTitle;
          break;*/
        case environment.orientationTypes[4].id:
          screen = environment.deviceType.tv;
          orientation = this.upperCase.transform(environment.orientations.landscape);
          imageType = environment.resolutionImageType.title;
          break;
        case environment.orientationTypes[2].id:
          screen = environment.deviceType.tablet;
          orientation = this.upperCase.transform(environment.orientations.portrait);
          imageType = environment.resolutionImageType.imageWithTitle;
          break;
        default:
          screen = environment.deviceType.tablet;
          orientation = this.upperCase.transform(environment.orientations.portrait);
          imageType = environment.resolutionImageType.imageWithoutTitle;
      }
      this.requestBody.posters.push({
          alt_tag : poster.caption,
          device_type : screen,
          orientation_type : orientation,
          image_name : (poster.source_url) ? poster.image_name : poster.file[0].name,
          source_url : poster.imageLocation.replace(environment.s3Bucket.actualPath,
            environment.s3Bucket.actualPathReplaceTo),
          image_conversion_status : environment.conversionStatus.pending,
          image_type: imageType,
          resolution_type: environment.resolutionTypes.fourX
        }
      );
    });
  }

  validateRequiredFields() {

    const landscapeWithTitle = this.posters.filter(value => value.screenType === this.selectValue[0].id);

    if (landscapeWithTitle.length <= 0) {
      this.alert.errorAlert(
        environment.alerts.error.movie.poster.screenType.landscapeWithTitle.title,
        environment.alerts.error.movie.poster.screenType.landscapeWithTitle.message );
      this.isFormValid = false;
      return;
    }

    const landscapeWithoutTitle = this.posters.filter(value => value.screenType === this.selectValue[1].id);

    if (landscapeWithoutTitle.length <= 0) {
      this.alert.errorAlert(
        environment.alerts.error.movie.poster.screenType.landscapeWithoutTitle.title,
        environment.alerts.error.movie.poster.screenType.landscapeWithoutTitle.message );
      this.isFormValid = false;
      return;
    }

/*    const landscapeDetailWithoutTitle = this.posters.filter(value => value.screenType === this.selectValue[2].id);

    if (landscapeDetailWithoutTitle.length <= 0) {
      this.alert.errorAlert(
        environment.alerts.error.movie.poster.screenType.landscapeDetailWithoutTitle.title,
        environment.alerts.error.movie.poster.screenType.landscapeDetailWithoutTitle.message );
      this.isFormValid = false;
      return;
    }*/

    const landscapeTitle = this.posters.filter(value => value.screenType === this.selectValue[2].id);

    if (landscapeTitle.length <= 0) {
      this.alert.errorAlert(
        environment.alerts.error.movie.poster.screenType.landscapeTitle.title,
        environment.alerts.error.movie.poster.screenType.landscapeTitle.message );
      this.isFormValid = false;
      return;
    }

    const portraitWithTitle = this.posters.filter(value => value.screenType === this.selectValue[3].id);

    if (portraitWithTitle.length <= 0) {
      this.alert.errorAlert(
        environment.alerts.error.movie.poster.screenType.portraitWithTitle.title,
        environment.alerts.error.movie.poster.screenType.portraitWithTitle.message );
      this.isFormValid = false;
      return;
    }

    const portraitWithoutTitle = this.posters.filter(value => value.screenType === this.selectValue[4].id);

    if (portraitWithoutTitle.length <= 0) {
      this.alert.errorAlert(
        environment.alerts.error.movie.poster.screenType.portraitWithoutTitle.title,
        environment.alerts.error.movie.poster.screenType.portraitWithoutTitle.message );
      this.isFormValid = false;
      return;
    }

    let index = 0;
    for (const poster of this.posters) {

      if (!poster.imageLocation && this.emptySubs.indexOf(index) === -1) {
        this.emptySubs.push(index);
      }

      if (!poster.screenType && this.emptyScreens.indexOf(index) === -1) {
        this.emptyScreens.push(index);
      }

      if (!poster.caption && this.emptyCaptions.indexOf(index) === -1) {
        this.emptyCaptions.push(index);
      }

      ++index;
    }

    if (this.emptySubs.length > 0 || this.emptyScreens.length > 0 || this.emptyCaptions.length > 0) {
      this.isFormValid = false;
    }
  }

  resetValidations(field, index) {
    this.isFormValid = true;

    switch (field) {
      case this.SCREEN: {
        if (this.emptyScreens.indexOf(index) !== -1) {
          this.emptyScreens.splice(this.emptyScreens.indexOf(index), 1);
        }
        this.validateImageFormat(index, this.posters[index].screenType);
        break;
      }
      case this.IMAGE: {
        if (this.emptySubs.indexOf(index) !== -1) {
          this.emptySubs.splice(this.emptySubs.indexOf(index), 1);
        }

        break;
      }
      default: {
        if (this.emptyCaptions.indexOf(index) !== -1) {
          this.emptyCaptions.splice(this.emptyCaptions.indexOf(index), 1);
        }

      }
    }
  }

  uploadImages() {
    this.validateRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.movieId) {
      this.commonService.save(Service.MOVIE.CONTENT, {type: environment.contentType.movie})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.movieId = response.id;
        this.eventService.getContentIdEventEmitter({id: response.id});
        this.s3upload();
      });
    } else {
      this.s3upload();
    }
    this.saveBtnDisabled = true;
  }


  s3upload() {
    let uploadPromise;
    for (const poster of this.posters) {
      if (poster.file[0]) {
        uploadPromise = new Promise((resolve, reject) => {
          this.s3Bucket.uploadFile(this.movieId, poster.file[0], environment.s3Bucket.path.posters,
              environment.s3Bucket.buckets.bucketPath +
              environment.s3Bucket.buckets.destinationFolder +
              environment.s3Bucket.contentTypeFolder.movies,
              (error, response, pr) => {
                if (error) {
                  reject({status: false, response: error});
                }
                poster.imageLocation = response.Location;
                resolve({status: true, response});
              }).on('httpUploadProgress', (progress) => {
            poster.progress = Math.round(progress.loaded / progress.total * 100);
          });
        });
        this.promiseArray.push(uploadPromise);
      }
    }
    Promise.all(this.promiseArray).then(value => {
      if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
        this.save();
        return;
      }
      this.alert.errorAlert(environment.s3Bucket.uploadError.title,
          environment.s3Bucket.uploadError.message);
    });
  }

  save() {
    this.emptySubs = [];
    this.emptyScreens = [];
    this.emptyCaptions = [];
    this.createRequestBody();
    this.commonService.update(Service.MOVIE.POSTER, this.movieId, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.formReference.form.pristine = true;
      this.alert.successAlert(environment.alerts.success.movie.poster.title,
          environment.alerts.success.movie.poster.message);
      this.saveBtnDisabled = false;
    }, error2 => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(environment.alerts.error.movie.poster.title,  error2.error.error);
    });
  }

  loadPosterData() {
    this.commonService.findById( Service.MOVIE.POSTER, this.movieId, environment.contentType.movie)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          if (result.posters) {
            for (let i = 0; i < result.posters.length; i++) {
              if (result.posters[i].source_url) {
                this.posters.push({file: this.file});
                this.posters[i].source_url = result.posters[i].source_url;
                this.posters[i].caption = result.posters[i].alt_tag;

                this.posters[i].isOrientationTypeDisable = true;
                this.posters[i].isPosterSizeValidate = true;
                this.posters[i].screenType = null;

                let screenType;
                if (result.posters[i].orientation_type === this.upperCase.transform(environment.orientations.landscape)) {
                  if (result.posters[i].image_type === environment.resolutionImageType.imageWithTitle) {
                    screenType = environment.orientationTypes[0].id;
                  } else if (result.posters[i].image_type === environment.resolutionImageType.imageWithoutTitle) {
                    screenType = environment.orientationTypes[1].id;
                  } else {
                    screenType = environment.orientationTypes[4].id;
                  }
                }
                if (result.posters[i].orientation_type === this.upperCase.transform(environment.orientations.portrait)) {
                  if (result.posters[i].image_type === environment.resolutionImageType.imageWithTitle) {
                    screenType = environment.orientationTypes[2].id;
                  } else {
                    screenType = environment.orientationTypes[3].id;
                  }
                }
                this.posters[i].screenType = screenType;
                this.posters[i].imageLocation = result.posters[i].source_url;
                this.posters[i].image_name = result.posters[i].image_name;
                this.posters[i].image_type = result.posters[i].image_type;
                this.posters[i].resolution_type = result.posters[i].resolution_type;
              }
            }
          }
    });
  }


  validateImageFormat(index, type) {
    let height;
    let width;
    const img = new Image();
    img.onload = () => {
      height = img.naturalHeight;
      width = img.naturalWidth;
      switch (type) {
        case environment.orientationTypesTitle.landscapeImageWithTitle:
          if (height === environment.orientationTypesSize.LANDSCAPE_IMAGE_WITH_TITLE.height &&
              width === environment.orientationTypesSize.LANDSCAPE_IMAGE_WITH_TITLE.width) {
            this.posters[index].isPosterSizeValidate = true;
          } else {
            this.posters[index].isPosterSizeValidate = false;
            this.isFormValid = false;
          }
          break;

        case environment.orientationTypesTitle.landscapeImageWithoutTitle:
          if (height === environment.orientationTypesSize.LANDSCAPE_IMAGE_WITHOUT_TITLE.height &&
              width === environment.orientationTypesSize.LANDSCAPE_IMAGE_WITHOUT_TITLE.width) {
            this.posters[index].isPosterSizeValidate = true;
          } else {
            this.posters[index].isPosterSizeValidate = false;
            this.isFormValid = false;

          }
          break;


        case environment.orientationTypesTitle.portraitImageWithTitle:
          if (height === environment.orientationTypesSize.PORTRAIT_IMAGE_WITH_TITLE.height &&
              width === environment.orientationTypesSize.PORTRAIT_IMAGE_WITH_TITLE.width) {
            this.posters[index].isPosterSizeValidate = true;
          } else {
            this.posters[index].isPosterSizeValidate = false;
            this.isFormValid = false;

          }
          break;


        case environment.orientationTypesTitle.portraitImageWithOutTitle:
          if (height === environment.orientationTypesSize.PORTRAIT_IMAGE_WITHOUT_TITLE.height &&
              width === environment.orientationTypesSize.PORTRAIT_IMAGE_WITHOUT_TITLE.width) {
            this.posters[index].isPosterSizeValidate = true;
          } else {
            this.posters[index].isPosterSizeValidate = false;
            this.isFormValid = false;

          }
          break;


        case environment.orientationTypesTitle.landscapeTitle:
          if (height === environment.orientationTypesSize.LANDSCAPE_TITLE.height &&
              width === environment.orientationTypesSize.LANDSCAPE_TITLE.width) {
            this.posters[index].isPosterSizeValidate = true;
          } else {
            this.posters[index].isPosterSizeValidate = false;
            this.isFormValid = false;

          }
          break;
        default:
          this.isPosterSizeValidate = true;
      }
    };
    img.src = URL.createObjectURL(this.posters[index].file[0]);
  }
}
