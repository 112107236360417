import { Injectable } from '@angular/core';
import {HttpService} from '../../http.service';
import {environment} from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TvShowService {

  constructor(private http: HttpService) { }

  findAll(options: any) {
    return this.http.get<any>(environment.serverurl + '/tv-shows/?' + '&page='
      + options.page + '&size=' + options.size);
  }

  validateSlug(slug) {
    return this.http.get<any>(environment.serverurl + '/tv-shows/slugs?slug=' + slug);
  }

  findAllByStatus(options: any) {
    return this.http.get<any>(environment.serverurl + '/tv-shows/status/?' + options.status + '&page='
        + options.page + '&size=' + options.size + '&maturity-rating=' + options.maturityRating);
  }

  seasonsByIdWithoutPagination(options: any) {
    return this.http.get<any>(environment.serverurl + '/season/' + options.id + '?&page='
        + options.page + '&size=' + options.size);
  }
  seasonsByIdWithoutPaginationWithSearch(options: any) {
    return this.http.get<any>(environment.serverurl + '/season/' + options.id + '?&page='
        + options.page + '&size=' + options.size + '&search-term=' + options.searchTerm);
  }
  seasonsById(id) {
    return this.http.get<any>(environment.serverurl + '/seasons/' + id);
  }
  findCastMembers(id) {
    return this.http.get<any>(environment.serverurl + '/contents/' + id + '/cast-members');
  }
  findTvshowKeywords(id) {
    return this.http.get<any>(environment.serverurl + '/contents/' + id + '/keywords');
  }

  findBySearchTerm(searchTerm: any) {
    return this.http.get<any>(environment.elasticSearchUrl + '/contents/' + searchTerm);
  }

  findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating) {
    return this.http.get<any>(environment.serverurl + '/tv-shows/search?searchTerm=' + searchTerm
        + '&contentType=' + contentStatus + '&page=' + page + '&size=' + size + '&maturity-rating=' + maturityRating);
  }
  saveAllSeasons(body) {
    return this.http.put<any>(environment.serverurl + '/tv-shows/season', body);
  }
  deleteSeason(id) {
    return this.http.delete<any>(environment.serverurl + '/tv-shows/season/' + id);
  }
}
