import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TailerBonusContentService {

  constructor(private http: HttpService) { }

  save(body) {
    return this.http.post<any>(environment.serverurl + '/contents/bonus-contents', body);
  }
  getTailerBonusContentById(id, contentType) {
    return this.http.get<any>(environment.serverurl + '/contents/' + id + '/trailers-bonus?content=' + contentType);
  }
  getAllTailerBonusContent() {
    return this.http.get<any>(environment.serverurl);
  }
  update(id, body) {
    return this.http.put<any>(environment.serverurl + '/contents/bonus-contents/' + id, body);
  }
  deleteTailerBonusContent(id) {
    return this.http.delete<any>(environment.serverurl + '' + id);
  }
}
