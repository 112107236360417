import { Injectable } from '@angular/core';
import {CookieService} from './cookie.service';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  user: any;
  isRefreshTokenProcessing = false;
  constructor(private cookieService: CookieService, private http: HttpClient) { }

  /**
   * Returns the current user
   */
  public currentUser(): any {
    if ( !this.user ) {
      this.user = JSON.parse(this.cookieService.getCookie(environment.cookies.currentUser));
    }
    return this.user;
  }

  /**
   * Login user
   */
  login( userName: string, password: string) {

  const httpOptions = {
      headers: new HttpHeaders({
        Authorization : 'Basic ' + btoa(userName + ':' + password)
      })
    };

  return this.http.post<any>(environment.serverurl + '/users/authenticate', {  }, httpOptions)
        .pipe(map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            this.user = user;
            // store user details and jwt in cookie
            this.cookieService.setCookie(environment.cookies.currentUser, JSON.stringify(user), null);
          }
          return user;
        }));

      // Integrate get login logic here and set user details to the cookie
  }

  /**
   * Logout User
   */
  logout() {
    this.cookieService.deleteCookie(environment.cookies.currentUser);
    localStorage.clear();
    this.user = null;
  }

  /**
   * Get token using refresh token
   */
  refreshToken() {
    const header = {
      headers : new HttpHeaders()
        .set('Authorization', `Bearer ${ this.user.refreshToken.token }`)
    };
    // Integrate get token using refresh token API and set it to user.token and return the http request

    // return this.http.get(environment.serverurl + '/refreshToken', header).pipe(tap((user: any) => {
    //   this.user.token = user.token;
    // }));

    return this.http.get(null, header).pipe(tap(( user: any ) => {}));
  }
}
