import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {VideoChannelModelComponent} from './model/video-channel-model/video-channel-model.component';
import {MatDialog} from '@angular/material';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CommonService} from '../../../core/service/common_service/common.service';
import {TitleCasePipe} from '@angular/common';
import {MovieModelComponent} from '../movies/model/movie-model/movie-model.component';
import {takeUntil} from 'rxjs/operators';
import {Service} from '../../../core/service/common_service/services';
import {VideoChannelService} from '../../../core/service/common_service/ http/contents/video-channel/video-channel.service';
import {VideoService} from '../../../core/service/common_service/ http/contents/video.service';
import {VideoEpisodeListComponent} from './model/video-episode-list/video-episode-list.component';
import {CommonServiceService} from '../../../core/service/common_service/ http/common-service.service';
import {CmsUserService} from '../../../core/service/common_service/ http/cms-user/cms-user.service';
import {ContentService} from '../../../core/service/common_service/ http/contents/content.service';
import {PackageTierService} from '../../../core/service/common_service/ http/packege-tier/package-tier.service';
import {Alerts} from '../../../alert/Alert';

@Component({
  selector: 'app-video-channel',
  templateUrl: './video-channel.component.html',
  styleUrls: ['./video-channel.component.scss']
})
export class VideoChannelComponent implements OnInit, OnDestroy {
  env = environment;
  destroy$ = new Subject();
  selectedPage: number;
  tableData: any[] = [];
  selectedTab;
  loading = false;
  searchTerm: any;
  pageCount: any;
  selectedVideoChannel: any;
  private playlists: any;
  cmsUsers: any[] = [];
  columnData: any[] = [];
  packages: any[] = [];
  deleteSubject: Subject<boolean> = new Subject();
  constructor(private matModel: MatDialog, private commonService: CommonService,
              private commonServiceService: CommonServiceService,
              private cmsUserService: CmsUserService, private alert: Alerts,
              private contentService: ContentService,
              private titleCasePipe: TitleCasePipe, private videoChannelService: VideoChannelService,
              private videoService: VideoService,
              private packageService: PackageTierService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.selectedPage = 1;
    this.selectedTab = this.env.contentStatus.published;
    this.loadAllPackages();
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchVideoChannel();
    } else {
      this.tableData = [];
      this.findVideoChanel();
    }
    this.loadAllCmsUsers(this.env.cmsUserAction.create);
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadFilterData(action) {
    const filterDataArray = [];
    const modifiedUserData = this.cmsUsers.map(val => {
      return {
        id: val.id,
        value: val.name
      };
    });
    let selectedField;

    if (action === this.env.cmsUserAction.create) {
      selectedField = this.env.unpublishedVideoChannelColumns[8].field;
    } else {
      selectedField = this.env.scheduledVideoChannelColumns[8].field;
    }

    filterDataArray.push({
      field: selectedField,
      data: modifiedUserData
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  loadAllCmsUsers(action) {
    this.loading = true;
    this.cmsUserService.findAllContentsCmsUsers({status: action})
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.cmsUsers = response;
      this.loadFilterData(action);
      this.loading = false;
    });
  }

  loadAllPackages() {
    this.loading =  true;
    this.packageService.findAllPackageTiers()
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.packages = response;
      this.loadPackageFilterData();
      this.loading = false;
    });
  }

  loadPackageFilterData() {
    const filterDataArray = [];
    const modifiedUserData = this.packages.map(val => {
      return {
        id: val.id,
        value: val.package_type
      };
    });

    modifiedUserData.splice(2, 1);
    const payToWatch = this.env.publishedTvShowColumns[3].field;
    const freeToWatch = this.env.publishedTvShowColumns[4].field;

    filterDataArray.push({
      field: payToWatch,
      data: modifiedUserData
    });

    filterDataArray.push({
      field: freeToWatch,
      data: modifiedUserData
    });
    this.columnData = [...this.columnData, ...filterDataArray];
  }

  addNewVideoChannel() {
    this.cd.detach();
    const matDialogRef = this.matModel.open(VideoChannelModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: false
      }
    });
    matDialogRef.afterClosed().subscribe(value => {
      this.cd.reattach();
    });
  }

  selectTab(event) {
    let action;
    switch (event.tab.textLabel.toUpperCase()) {
      case this.env.contentStatus.published: {
        action = this.env.cmsUserAction.create;
        this.loadAllPackages();
        this.selectedTab = this.env.contentStatus.published;
        break;
      }
      case this.env.contentStatus.scheduled: {
        action = this.env.cmsUserAction.create;
        this.selectedTab = this.env.contentStatus.scheduled;
        break;
      }
      default: {
        action = this.env.cmsUserAction.create;
        this.selectedTab = this.env.contentStatus.unpublished;
      }
    }

    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchVideoChannel();
    } else {
      this.tableData = [];
      this.findVideoChanel();
    }
    if (action) {
      this.loadAllCmsUsers(action);
    }
  }

  findVideoChanel() {
    this.loading = true;
    this.commonService.findAll(Service.VIDEO_CHANNEL.STATUS,
        {status: environment.contentStatusString + this.selectedTab,
          contentType: environment.contentType.videoChannel,
          page: this.selectedPage - 1,
          size: this.env.tableProperties.size})
        .pipe(takeUntil(this.destroy$))
        .subscribe((response: any) => {
          this.processVideoChannelData(response);
        });
  }

  setPage(event) {
    this.selectedPage = event;
    if (this.searchTerm && this.searchTerm.length >= 0) {
      this.searchVideoChannel();
    } else {
      this.tableData = [];
      this.findVideoChanel();
    }
  }

  openDetailView(event, status) {
    this.cd.detach();
    const matDialogRef = this.matModel.open(VideoChannelModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        isDetailView: true,
        videoChannelId: event.selectedData.id,
        videoChannelTitle: event.selectedData.englishTitle,
        publishStatus: status
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      this.cd.reattach();
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchVideoChannel();
      } else {
        this.tableData = [];
        this.findVideoChanel();
      }
    });
  }

  searchVideoChannel() {
    this.loading = true;
    this.commonService.search(Service.VIDEO_CHANNEL, this.selectedTab, this.searchTerm,
    this.selectedPage - 1, this.env.tableProperties.size, 'MATURE')
        .pipe(takeUntil(this.destroy$))
        .subscribe(response => {
          this.tableData = [];
          this.processVideoChannelData(response);
        }, error => {
          this.loading = false;
        });
  }

  processVideoChannelData(response) {
    let arr;
    if (response.content_by_multiple_status) {
      arr = response.content_by_multiple_status;
    } else {
      arr = response.contents_by_status;
    }
    this.pageCount = (response.total) / this.env.tableProperties.size * 10;
    new Promise(resolve => {
      const processedData = arr.map((content, index) => {
        if (content.title) {
          content.channelName = content.title.english;
        }
        if (content.uploaded_by) {
          content.uploadedBy = content.uploaded_by.name;
        }
        if (content.published_by) {
          if (content.published_by.name) {
            content.publishedBy = content.published_by.name;
          }
        }
        if (content.poster) {
          content.channelImage = {
            isPoster: true,
            url: content.poster.source_url
          };
        }
        return content;
      });
      resolve(processedData);
    }).then((value: any[]) => {
      this.tableData = value;
      this.loading = false;
    });
  }

  showVideoChannelVideos(event: any) {

    console.log(event);
    this.selectedVideoChannel = event.selectedData.id;
    const promis = new Promise((resolve, reject) => {
      this.videoService.findAllVideosByPlaylist({
        channelId: this.selectedVideoChannel,
        page: 0,
        size: 20
      })
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.playlists = response.data;
        resolve(true);
      });

    });
    promis.then((value) => {
      if (value) {
        this.cd.detach();
        const matDialogRef = this.matModel.open(VideoEpisodeListComponent, {
          disableClose: true,
          autoFocus: false,
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          data: {
            isDetailView: false,
            playlistId: event.selectedData.id,
            playlists: this.playlists,
            selectedPlaylist: this.selectedVideoChannel,
            videoChannelTitle: event.selectedData.channelName
          }
        });

        matDialogRef.afterClosed().subscribe(value1 => {
          this.cd.reattach();
          this.playlists.push(value1);
          this.playlists = [... this.playlists];
        });
      }
    });
  }

  findAllByFilter(filterData) {
    let publishedDateRange = [];
    let releasedDateRange = [];
    const queryObj = {
      createdBy: null,
      contentStatus: this.selectedTab,
      page: this.selectedPage - 1,
      size: this.env.size,
      payToWatch: null,
      freeToWatch: null,
      lessThanReleasedDateAndTime: null,
      greaterThanReleasedDateAndTime: null,
      lessThanPublishedDateAndTime: null,
      greaterThanPublishedDateAndTime: null
    };

    filterData.forEach(filter => {
      if (filter.field === this.env.unpublishedVideoChannelColumns[8].field) {
        const filteredCmsUsers = this.cmsUsers.filter(val => val.id === filter.value);
        if (filteredCmsUsers.length > 0) {
          queryObj.createdBy = filteredCmsUsers[0].name;
        }
      }

      if (filter.field === this.env.publishedVideoChannelColumns[8].field) {
        publishedDateRange = filter.value.split(' to ');
        if (publishedDateRange.length > 1) {
          queryObj.greaterThanPublishedDateAndTime = publishedDateRange[0].concat(' ') + '00:00:00';
          queryObj.lessThanPublishedDateAndTime = publishedDateRange[1].concat(' ') + '00:00:00';
        }
      }

      if (filter.field === this.env.publishedVideoChannelColumns[9].field) {
        releasedDateRange = filter.value.split(' to ');
        if (releasedDateRange.length > 1) {
          queryObj.greaterThanReleasedDateAndTime = releasedDateRange[0];
          queryObj.lessThanReleasedDateAndTime = releasedDateRange[1];
        }
      }

      if (filter.field === this.env.publishedMovieColumns[3].field) {
        const filteredPackages = this.packages.filter(val => val.id === filter.value);
        if (filteredPackages.length > 0) {
          queryObj.payToWatch = filteredPackages[0].package_type;
        }
      }

      if (filter.field === this.env.publishedMovieColumns[4].field) {
        const filteredPackages = this.packages.filter(val => val.id === filter.value);
        if (filteredPackages.length > 0) {
          queryObj.freeToWatch = filteredPackages[0].package_type;
        }
      }

    });
    if (queryObj.createdBy
      || queryObj.greaterThanPublishedDateAndTime
      || queryObj.freeToWatch
      || queryObj.payToWatch
      || queryObj.greaterThanReleasedDateAndTime) {

      if ((releasedDateRange.length === 1) && (releasedDateRange[0] !== '')) {
        return;
      }

      if ((publishedDateRange.length === 1) && (publishedDateRange[0] !== '')) {
        return;
      }

      this.loadDataByFilters(queryObj);
    } else if (filterData.filter(filter => filter.value).length <= 0) {
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.searchVideoChannel();
      } else {
        this.tableData = [];
        this.findVideoChanel();
      }
    }
  }

  loadDataByFilters(queryObj) {
    this.loading = true;
    this.contentService.contentByFilters(this.env.contentType.videoChannel, queryObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.tableData = [];
        this.processVideoChannelData(response);
      }, error => {
        console.log(error);
        this.loading = false;
      });
  }
  deleteContent(event) {
    this.alert.confirm(null, environment.alerts.success.video_channel.deleteVideo.confirmationMessage,
        environment.alerts.success.video_channel.deleteVideo.confirmButton).then(value => {
      if (value.isConfirmed) {
        this.commonService.delete(Service.VIDEO_CHANNEL, event.selectedData.id).subscribe(val => {
          this.deleteSubject.next(true);
        });
      }
    });
  }

  onKeyPress() {
    if (!this.searchTerm) {
      this.searchVideoChannel();
    }
  }
}
