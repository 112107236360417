import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-preview-slider-graphic',
  templateUrl: './preview-slider-graphic.component.html',
  styleUrls: ['./preview-slider-graphic.component.scss']
})
export class PreviewSliderGraphicComponent implements OnInit {


  sliderData;
  constructor(private model: MatDialogRef<PreviewSliderGraphicComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.sliderData = data;
    console.log('here slider data: ', this.sliderData);
  }

  ngOnInit() {
  }

  closeWindow() {
    this.model.close();
  }

}
