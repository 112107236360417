import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'app-tv-episode-tab',
  templateUrl: './tv-episode-tab.component.html',
  styleUrls: ['./tv-episode-tab.component.scss']
})
export class TvEpisodeTabComponent implements OnInit {
  @Input() template;
  env = environment;
  @Input() isDetailView = false;
  constructor() {
  }

  ngOnInit() {
  }

}
