import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {EventService} from '../../../../../core/service/event.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TitleCasePipe} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {Service} from '../../../../../core/service/common_service/services';

@Component({
  selector: 'app-video-media-detail-view',
  templateUrl: './video-media-detail-view.component.html',
  styleUrls: ['./video-media-detail-view.component.scss']
})
export class VideoMediaDetailViewComponent implements OnInit, OnDestroy {

  video: string[] = [];
  files: File[] = [];
  selectValue: string[] = ['240p', '360p', '720p', '1080p'];
  addedFile: File [] = [];
  videoId: number;
  videoLocation: string;
  requestBody: any = {};
  destroy$ = new Subject();
  progress: number;
  selectedResolutions: string[] = [];
  isVideoFileUploaded = false;
  isSelectedResolutionValidated = true;
  selectedResolutionValidatedMessage;
  getContentIdSubscription;
  saveBtnDisabled = false;
  isFileSelected = true;
  isFormValid = true;
  videoFileUploadValidationMessage;
  toggleLayer = true;
  editViewerEvent;
  videoDetails: any = {};
  isVideoPlaying = false;
  videoFileName;
  loading = false;
  videoUploadedDateTime;


  constructor(private service: CommonService, private s3Bucket: S3BucketService,
              private alert: Alerts, private eventService: EventService, private spinnerService: NgxSpinnerService,
              private titleCasePipe: TitleCasePipe) { }


  ngOnInit() {
    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.videoId = response.id;
      }
    });

    this.editViewerEvent = this.eventService.contentViewSender.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.toggleLayer = value.viewEnable;
    });

    this.loadAllVideo();
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
  }

  onSelect(event) {
    this.progress = 0;
    const file: File = event.addedFiles[0];
    this.addedFile[0] = event.addedFiles[0];


    const reader =   new FileReader();
    reader.addEventListener('load', () => {

      const video = document.createElement('video') as HTMLVideoElement;
      video.onloadeddata = () => {
        /*   this.generateResolutions(video.videoWidth, video.videoHeight);*/
      };
      video.src = reader.result as string;
    }, false );
    reader.readAsDataURL(file);
    this.isFileSelected = true;
  }


  validatingRequiredFields() {
    if (this.selectedResolutions.length <= 0) {
      this.isFormValid =  false;
      this.isSelectedResolutionValidated = false;
      this.selectedResolutionValidatedMessage = environment.validations.emptyResolutionValidatedMessage;
    }
    if (!this.addedFile[0]) {
      this.isFileSelected = false;
      this.videoFileUploadValidationMessage = environment.validations.emptyVideoFileValidationMessage;
    }
  }

  createRequestBody() {

    this.requestBody.content_type = environment.contentType.video;
    this.requestBody.conversion_resolutions = this.selectedResolutions;
    this.requestBody.source_url = this.videoLocation;
  }


  uploadVideo() {
    this.toggleLayer = false;
    this.validatingRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    this.s3upload();
  }

  s3upload() {
    this.saveBtnDisabled = true;
    if (this.addedFile[0]) {
      new Promise((resolve, reject) => {

        this.s3Bucket.uploadFile(this.videoId, this.addedFile[0], environment.s3Bucket.path.videos,
            environment.s3Bucket.buckets.bucketPath +
            environment.s3Bucket.buckets.sourceFolder +
            environment.s3Bucket.contentTypeFolder.videos, (error, response, pr) => {
              this.isVideoFileUploaded = true;
              this.videoLocation = response.Location;
              if (error) {
                reject(false);
              }
              resolve(true);
            }).on('httpUploadProgress', (progress) => {
          this.progress = Math.round(progress.loaded / progress.total * 100);
        });
      }).then(value => {
        if (value) {
          this.saveVideo();
        }
      });
    }

  }

  saveVideo() {
    this.createRequestBody();
    this.service.update(Service.VIDEO.VIDEO, this.videoId, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.alert.successAlert(environment.alerts.success.video.video.title,
          environment.alerts.success.video.video.message);
    }, error2 => {
      this.alert.errorAlert(environment.alerts.error.video.video.title,
          error2.error.error);
    });
    this.saveBtnDisabled = false;
    this.toggleLayer = true;
  }

  onRemove() {
    this.addedFile = [];
    this.selectedResolutions = [];
  }

  resetValidation() {
    this.isFormValid = true;
    if (this.selectedResolutions.length > 0) {
      this.isSelectedResolutionValidated = true;
    }
  }
  loadAllVideo() {
    this.loading = true;
    this.service.findById(Service.VIDEO.VIDEO, this.videoId, environment.contentType.video)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      if (result.conversion_resolutions) {
        this.selectedResolutions = result.conversion_resolutions;
      }
      if (result.source_url) {
        this.addedFile[0] = result.source_url;
        this.videoLocation = result.source_url;
        this.videoFileName = result.source_url.split('_').pop();
        this.progress = 100;
        this.videoUploadedDateTime = this.convertDateTimeFromS3Url(this.videoLocation);

      }
      if (result.cdn_url) {

        let subtitlesArray = [];

        if (result.subtitles && result.subtitles.length > 0) {
          subtitlesArray = result.subtitles.map(subtitle => {

            let languageLabel;
            let languageCode;
            let sub;
            if (subtitle.language === environment.subtitleLanguages.code.english) {
              languageLabel = this.titleCasePipe.transform(environment.subtitleLanguages.language.english);
              languageCode = environment.subtitleLanguages.code.english;
              sub = subtitle.source_url;
            } else if (subtitle.language === environment.subtitleLanguages.code.sinhala) {
              languageLabel = this.titleCasePipe.transform(environment.subtitleLanguages.language.sinhala);
              languageCode = environment.subtitleLanguages.code.sinhala;
              sub = subtitle.source_url;
            } else {
              languageLabel = this.titleCasePipe.transform(environment.subtitleLanguages.code.tamil);
              languageCode = environment.subtitleLanguages.code.tamil;
              sub = subtitle.source_url;
            }

            return {
              label: languageLabel,
              language: languageCode,
              src: sub
            };
          });
        }
        this.videoDetails = {
          manifestUri: result.cdn_url,
          autoplay: false,
          subtitles: subtitlesArray
        };
        // this.spinnerService.show('tvEpisode');
        setTimeout(() => {
          this.isVideoPlaying = true;
        }, 2000);
      }
      this.loading = false;

    });
  }

  afterInitializedPlayer( value ) {
    if ( value ) {
      // this.spinnerService.hide('tvEpisode');
    }
  }

  convertDateTimeFromS3Url(url: string) {
    const firstStr = url.split('video/')[1];
    const dateWIthTime = firstStr.split('_')[0];
    const  index = dateWIthTime.lastIndexOf('-');
    const time = dateWIthTime.substr(index + 1);
    const  date = dateWIthTime.substring(0, dateWIthTime.lastIndexOf('-'));
    const timeWithSpace = time.replace(/(\d{2})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
    return date + ' : ' + timeWithSpace;

  }
}

