import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {CastMemberService} from '../../../../../core/service/common_service/ http/cast-member/cast-member.service';
import {KeyWordService} from '../../../../../core/service/common_service/ http/keyword/key-word.service';
import {DatePipe, TitleCasePipe} from '@angular/common';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {EventService} from '../../../../../core/service/event.service';
import {MatDialog} from '@angular/material/dialog';
import {MediaVideoService} from '../../../../../core/service/common_service/ http/video/media-video.service';
import {VideoChannelMetadataService} from '../../../../../core/service/common_service/ http/video-channel/video-channel-metadata.service';
import {GenreService} from '../../../../../core/service/common_service/ http/genre/genre.service';
import {takeUntil} from 'rxjs/operators';
import {Service} from '../../../../../core/service/common_service/services';
import {CastModelComponent} from '../../../../meta-data/cast/model/cast-model/cast-model.component';
import {AddNewKeywordComponent} from '../../../../meta-data/keyword/model/add-new-keyword/add-new-keyword.component';
import {AddNewGenreComponent} from '../../../../meta-data/genre/model/add-new-genre/add-new-genre.component';
import {VideoChannelService} from '../../../../../core/service/common_service/ http/contents/video-channel/video-channel.service';
import {AddNewPlayListComponent} from '../add-new-play-list/add-new-play-list.component';

@Component({
  selector: 'app-video-media-meta-data-detail-view',
  templateUrl: './video-media-meta-data-detail-view.component.html',
  styleUrls: ['./video-media-meta-data-detail-view.component.scss']
})
export class VideoMediaMetaDataDetailViewComponent implements OnInit, OnDestroy {

  videoChannels: any [] = [];

  playLists: any [] = [];

  selectedVideoChannel: any;

  selectedPlayList: string;
  isPlayListValidate = true;
  playListValidateMessage;

  env = environment;
  file: File[] = [];
  subtitles: any[] = [{file: this.file}];

  videoId: number;
  videoNumber: number;
  isVideoNumberValidated = true;
  videoNumberValidateMassage;

  videoTitleInEnglish: string;
  isVideoTitleInEnglishValidated = true;
  videoTitleInEnglishValidateMessage;
  videoTitleInSinhala: string;
  videoTitleInTamil: string;
  slug: string;
  isSlugValidated = true;
  slugValidateMessage;
  description: string;
  runTime: any;
  isRunTimeValidated = true;
  runTimeValidateMessage;
  selectedDirector: number[] = [];
  isDirectorValidated = true;
  directorValidateMessage;
  selectedProducer: number[] = [];
  selectedCastMembers: number[] = [];
  selectedKeyWords: number[] = [];
  TITLE = 1;
  SLUG = 2;
  RUNTIME = 4;
  DIRECTOR = 5;
  KEY_WORD = 6;
  CAST = 7;
  PRODUCER = 8;
  DESCRIPTION = 9;
  SUBTITLE = 10;
  SUBTITLE_LANGUAGE = 11;
  PLAY_LIST = 12;
  MATURITY = 13;
  EPISODE = 14;
  VIDEO_NUMBER = 15;
  VIDEO_CHANNEL = 16;
  GENRE = 17;
  requestBody: any = {};
  directors: any [] = [];
  directorsClone: any = [];
  producers: any [] = [];
  producersClone: any [] = [];
  casts: any [] = [];
  castsClone: any [] = [];
  keywords: any [] = [];
  keywordsClone: any [] = [];
  destroy$ = new Subject();
  // tslint:disable-next-line:variable-name
  @ViewChild('director', null) director_reffrence: any;
  // tslint:disable-next-line:variable-name
  @ViewChild('producer', null) producer_reffrence: any;
  // tslint:disable-next-line:variable-name
  @ViewChild('cast', null) cast_reffrence: any;
  // tslint:disable-next-line:variable-name
  @ViewChild('keyword', null) keyword_reffernce: any;
  @ViewChild('genre', null) genresReffrence: any;
  @ViewChild('specialcast', null) specialCastReffrence: any;
  subLanguages: string[];
  subtitleDescription = [];
  subtitleLanguageValidatedMessage;
  files: File[] = [];
  minDate: Date;
  emptySubFile: number [] = [];
  emptySubLanguage: number [] = [];
  subFileAddedValidationMessage;
  domain;
  getContentIdSubscription;
  isCastValidated = true;
  castValidateMessage;
  isKeyWordsValidated = true;
  keyWordValidateMessage;
  isDescriptionValidated = true;
  descriptionValidateMessage;
  isProducerValidated = true;
  producerValidateMessage;
  isSlugPatternValidated = true;
  slugPatternValidateMessage;
  isFormValid = true;
  saveBtnDisabled = false;
  promiseArray: any[] = [];
  genres: any[] = [];
  isDetailViewOpen = false;
  loading = false;
  isSelectedVideoChannelValidate = true;
  videoChannelValidateMessage;

  selectedMaturityRating: string;
  isMaturityRatingValidated = true;
  maturityRatingValidateMessage;
  maturityRating = environment.maturityRating;

  selectedGenres: number[] = [];
  isSelectedGenresValidated = true;
  genresValidateMessage;
  genresClone: any[] = [];
  toggleLayer = true;
  editViewerEvent;
  currentProducersPage;
  currentDirectorsPage;
  currentActorsPage;
  totalProducers = 0;
  totalDirectors = 0;
  totalActors = 0;
  currentKeywordsPage;
  totalKeywords = 0;
  currentVideoChannelPage;
  totalVideoChannels = 0;
  currentGenresPage;
  totalGenres = 0;
  videoChannelsClone: any = [];
  selectedSpecialCastMembers: number[] = [];
  isSpecialCastValidated = true;
  castSubject: Subject<any> = new Subject<any>();
  producerSubject: Subject<any> = new Subject<any>();
  directorSubject: Subject<any> = new Subject<any>();
  genreSubject: Subject<any> = new Subject<any>();
  keywordsSubject: Subject<any> = new Subject<any>();
  constructor(
      private service: CommonService,
      private castMemberService: CastMemberService,
      private keyWordService: KeyWordService,
      private datePipe: DatePipe,
      private s3Bucket: S3BucketService,
      private alert: Alerts,
      private commonService: CommonServiceService,
      private eventService: EventService,
      private matModel: MatDialog,
      private titleCase: TitleCasePipe,
      private mediaVideoService: MediaVideoService,
      private videoChannelMetadataService: VideoChannelMetadataService,
      private genreService: GenreService,
      private videoChannelService: VideoChannelService
  ) {
  }

  ngOnInit() {
    this.currentProducersPage = this.env.page;
    this.currentDirectorsPage = this.env.page;
    this.currentActorsPage = this.env.page;
    this.currentKeywordsPage = this.env.page;
    this.currentVideoChannelPage = this.env.page;
    this.currentGenresPage = this.env.page;
    this.subLanguages = [this.titleCase.transform(this.env.metaDataSubLanguages.english),
      this.titleCase.transform(this.env.metaDataSubLanguages.sinhala),
      this.titleCase.transform(this.env.metaDataSubLanguages.tamil)];

    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.videoId = response.id;
      }
    });
    this.editViewerEvent = this.eventService.contentViewSender.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.toggleLayer = value.viewEnable;
    });


    /*this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        console.log('video id : ', response);
        this.videoId = response.id;
      }
    });*/
    this.getAllDirectors();
    this.getAllProducers();
    this.getAllCast();
    this.getAllKeyWords();
    this.getDomain();
    this.getAllGenre();
    this.getVideoChannels();
    this.loadMetaData();
  }


  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
  }

  getDomain() {
    this.commonService.findDomain(environment.contentType.video)
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.domain = response.domain;
    });
  }

  getVideoChannels() {
    this.videoChannelService.findAll({
      page: this.currentVideoChannelPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalVideoChannels = response.total;
      const videoChannelsResponse = response.data;
      this.videoChannels = [...this.videoChannels, ...videoChannelsResponse];

      this.videoChannelsClone = [...this.videoChannels, ...videoChannelsResponse];
    });
  }


  getVideoChannelPlayList() {
    this.videoChannelService.playListsById(this.selectedVideoChannel,
      {page: this.env.page, size: this.env.size})
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.playLists = response.data;
      this.selectedGenres = response.genre_ids;

      // this.maturityRating = response.maturity_rating;
      response.id = this.selectedVideoChannel;
      this.eventService.getTvShowIdEventEmitter({id: response.id});
    });
  }


  getVideoChannelCast() {
    this.mediaVideoService.findCastMembers(this.selectedVideoChannel)
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.selectedDirector = response.directors;
      this.selectedProducer = response.producers;
      this.selectedCastMembers = response.actors;
      this.selectedSpecialCastMembers = response.special_actors;

    });
  }

  selectVideoChannel(event) {
    this.selectedPlayList = '';
    if (event) {
      this.loading = true;
      this.getVideoChannelCast();
      this.getVideoChannelKeyWord();
      this.getVideoChannelPlayList();


      this.eventService.getTvShowIdEventEmitter({id: this.selectedVideoChannel});
      this.loading = false;
      return;
    }
    this.playLists = [];
  }

  getVideoChannelKeyWord() {
    this.service.findById(Service.TV_SHOW.META_DATA, this.selectedVideoChannel, environment.contentType.videoChannel)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.selectedKeyWords = result.keywords.map((keyword, index) => {
        return this.selectedKeyWords.push(keyword.id);
      });
      this.selectedGenres = result.genres.map((genres, index) => {
        return this.selectedGenres.push(genres.id);
      });
    });

  }


  isSlugAvailable(eventData: any) {
    if (eventData.target.value.length !== 0) {

      if (!this.slug.match(this.env.regexValidations.slug)) {
        this.isSlugPatternValidated = false;
        this.slugPatternValidateMessage = this.env.validations.slugRegexValidationMessage;
      } else {
        // TODO need BE API for implementation
        return this.commonService.validateSlug(eventData.target.value, this.env.contentType.video)
            .pipe(takeUntil(this.destroy$)).subscribe((response) => {
              if (!response.is_available) {
                this.isSlugValidated = false;
                this.slugValidateMessage = this.env.validations.existingSlugErrorMessage;
              }
            });
      }
    }
  }

  addAnotherSub() {
    this.file = [];
    this.subtitles.push({file: this.file});
  }

  deleteSubtitle(index) {
    if (this.subtitles[index].mediaConvertStatus) {
      this.setPendingSubtitles(this.env.mediaConvertStatus.pending);
    }
    this.subtitles.splice(index, 1);
  }

  runtimeValidate() {
    const time = this.runTime;
    if (time <= 0) {
      this.isRunTimeValidated = false;
      this.runTimeValidateMessage = this.env.validations.invalidRuntime;
    } else {
      this.isRunTimeValidated = true;
    }
  }

  getAllKeyWords() {
    this.service.findAll(Service.KEY_WORD, {
      page: this.currentKeywordsPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalKeywords = response.total;
      const keywordResponse = response.keywords.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.keywords = [...this.keywords, ...keywordResponse];

      this.keywordsClone = [...this.keywordsClone, ...keywordResponse];
      this.keywordsSubject.next(this.keywords);
    });
  }

  keywordsKeyPress() {
    if (!this.keyword_reffernce.searchTerm) {
      this.keywords = this.keywordsClone;
      return;
    }
    this.currentKeywordsPage = 0;
    this.keywords = [];
    this.searchKeywords();
  }

  searchKeywords() {
    this.keyWordService.searchKeyWordMemberByName(this.keyword_reffernce.searchTerm,
      this.env.page, this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.keywords = [this.keywords, ...response.keywords];
    });
  }

  clearKeywordsDropDown() {
    this.keywords = this.keywordsClone;
  }

  getAllDirectors() {
    this.service.findAll(Service.CAST_MEMBER, {
      castMemberRole: this.env.castMembers.director,
      page: this.currentDirectorsPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalDirectors = response.total;
      const directorsResponse = response.cast_members.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.directors = [...this.directors, ...directorsResponse];

      this.directorsClone = [...this.directorsClone, ...directorsResponse];
      this.directorSubject.next(this.directors);
    });
  }

  directorsKeyPress() {
    if (!this.director_reffrence.searchTerm) {
      this.directors = this.directorsClone;
      return;
    }

    this.currentDirectorsPage = 0;
    this.directors = [];
    this.searchDirectors();
  }

  searchDirectors() {
    this.castMemberService.searchCastMemberByName(this.director_reffrence.searchTerm,
      this.env.castMembers.director, this.currentDirectorsPage,
      this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.directors = [this.directors, ...response.cast_members];
    });
  }

  clearDirectorsDropDown() {
    this.directors = this.directorsClone;
  }

  getAllCast() {
    this.service.findAll(Service.CAST_MEMBER, {
      castMemberRole: this.env.castMembers.actor,
      page: this.currentActorsPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalActors = response.total;
      const actorResponse = response.cast_members.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.casts = [...this.casts, ...actorResponse];

      this.castsClone = [...this.castsClone, ...actorResponse];
      this.castSubject.next(this.casts);
    });
  }

  castsKeyPress() {
    if (!this.cast_reffrence.searchTerm) {
      this.casts = this.castsClone;
      return;
    }
    this.currentActorsPage = 0;
    this.casts = [];
    this.searchActors(this.cast_reffrence.searchTerm);
  }

  searchActors(searchTerm) {
    this.castMemberService.searchCastMemberByName(searchTerm,
      this.env.castMembers.actor, this.currentActorsPage,
      this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.casts = [this.casts, ...response.cast_members];
    });
  }

  clearCastDropDown() {
    this.casts = this.castsClone;
  }

  getAllProducers() {
    this.service.findAll(Service.CAST_MEMBER, {
      castMemberRole: this.env.castMembers.producer,
      page: this.currentProducersPage,
      size: this.env.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalProducers = response.total;
      const producersResponse = response.cast_members.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });

      this.producers = [...this.producers, ...producersResponse];

      this.producersClone = [...this.producersClone, ...producersResponse];
      this.producerSubject.next(this.producers);
    });
  }

  producersKeyPress() {
    if (!this.producer_reffrence.searchTerm) {
      this.producers = this.directorsClone;
      return;
    }

    this.currentProducersPage = 0;
    this.producers = [];
    this.searchProducers();
  }

  searchProducers() {
    this.castMemberService.searchCastMemberByName(this.producer_reffrence.searchTerm,
      this.env.castMembers.producer, this.currentProducersPage,
      this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.producers = [this.producers, ...response.cast_members];
    });
  }

  clearProducersDropDown() {
    this.producers = this.producersClone;
  }


  validatingRequiredFields() {

    if (this.selectedKeyWords.length <= 0) {
      this.isFormValid = false;
      this.isKeyWordsValidated = false;
      document.getElementById('keyword').focus();
      this.keyWordValidateMessage = this.env.validations.emptyKeyWords;
    }

    if (this.selectedGenres.length <= 0) {
      this.isFormValid = false;
      this.isSelectedGenresValidated = false;
      document.getElementById('genre').focus();
      this.genresValidateMessage = this.env.validations.emptyGenresMessage;
    }

    if (!this.selectedMaturityRating) {
      this.isFormValid = false;
      this.isMaturityRatingValidated = false;
      document.getElementById('maturity').focus();
      this.maturityRatingValidateMessage = this.env.validations.maturityValidateMessage;
    }
    if (!this.runTime) {
      this.isFormValid = false;
      this.isRunTimeValidated = false;
      document.getElementById('runtime').focus();
      this.runTimeValidateMessage = this.env.validations.emptyRunTime;
    }
    if (!this.videoNumber) {
      this.isVideoNumberValidated = false;
      document.getElementById('videoNo').focus();
      this.videoNumberValidateMassage = this.env.validations.videoNumberValidateMessage;
    }

    if (!this.selectedPlayList) {
      this.isFormValid = false;
      this.isPlayListValidate = false;
      document.getElementById('palylist').focus();
      this.playListValidateMessage = this.env.validations.playListValidateMessage;
    }
    if (!this.description) {
      this.isFormValid = false;
      this.isDescriptionValidated = false;
      document.getElementById('desc').focus();
      this.descriptionValidateMessage = this.env.validations.emptyDescription;
    }

    if (!this.slug) {
      this.isFormValid = false;
      this.isSlugValidated = false;
      document.getElementById('slug').focus();
      this.slugValidateMessage = this.env.validations.emptySlug;
    }

    if (!this.videoTitleInEnglish) {
      this.isFormValid = false;
      this.isVideoTitleInEnglishValidated = false;
      document.getElementById('titleEnglish').focus();
      this.videoTitleInEnglishValidateMessage = this.env.validations.emptyTitle;
    }
    if (!this.selectedVideoChannel) {
      this.isFormValid = false;
      this.isSelectedVideoChannelValidate = false;
      document.getElementById('videchannel').focus();
      this.videoChannelValidateMessage = this.env.validations.videoChannelValidateMessage;
    }

    let index = 0;

    for (const subtitle of this.subtitles) {

      if (!subtitle.subtitleLanguage && (subtitle.file.length <= 0)) {

        if (this.emptySubFile.indexOf(index) !== -1) {
          this.emptySubFile.splice(this.emptySubFile.indexOf(index), 1);
        }

        if (this.emptySubLanguage.indexOf(index) !== -1) {
          this.emptySubLanguage.splice(this.emptySubFile.indexOf(index), 1);
        }
      } else {

        if (subtitle.subtitleLanguage && (subtitle.file.length <= 0) &&
            (this.emptySubFile.indexOf(index) === -1)) {
          this.emptySubFile.push(index);
          this.subFileAddedValidationMessage = this.env.validations.emptySubtitleMessage;
        }

        if ((subtitle.file.length > 0) && !subtitle.subtitleLanguage &&
            (this.emptySubLanguage.indexOf(index) === -1)) {
          this.emptySubLanguage.push(index);
          this.subtitleLanguageValidatedMessage = this.env.validations.emptySubtileLanugateMessage;
        }

      }

      ++index;
    }

    if (this.emptySubFile.length > 0 || this.emptySubLanguage.length > 0) {
      this.isFormValid = false;
    }

  }

  resetValidation(field, index?) {
    this.isFormValid = true;
    switch (field) {
      case this.VIDEO_CHANNEL:
        this.isSelectedVideoChannelValidate = true;
        break;
      case this.TITLE:
        this.isVideoTitleInEnglishValidated = true;
        break;
      case this.PLAY_LIST:
        this.isPlayListValidate = true;
        break;
      case this.MATURITY:
        this.isMaturityRatingValidated = true;
        break;
      case this.VIDEO_NUMBER:
        this.isVideoNumberValidated = true;
        return;
      case this.DIRECTOR:
        this.isDirectorValidated = true;
        break;
      case this.SLUG:
        this.isSlugValidated = true;
        this.isSlugPatternValidated = true;
        return;
      case this.RUNTIME:
        this.isRunTimeValidated = true;
        return;
      case this.DESCRIPTION:
        this.isDescriptionValidated = true;
        return;
      case this.PRODUCER:
        this.isProducerValidated = true;
        return;
      case this.CAST:
        this.isCastValidated = true;
        this.specialCastReffrence.searchTerm = '';
        this.cast_reffrence.searchTerm = '';
        this.getAllCast();
        return;
      case this.KEY_WORD:
        this.isKeyWordsValidated = true;
        this.keyword_reffernce.searchTerm = '';
        return;
      case this.GENRE:
        this.genresReffrence.searchTerm = '';
        this.isSelectedGenresValidated = true;
        return;
      case this.SUBTITLE: {
        if (this.emptySubFile.indexOf(index) !== -1) {
          this.emptySubFile.splice(this.emptySubFile.indexOf(index), 1);
        }
        return;
      }
      default: {
        if (this.emptySubLanguage.indexOf(index) !== -1) {
          this.emptySubLanguage.splice(this.emptySubLanguage.indexOf(index), 1);
        }
      }
    }
  }

  async onSelect(event, index) {

    const updatedFiles: File[] = [];
    for (let index = 0; index < event.addedFiles.length; index++) {
      let originFile = event.addedFiles[index];
      let originText = await originFile.text()
      let splitByEnter = originText.split("\n");
      for (let i = 0; i < splitByEnter.length; i++) {
        let splitByArrow = splitByEnter[i].split("-->");
        if (splitByArrow.length == 2) {
          splitByArrow = splitByArrow.map(
              (time) => {
                let timeSplit = time.split(",");
                return timeSplit.join(".");
              }
          );
        }
        splitByEnter[i] = splitByArrow.join("-->");
      }
      let updatedText = splitByEnter.join("\n");
      let updatedFile: File = new File([updatedText], originFile.name, { type: originFile.type });
      updatedFiles.push(updatedFile);
    }

    this.subtitles[index].file  = updatedFiles;
  }

  genresKeyPress() {
    if (!this.genresReffrence.searchTerm) {
      this.genres = this.genresClone;
      return;
    }
    this.currentGenresPage = 0;
    this.genres = [];
    this.searchGenres();
  }

  searchGenres() {
    this.genreService.searchGenresByName(this.genresReffrence.searchTerm,
      this.currentGenresPage, this.env.size)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.genres = [...this.genres, ...response.genres];
    });
  }

  createRequestBody() {
    this.requestBody.content_type = environment.contentType.video;
    this.requestBody.special_actor_ids = this.orderCastNumber(this.selectedSpecialCastMembers, 1);
    this.requestBody.actor_ids = this.orderCastNumber(this.selectedCastMembers, this.selectedSpecialCastMembers.length);
    this.requestBody.description = this.description;
    this.requestBody.director_ids = this.selectedDirector;
    this.requestBody.keyword_ids = this.selectedKeyWords;
    this.requestBody.producer_ids = this.selectedProducer;
    this.requestBody.genres_ids = this.selectedGenres;
    this.requestBody.run_time = this.runTime;
    this.requestBody.video_number = +this.videoNumber;
    this.requestBody.playlist_no = this.selectedPlayList;
    this.requestBody.releasing_date = '2020-01-03';
    this.requestBody.slug = this.slug;
    this.requestBody.maturity_rating = this.selectedMaturityRating.toUpperCase();
    this.requestBody.video_channel = this.selectedVideoChannel;
    this.requestBody.subtitles = [];
    this.subtitles.forEach((subtitle, index) => {
      let subCode;
      let mediaConvertStatus;
      if (subtitle.subtitleLanguage && subtitle.file.length > 0) {
        if (subtitle.subtitleLanguage.toUpperCase() === this.env.metaDataSubLanguages.english) {
          subCode = this.env.metaDataSubLanguages.codes.eng;
        }
        if (subtitle.subtitleLanguage.toUpperCase() === this.env.metaDataSubLanguages.sinhala) {
          subCode = this.env.metaDataSubLanguages.codes.sin;
        }
        if (subtitle.subtitleLanguage.toUpperCase() === this.env.metaDataSubLanguages.tamil) {
          subCode = this.env.metaDataSubLanguages.codes.tam;
        }

        if (subtitle.mediaConvertStatus) {
          mediaConvertStatus = subtitle.mediaConvertStatus;
        } else {
          mediaConvertStatus = this.env.mediaConvertStatus.pending;
        }

        this.requestBody.subtitles.push(
            {
              file_name: subtitle.file[0].name,
              language: subCode,
              source_url: subtitle.subTitleLocation,
              converted_url: null,
              description: subtitle.subtitleDescription,
              file_type: subtitle.file[0].name.split('.').pop().toUpperCase(),
              media_convert_status: mediaConvertStatus
            });
      }

    });
    if (this.requestBody.subtitles.length <= 0) {
      delete this.requestBody.subtitles;
    }
    this.requestBody.title = {
      english: this.videoTitleInEnglish,
      sinhala: this.videoTitleInSinhala,
      tamil: this.videoTitleInTamil
    };

  }

  uploadSubtitle() {
    this.validatingRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.videoId) {
      this.service.save(Service.VIDEO.CONTENT, {type: environment.contentType.video})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.videoId = response.id;
        this.s3upload();
      });
    } else {
      this.s3upload();
    }
    this.saveBtnDisabled = true;
  }


  s3upload() {
    let uploadPromise;
    for (const subtitle of this.subtitles) {
      if (subtitle.file.length > 0) {
        uploadPromise = new Promise((resolve, reject) => {
          this.s3Bucket.uploadFile(this.videoId, subtitle.file[0], this.env.s3Bucket.path.subs,
              this.env.s3Bucket.buckets.bucketPath +
              this.env.s3Bucket.buckets.destinationFolder +
              this.env.s3Bucket.contentTypeFolder.tvEpisodes,
              (error, response, pr) => {
                if (error) {
                  reject({status: false, response: error});
                }
                subtitle.subTitleLocation = response.Location;
                resolve({status: true, response});
              }).on('httpUploadProgress', (progress) => {
            subtitle.progress = Math.round(progress.loaded / progress.total * 100);
          });
        });
        this.promiseArray.push(uploadPromise);
      }
    }
    if (this.promiseArray.length > 0) {
      Promise.all(this.promiseArray).then(value => {
        if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
          this.save();
          return;
        }
        this.alert.errorAlert(environment.s3Bucket.uploadError.title,
            environment.s3Bucket.uploadError.message);
      });
    } else {
      this.save();
    }
  }

  save() {
    if (this.checkDuplicates()) {
      this.alert.errorAlert(this.env.alerts.error.video.metaData.title,
          'Duplicate cast and special cast!!');
      this.saveBtnDisabled = false;
      return;
    }
    this.createRequestBody();
    this.service.update(Service.VIDEO.META_DATA, this.videoId, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.eventService.getContentIdEventEmitter({id: result.id, title: result.title.english});
      this.alert.successAlert(this.env.alerts.success.video.metaData.title,
          this.env.alerts.success.video.metaData.message);
      this.saveBtnDisabled = false;
    }, error2 => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(this.env.alerts.error.video.metaData.title,
          error2.error.error);
    });
  }

  getAllGenre() {
    this.service.findAll(Service.GENRE, {
      page: this.currentGenresPage,
      size: environment.size
    }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.totalGenres = response.total;
      const genreResponse = response.genres;

      this.genres = [...this.genres, ...genreResponse];

      this.genresClone = [...this.genresClone, ...genreResponse];
      this.genreSubject.next(this.genres);
    });
  }

  addActor() {
    this.addCast(this.env.castMemberRoles[0].role);
  }


  addCast(role: string) {
    const matDialogRef = this.matModel.open(CastModelComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '90%',
      width: '90%',
      data: {
        role
      }
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value) {
        if (value.role === this.env.castMembers.actor) {
          this.castsClone.push(value);
          this.casts = [...this.castsClone];

        }

        if (value.role === this.env.castMembers.director) {
          this.directorsClone.push(value);
          this.directors = [...this.directorsClone];

        }

        if (value.role === this.env.castMembers.producer) {
          this.producersClone.push(value);
          this.producers = [...this.producersClone];

        }
      }
    });
  }

  addKeyword() {
    const matDialogRef = this.matModel.open(AddNewKeywordComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '50vh',
      height: '50%',
      width: '50%',
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.keywordsClone.push(value);
        this.keywords = [...this.keywordsClone];
        this.selectedKeyWords.push(value.id);
        this.selectedKeyWords = [...this.selectedKeyWords];
      }

    });
  }

  addProducer() {
    this.addCast(this.env.castMemberRoles[2].role);
  }

  addDirector() {
    this.addCast(this.env.castMemberRoles[1].role);
  }

  addGenre() {
    const matDialogRef = this.matModel.open(AddNewGenreComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '50vh',
      height: '50%',
      width: '50%',
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.genresClone.push(value);
        this.genres = [...this.genresClone];
        this.selectedGenres.push(value.id);
        this.selectedGenres = [...this.selectedGenres];
      }

    });
  }

  addPlayList() {
    if (!this.isDetailViewOpen && this.selectedVideoChannel) {
      this.isDetailViewOpen = true;
      this.videoChannelService.playListsById(this.selectedVideoChannel,
        {page: this.env.page, size: this.env.size})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.playLists = response.data;

        const matDialogRef = this.matModel.open(AddNewPlayListComponent, {
          disableClose: true,
          autoFocus: false,
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '90%',
          data: {
            videoChannelId: this.selectedVideoChannel,
            playlist: this.playLists
          }
        });

        matDialogRef.afterClosed().subscribe(value => {

          if (value) {
            this.isDetailViewOpen = false;
            this.playLists.push(value);
            this.playLists = [...this.playLists];
          }
        });
      });
    } else {
      this.alert.errorAlert('Error', 'Select a Video Channel First');
    }
  }


  loadMetaData() {
    const promis = new Promise((resolve, reject) => {
      this.loading = true;

      this.service.findById(Service.TV_EPISODE.META_DATA, this.videoId, environment.contentType.video)
          .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
            if (result) {
              if (result.title.english) {
                this.videoTitleInEnglish = result.title.english;
              }

              if (result.title.sinhala) {
                this.videoTitleInSinhala = result.title.sinhala;
              }

              if (result.title.tamil) {
                this.videoTitleInTamil = result.title.tamil;
              }

              if (result.video_channel) {
                this.selectedVideoChannel = result.video_channel.id;
              }

              if (result.slug) {
                this.slug = result.slug;
              }
              if (result.keyword_ids) {
                this.keywords = result.keyword_ids;
              }

              if (result.description) {
                this.description = result.description;
              }
              if (result.run_time) {
                this.runTime = result.run_time;
              }

              if (result.maturity_rating) {
                this.selectedMaturityRating = result.maturity_rating;
              }

              if (result.directors) {
                this.selectedDirector = result.directors.map((director) => {
                  return director.id;
                });
                this.directorSubject.asObservable().subscribe((value: []) => {
                  const castIds = value.map((value1: any) => value1.id);
                  const notFetchedIds = this.selectedDirector.filter(value2 => !castIds.find(value1 => value2 === value1));
                  notFetchedIds.forEach(value1 => {
                    this.directors.push(result.directors.find(val => val.id === value1));
                  });
                });
              }
              if (result.play_list) {
                this.selectedPlayList = result.play_list.id;
              }
              if (result.video_number) {
                this.videoNumber = result.video_number;
              }

              if (result.genres) {
                this.selectedGenres = result.genres.map((genre) => {
                  return genre.id;
                });
                this.genreSubject.asObservable().subscribe((value: []) => {
                  const castIds = value.map((value1: any) => value1.id);
                  const notFetchedIds = this.selectedGenres.filter(value2 => !castIds.find(value1 => value2 === value1));
                  notFetchedIds.forEach(value1 => {
                    this.genres.push(result.genres.find(val => val.id === value1));
                  });
                });
              }

              if (result.keywords) {
                this.selectedKeyWords = result.keywords.map((keyword) => {
                  return keyword.id;
                });
                this.keywordsSubject.asObservable().subscribe((value: []) => {
                  const castIds = value.map((value1: any) => value1.id);
                  const notFetchedIds = this.selectedKeyWords.filter(value2 => !castIds.find(value1 => value2 === value1));
                  notFetchedIds.forEach(value1 => {
                    this.keywords.push(result.keywords.find(val => val.id === value1));
                  });
                });
              }

              if (result.actors) {
                this.selectedCastMembers = result.actors.map((actor) => {
                  return actor.id;
                });
                this.castSubject.asObservable().subscribe((value: []) => {
                  const castIds = value.map((value1: any) => value1.id);
                  const notFetchedIds = this.selectedCastMembers.filter(value2 => !castIds.find(value1 => value2 === value1));
                  notFetchedIds.forEach(value1 => {
                    this.casts.push(result.actors.find(val => val.id === value1));
                  });
                });
              }

              if (result.special_actors) {
                this.selectedSpecialCastMembers = result.special_actors.map((actor) => {
                  return actor.id;
                });
                this.castSubject.asObservable().subscribe((value: []) => {
                  const castIds = value.map((value1: any) => value1.id);
                  const notFetchedIds = this.selectedSpecialCastMembers.filter(value2 => !castIds.find(value1 => value2 === value1));
                  notFetchedIds.forEach(value1 => {
                    this.casts.push(result.special_actors.find(val => val.id === value1));
                  });
                });
              }

              if (result.producers) {
                this.selectedProducer = result.producers.map((producer) => {
                  return producer.id;
                });
                this.producerSubject.asObservable().subscribe((value: []) => {
                  const castIds = value.map((value1: any) => value1.id);
                  const notFetchedIds = this.selectedProducer.filter(value2 => !castIds.find(value1 => value2 === value1));
                  notFetchedIds.forEach(value1 => {
                    this.producers.push(result.producers.find(val => val.id === value1));
                  });
                });
              }
              if (result.subtitles) {
                for (let i = 0; i < result.subtitles.length; i++) {
                  this.subtitles.push({file: this.file});
                  this.subtitles[i].subtitleDescription = result.subtitles[i].description;
                  this.subtitles[i].subTitleLocation = result.subtitles[i].source_url;
                  this.subtitles[i].mediaConvertStatus = result.subtitles[i].media_convert_status;
                  this.subtitles[i].file = [new File([], '.' + result.subtitles[i].file_type.toLowerCase())];

                  let language;
                  if (result.subtitles[i].language === 'SIN') {
                    language = 'Sinhala';
                  }
                  if (result.subtitles[i].language === 'ENG') {
                    language = 'English';
                  }
                  if (result.subtitles[i].language === 'TAM') {
                    language = 'Tamil';
                  }
                  this.subtitles[i].subtitleLanguage = language;
                }
              }
            } else {
              this.loading = false;
            }
            this.loading = false;
            resolve(true);
          },
          error => {
            this.loading = false;
            reject(false);
          });
      this.loading = false;
    });
    promis.then((value) => {
      if (value) {
        this.videoChannelService.playListsById(this.selectedVideoChannel,
          {page: this.env.page, size: this.env.size})
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.playLists = response.data;
        });
      }
    });
  }

  producersOnScroll() {
    ++this.currentProducersPage;
    if (this.producer_reffrence.searchTerm) {
      this.searchProducers();
    } else {
      if (this.producersClone.length < this.totalProducers) {
        this.getAllProducers();
      }
    }
  }
  checkDuplicates() {
    for (let i = 0 ; i < this.selectedCastMembers.length ; i++) {
      if (this.selectedSpecialCastMembers.includes(this.selectedCastMembers[i])) {
        return true;
      }
    }
    return false;
  }
  directorsOnScroll() {
    ++this.currentDirectorsPage;
    if (this.director_reffrence.searchTerm) {
      this.searchDirectors();
    } else {
      if (this.directorsClone.length < this.totalDirectors) {
        this.getAllDirectors();
      }
    }
  }
  actorsOnScroll() {
    ++this.currentActorsPage;
    if (this.cast_reffrence.searchTerm) {
      this.searchActors(this.cast_reffrence.searchTerm);
    } else {
      if (this.castsClone.length < this.totalActors) {
        this.getAllCast();
      }
    }
  }
  keywordsOnScroll() {
    ++this.currentKeywordsPage;
    if (this.keyword_reffernce.searchTerm) {
      this.searchKeywords();
    } else {
      if (this.keywordsClone.length < this.totalKeywords) {
        this.getAllKeyWords();
      }
    }
  }

  videoChannelsOnScroll() {
    ++this.currentVideoChannelPage;
    if (this.videoChannelsClone.length < this.totalVideoChannels) {
      this.getVideoChannels();
    }
  }

  genresOnScroll() {
    ++this.currentGenresPage;
    if (this.genresReffrence.searchTerm) {
      this.searchActors(this.genresReffrence.searchTerm);
    } else {
      if (this.castsClone.length < this.totalActors) {
        this.getAllGenre();
      }
    }
  }

  setPendingSubtitles(status) {
    this.subtitles = this.subtitles.map(subtitle => {
      subtitle.mediaConvertStatus = status;
      return subtitle;
    });
  }

  specialCastsKeyPress() {
    if (!this.specialCastReffrence.searchTerm) {
      this.casts = this.castsClone;
      return;
    } else {}
    this.currentActorsPage = 0;
    this.casts = [];
    this.searchActors(this.specialCastReffrence.searchTerm);
  }

  specialActorsOnScroll() {
    ++this.currentActorsPage;
    if (this.specialCastReffrence.searchTerm) {
      this.searchActors(this.specialCastReffrence.searchTerm);
    } else {
      if (this.castsClone.length < this.totalActors) {
        this.getAllCast();
      }
    }
  }
  orderCastNumber(cast: any[], startIndex: number) {
    const orderedSelectedCastMembers: any[] = [];
    for (let i = 0; i < cast.length; i++) {
      orderedSelectedCastMembers.push({
        id: cast[i],
        seq_no: i + startIndex
      });
    }
    return orderedSelectedCastMembers;
  }
}
