import { Component, OnInit, Inject } from '@angular/core';
/*import { MAT_DIALOG_DATA } from '@angular/material';*/
/*import {MatDialog, MatDialogRef} from '@angular/material/dialog';*/
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Chart } from 'chart.js';
import {DashbordService} from '../../core/service/common_service/ http/dashbord/dashbord.service';

@Component({
  selector: 'app-home-analytic',
  templateUrl: './home-analytic.component.html',
  styleUrls: ['./home-analytic.component.scss']
})
export class HomeAnalyticComponent implements OnInit {
  range: any;
  range2: any;
  basicData: any;
  basicOptions: any;
  basicData2: any;
  basicData3: any;
  basicData4: any;
  basicData5: any;
  basicData9: any;
  basicData6: any;
  basicData7: any;
  basicData8: any;
  basicData10: any;
  basicData11: any;
  hours: any;
  dateRangeInserted: boolean=false;
  hourShows: boolean =false;
  deviceHours: boolean = false;
  data: any;
  data2: any;
  data3: any;
  chartOptions: any;
  androidDuration:any;
  iosDuration: any;
  webDuration:any;
  androidTime:any;
  iosTime: any;
  webTime:any;
  //subscription: Subscription | undefined;
  defaultKey1: boolean=false;
  wh_platformWise: boolean= false;
  wh_topRated: boolean = false;
  wh_categories: boolean = false;
  wh_packageWise: boolean = false;
  wh_lastWeek: boolean = false;
  view_contentWise: boolean = false;
  view_ageWise: boolean = false;
  view_genderWise: boolean = false;
  top_rated_views: boolean = false;
  seven_day_top_rated: boolean = false;
  top_rated_views_by_movies: boolean = false;
  top_rated_views_by_tvShow: boolean = false;
  top_rated_views_by_vidioChanel: boolean = false;
  views_lastWeek:boolean = false;
  wh_gender_male: any;
  wh_gender_female: any;
  wh_gender_other: any;
  view_gender_male: any;
  view_gender_female: any;
  view_gender_other: any;
  view_allMale:any;
  view_allFeMale:any;
  view_allOther:any;
  contentSet: any[] = [];
  //contentTypeDetails: any[] = [];
  moviewContentDetails:any[]=[];
  topRateMovies:any[]=[];
  sevenDaytopRate:any[]=[];
  topRateTvShows:any[]=[];
  topRateVidioChanels:any[]=[];
  tvShowContentDetails:any[]=[];
  vidioChanelContentDetails:any[]=[];
  top1:any=0;
  top2:any=0;
  top3:any=0;
  top4:any=0;
  top5:any=0;
  top6:any=0;
  top7:any=0;
  top8:any=0;
  top9:any=0;
  top10:any=0;
  movie1:any=0;
  movie2:any=0;
  movie3:any=0;
  movie4:any=0;
  movie5:any=0;
  movie6:any=0;
  movie7:any=0;
  movie8:any=0;
  movie9:any=0;
  movie10:any=0;
  tvShow1:any=0;
  tvShow2:any=0;
  tvShow3:any=0;
  tvShow4:any=0;
  tvShow5:any=0;
  tvShow6:any=0;
  tvShow7:any=0;
  tvShow8:any=0;
  tvShow9:any=0;
  tvShow10:any=0;
  vidioChanel1:any=0;
  vidioChanel2:any=0;
  vidioChanel3:any=0;
  vidioChanel4:any=0;
  vidioChanel5:any=0;
  vidioChanel6:any=0;
  vidioChanel7:any=0;
  vidioChanel8:any=0;
  vidioChanel9:any=0;
  vidioChanel10:any=0;
  moviewWh:any=0;
  tvShowWh:any=0;
  vidioChanelWh:any=0;
 // myControl = new FormControl('');
  options: any[] = [];
  //filteredOptions: Observable<string[]> | undefined;
  timeout: any = null;
  startDate: any =null;
  endDate: any =null;
  defualtEndDate: any =null;
  defualtStartDate: any =null;
  content_whCount: any=null;
  content_views: any=null;
  content_packages: any=null;
  content_gender: any=null;
  contentDetails_OK: boolean=false;
  //loading$ = this.loader.loading$;
  dropdownList: any[] = [];
  selectContents: any[] = [];
  mappedContentIdName: any[]  = [];
  //dropdownSettings:IDropdownSettings  = {};
  content_views_list: any[]=[];
  content_details: any[]=[];
  content_wh_list: any[]=[];
  content_package_list: any[]=[];
  content_gender_list: any[]=[];
  contentPackage1: any;
  contentPackage2: any;
  contentPackage3: any;
  searchButtonDisable: boolean = true;
  check:boolean=true;
  check1:boolean=true;
  selectedItems = [];
  minDate:any;
  maxDate:any;
  splitDate:any[]=[];

  constructor(@Inject(MAT_DIALOG_DATA) public data1:any , private dashboardService: DashbordService) { }
  /*----------------Top rated ----------*/
  show_top_rated_views(){
    this.defaultKey1=false;
    this.wh_platformWise=false;
    this.wh_topRated=false;
    this.top_rated_views=true;
    this.top_rated_views_by_movies=true;
    this.top_rated_views_by_tvShow=false;
    this.top_rated_views_by_vidioChanel=false;
    this.wh_categories=false;
    this.wh_packageWise=false;
    this.wh_lastWeek=false;
  }
  show_top_rated_views_by_movies(){
    this.defaultKey1=false;
    this.wh_platformWise=false;
    this.wh_topRated=false;
    this.seven_day_top_rated=false;
    this.top_rated_views=true;
    this.top_rated_views_by_movies=true;
    this.top_rated_views_by_tvShow=false;
    this.top_rated_views_by_vidioChanel=false;
    this.wh_categories=false;
    this.wh_packageWise=false;
    this.wh_lastWeek=false;
  }
  show_top_rated_views_by_tvShow(){
    this.defaultKey1=false;
    this.wh_platformWise=false;
    this.wh_topRated=false;
    this.seven_day_top_rated=false;
    this.top_rated_views=true;
    this.top_rated_views_by_movies=false;
    this.top_rated_views_by_vidioChanel=false;
    this.top_rated_views_by_tvShow=true;
    this.wh_categories=false;
    this.wh_packageWise=false;
    this.wh_lastWeek=false;
  }
  show_top_rated_views_by_vidioChanel(){
    this.defaultKey1=false;
    this.wh_platformWise=false;
    this.wh_topRated=false;
    this.seven_day_top_rated=false;
    this.top_rated_views=true;
    this.top_rated_views_by_movies=false;
    this.top_rated_views_by_tvShow=false;
    this.top_rated_views_by_vidioChanel=true;
    this.wh_categories=false;
    this.wh_packageWise=false;
    this.wh_lastWeek=false;
  }

  ngOnInit() {
    this.splitDate=this.data1.date.split(" to ");
    this.startDate=this.splitDate[0];
    this.endDate=this.splitDate[1];
    if (this.data1.nameTag=='topRate'){
      this.showHoursDateRange();
    }else if (this.data1.nameTag=='devise'){
      this.deviseViseWatchHours(this.startDate,this.endDate);
    }


    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }
  }
  showHoursDateRange(){
    this.check1=false;
    this.seven_day_top_rated=false;
    this.moviewContentDetails=[];
    this.tvShowContentDetails=[];
    this.vidioChanelContentDetails=[];
    this.topRateMovies=[];
    this.topRateTvShows=[];
    this.topRateVidioChanels=[];
    this.dateRangeInserted=false;
    this.defaultKey1=false;
    this.wh_platformWise=false;
    this.wh_topRated=false;
    this.wh_categories=false;
    this.dateRangeInserted=true;
    this.top_rated_views_by_tvShow=false;
    this.top_rated_views_by_vidioChanel=false;

   // this.loader.show();

   /* let start;
    let end;

    let startYear = this.range.value.start.getFullYear();
    let endYear = this.range.value.end.getFullYear();

    let startMonth = this.range.value.start.getMonth()+1;
    let endMonth = this.range.value.end.getMonth()+1;

    let startDate = this.range.value.start.getDate();
    let endDate = this.range.value.end.getDate();

    if(startMonth < 10){
      startMonth='0'+startMonth;
    }
    if(endMonth< 10){
      endMonth='0'+endMonth;
    }
    if(startDate < 10){
      startDate='0'+startDate;
    }
    if(endDate < 10){
      endDate='0'+endDate;
    }

    start = startYear+ "-" +startMonth +"-"+ startDate;
    end = endYear+ "-" +endMonth +"-"+ endDate;

    console.log(start);
    console.log(end);

    this.startDate=start;
    this.endDate=end;*/
    /*--------------------Issuse place of data adding--------------------*/
    this.dashboardService.getMovieContentDetails(this.startDate,this.endDate).subscribe(res=>{
      this.moviewContentDetails.push(res);
      //this.viewAll();
    });
    this.dashboardService.getTvShowContentDetails(this.startDate,this.endDate).subscribe(res=>{
      this.tvShowContentDetails.push(res);
      //this.viewAll();

    });
    this.dashboardService.getVidioChanelContentDetails(this.startDate,this.endDate).subscribe(res=>{
      this.vidioChanelContentDetails.push(res);
      //this.viewAll();

    });
    /*-------------------start TOP RATE CONTENT DATA SET TO TABLE-----------*/
    this.dashboardService.getMovieTopRatedDetails(this.startDate,this.endDate).subscribe(res=>{
      this.topRateMovies.push(res);
      this.movie1=this.topRateMovies[0][0]!=null?this.topRateMovies[0][0].content_slug:'';
      this.movie2=this.topRateMovies[0][1]!=null?this.topRateMovies[0][1].content_slug:'';
      this.movie3=this.topRateMovies[0][2]!=null?this.topRateMovies[0][2].content_slug:'';
      this.movie4=this.topRateMovies[0][3]!=null?this.topRateMovies[0][3].content_slug:'';
      this.movie5=this.topRateMovies[0][4]!=null?this.topRateMovies[0][4].content_slug:'';
      this.movie6=this.topRateMovies[0][5]!=null?this.topRateMovies[0][5].content_slug:'';
      this.movie7=this.topRateMovies[0][6]!=null?this.topRateMovies[0][6].content_slug:'';
      this.movie8=this.topRateMovies[0][7]!=null?this.topRateMovies[0][7].content_slug:'';
      this.movie9=this.topRateMovies[0][8]!=null?this.topRateMovies[0][8].content_slug:'';
      this.movie10=this.topRateMovies[0][9]!=null?this.topRateMovies[0][9].content_slug:'';

      this.basicData6 = {
        labels: [this.movie1,this.movie2,this.movie3,this.movie4,this.movie5,this.movie6,this.movie7,this.movie8,this.movie9,this.movie10],
        datasets: [
          {
            label: 'watch hours',
            backgroundColor: '#42A5F5',
            data: [this.topRateMovies[0][0]!=null?(this.topRateMovies[0][0].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateMovies[0][1]!=null?(this.topRateMovies[0][1].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateMovies[0][2]!=null?(this.topRateMovies[0][2].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateMovies[0][3]!=null?(this.topRateMovies[0][3].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateMovies[0][4]!=null?(this.topRateMovies[0][4].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateMovies[0][5]!=null?(this.topRateMovies[0][5].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateMovies[0][6]!=null?(this.topRateMovies[0][6].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateMovies[0][7]!=null?(this.topRateMovies[0][7].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateMovies[0][8]!=null?(this.topRateMovies[0][8].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateMovies[0][9]!=null?(this.topRateMovies[0][9].sum_total_watch_hour/3600000).toFixed(2):0]

          }
        ]
      }

    })
    this.dashboardService.getTvShowTopRatedDetails(this.startDate,this.endDate).subscribe(res=>{
      this.topRateTvShows.push(res);
      this.tvShow1=this.topRateTvShows[0][0]!=null?this.topRateTvShows[0][0].content_slug:'';
      this.tvShow2=this.topRateTvShows[0][1]!=null?this.topRateTvShows[0][1].content_slug:'';
      this.tvShow3=this.topRateTvShows[0][2]!=null?this.topRateTvShows[0][2].content_slug:'';
      this.tvShow4=this.topRateTvShows[0][3]!=null?this.topRateTvShows[0][3].content_slug:'';
      this.tvShow5=this.topRateTvShows[0][4]!=null?this.topRateTvShows[0][4].content_slug:'';
      this.tvShow6=this.topRateTvShows[0][5]!=null?this.topRateTvShows[0][5].content_slug:'';
      this.tvShow7=this.topRateTvShows[0][6]!=null?this.topRateTvShows[0][6].content_slug:'';
      this.tvShow8=this.topRateTvShows[0][7]!=null?this.topRateTvShows[0][7].content_slug:'';
      this.tvShow9=this.topRateTvShows[0][8]!=null?this.topRateTvShows[0][8].content_slug:'';
      this.tvShow10=this.topRateTvShows[0][9]!=null?this.topRateTvShows[0][9].content_slug:'';

      this.basicData7 = {
        labels: [this.tvShow1,this.tvShow2,this.tvShow3,this.tvShow4,this.tvShow5,this.tvShow6,this.tvShow7,this.tvShow8,this.tvShow9,this.tvShow10],
        datasets: [
          {
            label: 'watch hours',
            backgroundColor: '#42A5F5',
            data: [this.topRateTvShows[0][0]!=null?(this.topRateTvShows[0][0].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateTvShows[0][1]!=null?(this.topRateTvShows[0][1].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateTvShows[0][2]!=null?(this.topRateTvShows[0][2].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateTvShows[0][3]!=null?(this.topRateTvShows[0][3].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateTvShows[0][4]!=null?(this.topRateTvShows[0][4].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateTvShows[0][5]!=null?(this.topRateTvShows[0][5].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateTvShows[0][6]!=null?(this.topRateTvShows[0][6].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateTvShows[0][7]!=null?(this.topRateTvShows[0][7].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateTvShows[0][8]!=null?(this.topRateTvShows[0][8].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateTvShows[0][9]!=null?(this.topRateTvShows[0][9].sum_total_watch_hour/3600000).toFixed(2):0]
          }
        ]
      }

    })
    this.dashboardService.getVideoChanelTopRatedDetails(this.startDate,this.endDate).subscribe(res=>{
      this.topRateVidioChanels.push(res);
      this.vidioChanel1=this.topRateVidioChanels[0][0]!=null?this.topRateVidioChanels[0][0].content_slug:'';
      this.vidioChanel2=this.topRateVidioChanels[0][1]!=null?this.topRateVidioChanels[0][1].content_slug:'';
      this.vidioChanel3=this.topRateVidioChanels[0][2]!=null?this.topRateVidioChanels[0][2].content_slug:'';
      this.vidioChanel4=this.topRateVidioChanels[0][3]!=null?this.topRateVidioChanels[0][3].content_slug:'';
      this.vidioChanel5=this.topRateVidioChanels[0][4]!=null?this.topRateVidioChanels[0][4].content_slug:'';
      this.vidioChanel6=this.topRateVidioChanels[0][5]!=null?this.topRateVidioChanels[0][5].content_slug:'';
      this.vidioChanel7=this.topRateVidioChanels[0][6]!=null?this.topRateVidioChanels[0][6].content_slug:'';
      this.vidioChanel8=this.topRateVidioChanels[0][7]!=null?this.topRateVidioChanels[0][7].content_slug:'';
      this.vidioChanel9=this.topRateVidioChanels[0][8]!=null?this.topRateVidioChanels[0][8].content_slug:'';
      this.vidioChanel10=this.topRateVidioChanels[0][9]!=null?this.topRateVidioChanels[0][9].content_slug:'';

      this.basicData8 = {
        labels: [this.vidioChanel1,this.vidioChanel2,this.vidioChanel3,this.vidioChanel4,this.vidioChanel5,this.vidioChanel6,this.vidioChanel7,this.vidioChanel8,this.vidioChanel9,this.vidioChanel10],
        datasets: [
          {
            label: 'watch hours',
            backgroundColor: '#42A5F5',
            data: [this.topRateVidioChanels[0][0]!=null?(this.topRateVidioChanels[0][0].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateVidioChanels[0][1]!=null?(this.topRateVidioChanels[0][1].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateVidioChanels[0][2]!=null?(this.topRateVidioChanels[0][2].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateVidioChanels[0][3]!=null?(this.topRateVidioChanels[0][3].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateVidioChanels[0][4]!=null?(this.topRateVidioChanels[0][4].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateVidioChanels[0][5]!=null?(this.topRateVidioChanels[0][5].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateVidioChanels[0][6]!=null?(this.topRateVidioChanels[0][6].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateVidioChanels[0][7]!=null?(this.topRateVidioChanels[0][7].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateVidioChanels[0][8]!=null?(this.topRateVidioChanels[0][8].sum_total_watch_hour/3600000).toFixed(2):0,this.topRateVidioChanels[0][9]!=null?(this.topRateVidioChanels[0][9].sum_total_watch_hour/3600000).toFixed(2):0]
          }
        ]
      }

    })
    /*-------------------end TOP RATE CONTENT DATA SET TO TABLE-----------*/

    /*this.dashboardService.getHoursByDate(this.startDate,this.endDate).subscribe(hours => {
      this.deviceHours = true;

      this.hourShows = true;
      this.hours=hours;

      /!*-----------GetHourseByDeviceDate------*!/

      this.androidDuration= hours.android_watch_hour/3600000;
      this.iosDuration=hours.ios_watch_hour/3600000;
      this.webDuration=hours.web_watch_hour/3600000;

      this.data = {
        datasets: [{
          data: [
            this.androidDuration,
            this.iosDuration,
            this.webDuration
          ],
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FFA726"
          ],
          label: 'Watch Hours'
        }],
        labels: [
          "Android",
          "IOS",
          "Web"
        ]
      };

      /!* /!*---------GET VIWS BY GENDER----------*!/
       this.basicData9 = {
         labels: ['Male','Female','Other'],
         datasets: [
           {
             data: [this.view_allMale, this.view_allFeMale, this.view_allOther],
             backgroundColor: [
               "#1f388d",
               "#7942e8",
               "#2495af"
             ],
             hoverBackgroundColor: [
               "#1f388d",
               "#7942e8",
               "#2495af"
             ]
           }
         ]
       }*!/
      /!*---------------GET VIEWS BY CONTENT DATA--------------*!/
      /!*this.basicData2 = {
        labels: ['Movies', 'Tv Shows', 'Video Channels', 'Tv Episodes', 'Live Channels', 'Programs'],
        datasets: [
          {
            label: 'Views',
            backgroundColor: '#42A5F5',
            data: [views.movie, views.tv_show, views.video_channel, views.tv_episode, views.live_channel, views.program]
          }
        ]
      }*!/

      /!*------------GET WHATCH HOURS BY GENDER----------*!/
      this.wh_gender_male = hours.male_watch_hour/3600000;
      this.wh_gender_female = hours.female_watch_hour/3600000;
      this.wh_gender_other = hours.other_watch_hour/3600000;
      this.basicData5 = {
        labels: ['Male','Female','Other'],
        datasets: [
          {
            data: [this.wh_gender_male, this.wh_gender_female, this.wh_gender_other],
            backgroundColor: [
              "#2148ce",
              "#8d6cd2",
              "#2495af"
            ],
            hoverBackgroundColor: [
              "#2148ce",
              "#8d6cd2",
              "#2495af"
            ]
          }
        ]
      }

    });*/
    this.top_rated_views_by_movies=true;
    this.top_rated_views=true;

  }
 /* watchHours(Startdate,endDate){
    //this.hours.total_watch_hour=null;
   // this.deviseViseWatchHours(Startdate,endDate,false);
    this.dashboardService.getHoursByDate(Startdate,endDate).subscribe(hours => {
      this.hours=hours;

      if (this.hours){
        this.hours.total_watch_hour=(this.hours.total_watch_hour/3600000).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        console.log(this.hours.total_watch_hour);
        return this.hours.total_watch_hour;
      }

    });
  }*/
  deviseViseWatchHours(Startdate,endDate){
    this.top_rated_views=false;
    this.wh_platformWise=true;
    this.dashboardService.getHoursByDate(Startdate,endDate).subscribe(hours => {
      this.deviceHours = true;

      this.hourShows = true;
      this.hours=hours;

      /*-----------GetHourseByDeviceDate------*/

      this.androidDuration= hours.android_watch_hour/3600000;
      this.iosDuration=hours.ios_watch_hour/3600000;
      this.webDuration=hours.web_watch_hour/3600000;
      this.androidTime=this.androidDuration!=null? this.androidDuration.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}):0;
      this.iosTime=this.iosDuration!=null? this.iosDuration.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}):0;
      this.webTime=this.webDuration!=null? this.webDuration.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}):0;

      this.data = {
        datasets: [{
          data: [
            this.androidDuration,
            this.iosDuration,
            this.webDuration
          ],
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FFA726"
          ],
          label: 'Watch Hours'
        }],
        labels: [
          "Android",
          "IOS",
          "Web"
        ]
      };

      /* /!*---------GET VIWS BY GENDER----------*!/
       this.basicData9 = {
         labels: ['Male','Female','Other'],
         datasets: [
           {
             data: [this.view_allMale, this.view_allFeMale, this.view_allOther],
             backgroundColor: [
               "#1f388d",
               "#7942e8",
               "#2495af"
             ],
             hoverBackgroundColor: [
               "#1f388d",
               "#7942e8",
               "#2495af"
             ]
           }
         ]
       }*/
      /*---------------GET VIEWS BY CONTENT DATA--------------*/
      /*this.basicData2 = {
        labels: ['Movies', 'Tv Shows', 'Video Channels', 'Tv Episodes', 'Live Channels', 'Programs'],
        datasets: [
          {
            label: 'Views',
            backgroundColor: '#42A5F5',
            data: [views.movie, views.tv_show, views.video_channel, views.tv_episode, views.live_channel, views.program]
          }
        ]
      }*/

      /*------------GET WHATCH HOURS BY GENDER----------*/
      /*this.wh_gender_male = hours.male_watch_hour/3600000;
      this.wh_gender_female = hours.female_watch_hour/3600000;
      this.wh_gender_other = hours.other_watch_hour/3600000;
      this.basicData5 = {
        labels: ['Male','Female','Other'],
        datasets: [
          {
            data: [this.wh_gender_male, this.wh_gender_female, this.wh_gender_other],
            backgroundColor: [
              "#2148ce",
              "#8d6cd2",
              "#2495af"
            ],
            hoverBackgroundColor: [
              "#2148ce",
              "#8d6cd2",
              "#2495af"
            ]
          }
        ]
      }*/

    });
  }

}
