import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Service} from '../../../../../core/service/common_service/services';
import {environment} from '../../../../../../environments/environment';
import {PackageTierService} from '../../../../../core/service/common_service/ http/packege-tier/package-tier.service';
import {Alerts} from '../../../../../alert/Alert';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {NotificationTemplateService} from '../../../../../core/service/common_service/ http/notification-template/notification-template.service';

@Component({
  selector: 'app-publish-movie-model',
  templateUrl: './publish-movie-model.component.html',
  styleUrls: ['./publish-movie-model.component.scss']
})
export class PublishMovieModelComponent implements OnInit {
  schedule = {};
  schedules: any = [this.schedule];
  initialData;
  countries: any[] = [];
  countriesClone: any[] = [];
  selectedCountryIds: any[] = [];
  generalPublishDateAndTime;
  generalDateAndTimeForFree;
  // generalDateAndTimeForStandard;
  generalDateAndTimeForPremium;
  generalDateAndTimeForAnnualPremium;
  isGeneralPublishDateAndTimeAvailable = true;
  isGeneralDateAndTimeForFreeAvailable = true;
  // isGeneralDateAndTimeForStandardAvailable = true;
  isGeneralDateAndTimeForPremiumAvailable = true;
  isGeneralDateAndTimeForAnnualPremiumAvailable = true;
  isSpecialPublishDateAndTimeAvailable: any[] = [];
  isSpecialDateAndTimeForFreeAvailable: any[] = [];
  // isSpecialDateAndTimeForStandardAvailable: any[] = [];
  isSpecialDateAndTimeForPremiumAvailable: any[] = [];
  isSpecialDateAndTimeForAnnualPremiumAvailable: any[] = [];
  isSpecialCountryAvailable: any[] = [];
  generalPublishDateAndTimeMessage;
  generalDateAndTimeForFreeMessage;
  // generalDateAndTimeForStandardMessage;
  generalDateAndTimeForPremiumMessage;
  generalDateAndTimeForAnnualPremiumMessage;
  specialCountryMessage;
  specialPublishDateAndTimeMessage;
  specialDateAndTimeForFreeMessage;
  // specialDateAndTimeForStandardMessage;
  specialDateAndTimeForPremiumMessage;
  GENERAL_PUBLISH_DATE_TIME = 1;
  GENERAL_WATCH_FREE_DATE_TIME = 2;
  GENERAL_WATCH_STANDARD_DATE_TIME = 3;
  GENERAL_WATCH_PREMIUM_DATE_TIME = 4;
  SPECIAL_COUNTRY = 5;
  SPECIAL_PUBLISH_DATE_TIME = 6;
  SPECIAL_WATCH_FREE_DATE_TIME = 7;
  SPECIAL_WATCH_STANDARD_DATE_TIME = 8;
  SPECIAL_WATCH_PREMIUM_DATE_TIME = 9;
  movieMonetizationData;
  isFreeAllowed = true;
  isStandardAllowed = true;
  isPremiumAllowed = true;
  isAnnualPremiumAllowed = true;
  packages: any[] = [];
  env = environment;
  isFormValid = true;
  requestBody: any = {};
  packageTiers: any[] = [];
  isPublished = false;
  currentDateTime;
  isFireBaseSendNotification = false;
  isEmailSendNotification = false;
  notificationTemplate;
  newSchedule = false;
  constructor(private model: MatDialogRef<PublishMovieModelComponent>, private matModel: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: any, private commonService: CommonService,
              private commonServiceService: CommonServiceService,
              private notificationTemplateService: NotificationTemplateService,
              private packageTierService: PackageTierService, private alert: Alerts) {
    this.initialData = data;
  }


  ngOnInit() {
    const today = new Date();
    this.generalPublishDateAndTime = today;
    this.generalDateAndTimeForFree = today;
    // this.generalDateAndTimeForStandard = today;
    this.generalDateAndTimeForPremium = today;
    this.generalDateAndTimeForPremium = today;
    this.generalDateAndTimeForPremium = today;
    this.generalDateAndTimeForAnnualPremium = today;
    this.getNotificationTemplate();
    this.getAllCountries();
    this.getContentRules();
    this.getPackages();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  addAnotherSchedule() {
     this.schedule = {};
     this.schedules.push(this.schedule);
     this.newSchedule = true;
  }

  onNoClick() {
    this.closeWindow();
  }

  closeWindow() {
    this.model.close(this.isPublished);
  }

  getNotificationTemplate() {
    this.notificationTemplateService.findAllByContentType(environment.contentType.movie, 0, 10)
      .subscribe(response => {
        this.notificationTemplate = response[0];
    }, error => {
      console.log(error);
    });
  }

  publishSchedule() {
    this.validateRequiredFields();
    if (this.isFormValid) {
      this.createRequestBody();
      this.commonService.update(Service.MOVIE.PUBLISH, this.initialData.id, this.requestBody)
          .subscribe(response => {
            this.isPublished = true;
            this.alert.publishedSuccessAlert(this.env.publish.success.movie.title, this.env.publish.success.movie.message)
                .then(() => {
                  this.closeWindow();
                });
      }, error => {
            this.alert.errorAlert(this.env.publish.error.movie.title, this.env.publish.error.movie.errorSavingMessage);
          });
    }
  }

  getAllCountries() {
    this.commonService.findAll(Service.COUNTRY).subscribe((response: any) => {
      this.countries = response;
      this.countriesClone = [...this.countries];
    });
  }

  changeCountry(event, index) {
    if (event) {
      this.selectedCountryIds.push({index, obj: event});
      this.removeFromCountryArray();
    } else {
      this.selectedCountryIds = this.selectedCountryIds.filter(value => value.index !== index);
      this.removeFromCountryArray();
    }
  }

  deleteContentRule(index, schedule) {
    this.schedules.splice(index, 1);
    this.removeSelectedCountry(schedule);
    this.removeFromCountryArray();
  }

  removeSelectedCountry(schedule) {
    this.selectedCountryIds = this.selectedCountryIds
        .filter(value => (value.obj.id !== schedule.selectedCountryId));
  }

  removeFromCountryArray() {
    this.countries = this.countriesClone.filter(country => {
      return this.selectedCountryIds.filter(value =>
          JSON.stringify(value.obj) === JSON.stringify(country)).length === 0;
    });
  }

  validateRequiredFields() {
    if (!this.generalPublishDateAndTime) {
      this.isFormValid = false;
      this.isGeneralPublishDateAndTimeAvailable = false;
      this.generalPublishDateAndTimeMessage = this.env.publish.error.movie.publishDateAndTimeNotAvailable;
    }

    if (!this.generalDateAndTimeForFree && this.isFreeAllowed) {
      this.isFormValid = false;
      this.isGeneralDateAndTimeForFreeAvailable = false;
      this.generalDateAndTimeForFreeMessage =
          this.env.publish.error.movie.watchEnableDateAndTimeNotStandardAndFreeAvailable;
    }

    // if (!this.generalDateAndTimeForStandard && this.isStandardAllowed) {
    //   this.isFormValid = false;
    //   this.isGeneralDateAndTimeForStandardAvailable = false;
    //   this.generalDateAndTimeForStandardMessage =
    //       this.env.publish.error.movie.watchEnableDateAndTimeNotStandardAndFreeAvailable;
    // }

    if (!this.generalDateAndTimeForPremium && this.isPremiumAllowed) {
      this.isFormValid = false;
      this.isGeneralDateAndTimeForPremiumAvailable = false;
      this.generalDateAndTimeForPremiumMessage =
          this.env.publish.error.movie.watchEnableDateAndTimePremiumNotAvailable;
    }
    if (!this.generalDateAndTimeForAnnualPremium && this.isAnnualPremiumAllowed) {
      this.isFormValid = false;
      this.isGeneralDateAndTimeForAnnualPremiumAvailable = false;
      this.generalDateAndTimeForAnnualPremiumMessage =
          this.env.publish.error.movie.watchEnableDateAndTimePremiumNotAvailable;
    }

    this.schedules.forEach((schedule, index) => {

      if (!schedule.selectedCountryId) {
        this.isFormValid = false;
        this.isSpecialCountryAvailable.push(index);
      }

      if (!schedule.specialPublishDateAndTime) {
        this.isFormValid = false;
        this.isSpecialPublishDateAndTimeAvailable.push(index);
      }

      if (!schedule.specialDateAndTimeForFree && this.isFreeAllowed) {
        this.isFormValid = false;
        this.isSpecialDateAndTimeForFreeAvailable.push(index);
      }

    /*  if (!schedule.specialDateAndTimeForStandard && this.isStandardAllowed) {
        this.isFormValid = false;
        this.isSpecialDateAndTimeForStandardAvailable.push(index);
      }*/

      if (!schedule.specialDateAndTimeForPremium && this.isPremiumAllowed) {
        this.isFormValid = false;
        this.isSpecialDateAndTimeForPremiumAvailable.push(index);
      }

      if (!schedule.specialDateAndTimeForAnnualPremium && this.isAnnualPremiumAllowed) {
        this.isFormValid = false;
        this.isSpecialDateAndTimeForAnnualPremiumAvailable.push(index);
      }

      this.specialCountryMessage = this.env.publish.error.movie.countryNotAvailable;
      this.specialPublishDateAndTimeMessage = this.env.publish.error.movie.publishDateAndTimeNotAvailable;
      this.specialDateAndTimeForFreeMessage =
          this.env.publish.error.movie.watchEnableDateAndTimeNotStandardAndFreeAvailable;
      // this.specialDateAndTimeForStandardMessage =
      //     this.env.publish.error.movie.watchEnableDateAndTimeNotStandardAndFreeAvailable;
      this.specialDateAndTimeForPremiumMessage =
          this.env.publish.error.movie.watchEnableDateAndTimePremiumNotAvailable;
    });
  }

  resetValidation(field, index?) {
    this.isFormValid = true;
    switch (field) {
      case this.GENERAL_PUBLISH_DATE_TIME: {
        this.isGeneralPublishDateAndTimeAvailable = true;
        break;
      }
      case this.GENERAL_WATCH_FREE_DATE_TIME: {
        this.isGeneralDateAndTimeForFreeAvailable = true;
        break;
      }
      // case this.GENERAL_WATCH_STANDARD_DATE_TIME: {
      //   this.isGeneralDateAndTimeForStandardAvailable = true;
      //   break;
      // }
      case this.GENERAL_WATCH_PREMIUM_DATE_TIME: {
        this.isGeneralDateAndTimeForPremiumAvailable = true;
        break;
      }
      case this.SPECIAL_COUNTRY: {
        this.isSpecialCountryAvailable.splice(this.isSpecialCountryAvailable.indexOf(index), 1);
        break;
      }
      case this.SPECIAL_PUBLISH_DATE_TIME: {
        this.isSpecialPublishDateAndTimeAvailable
            .splice(this.isSpecialPublishDateAndTimeAvailable.indexOf(index), 1);
        break;
      }
      case this.SPECIAL_WATCH_FREE_DATE_TIME: {
        this.isSpecialDateAndTimeForFreeAvailable
            .splice(this.isSpecialDateAndTimeForFreeAvailable.indexOf(index), 1);
        break;
      }
      // case this.SPECIAL_WATCH_STANDARD_DATE_TIME: {
      //   this.isSpecialDateAndTimeForStandardAvailable
      //       .splice(this.isSpecialDateAndTimeForStandardAvailable.indexOf(index), 1);
      //   break;
      // }
      case this.SPECIAL_WATCH_PREMIUM_DATE_TIME: {
        this.isSpecialDateAndTimeForPremiumAvailable
            .splice(this.isSpecialDateAndTimeForPremiumAvailable.indexOf(index), 1);
        break;
      }
    }
  }

  getContentRules() {
    this.commonService.findById(Service.MOVIE.MONETIZATION, this.initialData.id, this.env.contentType.movie)
        .subscribe((response: any) => {

          if (response.monetization) {
            this.movieMonetizationData = response;
            this.setAvailablePackages();
            return;
          }
        }, error => {
        });
  }

  setAvailablePackages() {
    if (this.movieMonetizationData.monetization.plans.length > 0) {
      this.packages = this.movieMonetizationData.monetization.plans.filter(plan => plan.package_type !== null)
          .map((plan) => {
        return plan.package_type;
      });
    }
    this.packages = this.packages.concat(this.movieMonetizationData.monetization.free_to_watch);

    if (this.packages.indexOf(this.env.packages.free) < 0) {
      this.isFreeAllowed =  false;
    }

    if (this.packages.indexOf(this.env.packages.standard) < 0) {
      this.isStandardAllowed =  false;
    }

    if (this.packages.indexOf(this.env.packages.premium) < 0) {
      this.isPremiumAllowed =  false;
    }
    if (this.packages.indexOf(this.env.packages.annualPremium) < 0) {
      this.isAnnualPremiumAllowed =  false;
    }
  }

  createRequestBody() {
    this.requestBody.title = this.notificationTemplate.name;
    this.requestBody.message = this.notificationTemplate.template.replace('%movie_name%', this.initialData.title);
    this.requestBody.is_send_firebase_notification = this.isFireBaseSendNotification;
    this.requestBody.is_send_email_notification = this.isEmailSendNotification;
    this.requestBody.content_type = this.env.contentType.movie;
    // todo remove allow early access
    this.requestBody.allow_early_access = true;
    this.requestBody.country_publish_rule_rqs = this.schedules.map((schedule) => {
      return {
        country_id: schedule.selectedCountryId,
        publish_date_and_time: schedule.specialPublishDateAndTime
      };
    });

    this.requestBody.country_watch_enable_rule_rqs = [];

    this.schedules.forEach((schedule) => {

      if (this.isFreeAllowed) {
        this.requestBody.country_watch_enable_rule_rqs.push({
          country_id: schedule.selectedCountryId,
          package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.free)[0].id,
          watch_enable_date_and_time: schedule.specialDateAndTimeForFree
        });
      }

      /*if (this.isStandardAllowed) {
        this.requestBody.country_watch_enable_rule_rqs.push({
          country_id: schedule.selectedCountryId,
          package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.standard)[0].id,
          watch_enable_date_and_time: schedule.specialDateAndTimeForStandard
        });
      }*/

      if (this.isPremiumAllowed) {
        this.requestBody.country_watch_enable_rule_rqs.push({
          country_id: schedule.selectedCountryId,
          package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.premium)[0].id,
          watch_enable_date_and_time: schedule.specialDateAndTimeForPremium
        });
      }
      if (this.isAnnualPremiumAllowed) {
        this.requestBody.country_watch_enable_rule_rqs.push({
          country_id: schedule.selectedCountryId,
          package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.annualPremium)[0].id,
          watch_enable_date_and_time: schedule.specialDateAndTimeForAnnualPremium
        });
      }
    });

    this.requestBody.general_publish_date_and_time = this.generalPublishDateAndTime;
    this.requestBody.general_watch_enable_rule_rqs = [];

    if (this.isFreeAllowed) {
      this.requestBody.general_watch_enable_rule_rqs.push({
        package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.free)[0].id,
        watch_enable_date_and_time: this.generalDateAndTimeForFree
      });
    }

    // if (this.isStandardAllowed) {
    //   this.requestBody.general_watch_enable_rule_rqs.push({
    //     package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.standard)[0].id,
    //     watch_enable_date_and_time: this.generalDateAndTimeForStandard
    //   });
    // }

    if (this.isPremiumAllowed) {
      this.requestBody.general_watch_enable_rule_rqs.push({
        package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.premium)[0].id,
        watch_enable_date_and_time: this.generalDateAndTimeForPremium
      });
    }
    if (this.isAnnualPremiumAllowed) {
      this.requestBody.general_watch_enable_rule_rqs.push({
        package_id: this.packageTiers.filter(value => value.package_type === this.env.packages.annualPremium)[0].id,
        watch_enable_date_and_time: this.generalDateAndTimeForAnnualPremium
      });
    }
  }

  cancelSchedule() {
  }
  getPackages() {
    this.packageTierService.findAllPackageTiers().subscribe((response: any) => {
        this.packageTiers = response;
        }, error => {
        });
  }
}
