import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TvEpisodePosterService {

  constructor(private http: HttpService) { }

  savePoster(body) {
    return this.http.post(environment.serverurl + '/content-details/posters', body);
  }

  updatePoster(id, body) {
    return this.http.put(environment.serverurl + '/content-details/posters/' + id, body);
  }
}
