import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentDetailMetadataService {

  constructor(private http: HttpService) { }

  update(id, body) {
    return this.http.put(environment.serverurl + '/content-details/metadata/' + id, body);
  }
  getMetaDataById(id, contentType) {
    return this.http.get<any>(environment.serverurl + '/content-details/' + id + '/metadata?content=' + contentType);
  }
}
