import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {MatDialog, MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CommonService} from '../../../../core/service/common_service/common.service';
import {PackageTierService} from '../../../../core/service/common_service/ http/packege-tier/package-tier.service';
import {Alerts} from '../../../../alert/Alert';
import {CategoryService} from '../../../../core/service/common_service/ http/category/category.service';
import {Service} from '../../../../core/service/common_service/services';
import {ScreenService} from '../../../../core/service/common_service/ http/screen/screen.service';

@Component({
  selector: 'app-publish-screen',
  templateUrl: './publish-screen.component.html',
  styleUrls: ['./publish-screen.component.scss']
})
export class PublishScreenComponent implements OnInit {
  generalPublishDateAndTime;
  isGeneralPublishDateAndTimeAvailable = true;
  generalPublishDateAndTimeMessage;
  schedule = {};
  schedules: any = [this.schedule];
  specialCountryMessage;
  isSpecialCountryAvailable: any[] = [];
  isSpecialPublishDateAndTimeAvailable: any[] = [];
  specialPublishDateAndTimeMessage;


  initialData;
  countries: any[] = [];
  countriesClone: any[] = [];

  selectedCountryIds: any[] = [];
  generalDateAndTimeForStandard;
  isGeneralDateAndTimeForStandardAvailable = true;
  isSpecialDateAndTimeForStandardAvailable: any[] = [];
  generalDateAndTimeForStandardMessage;
  generalDateAndTimeForPremiumMessage;
  specialDateAndTimeForFreeMessage;
  specialDateAndTimeForStandardMessage;
  specialDateAndTimeForPremiumMessage;
  GENERAL_PUBLISH_DATE_TIME = 1;
  SPECIAL_COUNTRY = 5;
  SPECIAL_PUBLISH_DATE_TIME = 6;
  movieMonetizationData;
  isFreeAllowed = true;
  isStandardAllowed = true;
  isPremiumAllowed = true;
  packages: any[] = [];
  env = environment;
  isFormValid = true;
  requestBody: any = {};
  packageTiers: any[] = [];
  isPublished = false;
  closeParentWindow: boolean;

  constructor(private model: MatDialogRef<PublishScreenComponent>, private matModel: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: any, private commonService: CommonService,
              private packageTierService: PackageTierService, private alert: Alerts, private screenService: ScreenService) {
    this.initialData = data;
  }

  ngOnInit() {
    const today = new Date();
    this.generalPublishDateAndTime = today;
    this.getAllCountries();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  addAnotherSchedule() {
    this.schedule = {};
    this.schedules.push(this.schedule);
  }

  onNoClick() {
    this.closeWindow();
  }

  closeWindow() {
    this.model.close({isPublish: this.isPublished, });
  }

  publishSchedule() {
    this.validateRequiredFields();
    if (this.isFormValid) {
      this.createRequestBody();

      this.screenService.publish(this.initialData.id, this.requestBody)
          .subscribe(response => {
            this.isPublished = true;
            this.alert.publishedSuccessAlert(this.env.publish.success.category.title, this.env.publish.success.screen.message)
                .then(() => {
                  this.closeWindow();
                });
          }, error => {
            this.alert.errorAlert(this.env.publish.error.category.title, this.env.publish.error.category.message);
          });
    }
  }

  getAllCountries() {
    this.commonService.findAll(Service.COUNTRY).subscribe((response: any) => {
      this.countries = response;
      this.countriesClone = [...this.countries];
    });
  }

  changeCountry(event, index) {
    if (event) {
      this.selectedCountryIds.push({ index, obj: event});
      this.removeFromCountryArray();
    } else {
      this.selectedCountryIds = this.selectedCountryIds.filter(value => value.index !== index);
      this.removeFromCountryArray();
    }
  }

  deleteContentRule(index, schedule) {
    this.schedules.splice(index, 1);
    this.removeSelectedCountry(schedule);
    this.removeFromCountryArray();
  }

  removeSelectedCountry(schedule) {
    this.selectedCountryIds = this.selectedCountryIds
        .filter(value => (value.obj.id !== schedule.selectedCountryId));
  }

  removeFromCountryArray() {
    this.countries = this.countriesClone.filter(country => {
      return this.selectedCountryIds.filter(value =>
          JSON.stringify(value.obj) === JSON.stringify(country)).length === 0;
    });
  }

  validateRequiredFields() {

    if (!this.generalPublishDateAndTime) {
      this.isFormValid = false;
      this.isGeneralPublishDateAndTimeAvailable = false;
      this.generalPublishDateAndTimeMessage = this.env.publish.error.movie.publishDateAndTimeNotAvailable;
    }

    this.schedules.forEach((schedule, index) => {

      if (!schedule.selectedCountryId) {
        this.isFormValid = false;
        this.isSpecialCountryAvailable.push(index);
      }

      if (!schedule.specialPublishDateAndTime) {
        this.isFormValid = false;
        this.isSpecialPublishDateAndTimeAvailable.push(index);
      }

      this.specialCountryMessage = this.env.publish.error.movie.countryNotAvailable;
      this.specialPublishDateAndTimeMessage = this.env.publish.error.movie.publishDateAndTimeNotAvailable;
    });
  }

  resetValidation(field, index?) {
    this.isFormValid = true;
    switch (field) {
      case this.GENERAL_PUBLISH_DATE_TIME: {
        this.isGeneralPublishDateAndTimeAvailable = true;
        break;
      }
      case this.SPECIAL_COUNTRY: {
        this.isSpecialCountryAvailable.splice(this.isSpecialCountryAvailable.indexOf(index), 1);
        break;
      }
      case this.SPECIAL_PUBLISH_DATE_TIME: {
        this.isSpecialPublishDateAndTimeAvailable
            .splice(this.isSpecialPublishDateAndTimeAvailable.indexOf(index), 1);
        break;
      }
    }
  }

  createRequestBody() {
    this.requestBody.country_publish_rule_rqs = this.schedules.map((schedule) => {
      return {
        country_id: schedule.selectedCountryId,
        publish_date_and_time: schedule.specialPublishDateAndTime
      };
    });
    this.requestBody.general_publish_date_and_time = this.generalPublishDateAndTime;
  }

  cancelSchedule() {

  }
}
