import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs';
import {PaymentService} from '../../../core/service/common_service/ http/payment/payment.service';
import {Service} from '../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-subscriber-detail-view',
  templateUrl: './subscriber-detail-view.component.html',
  styleUrls: ['./subscriber-detail-view.component.scss']
})
export class SubscriberDetailViewComponent implements OnInit {

  subscriberDetails: any;
  env = environment;
  loading = false;
  destroy$ = new Subject();
  selectedPage: number;
  tableData: any[] = [];
  userId;
  pageCount: any;

  constructor(private model: MatDialogRef<SubscriberDetailViewComponent>,
              private  paymentService: PaymentService,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private datePipe: DatePipe) {
    this.subscriberDetails = data.data;
    this.userId = this.subscriberDetails.id;
  }

  ngOnInit() {
    this.selectedPage = 1;
    this.loadBillingHistory();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  closeWindow() {
    this.model.close();
  }

  setPage(event) {
    this.selectedPage = event;
    this.loadBillingHistory();
  }


  loadBillingHistory() {
    this.paymentService.getPaymentsByUser(this.userId, this.selectedPage - 1, environment.tableProperties.size)
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

      this.pageCount = (response.user_payment_history_detail.length) / this.env.tableProperties.size * 10;

      this.tableData = response.user_payment_history_detail.map((detail) => {

        if (detail.transaction_date_and_time) {


          detail.dateOfPayment = this.datePipe.transform(detail.transaction_date_and_time, 'yyyy-MM-dd');
          detail.dateOfPayment = detail.transaction_date_and_time;
        } else {
          detail.dateOfPayment = '-';
        }

        if (detail.payment_status) {
          detail.status = detail.payment_status;
        }

        if (detail.transaction_amount) {
          detail.amount = 'LKR ' + detail.transaction_amount.toFixed(2);
        }

        if (detail.payment_method) {
          detail.paymentMethod = detail.payment_method;
        }
        return detail;
      });
    });
  }
}
