import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';


interface Event {
  type: string;
  payload?: any;
}
type EventCallback = (payload: any) => void;
@Injectable({
  providedIn: 'root'
})
export class EventService {

  public returnContentIdEvent: EventEmitter<any> = new EventEmitter<any>();
  public returnTvShowId: EventEmitter<any> = new EventEmitter<any>();
  private handler = new Subject<Event>();
  private idSender = new BehaviorSubject({id: 0});
  contentIdSender = this.idSender.asObservable();

  private isViewEditable = new BehaviorSubject({viewEnable: false});
  contentViewSender = this.isViewEditable.asObservable();
  constructor() { }

  /**
   * Broadcast the event
   * @param type type of event
   * @param payload payload
   */
  broadcast(type: string, payload = {}) {
    this.handler.next({ type, payload });
  }

  /**
   * Subscribe to event
   * @param type type of event
   * @param callback call back function
   */
  subscribe(type: string, callback: EventCallback): Subscription {
    return this.handler.pipe(
      filter(event => event.type === type)).pipe(
      map(event => event.payload))
      .subscribe(callback);
  }

  getContentIdEventEmitter(data) {
    this.returnContentIdEvent.emit(data);
  }

  getTvShowIdEventEmitter(data) {
    this.returnTvShowId.emit(data);
  }

  sendContentIdToTab(data) {
    this.idSender.next(data);
  }

  sendEditDetails(data) {
    this.isViewEditable.next(data);
  }
}
