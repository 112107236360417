import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentDetailMonetizationService {

  constructor(private http: HttpService) { }

  save(body) {
    return this.http.post(environment.serverurl + '/content-details/monetizations', body);
  }

  update(id, body) {
    return this.http.put<any>(environment.serverurl + '/content-details/monetizations/' + id, body);
  }

  findById(id, contentType) {
    return this.http.get<any>(environment.serverurl + '/content-details/' + id + '/monetization?content=' + contentType);
  }

}
