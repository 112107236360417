import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpService} from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class PlayListService {

  constructor(private http: HttpService) { }

  save(body) {
    return this.http.post<any>(environment.serverurl + '/video-channel/play-list', body);
  }
}
