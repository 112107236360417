import {Injectable} from '@angular/core';
import {AuthenticationService} from '../service/auth.service';
import {Router} from '@angular/router';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const currentUrl = this.router.url;
            if (err.status === 401 && err.error.accessCodeNotMatch) {
                this.authenticationService.logout();
                this.router.navigate(['/account/login'], {queryParams: {returnUrl: this.router.url, isExpired: 'true'}});
            } else if (err.status === 403 && err.error.isRefreshTokenExpires) {
                this.authenticationService.logout();
                this.router.navigate(['/account/login'], {queryParams: {returnUrl: this.router.url, isRefreshTokenExpires: 'true'}});
            }
            return throwError(err);
        }));
    }
}
