import {Component, OnInit, OnDestroy} from '@angular/core';
import {EventService} from '../../../../../core/service/event.service';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {environment} from '../../../../../../environments/environment';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TitleCasePipe} from '@angular/common';
import {isFormattedError} from '@angular/compiler';
import {consoleTestResultHandler} from 'tslint/lib/test';

@Component({
  selector: 'app-cue-point',
  templateUrl: './cue-point.component.html',
  styleUrls: ['./cue-point.component.scss']
})
export class CuePointComponent implements OnInit, OnDestroy {
  selectValue: any[] = [];
  getContentIdSubscription;
  movieId;
  requestBody: any = {};
  env = environment;
  cueTypes: any[] = [];
  cue = {};
  cues: any = [this.cue];
  cueInTimes: any[] = [];
  cueOutTimes: any[] = [];
  saveBtnDisabled = false;
  destroy$ = new Subject();
  emptyType: any[] = [];
  emptyStart: any[] = [];
  emptyEnd: any[] = [];
  startTimeValidate: any[] = [];
  endTimeValidate: any[] = [];
  isFormValid = false;
  startEndValidate: any[] = [];
  TYPE  = 1;
  START = 2;
  END = 3;
  typeValidationMessage = environment.validations.emptyTypeMessage;
  startValidationMessage = environment.validations.emptyStartMessage;
  endValidationMessage = environment.validations.emptyEndMessage;
  timeValidationMessage = environment.validations.cueTimeValidate;
  startTimeFormatValidationMessage = environment.validations.startTimeFormatValidationMessage;
  endTimeFormatValidationMessage = environment.validations.endTimeFormatValidationMessage;
  constructor(private  eventService: EventService,
              private titlePipe: TitleCasePipe, private service: CommonService,  private alert: Alerts) { }

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        this.movieId = response.id;
      }
    });
    this.cueTypes.push({id: 1, value: this.titlePipe.transform(environment.cueType.openingCredits.replace('-', ' ')
              .replace('_', ' '))},
        {id: 2, value: this.titlePipe.transform(environment.cueType.endCredits.replace('-', ' ')
              .replace('_', ' '))},
        {id: 3, value: this.titlePipe.transform(environment.cueType.advertisement.replace('-', ' ')
              .replace('_', ' '))},
        {id: 4, value: this.titlePipe.transform(environment.cueType.midBreak.replace('-', ' ')
              .replace('_', ' '))});

  }
  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
  }
  addAnotherCue() {
    this.cue = {};
    this.cues.push(this.cue);
  }

  deleteCue( index ) {
    this.cues.splice( index, 1);
  }

  creatRequestBody() {

    this.requestBody.content_type = environment.contentType.movie;
    this.requestBody.cue_points = [];
    let cueType;
    this.cues.forEach((cue) => {
      if (cue.cueType === 1) {
        cueType = environment.cueType.openingCredits;
      } else if (cue.cueType === 2) {
        cueType = environment.cueType.endCredits;
      } else if (cue.cueType === 3) {
        cueType = environment.cueType.advertisement;
      } else if (cue.cueType === 4) {
        cueType = environment.cueType.midBreak;
      }
      this.requestBody.cue_points.push({
        type : cueType,
        cue_in : cue.cueInTime,
        cue_out: cue.cueOutTime
        });
    });
  }

  validationRequiredFields() {

      let index = 0;

      for (const cue of this.cues) {
        if (!cue.cueType && this.emptyType.indexOf(index) === -1) {
          this.emptyType.push(index);
        }
        if (!cue.cueInTime && this.emptyStart.indexOf(index) === -1) {
          this.emptyStart.push(index);
        }
        if (!cue.cueOutTime && this.emptyEnd.indexOf(index) === -1) {
          this.emptyEnd.push(index);
        }
        if (cue.cueInTime && cue.cueOutTime && (cue.cueInTime >= cue.cueOutTime)
            && this.startEndValidate.indexOf(index) === -1) {
          this.startEndValidate.push(index);
        }

        if (cue.cueInTime) {
          const isTimeFormatValid = this.timeFormatValidation(cue.cueInTime);
          if (!isTimeFormatValid) {
            this.startTimeValidate.push(index);
          }
        }

        if (cue.cueOutTime) {
          const isTimeFormatValid = this.timeFormatValidation(cue.cueOutTime);
          if (!isTimeFormatValid) {
            this.endTimeValidate.push(index);
          }
        }
        ++index;
      }

      if (this.emptyType.length > 0 || this.emptyStart.length > 0 || this.emptyEnd.length > 0 ||
          this.startEndValidate.length > 0 || this.startTimeValidate.length > 0 || this.endTimeValidate.length > 0) {
        this.isFormValid = false;
      }

  }

  resetValidations(field, index) {
    this.isFormValid = true;

    switch (field) {
      case this.TYPE: {
        if (this.emptyType.indexOf(index) !== -1) {
          this.emptyType.splice(this.emptyType.indexOf(index), 1);
        }
        break;
      }
      case this.START: {
        if (this.emptyStart.indexOf(index) !== -1) {
          this.emptyStart.splice(this.emptyStart.indexOf(index), 1);
          this.startTimeValidate.splice(this.startTimeValidate.indexOf(index), 1);
        }
        break;
      }
      case this.END: {
        if (this.emptyEnd.indexOf(index) !== -1) {
          this.emptyEnd.splice(this.emptyEnd.indexOf(index), 1);
          this.endTimeValidate.splice(this.endTimeValidate.indexOf(index), 1);
        }
        break;
      }
      case this.TYPE:
      case this.START:
      case this.END: {
        if (this.startEndValidate.indexOf(index) !== -1) {
          this.startEndValidate.splice(this.startEndValidate.indexOf(index), 1);
        }
        break;
      }

    }
  }
 updateContent() {
   this.service.update(Service.MOVIE.CUE_POINT, this.movieId, this.requestBody)
       .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
     this.alert.successAlert(environment.alerts.success.movie.cuePoints.title,
         environment.alerts.success.movie.cuePoints.message);
     this.eventService.getContentIdEventEmitter({id: response.id});
     this.saveBtnDisabled = false;

   }, error => {
     this.alert.errorAlert( environment.alerts.error.movie.cuePoints.title,
         error.error.error);
     this.saveBtnDisabled = false;
   });
 }
  save() {
    this.endTimeValidate = [];
    this.startTimeValidate = [];
    this.emptyType = [];
    this.emptyStart = [];
    this.emptyEnd = [];
    this.startEndValidate = [];
    this.validationRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    this.creatRequestBody();
    this.saveBtnDisabled = true;
    if (!this.movieId) {
        new Promise((resolve, reject) => {
          this.service.save(Service.MOVIE.CONTENT, {type: environment.contentType.movie})
              .pipe(takeUntil(this.destroy$)).subscribe((response: any) =>{
              this.movieId = response.id;
              resolve(true);
          }, error => {
            reject(false);
            this.saveBtnDisabled = false;
          });
        }).then(value => {
          if(value) {
            this.updateContent();
          }

        });
      } else {
      this.updateContent();
    }
    }

    timeFormatValidation(time) {
      const regex = new RegExp('^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))');

      if ( regex.test(time)) {
        return true;
      } else {
        return  false;
      }
    }

  }
