import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {CommonService} from '../../../../core/service/common_service/common.service';
import {SliderGraphicService} from '../../../../core/service/common_service/ http/slider-graphic/slider-graphic.service';
import {Alerts} from '../../../../alert/Alert';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {Subject} from 'rxjs';
import {Service} from '../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {PublishScreenComponent} from '../publish-screen/publish-screen.component';
import {ScreenService} from '../../../../core/service/common_service/ http/screen/screen.service';
import {PreviewScreenComponent} from '../preview-screen/preview-screen.component';

@Component({
    selector: 'app-screen-detail-view',
    templateUrl: './screen-detail-view.component.html',
    styleUrls: ['./screen-detail-view.component.scss']
})
export class ScreenDetailViewComponent implements OnInit {
    countries = [];
    selectedRule = null;
    rules = [environment.contentRules.bl, environment.contentRules.wl];
    httpDestroy = new Subject();
    categoryData: any[] = [];
    realityCategoryData: any[] = [];
    categoryDataColone: any[] = [];
    realityCategoryDataColone: any[] = [];
    maturities = environment.maturityRating;
    selectedMaturity = null;
    file: any = {};
    sliders: any[] = [];
    realitySliders: any[] = [];
    categories: any[] = [];
    realityCategories: any[] = [];
    slidersData: any[] = [];
    realitySlidersData: any[] = [];
    slidersDataClone: any[] = [];
    realitySlidersDataClone: any[] = [];
    screenName: string;
    isScreenNameValidate = true;
    screenNameValidateMessage;
    selectedCountries: any[] = [];
    isSelectedCountriesValidate = true;
    selectedCountryValidateMessage;
    isSelectedRulesValidate = true;
    ruleValidateMessage;
    isSelectedMaturity = true;
    maturityValidateMessage;
    destroy$ = new Subject();
    requestBody: any = {};
    SCREEN_NAME = 1;
    MATURITY = 2;
    RULES = 3;
    COUNTRY = 4;
    SLIDER = 5;
    REALITYSLIDER = 8;
    CATEGORY = 6;
    REALITYCATEGORY = 9;
    isFormValidated = false;
    screenId;
    emptySliders = [];
    emptyRealitySliders = [];
    emptyCategory = [];
    emptyRealityCategory = [];
    selectedScreenData: any;
    isFieldDisable = true;
    isClickFieldDisable = true;
    saveBtnDisabled;
    editBtnDisabled = false;
    isSaveDisable = true;
    publishedStatus;
    buttonStatus;
    publishBtnDisabled;
    selectedSliderIds = [];
    selectedRealitySliderIds = [];
    selectedCategoryIds = [];
    selectedRealityCategoryIds = [];
    currentSlidersPage;
    currentRealitySlidersPage;
    totalSliders = 0;
    totalRealitySliders = 0;
    currentCategoriesPage;
    currentRealityCategoriesPage;
    totalCategories = 0;
    totalRealityCategories = 0;
    deletableCategories = [];
    deletableRealityCategories = [];
    realityCategorySubject: Subject<any> = new Subject<any>();
    realitySliderSubject: Subject<any> = new Subject<any>();
    constructor(
        private model: MatDialogRef<ScreenDetailViewComponent>,
        private service: CommonService,
        private sliderGraphicService: SliderGraphicService,
        private alert: Alerts,
        private screenService: ScreenService,
        private matModel: MatDialog,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.selectedScreenData = data;
        this.publishedStatus = data.publishStatus;
    }

    ngOnInit() {
        this.currentSlidersPage = environment.page;
        this.currentCategoriesPage = environment.page;
        this.disableFields();
        if (this.publishedStatus === environment.masterDataStatus.published) {
            this.buttonStatus = environment.masterDataStatus.unpublished;
        }
        if (this.publishedStatus === environment.masterDataStatus.unpublished) {
            this.buttonStatus = environment.masterDataStatus.published;
        }
        // TODO Stop scheduling function not implemented.
        if (this.publishedStatus === environment.masterDataStatus.scheduled) {
            this.buttonStatus = 'STOP SCHEDULING';
        }

        this.loadAllCountries();
        this.loadAllSliders();
        this.getAllCategories();
        this.getAllRealityCategories();
        this.loadAllRealitySliders();
        this.loadData();
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.closeWindow();
    }

    closeWindow() {
        this.model.close();
    }

    loadAllCountries() {
        this.service.findAll(Service.COUNTRY).pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
            this.countries = response;
        });
    }

    onDropSliders(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.sliders, event.previousIndex, event.currentIndex);
    }

    onDropRealitySliders(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.realitySliders, event.previousIndex, event.currentIndex);
    }

    onDropCategory(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    }

    onDropRealityCategory(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.realityCategories, event.previousIndex, event.currentIndex);
    }


    editForm() {

        // Publish Tab Click
        if (this.publishedStatus === environment.masterDataStatus.published) {
            if (this.buttonStatus === environment.masterDataStatus.unpublished) {
                if (this.publishedStatus === environment.masterDataStatus.unpublished) {
                    this.enableFields();
                    return;
                }
                if (this.buttonStatus === environment.masterDataStatus.unpublished) {
                    this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
                        environment.alerts.success.movie.unPublish.confirmButton).then(value => {
                        if (value.isConfirmed) {
                            this.service.unPublish(Service.SCREEN, this.selectedScreenData.sliderGraphicID,
                                {country_id: 0, un_publish_general_rule: true})
                                .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
                                this.enableFields();
                                this.buttonStatus = environment.masterDataStatus.published;
                                this.editBtnDisabled = true;
                                this.publishBtnDisabled = true;
                                this.isSaveDisable = false;
                            });
                        }
                    });
                }

            } else {
                if (this.publishedStatus === environment.masterDataStatus.published) {
                    this.enableFields();
                    this.publishBtnDisabled = true;
                    this.isSaveDisable = false;
                    return;
                }
            }
        }

        // Unpublished Tab Click
        if (this.publishedStatus === environment.masterDataStatus.unpublished) {
            if (this.buttonStatus === environment.masterDataStatus.published) {
                if (this.publishedStatus === environment.masterDataStatus.unpublished) {
                    this.enableFields();
                    this.publishBtnDisabled = true;
                    this.isSaveDisable = false;
                    return;
                }

                if (this.publishedStatus === environment.masterDataStatus.published) {
                    this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
                        environment.alerts.success.movie.unPublish.confirmButton).then(value => {

                        if (value.isConfirmed) {
                            this.service.unPublish(Service.SCREEN, this.selectedScreenData.sliderGraphicID,
                                {country_id: 0, un_publish_general_rule: true})
                                .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
                                this.enableFields();
                                this.buttonStatus = environment.masterDataStatus.published;
                                this.editBtnDisabled = true;
                                this.publishBtnDisabled = true;
                                this.isSaveDisable = false;
                            });
                        }
                    });
                }
            } else {
                if (this.buttonStatus === environment.masterDataStatus.unpublished) {
                    this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
                        environment.alerts.success.movie.unPublish.confirmButton).then(value => {
                        if (value.isConfirmed) {
                            this.service.unPublish(Service.SCREEN, this.selectedScreenData.sliderGraphicID,
                                {country_id: 0, un_publish_general_rule: true})
                                .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
                                this.enableFields();
                                this.buttonStatus = environment.masterDataStatus.published;
                                this.editBtnDisabled = true;
                                this.publishBtnDisabled = true;
                                this.isSaveDisable = false;
                            });
                        }
                    });
                }
            }
        }
    }


    unPublishedScreen() {
        if (this.buttonStatus === environment.masterDataStatus.unpublished) {
            this.service.unPublish(Service.SCREEN, this.selectedScreenData.sliderGraphicID, {
                country_id: 0,
                un_publish_general_rule: true
            }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
                this.buttonStatus = environment.masterDataStatus.published;
                this.alert.successAlert(environment.publish.success.screen.unpublish, environment.publish.success.screen.unpublishMessage);
            });
        } else {

            let matDialogRef = this.matModel.open(PublishScreenComponent, {
                disableClose: true,
                autoFocus: false,
                maxWidth: '100vw',
                maxHeight: '100vh',
                height: '90%',
                width: '70%',
                data: {
                    id: this.selectedScreenData.sliderGraphicID,
                    title: this.screenName
                }
            });

            matDialogRef.afterClosed().subscribe(value => {
                if (value.isPublish) {
                    this.closeWindow();
                }
            });
        }
    }


    changeCategory(event, category) {
        console.log(category);
        if (event) {
            this.categoryData.splice(category.id, 1);
        } else {

        }
    }

    changeRealityCategory(event, category) {
        if (event) {
            this.realityCategoryData.splice(category.id, 1);
        } else {

        }
    }

    addAnotherSliderGraphic() {
        this.file = {};
        this.sliders.push(this.file);
    }

    addAnotherRealitySliderGraphic() {
        this.file = {};
        this.realitySliders.push(this.file);
    }

    deleteSlider(index, slider) {
        this.sliders.splice(index, 1);
        this.slidersData.push(this.slidersDataClone[this.slidersDataClone.findIndex(value => value.id === slider.id)]);
        this.removeSlider(slider.id, index);
    }

    deleteRealitySlider(index, slider) {
        this.realitySliders.splice(index, 1);
        this.realitySlidersData.push(this.realitySlidersDataClone[this.realitySlidersDataClone.findIndex(value => value.id === slider.id)]);
        this.removeRealitySlider(slider.id, index);
    }

    deleteCategory(index, category) {
        this.categories.splice(index, 1);
        this.categoryData.push(this.categoryDataColone[this.categoryDataColone.findIndex(value => value.id === category.id)]);
        this.removeCategory(category.id, index);
    }

    deleteRealityCategory(index, category) {
        this.realityCategories.splice(index, 1);
        this.realityCategoryData.push(this.realityCategoryDataColone[this.realityCategoryDataColone.findIndex(value => value.id === category.id)]);
        this.removeRealityCategory(category.id, index);
    }

    addAnotherCategory() {
        this.file = {};
        this.categories.push(this.file);
    }

    addAnotherRealityCategory() {
        this.file = {};
        this.realityCategories.push(this.file);
    }

    validatingRequiredFields() {

        if (!this.screenName) {
            this.isScreenNameValidate = false;
            this.screenNameValidateMessage = environment.validations.screenNameValidateMessage;
        }

        if (this.selectedCountries.length > 0 && this.selectedRule === null) {
            this.isSelectedRulesValidate = false;
            this.ruleValidateMessage = environment.validations.screenRulesValidate;
        } else {
            this.isSelectedRulesValidate = true;
        }

        if (this.selectedRule !== null && this.selectedCountries.length === 0) {
            this.isSelectedCountriesValidate = false;
            this.selectedCountryValidateMessage = environment.validations.screenCountryValidate;
        } else {
            this.isSelectedCountriesValidate = true;
        }

        if (this.selectedMaturity === null) {
            this.isSelectedMaturity = false;
            this.maturityValidateMessage = environment.validations.screenMaturityValudate;
        } else {
            this.isSelectedMaturity = true;
        }

        this.sliders.forEach((slider, index) => {
            if (!slider.id) {
                this.emptySliders.push(index);
            }
        });

        this.categories.forEach((c, index) => {
            if (!c.id) {
                this.emptyCategory.push(index);
            }
        });
    }

    resetValidation(field, index) {
        switch (field) {
            case this.SCREEN_NAME:
                this.isScreenNameValidate = true;
                break;
            case this.RULES:
                this.isSelectedRulesValidate = true;
                break;
            case this.MATURITY:
                this.isSelectedMaturity = true;
                break;
            case this.COUNTRY:
                this.isSelectedCountriesValidate = true;
                break;
            case this.CATEGORY:
                this.emptyCategory = this.emptyCategory.filter(c => c !== index);
                break;
            case this.REALITYCATEGORY:
                this.emptyRealityCategory = this.emptyRealityCategory.filter(c => c !== index);
                break;
            case this.SLIDER:
                this.emptySliders = this.emptySliders.filter(s => s !== index);
                break;
            case this.REALITYSLIDER:
                this.emptyRealitySliders = this.emptyRealitySliders.filter(s => s !== index);
                break;
        }
    }

    loadAllSliders() {
      this.service.findAll(Service.SLIDER_GRAPHIC, {page: this.currentSlidersPage,
        size: environment.size,
        status: environment.contentStatus.published})
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.totalSliders = response.total;
        const slidersResponse = response.slider_graphics.map((slider, index) => {
          slider.name = '[' + slider.id + '] ' + slider.name;
          return slider;
        });

        this.slidersData = [...this.slidersData, ...slidersResponse];
        this.slidersData = this.slidersData.filter(value => !this.selectedSliderIds.includes(value.id));
        this.slidersDataClone = [...this.slidersDataClone, ...slidersResponse];
      });
    }

    loadAllRealitySliders() {
        this.service.findAll(Service.SLIDER_GRAPHIC_REALITY, {page: this.currentSlidersPage,
            size: environment.size})
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            this.totalSliders = response.totalElements;
            const slidersResponse = response.sliderGraphics;

            this.realitySlidersData = [...this.realitySlidersData, ...slidersResponse];
            this.realitySlidersData = this.realitySlidersData.filter(value => !this.selectedRealitySliderIds.includes(value.id));
            this.realitySlidersDataClone = [...this.realitySlidersDataClone, ...slidersResponse];
            this.realitySliderSubject.next(this.realitySlidersData);
        });
    }

    getAllCategories() {
        this.service.findAll(Service.CATEGORY,  {status: environment.contentStatus.published,
          page: this.currentCategoriesPage, size: environment.size})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.totalCategories = response.total;
          const categoryResponse = response.categories.map((category, index) => {
            category.name = '[' + category.id + '] ' + category.name;
            return category;
          });
          this.categoryData = [...this.categoryData, ...categoryResponse];
          this.categoryData = this.categoryData.filter(value => !this.selectedCategoryIds.includes(value.id));
          this.categoryDataColone = [...this.categoryDataColone, ...categoryResponse];
        });
    }

    getAllRealityCategories() {
        this.service.findAll(Service.REALITY_CATEGORY, {page: this.currentCategoriesPage, size: environment.size})
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
            this.totalRealityCategories = response.totalElements;
            const categoryResponse = response.categories;
            this.realityCategoryData = [...this.realityCategoryData, ...categoryResponse];
            this.realityCategoryDataColone = [...this.realityCategoryDataColone, ...categoryResponse];
            this.realityCategorySubject.next(this.realityCategoryData);
        });
    }

    createRequestBody() {
  /*      const categoryID: number[] = [];
        for (const category of this.categories) {
            categoryID.push(category.id);
        }*/
        this.requestBody.category_ids =  this.categories.map(value => value.id);
        this.requestBody.country_ids = this.selectedCountries;
        this.requestBody.maturity_rating = this.selectedMaturity.toUpperCase();
        this.requestBody.name = this.screenName;

        let ruleType;
        if (this.selectedRule === environment.contentRules.bl) {
            ruleType = environment.contentRules.upperCaseBL;
        }

        if (this.selectedRule === environment.contentRules.wl) {
            ruleType = environment.contentRules.upperCaseWL;
        }

        this.requestBody.rule_type = ruleType;
        this.requestBody.screen_type = 'standard';
        this.requestBody.slider_graphic_ids = this.sliders.map(value => value.id);
        this.requestBody.reality_slider_graphic_ids = this.realitySliders.map(value => value.id);
        this.requestBody.reality_category_ids = this.realityCategories.map(value => value.id);
    }

    isFormValidate() {
        if (this.isSelectedCountriesValidate && this.isSelectedRulesValidate && this.isSelectedMaturity && this.isScreenNameValidate
            && this.emptySliders.length === 0 && this.emptyCategory.length === 0) {
            this.isFormValidated = true;
        } else {
            this.isFormValidated = false;
        }
    }


    update() {
        this.emptySliders = [];
        this.emptyCategory = [];
        this.validatingRequiredFields();
        this.isFormValidate();
        if (this.isFormValidated) {
            this.createRequestBody();

            this.screenService.update(this.selectedScreenData.sliderGraphicID, this.requestBody)
                .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
                this.publishBtnDisabled = false;
                this.editBtnDisabled = false;
                this.isSaveDisable = true;
                this.disableFields();
                this.alert.successAlert(environment.alerts.success.screen.updateTitle, environment.alerts.success.screen.updateMessage);
            }, error => {
                  if (environment.alerts.error.screen.category.errorCode !== error.error.code) {
                    this.alert.errorAlert(environment.alerts.error.screen.title,
                      environment.alerts.error.screen.message);
                  } else {
                    this.deletableCategories = error.error.ids;
                    this.alert.errorAlert(environment.alerts.error.screen.category.title,
                      environment.alerts.error.screen.category.message);
                  }
            });
        }
    }

    onChangeOnCategory(event, index) {
        if (event) {
            this.categories[index].name = this.categoryDataColone.filter(value => value.id === event)[0].name;
            this.categoryData = this.categoryData.filter(value => value.id !== event);
            return;
        }
        this.categoryData = this.categoryDataColone.filter(value =>
            (this.categories.findIndex(value1 => value1.id === value.id) === -1));
    }

    onChangeOnRealityCategory(event, index) {
        if (event) {
            this.realityCategories[index] = this.realityCategoryDataColone.filter(value => value.id === event)[0];
            this.realityCategoryData = this.realityCategoryData.filter(value => value.id !== event);
            return;
        }
        this.realityCategoryData = this.realityCategoryDataColone.filter(value =>
            (this.realityCategories.findIndex(value1 => value1.id === value.id) === -1));
    }

    onChangeOnSliderGraphic(event, index) {
        if (event) {
            this.sliders[index].name = this.slidersDataClone.filter(value => value.id === event)[0].name;
            this.slidersData = this.slidersData.filter(value => value.id !== event);
            return;
        }
        this.slidersData = this.slidersDataClone.filter(value =>
            (this.categories.findIndex(value1 => value1.id === value.id) === -1));
    }

    onChangeOnRealitySliderGraphic(event, index) {
        if (event) {
            this.realitySliders[index] = this.realitySlidersDataClone.filter(value => value.id === event)[0];
            this.realitySlidersData = this.realitySlidersData.filter(value => value.id !== event);
            return;
        }
        this.realitySlidersData = this.realitySlidersDataClone.filter(value =>
            (this.realityCategories.findIndex(value1 => value1.id === value.id) === -1));
        console.log(this.realitySliders);
    }


    loadData() {
        this.selectedScreenData.data.screen_slider_graphics.sort((a, b) => a.sequence_no - b.sequence_no);
        this.selectedScreenData.data.screen_categories.sort((a, b) => a.sequence_no - b.sequence_no);
        this.screenName = this.selectedScreenData.data.name;
        this.selectedMaturity = this.selectedScreenData.data.maturity_rating;
        this.selectedRule = this.selectedScreenData.data.rule_type;
        this.selectedCountries = this.selectedScreenData.data.country_ids;
        for (const c of this.selectedScreenData.data.screen_categories) {
            c.name = '[' + c.id + '] ' + c.name;
            this.selectedCategoryIds.push(c.id);
            this.categories.push(c);
        }
        for (const c of this.selectedScreenData.data.reality_category_ids) {
            this.realityCategorySubject.subscribe(val => {
                c.title = this.realityCategoryData.filter(value => value.id = c.id)[0].title;
                this.selectedRealityCategoryIds.push(c.id);
                this.realityCategories.push(c);
            });
        }
        for (const s of this.selectedScreenData.data.screen_slider_graphics) {
            s.name =  '[' + s.id + '] ' + s.name;
            this.selectedSliderIds.push(s.id);
            this.sliders.push(s);
        }
        for (const s of this.selectedScreenData.data.reality_slider_graphic_ids) {
            this.realitySliderSubject.subscribe(val => {
                s.title = this.realitySlidersData.filter(value => value.id = s.id)[0].title;
                this.selectedRealitySliderIds.push(s.id);
                this.realitySliders.push(s);
            });
        }
    }

    enableFields() {
        this.isFieldDisable = false;
        this.isClickFieldDisable = false;
    }

    disableFields() {
        this.isFieldDisable = true;
        this.isClickFieldDisable = true;
    }

    preview() {
        const matDialogRef = this.matModel.open(PreviewScreenComponent, {
            disableClose: true,
            autoFocus: false,
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '80%',
            data: {
                screenId: this.selectedScreenData.sliderGraphicID
            }
        });
    }

    sliderLabelClick(sliderId, index) {
       this.removeSlider(sliderId, index);
    }

    realitySliderLabelClick(sliderId, index) {
        this.removeRealitySlider(sliderId, index);
    }

    categoryLabelClick(categoryId, index) {
       this.removeCategory(categoryId, index);
    }

    realityCategoryLabelClick(categoryId, index) {
        this.removeRealityCategory(categoryId, index);
    }

    removeSlider(sliderId, index) {
        console.log('sliderId: ', sliderId);
        console.log('index: ', index);
        this.selectedSliderIds.splice(this.selectedSliderIds.indexOf(sliderId), 1);
        this.slidersData = this.slidersDataClone.filter(value => !this.selectedSliderIds.includes(value.id));
        // this.sliders[index].id = null;
    }

    removeRealitySlider(sliderId, index) {
        this.selectedRealitySliderIds.splice(this.selectedRealitySliderIds.indexOf(sliderId), 1);
        this.realitySlidersData = this.realitySlidersDataClone.filter(value => !this.selectedRealitySliderIds.includes(value.id));
    }

    removeCategory(categoryId, index) {
        console.log('categoryId: ', categoryId);
        console.log('index: ', index);

        this.selectedCategoryIds.splice(this.selectedCategoryIds.indexOf(categoryId), 1);
        this.categoryData = this.categoryDataColone.filter(value => !this.selectedCategoryIds.includes(value.id));
        // this.categories[index].id = null;
    }

    removeRealityCategory(categoryId, index) {
        this.selectedRealityCategoryIds.splice(this.selectedRealityCategoryIds.indexOf(categoryId), 1);
        this.realityCategoryData = this.realityCategoryDataColone.filter(value => !this.selectedRealityCategoryIds.includes(value.id));
    }

    slidersOnScroll() {
      ++this.currentSlidersPage;
      if (this.slidersDataClone.length < this.totalSliders) {
        this.loadAllSliders();
      }
    }

    realitySlidersOnScroll() {
        ++this.currentRealitySlidersPage;
        if (this.realitySlidersDataClone.length < this.totalRealitySliders) {
            this.loadAllRealitySliders();
        }
    }

    categoriesOnScroll() {
      ++this.currentCategoriesPage;
      if (this.categoryDataColone.length < this.totalCategories) {
        this.getAllCategories();
      }
    }

    realityCategoriesOnScroll() {
        ++this.currentRealityCategoriesPage;
        if (this.realityCategoryDataColone.length < this.totalRealityCategories) {
            this.getAllRealityCategories();
        }
    }
}
