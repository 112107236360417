import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CommonServiceService} from '../../../../../core/service/common_service/ http/common-service.service';
import {Alerts} from '../../../../../alert/Alert';
import {EventService} from '../../../../../core/service/event.service';
import {PublishLiveChannelModelComponent} from '../publish-live-channel-model/publish-live-channel-model.component';

@Component({
  selector: 'app-live-channel-model',
  templateUrl: './live-channel-model.component.html',
  styleUrls: ['./live-channel-model.component.scss']
})
export class LiveChannelModelComponent implements OnInit, OnDestroy {

  env = environment;
  destroy$ = new Subject();
  liveChannelId;
  title;
  getContentIdSubscription;
  constructor(private model: MatDialogRef<LiveChannelModelComponent>, private matModel: MatDialog,
              private commonServiceService: CommonServiceService, private alert: Alerts,
              private eventService: EventService) { }

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        if (response.id)
          this.liveChannelId = response.id;

        if (response.title)
          this.title = response.title;
      }
    });

  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeWindow();
  }

  ngOnDestroy(): void {

    this.destroy$.next();
    this.destroy$.complete();
  }

  closeWindow() {
    this.model.close();
  }

  publishLiveChannel() {
    if (!this.liveChannelId) {
      return;
    }
    this.commonServiceService.findAllWizardStatus(this.liveChannelId, environment.contentType.liveChannel)
        .subscribe(response => {
          this.matModel.open(PublishLiveChannelModelComponent, {
            disableClose: true,
            autoFocus: false,
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '90%',
            width: '90%',
            data: {
              id: this.liveChannelId,
              title: this.title.english
            }
          });
        }, error => {
          this.alert.errorAlert(this.env.publish.error.liveChannel.title, this.env.publish.error.liveChannel.message);
        });
  }

  getEventData() {
    this.eventService.sendContentIdToTab({id: this.liveChannelId});
  }
}
