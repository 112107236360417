import { Injectable } from '@angular/core';
import {HttpService} from '../../http.service';
import {environment} from '../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveChannelService {

  constructor(private http: HttpService) { }

  findAll(options: any) {
    return this.http.get<any>(environment.serverurl + '/live-channels/?' + '&page='
      + options.page + '&size=' + options.size);
  }

  findAllByStatus(options: any) {
    return this.http.get<any>(environment.serverurl + '/live-channels/status/?' + options.status + '&page='
        + options.page + '&size=' + options.size + '&maturity-rating=' + options.maturityRating);
  }

  findAllWithProgrammes(id, date) {
    return this.http.get<any>(environment.serverurl + '/live-channels/' + id + '/programmes?' + 'date=' + date);
  }

  findChannelById(id) {
    return this.http.get<any>(environment.serverurl + '/live-channels/meta-data/' + id);
  }

  update(body) {
    return this.http.put(environment.serverurl + '/live-channels/meta-data', body);
  }
}

