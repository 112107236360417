import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

/**
 * Import shaka player library
 */
// @ts-ignore
import * as shaka from 'shaka-player';

/**
 * Import shaka player ui library
 */

// @ts-ignore
import shakaUi from 'shaka-player/dist/shaka-player.ui';

/**
 * Import Plyr shaka library
 */

import * as Plyr from '@baladshow/plyr-shaka';
import {environment} from '../../../../environments/environment';
import {LoggerService} from '../../../core/logs/logger.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {

  @ViewChild('videoElement', null ) videoElementRef: ElementRef;

  playerRef: HTMLVideoElement;

  @Input()
  videoDetails: any = {};

  @Output() afterInitPlayer = new EventEmitter();
  constructor( private logger: LoggerService ) { }

  ngOnInit() { this.initShakaLibrary();  }

  /**
   * Initialize the shaka library
   */
  initShakaLibrary() {
    /**
     * Install built-in polyfills to patch browser incompatibilities.
     */
    shaka.polyfill.installAll();

    /**
     * Check to see if the browser supports the basic APIs Shaka needs.
     */
    if (  shaka.Player.isBrowserSupported() ) { this.initShakaPlayer() ; }
  }


  /**
   * Initialize the shaka player
   */
  initShakaPlayer() {

    this.playerRef = this.videoElementRef.nativeElement;

    const shakaPlayer =  new shakaUi.Player( this.playerRef );

    /**
     * Configure the DRM if DRM is enabled
     */
    if ( this.videoDetails.licenseServer ) {
      let licenseServer = {};
      /**
       * Assign the license server
       */
      if ( this.videoDetails.videoFormat === environment.videoFormats.dash ) {

        licenseServer = {
          'com.widevine.alpha' : this.videoDetails.licenseServer
        };
      } else {
        // TODO need to implement when HLS license server is enabled
      }

      /**
       * configure the license server
       */
      shakaPlayer.configure({
        drm: {
          servers: licenseServer
        }
      });
    }
    /**
     * Add error event listener
     */
    shakaPlayer.addEventListener( 'error', this.onError );

    /**
     * Load the manifest URL and configure the plyr with shaka player
     */
    shakaPlayer.load( this.videoDetails.manifestUri ).then( () => {
      /**
       * Get the video qualities
       */
      const qualities = shakaPlayer.getVariantTracks();
      const qualityArray = this.getVideoQualityAsArray(shakaPlayer.getVariantTracks());

      /**
       * Get the audio  languages
       */
      const audioLanguages = shakaPlayer.getAudioLanguagesAndRoles();
      const audioArray = this.getAudioLanguages( audioLanguages );

      /**
       * Disable the ABR from shaka player
       */
      shakaPlayer.configure( { abr: { enabled: false } } );

      /**
       * Load subtitles to the player
       */
      if ( this.videoDetails.subtitles ) {
        this.videoDetails.subtitles.forEach(( subtitle ) => {

          const track = document.createElement('track');

          Object.assign( track , {
            label: subtitle.label,
            srclang: subtitle.language,
            src: subtitle.src
          });
          this.playerRef.appendChild( track );
        });
      }

      /**
       * Configure the Plry library
       */
      const plyr = new Plyr( this.playerRef, {
        qualities : qualityArray,
        mpdFiles: qualities,
        quality: { default: 'Auto', options: qualityArray },
        multipleQualities: true,
        settings: ['quality', 'audio', 'speed', 'captions'],
        audio: { options: { audioArray, audioLanguages, selected: audioArray[ 0 ] } },
        shakaInstance: shakaPlayer,
        autoplay: this.videoDetails.autoplay,
        previewThumbnails: {
          enabled: this.videoDetails.previewThumbnailUrl ? true : false,
          src: this.videoDetails.previewThumbnailUrl
        },
        ads: {
          enabled: this.videoDetails.tagUrl ? true : false,
          tagUrl: this.videoDetails.tagUrl
        },
        ratio: '16.9',
        listeners: {
          /**
           * This listeners will execute then quality change button click. In here we are change the quality in side this methos
           */
          quality( event ) {
            if ( event.target.innerText.split('p') !== 'Auto' ) {
              const selectedQuality = qualities.indexOf( + event.target.innerText.split('p')[0] );
              setTimeout(() => {
                shakaPlayer.selectVariantTrack( selectedQuality, true );
                return true;
              }, 1500);

              return true;
            }
            return true;
          }
        }
      });
      this.afterInitPlayer.emit({ initialized : true } );
    });
  }

  onError( event ) {
    this.logger.error( ' Error code ' + event.details.error.code + 'Error Object '  + event.details.error );
  }

  /**
   * convert video qualities to array
   */
  getVideoQualityAsArray( qualities ) {

    const qArray = [];

    qualities.forEach(( quality ) => {
      qArray.push( quality.height);
    });

    qArray.push('Auto');

    return qArray;
  }

  getAudioLanguages( audios ) {
    const aArray = [];

    audios.forEach( ( audio ) => {
      aArray.push( audio.language );
    });

    return aArray;
  }
}
