import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {environment} from '../../../../environments/environment';
import Table = WebAssembly.Table;
import {Observable} from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
  @Input()
  tableColumns: any [] = [];
  @Input()
  selectedTab;
  @Input()
  filterData: any [] = [];
  @Input()
  tableData: any [] = [];
  @Input()
  isDraggable: boolean;
  @Input()
  isSelectable: boolean;
  @Input()
  isCheckboxSelect: boolean;
  @Input()
  isDeletable: boolean;
  @Input()
  isButton: boolean;
  @Input()
  isScrollable: boolean;
  @Input()
  loading = false;
  @Input()
  isEnableSearch;
  @Input()
  searchTerm;
  @Input()
  pageCount;
  @Input()
  deleteSubject: Observable<any>;
  @Input()
  isFilterEnabled = false;
  @Input()
  isRefreshFilterValues = false;
  @Output()
  detailViewOpenEvent = new EventEmitter<any>();

  @Output()
  pageChangeEventEmitter = new EventEmitter<any>();

  @Output()
  selectedRowsEventEmitter = new EventEmitter<any>();

  @Output()
  deleteMultipleEventEmitter = new EventEmitter<any>();

  @Output()
  buttonClickEventEmitter = new EventEmitter<any>();

  @Output()
  searchTermEventEmitter = new EventEmitter<any>();

  @Output()
  scrollEventEmitter = new EventEmitter<any>();

  env = environment;
  @Output()
  filterEvent = new EventEmitter<any>();
  @ViewChild('table', null)  tableRef: Table ;

  constructor() { }
  selectedValue;
  page = 1;
  pageSize = 10;
  selectedRowData: any[] = [];
  selectedPage = 1;
  selectedIndexes: any[] = [];
  isButtonClicked = false;
  filteredData: any[] = [];
  allSelected;
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.isFilterEnabled
      && (changes.selectedTab)
      && (changes.selectedTab.previousValue)
      && (changes.selectedTab.previousValue !== changes.selectedTab.firstChange)) {
      this.tableColumns.forEach(value => {
        if (value.selectedValue) {
          value.selectedValue = null;
        }
      });
    }
    if ((changes.tableData) &&
      (changes.tableData.previousValue) &&
      (changes.tableData.previousValue !== changes.tableData.firstChange)) {
      if (this.isSelectable !== null && this.isSelectable) {
        this.selectedIndexes = [];
        this.selectedIndexes = [...this.tableData.map((value, index) => value.isSelected ? index : -1).filter(value => value !== -1)];
      }
      if (this.isCheckboxSelect !== null && this.isCheckboxSelect) {
        this.selectedRowData = [];
        this.selectedIndexes = [];
        this.allSelected = false;
      }
    }
    if (this.isFilterEnabled
      && (changes.filterData)
      && (changes.filterData.previousValue)
      && (changes.filterData.previousValue !== changes.filterData.firstChange)) {
      this.mapColumnData();
    }
    if (this.isFilterEnabled
        && (changes.isRefreshFilterValues)
        && (changes.isRefreshFilterValues.previousValue)
        && (changes.isRefreshFilterValues.previousValue !== changes.isRefreshFilterValues.firstChange)) {
        this.refreshFilterData();
    }
  }

  pageChange( page ) {
    this.pageChangeEventEmitter.emit(page);
    this.selectedPage = page;
  }

  changeSelectedIds(event, data, index) {
    if (!this.selectedIndexes.includes(index)) {
      this.selectedRowData.push(data);
      this.selectedIndexes.push(index);
    } else {
      data.isSelected = false;
      this.selectedRowData.splice(this.selectedRowData.indexOf(data), 1);
      this.selectedIndexes.splice(this.selectedIndexes.indexOf(index), 1);
    }
    if (this.isCheckboxSelect) {
      this.deleteMultipleEventEmitter.emit(this.selectedRowData);
    } else {
      this.selectedRowsEventEmitter.emit(this.selectedRowData);
    }

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tableData, event.previousIndex, event.currentIndex);
    this.selectedRowsEventEmitter.emit(this.tableData);
  }

  openDetailView( data ) {
    if (!this.isButtonClicked) {
      this.detailViewOpenEvent.emit({
        selectedData: data,
        allData: this.tableData,
        isError: ((data.error !== null) && (data.error === true)) ? data.error : false
      });
    }
  }
  buttonClickView(data) {
    this.isButtonClicked = true;
    this.buttonClickEventEmitter.emit({
      selectedData: data,
      allData : this.tableData
    });
  }
  scroll(event) {
    if (!this.loading) {
      if ((event.target.offsetHeight + event.target.scrollTop) === event.target.scrollHeight) {
        this.pageChange(this.selectedPage + 1);
      }
    }
  }

  deleteContent(data) {
    if (!this.allSelected) {
      if (this.deleteSubject != null) {
        this.deleteSubject.subscribe(value => {
          if (value) {
            this.tableData.splice(this.tableData.indexOf(data), 1);
          }
        });
      }
      this.selectedRowsEventEmitter.emit({
        selectedData: data,
        allData: this.tableData
      });
    }
  }
  filterTable( data ) {
    this.searchTermEventEmitter.emit({
      searchTerm: data
    });
  }
  onSelection(col, selectedValue) {
    const index = this.filteredData.findIndex(filter => filter.field === col);
    if (index === -1) {
      this.filteredData.push({
        field: col,
        value: selectedValue
      });
    } else {
      this.filteredData[index].value = selectedValue;
    }
    this.filterEvent.emit(this.filteredData);
  }

  mapColumnData() {
    this.tableColumns.forEach(value => {
      const index = this.filterData.findIndex(val => val.field === value.field);
      if (index !== -1) {
        value.data = this.filterData[index].data;
      }
    });
  }

  dateChange(col, event) {
    const index = this.filteredData.findIndex(filter => filter.field === col);
    if (index === -1) {
      this.filteredData.push({
        field: col,
        value: event.dateString
      });
    } else {
      this.filteredData[index].value = event.dateString;
    }
    this.filterEvent.emit(this.filteredData);
  }

  dropDownScroll(col) {
    this.scrollEventEmitter.emit(col);
  }

  refreshFilterData() {
    this.tableColumns = this.tableColumns.map(tableColumn => {
      tableColumn.selectedValue = null;
      return tableColumn;
    });
  }

  changeHeaderCheckbox(event, data) {
    if (event.target.checked) {
      this.allSelected = true;
      for (let i = 0 ; i < data.length ; i++) {
        this.selectedRowData.push(data[i]);
        this.selectedIndexes.push(i);
      }
      this.deleteMultipleEventEmitter.emit(this.selectedRowData);
      // data.forEach((value, index) => {
      //   this.selectedRowData.push(value);
      //   this.selectedIndexes.push(index);
      // });
    } else {
      this.allSelected = false;
      this.selectedRowData = [];
      this.selectedIndexes = [];
    }
  }
}
