import {environment} from '../../../../../../environments/environment';
import {HttpService} from '../http.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MediaVideoService {

  constructor(private http: HttpService) { }

  saveVideo(body) {
    return this.http.post(environment.serverurl + '/content-details/videos', body);
  }

  updatePoster(id, body) {
    return this.http.put(environment.serverurl + '/content-details/posters/' + id, body);
  }

  findCastMembers(id) {
    return this.http.get<any>(environment.serverurl + '/contents/' + id + '/cast-members');
  }

  findByTitleAndStatus(searchTerm, contentStatus, page, size, maturityRating) {
    return this.http.get<any>(environment.serverurl + '/videos/search?searchTerm=' + searchTerm
      + '&contentType=' + contentStatus + '&page=' + page + '&size=' + size + '&maturity-rating=' + maturityRating);
  }
}
