import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatSlideToggleChange} from '@angular/material';
import {Alerts} from '../../../../../alert/Alert';
import {environment} from '../../../../../../environments/environment';
import {EventService} from '../../../../../core/service/event.service';

@Component({
  selector: 'app-content-rules',
  templateUrl: './content-rules.component.html',
  styleUrls: ['./content-rules.component.scss']
})
export class ContentRulesComponent implements OnInit {
  httpDestroy = new Subject();
  selectValue: string[] = ['Blacklist countries', 'Whitelist countries'];
  allCountries: any;
  selectedCountries: any[] = [];
  allowDownload;
  allowGuest;
  allowEarlyAccess;
  ruleType: string = null;
  isblackWhiteListValid = true;
  blackWhiteListValidateMessage;
  requestBody: any = {};
  isCountriesValid = true;
  countriesValidaMessage;
  movieId;
  getContentIdSubscription;
  constructor(private service: CommonService,  private alert: Alerts, private eventService: EventService ) { }

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        this.movieId = response.id;
      }
    });
    this.loadAllCountries();
    this.allowDownload = false;
    this.allowGuest = false;
    this.allowEarlyAccess = false;

  }
  onChangeEarlyAccess($event: MatSlideToggleChange) {
    this.allowEarlyAccess = $event.checked;
  }

  onChangeDownload($event: MatSlideToggleChange) {
    this.allowDownload = $event.checked;
  }

  onChangeGuest($event: MatSlideToggleChange) {
    this.allowGuest = $event.checked;
  }

  loadAllCountries() {
    this.service.findAll(Service.COUNTRY).pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
      this.allCountries = response;
  });
  }

  validateRules() {
    if (!this.ruleType) {
      this.isblackWhiteListValid = false;
      this.blackWhiteListValidateMessage = environment.validations.emptyBlackWhiteListValidateMessage;
    } else {
      this.isblackWhiteListValid = true;
    }
  }

  clearCountires() {
    if (!this.ruleType) {
      this.isCountriesValid = true;
    } else {
      this.isCountriesValid = false;
    }
  }

  validateCountries() {
    if (!this.selectedCountries) {
      this.isCountriesValid = false;
    } else {
      this.isCountriesValid = true;
    }
  }

  validation(): boolean {

    if (this.ruleType && this.selectedCountries.length <= 0) {
      this.alert.errorAlert(environment.alerts.error.movie.content_rules.contentRulesCountry.title,
          environment.alerts.error.movie.content_rules.contentRulesCountry.addCountry).then(value => {
        return false;
      });
    } else if (this.ruleType === null && this.selectedCountries.length > 0) {
      this.alert.errorAlert(environment.alerts.error.movie.content_rules.contentRulesCountry.title,
          environment.alerts.error.movie.content_rules.contentRulesCountry.addRule).then(value => {
        return false;
      });
    } else {
      return true;
    }
  }





  createRequestBody() {

    let ruleType;
    if (this.ruleType === 'Blacklist countries') {
      ruleType = 'BLACK_LISTED';
    } else  if (this.ruleType === 'Whitelist countries') {
      ruleType = 'WHITE_LISTED';
    } else {
      ruleType = null;
    }

    let countries;
    if (!this.selectedCountries) {
      countries = null;
    } else {
      countries = this.selectedCountries;
    }

    this.requestBody.allow_download = this.allowDownload;
    this.requestBody.allow_early_access = this.allowEarlyAccess;
    this.requestBody.allow_guest_user_to_watch = this.allowGuest;
    this.requestBody.content_type = environment.contentType.movie;
    this.requestBody.country_ids = countries;
    this.requestBody.rule_type = ruleType;
  }


  save() {
    /*this.validateRules();
    this.validateCountries();
    this.createRequestBody();*/

      const value = this.validation();

      if (value) {
        this.createRequestBody();
        if (!this.movieId) {


          this.service.save(Service.MOVIE.CONTENT_RULE, this.requestBody)
              .pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
            this.alert.successAlert(environment.alerts.success.movie.content_rules.title,
                environment.alerts.success.movie.content_rules.message);
            this.eventService.getContentIdEventEmitter({id: response.id});
          }, error => {
            this.alert.errorAlert( environment.alerts.error.movie.content_rules.title,
                environment.alerts.error.movie.content_rules.message);
          });
        } else {

          this.service.update(Service.MOVIE.CONTENT_RULE, this.movieId, this.requestBody)
              .pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
            this.alert.successAlert(environment.alerts.success.movie.content_rules.title,
                environment.alerts.success.movie.content_rules.message);
            this.eventService.getContentIdEventEmitter({id: response.id});
          }, error => {
            this.alert.errorAlert( environment.alerts.error.movie.content_rules.title,
                error.error.error);
          });
        }
      }
    }
}
