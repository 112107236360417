import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpService) { }

  save(body) {
      return  this.http.post<any>(environment.serverurl + '/contents/', body);
  }

  findAll(options: any) {
      if (options.contentType === environment.contentType.movie) {
          return this.http.get<any>(environment.serverurl + '/movies/status/?' + options.status + '&page='
              + options.page + '&size=' +  options.size);
      } else if (options.contentType === environment.contentType.tvShow) {
          return this.http.get<any>(environment.serverurl + '/tv-shows/status/?' + options.status + '&page='
              + options.page + '&size=' +  options.size);
      } else if (options.contentType === environment.contentType.tvEpisode) {
          return this.http.get<any>(environment.serverurl + '/tv-episodes/status/?' + options.status + '&page='
              + options.page + '&size=' +  options.size);
      } else if (options.contentType === environment.contentType.liveChannel) {
          return this.http.get<any>(environment.serverurl + '/live-channels/status/?' + options.status + '&page='
              + options.page + '&size=' +  options.size);
      }  else if (options.contentType === environment.contentType.videoChannel) {
          return this.http.get<any>(environment.serverurl + '/video-channel/status/?' + options.status + '&page='
              + options.page + '&size=' +  options.size);
      } else if (options.contentType === environment.contentType.video) {
          return this.http.get(environment.serverurl + '/videos/status?contentStatus=' + options.status + '&page='
              + options.page + '&size=' +  options.size);
      }
  }

    getAllContentBySearch(contentType, searchTerm, page, size ) {
      return this.http.get<any>(environment.serverurl + '/commons/contents/' + contentType + '/' + searchTerm + '?page=' + page + '&size=' + size);
  }
    findAllContentESByStatus(contentType, searchTerm, status, page, size ) {
        return this.http.get<any>(environment.elasticSearchUrl + '/contents/'
            + contentType + '/' + status + '/' + searchTerm + '?page=' + page + '&size=' + size);
    }

  deleteContentById(id) {
      return this.http.delete<any>(environment.serverurl + '/contents/' +id);
  }

  findErrorsById(id, page, size) {
    return this.http.get<any>(environment.serverurl + '/contents/' + id + '/errors?page=' + page + '&size=' + size);
  }

  contentByFilters(contentType, options) {
    let queryString = '';
    if (options.createdBy) {
      queryString = '&created-by=' + options.createdBy;
    }

    if (options.uploadedBy) {
      queryString = queryString + '&uploaded-by=' + options.uploadedBy;
    }

    if (options.greaterThanCreatedDateAndTime) {
      queryString = queryString + '&greater-than-create-date-time=' + options.greaterThanCreatedDateAndTime +
        '&less-than-create-date-time=' + options.lessThanCreatedDateAndTime;
    }

    if (options.greaterThanReleasedDateAndTime) {
      queryString = queryString + '&greater-than-release-date-time=' + options.greaterThanReleasedDateAndTime +
        '&less-than-release-date-time=' + options.lessThanReleasedDateAndTime;
    }

    if (options.greaterThanPublishedDateAndTime) {
      queryString = queryString + '&greater-than-publish-date-time=' + options.greaterThanPublishedDateAndTime +
        '&less-than-publish-date-time=' + options.lessThanPublishedDateAndTime;
    }

    if (options.payToWatch) {
      queryString = queryString + '&free-to-watch=' + options.payToWatch;
    }

    if (options.freeToWatch) {
      queryString = queryString + '&pay-to-watch=' + options.freeToWatch;
    }

    if (options.contentStatus) {
      queryString = queryString + '&status=' + options.contentStatus;
    }

    if (options.isCategoryFilter) {
      queryString = queryString + '&is-category-filter=true';
    }

    if (options.keywordId) {
      queryString = queryString + '&keyword=' + options.keywordId;
    }

    if (options.genreId) {
      queryString = queryString + '&genre=' + options.genreId;
    }

    if (options.producerId) {
      queryString = queryString + '&producer=' + options.producerId;
    }

    if (options.directorId) {
      queryString = queryString + '&director=' + options.directorId;
    }

    if (options.actorId) {
      queryString = queryString + '&actor=' + options.actorId;
    }

    if (options.runTime) {
      queryString = queryString + '&run-time=' + options.runTime;
    }

    if (options.multipleContentStatus) {
      queryString = queryString + '&status=' + environment.contentStatus.published +
        '&status=' + environment.contentStatus.scheduled;
    }
    switch (contentType) {
      case environment.contentType.movie:
        return this.http.get<any>(environment.serverurl + '/movies/filters?' + 'page='
          + options.page + '&size='  +  options.size + queryString);
      case environment.contentType.tvShow:
        return this.http.get<any>(environment.serverurl + '/tv-shows/filters?' + 'page='
          + options.page + '&size='  +  options.size + queryString);
      case environment.contentType.videoChannel:
        return this.http.get<any>(environment.serverurl + '/video-channels/filters?' + 'page='
          + options.page + '&size='  +  options.size + queryString);
      case environment.contentType.liveChannel:
        return this.http.get<any>(environment.serverurl + '/live-channels/filters?' + 'page='
          + options.page + '&size='  +  options.size + queryString);
    }
  }

  findKeywords(contentType, page, size) {
    return this.http.get<any>(environment.serverurl +
      '/contents/content-keyword/' + contentType + '/keywords/?page=' + page + '&size=' + size);
  }

  findGenres(contentType, page, size) {
    return this.http.get<any>(environment.serverurl +
      '/contents/content-genres/' + contentType + '/genres/?page=' + page + '&size=' + size);
  }

  findCatMembers(contentType, role, page, size) {
    return this.http.get<any>(environment.serverurl +
      '/contents/' + contentType + '/cast-members/' + role + '?page=' + page + '&size=' + size);
  }
}
