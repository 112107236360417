import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProblemService {

  constructor(private http: HttpService) { }

  getUserProblems(options: any) {
    return this.http.get(environment.serverurl + '/user-problem?page=' + options.page + '&size=' + options.size
        + '&custom=' + options.custom + '&solved=' + options.solved);
  }
  solveProblem(body: any) {
    return this.http.post<any>(environment.serverurl + '/user-problem/slove', body);
  }
}
