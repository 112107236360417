import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {EventService} from '../../../../../core/service/event.service';
import {takeUntil} from 'rxjs/operators';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Service} from '../../../../../core/service/common_service/services';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'app-media-content-rule-detail-view',
  templateUrl: './media-content-rule-detail-view.component.html',
  styleUrls: ['./media-content-rule-detail-view.component.scss']
})
export class MediaContentRuleDetailViewComponent implements OnInit {
  destroy$ = new Subject();
  selectValue: string[] = ['Blacklist countries', 'Whitelist countries'];
  allCountries: any;
  selectedCountries: any[] = [];
  allowDownload;
  allowGuest;
  allowEarlyAccess;
  ruleType: string = null;
  isBlackWhiteListValid = true;
  blackWhiteListValidateMessage;
  requestBody: any = {};
  isCountriesValid = true;
  countriesValidaMessage;
  videoId;
  getContentIdSubscription;
  loading = false;
  toggleLayer = true;
  editViewerEvent;
  constructor(private service: CommonService,  private alert: Alerts, private eventService: EventService ) { }

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.videoId = response.id;
      }
    });
    this.loadAllCountries();
    this.allowDownload = false;
    this.allowGuest = false;
    this.allowEarlyAccess = false;
    this.editViewerEvent = this.eventService.contentViewSender.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.toggleLayer = value.viewEnable;
    });
    this.loadAllContentRule();

  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChangeEarlyAccess($event: MatSlideToggleChange) {
    this.allowEarlyAccess = $event.checked;
  }

  onChangeDownload($event: MatSlideToggleChange) {
    this.allowDownload = $event.checked;
  }

  onChangeGuest($event: MatSlideToggleChange) {
    this.allowGuest = $event.checked;
  }

  loadAllCountries() {
    this.service.findAll(Service.COUNTRY).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.allCountries = response;
    });
  }

  validateRules() {
    if (!this.ruleType) {
      this.isBlackWhiteListValid = false;
      this.blackWhiteListValidateMessage = environment.validations.emptyBlackWhiteListValidateMessage;
    } else {
      this.isBlackWhiteListValid = true;
    }
  }

  clearCountires() {
    if (!this.ruleType) {
      this.isCountriesValid = true;
    } else {
      this.isCountriesValid = false;
      this.countriesValidaMessage = 'ddds';
    }
  }

  validateCountries() {
    if (!this.selectedCountries) {
      this.isCountriesValid = false;
      this.countriesValidaMessage = 'ddddd';
    } else {
      this.isCountriesValid = true;
    }
  }

  validation(): boolean {
    if (this.ruleType && this.selectedCountries.length <= 0) {
      this.alert.errorAlert(environment.alerts.error.video.content_rules.contentRulesCountry.title,
          environment.alerts.error.video.content_rules.contentRulesCountry.addCountry).then(value => {
        return false;
      });
    } else if (this.ruleType === null && this.selectedCountries.length > 0) {
      this.alert.errorAlert(environment.alerts.error.video.content_rules.contentRulesCountry.title,
          environment.alerts.error.video.content_rules.contentRulesCountry.addRule).then(value => {
        return false;
      });
    } else {
      return true;
    }
  }

  createRequestBody() {

    let ruleType;
    if (this.ruleType === 'Blacklist countries') {
      ruleType = 'BLACK_LISTED';
    } else  if (this.ruleType === 'Whitelist countries') {
      ruleType = 'WHITE_LISTED';
    } else {
      ruleType = null;
    }

    let countries;
    if (!this.selectedCountries) {
      countries = null;
    } else {
      countries = this.selectedCountries;
    }

    this.requestBody.allow_download = this.allowDownload;
    this.requestBody.allow_early_access = this.allowEarlyAccess;
    this.requestBody.allow_guest_user_to_watch = this.allowGuest;
    this.requestBody.content_type = environment.contentType.video;
    this.requestBody.country_ids = countries;
    this.requestBody.rule_type = ruleType;
    this.requestBody.inherited_from_content = false;
  }


  save() {
    const value = this.validation();

    if (value) {
      this.createRequestBody();
        this.service.update(Service.VIDEO.CONTENT_RULE, this.videoId, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          this.alert.successAlert(environment.alerts.success.video.content_rules.title,
              environment.alerts.success.video.content_rules.message);
          this.eventService.getContentIdEventEmitter({id: response.id});
        }, error => {
          this.alert.errorAlert( environment.alerts.error.video.content_rules.title,
              error.error.error);
        });
      }
  }
  loadAllContentRule() {
    this.loading = true;

    this.service.findById(Service.VIDEO.CONTENT_RULE, this.videoId, environment.contentType.video)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {

      if (result.allow_download) {
        this.allowDownload = result.allow_download;
      }
      if (result.allow_early_access) {
        this.allowEarlyAccess = result.allow_early_access;
      }
      if (result.allow_guest_user_to_watch) {
        this.allowGuest = result.allow_guest_user_to_watch;
      }
      if (result.countries) {
        this.selectedCountries = result.countries.map((country) => {
          return country.id;
        });
        if (result.rule_type) {
          this.ruleType = result.rule_type;
        }
      }
      this.loading = true;
    });
  }
}
