import {Component, OnDestroy, OnInit} from '@angular/core';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Subject} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {Alerts} from '../../../../../alert/Alert';
import {EventService} from '../../../../../core/service/event.service';
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {

  video: string[] = [];
  files: File[] = [];
  selectValue: string[] = ['240p', '360p', '720p', '1080p'];
  addedFile: File [] = [];
  movieId: string;
  videoLocation: string;
  requestBody: any = {};
  destroy$ = new Subject();
  progress: number;
  selectedResolutions: string[] = [];
  isVideoFileUploaded = false;
  isSelectedResolutionValidated = true;
  selectedResolutionValidatedMessage;
  getContentIdSubscription;
  saveBtnDisabled = false;
  isFileSelected = true;
  isFormValid = true;
  videoFileUploadValidationMessage;
  isDropZoneDisable = false;
  constructor(private service: CommonService, private s3Bucket: S3BucketService,
              private alert: Alerts, private eventService: EventService) { }


  ngOnInit() {
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        this.movieId = response.id;
      }
    });
  }

    ngOnDestroy(): void {
        /**
         * Unsubscribe all http request
         */
        this.destroy$.next();
        this.destroy$.complete();
        this.getContentIdSubscription.unsubscribe();
    }

  // TODO unnecessary method. Delete if not need in advance.

  /*generateResolutions(width, height) {

    if ((width >= 640 && width <= 853) && (height >= 360 && height <= 479)) {
      this.selectValue = [...this.selectValue, '360p'];
      this.selectedResolutions = [...this.selectValue, '360p'];
    }
    if ((width >= 854 && width <= 1279) && (height >= 480 && height <= 719)) {
      this.selectValue = [...this.selectValue, '360p', '480p'];
      this.selectedResolutions = [...this.selectValue, '360p', '480p'];
    }
    if ((width >= 1280 && width <= 1919) && (height >= 720 && height <= 1079)) {
      this.selectValue = [...this.selectValue,  '360p', '480p', '720p'];
      this.selectedResolutions = [...this.selectValue, '360p', '480p', '720p'];
    }
    if (width >= 1920 && height >= 1080 ) {
      this.selectValue = [...this.selectValue, '360p', '480p', '720p', '1080p'];
      this.selectedResolutions = [...this.selectValue, '360p', '480p', '720p', '1080p'];
    }
  }*/



  onSelect(event) {
    this.progress = 0;
    const file: File = event.addedFiles[0];
    this.addedFile[0] = event.addedFiles[0];


    const reader =   new FileReader();
    reader.addEventListener('load', () => {

      const video = document.createElement('video') as HTMLVideoElement;
      video.onloadeddata = () => {
     /*   this.generateResolutions(video.videoWidth, video.videoHeight);*/
      };
      video.src = reader.result as string;
    }, false );
    reader.readAsDataURL(file);
    this.isFileSelected = true;

   /* if (!this.movieId) {
      console.log(this.movieId);
      this.service.save(Service.MOVIE.CONTENT, {type: 'MOVIE'})
          .pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
        this.movieId = response.id;
      });
    } else {
      console.log(this.movieId);
      this.s3Bucket.uploadFile(this.movieId, this.addedFile[0], environment.s3Bucket.buckets.video, (error, res ) => {
        this.isVideoFileUploaded = true;
        console.log(res);
        this.videoLocation = res.Location;
      });
    }*/
  }


  validatingRequiredFields() {
    if (this.selectedResolutions.length <= 0) {
        this.isFormValid =  false;
        this.isSelectedResolutionValidated = false;
        this.selectedResolutionValidatedMessage = environment.validations.emptyResolutionValidatedMessage;
    }
    if (!this.addedFile[0]) {
        this.isFileSelected = false;
        this.videoFileUploadValidationMessage = environment.validations.emptyVideoFileValidationMessage;
    }
  }

  createRequestBody() {

    this.requestBody.content_type = environment.contentType.movie;
    this.requestBody.conversion_resolutions = this.selectedResolutions;
    this.requestBody.source_url = this.videoLocation;
    this.requestBody.video_conversion_status = environment.conversionStatus.pending;
  }


 uploadVideo() {
    // this.saveBtnDisabled = true;
    this.validatingRequiredFields();
    if (!this.isFormValid) {
         return;
     }
    if (!this.movieId) {
        this.service.save(Service.MOVIE.CONTENT, {type: environment.contentType.movie})
             .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
           this.movieId = response.id;
           this.eventService.getContentIdEventEmitter({id: response.id});
           this.s3upload();
         });
      } else {
        this.s3upload();
      }
  }

  s3upload() {
      this.saveBtnDisabled = true;
      this.isDropZoneDisable = true;
      if (this.addedFile[0]) {
          new Promise((resolve, reject) => {

              this.s3Bucket.uploadFile(this.movieId, this.addedFile[0], environment.s3VideoBucket.path.videos,
                  environment.s3VideoBucket.buckets.bucketPath +
                  environment.s3VideoBucket.buckets.sourceFolder +
                  environment.s3VideoBucket.contentTypeFolder.movies, (error, response, pr) => {
                      this.isVideoFileUploaded = true;
                      this.videoLocation = response.Location;
                      if (error) {
                          reject(false);
                      }
                      resolve(true);
                  }).on('httpUploadProgress', (progress) => {
                  this.progress = Math.round(progress.loaded / progress.total * 100);
              });
          }).then(value => {
              if (value) {
                  this.saveVideo();
              }
          });
      }

  }

  saveVideo() {
      this.createRequestBody();
      this.service.update(Service.MOVIE.VIDEO, this.movieId, this.requestBody)
          .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
              this.isDropZoneDisable = false;
        this.alert.successAlert(environment.alerts.success.movie.video.title,
            environment.alerts.success.movie.video.message);

      }, error2 => {
          this.isDropZoneDisable = false;
          this.alert.errorAlert(environment.alerts.error.movie.video.title, error2.error.error);
      });
      this.saveBtnDisabled = false;
  }

  onRemove() {
    this.selectValue = [];
    this.selectedResolutions = [];
  }

  resetValidation() {
      this.isFormValid = true;
      if (this.selectedResolutions.length > 0) {
      this.isSelectedResolutionValidated = true;
     /* if (this.addedFile[0]) {
          this.isFileSelected = true;
      }*/
    }
  }
  //  display(seconds) {
  //   const format = val => `0${Math.floor(val)}`.slice(-2);
  //   const hours = seconds / 3600;
  //   const minutes = (seconds % 3600) / 60;
  //
  //   return [hours, minutes, seconds % 60].map(format).join(':');
  // }
}
