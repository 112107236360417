import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {Service} from '../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {CommonService} from '../../../core/service/common_service/common.service';
import {environment} from '../../../../environments/environment';
import {Alerts} from '../../../alert/Alert';

@Component({
  selector: 'app-notification-template-detail-view',
  templateUrl: './notification-template-detail-view.component.html',
  styleUrls: ['./notification-template-detail-view.component.scss']
})
export class NotificationTemplateDetailViewComponent implements OnInit, OnDestroy {
  loading = false;
  initialData;
  destroy$ = new Subject();
  notificationTemplate;
  saveBtnDisabled = false;
  requestBody: any = {};
  isFormValid = true;
  name;
  template;
  isTemplateValidated = true;
  notificationValidationMessage;
  constructor(@Inject(MAT_DIALOG_DATA) private data, private commonService: CommonService,
              private alert: Alerts, private model: MatDialogRef<NotificationTemplateDetailViewComponent>) {
    this.initialData = data;
  }

  ngOnInit() {
    this.findTemplate();
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  findTemplate() {
    this.loading = true;
    this.commonService.findById(Service.NOTIFICATION, this.initialData.templateId, null)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.notificationTemplate = response;
        this.name = this.notificationTemplate.name;
        this.template = this.notificationTemplate.template;
        this.loading = false;
    });
  }

  update() {
    this.validateRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    this.loading = true;
    this.saveBtnDisabled = true;
    this.createRequestBody();
    this.commonService.update(Service.NOTIFICATION, this.initialData.templateId, this.requestBody)
      .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.alert.successAlert(environment.alerts.success.notification.title,
        environment.alerts.success.notification.message).then(value => {
        this.model.close();
      });
      this.saveBtnDisabled = false;
      this.loading = false;
    }, error => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(environment.alerts.error.notification.title,
        environment.alerts.error.notification.message);
    });
  }

  validateRequiredFields() {
    if (this.template.length <= 0) {
      this.isFormValid = false;
      this.isTemplateValidated = false;
      this.notificationValidationMessage = environment.validations.notificationValidationMessage;
    }
  }

  createRequestBody() {
    this.requestBody.template = this.template;
  }

  closeWindow() {
    this.model.close();
  }

  resetValidation() {
    this.isFormValid = true;
    this.isTemplateValidated = true;
  }
}
