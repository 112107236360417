export const environment = {
    roles: ['Actor', 'Director', 'Producer'],
    production: false,
    cookies: {
        currentUser: 'currentUser'
    },
    pageSize: 10,
    table: {
        defaultPageSize: 25,
        set1: 10,
        set2: 25,
        set3: 50,
        set4: 75,
        set5: 100,
    },
    matchMode: {
        range: 'range',
        in: 'in',
        gt: 'gt',
        lt: 'lt',
        dateRange: 'dateRange'
    },
    movieTabs: {
        metaData: 1,
        video: 2,
        poster: 3,
        monetization: 4,
        cue: 5,
        contentRules: 6,
        tailerBonus: 7,
        errors: 8
    },
    mediaVideoTabs: {
        metaData: 8,
        video: 9,
        poster: 10,
        monetization: 11,
        cue: 12,
        contentRules: 13,
    },
    tv_episodeTabs: {
        metaData: 14,
        video: 15,
        poster: 16,
        monetization: 17,
        cue: 18,
        contentRules: 19,
    },
    video_channel: {
        metaData: 20,
        video: 21,
        poster: 22,
        monetization: 23,
        cue: 18,
        contentRules: 24,
        trailerBonus: 25
    },
    tvShowTabs: {
        metaData: 1,
        monetization: 2,
        poster: 3,
        contentRules: 4,
        trailerBonus: 5,
        seasons: 6
    },
    liveChannelTabs: {
        programmes: 1,
        poster: 3,
        metadata: 4
    },
    maturityRating: ['Mature', 'Standard', 'Kids'],
    serverurl:  'https://cms-api.dflix.com',
    realityCmsServiceApi: 'https://cms.reality.rumex.com.lk/',
    elasticSearchUrl: 'https://search-api.dflix.com',
    paymentUrl: 'https://payment-api.dflix.com',
    apiVersion: 'v1',
    castMembers: {
        director: 'DIRECTOR',
        producer: 'PRODUCER',
        actor: 'ACTOR'
    },
    page: 0,
    size: 50,

    alerts: {
        success: {
            movie: {
                unPublish: {
                    title: 'Movie Unpublished ',
                    message: 'Successfully Unpublished',
                    confirmationMessage: 'The content will be unpublished from Gluuoo application before edit. Do you still want to continue?',
                    confirmButton: 'Yes'
                },
                metaData: {
                    title: 'Meta Data saved',
                    message: 'Successfully saved Meta data',
                    updateTitle: 'Meta Data Updated',
                    updateMessage: 'Successfully updated Meta data'
                },
                video: {
                    title: 'Video saved',
                    message: 'Successfully saved Video'
                },
                poster: {
                    title: 'Poster saved',
                    message: 'Successfully saved Poster'
                },
                monetization: {
                    title: 'Monetization saved',
                    message: 'Successfully saved Monetization'
                },
                content_rules: {
                    title: 'saved Content Rules',
                    message: 'Successfully saved Content Rules'
                },
                tailersBouns: {
                    title: 'saved Trailers & Bonus',
                    message: 'Successfully saved Trailers & Bonus'
                },
                cuePoints: {
                    title: 'saved cue points',
                    message: 'Successfully saved cue points'
                },
                deleteMovie: {
                    title: 'Do you want do delete this movie?',
                    message: 'Movie successfully deleted.',
                    confirmationMessage: 'The content will be deleted from Gluuoo application. Do you still want to continue?',
                    confirmButton: 'Yes'
                }
            },
            tv_show: {
                metaData: {
                    title: 'Saved Meta Data',
                    message: 'Successfully saved Meta Data'
                },
                video: {
                    title: 'Saved Video',
                    message: 'Successfully saved Video'
                },
                poster: {
                    title: 'Saved Poster',
                    message: 'Successfully saved Poster'
                },
                monetization: {
                    title: 'Saved Monetization',
                    message: 'Successfully saved'
                },
                content_rules: {
                    title: 'Saved Content Rules',
                    message: 'Successfully saved Content Rules'
                },
                tailersBouns: {
                    title: 'Saved Trailers & Bonus',
                    message: 'Successfully saved Trailers & Bonus'
                },
                cuePoints: {
                    title: 'saved cue points',
                    message: 'Successfully saved cue points'
                },
                seasons: {
                    title: 'Seasons',
                    message: 'Successfully saved seasons',
                    deleteSeason: {
                        title: 'Do you want do delete this Season?',
                        message: 'Season successfully deleted.',
                        confirmationMessage: 'This Season will be deleted from Gluuoo application. Do you still want to continue?',
                        confirmButton: 'Yes'
                    }
                },
            },
            tv_episode: {
                metaData: {
                    title: 'Saved Meta Data',
                    message: 'Successfully saved Meta Data'
                },
                video: {
                    title: 'Saved Video',
                    message: 'Successfully saved Video'
                },
                poster: {
                    title: 'Saved Poster',
                    message: 'Successfully saved Poster'
                },
                monetization: {
                    title: 'Saved Monetization',
                    message: 'Successfully saved Monetization'
                },
                content_rules: {
                    title: 'Saved Content Rules',
                    message: 'Successfully saved Content Rules'
                },
                tailersBouns: {
                    title: 'Saved Trailers & Bonus',
                    message: 'Successfully saved Trailers & Bonus'
                },
            },
            video_channel: {
                metaData: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                video: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                poster: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                monetization: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                content_rules: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                tailersBouns: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                deleteVideo: {
                    title: 'Do you want do delete this video-channel?',
                    message: 'Video Channel successfully deleted.',
                    confirmationMessage: 'The content will be deleted from Gluuoo application. Do you still want to continue?',
                    confirmButton: 'Yes'
                },
            },
            video: {
                metaData: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                video: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                poster: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                monetization: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                content_rules: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                tailersBouns: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                deleteVideo: {
                    title: 'Do you want do delete this video?',
                    message: 'Video successfully deleted.',
                    confirmationMessage: 'The content will be deleted from Gluuoo application. Do you still want to continue?',
                    confirmButton: 'Yes'
                },
            },
            live_channel: {
                unPublish: {
                    title: 'Video Channel Unpublished ',
                    message: 'Successfully Unpublished',
                    confirmationMessage: 'The content will be unpublished from Gluuoo application before edit. Do you still want to continue?',
                    confirmButton: 'Yes'
                },
                metaData: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                video: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                poster: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                monetization: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                content_rules: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                tailersBouns: {
                    title: 'saved',
                    message: 'Successfully saved'
                },
                programme: {
                    title: 'saved',
                    message: 'Successfully saved'
                }
            },
            keyword: {
                title: 'saved',
                message: 'Successfully saved'
            },
            genre: {
                title: 'saved',
                message: 'Successfully saved'
            },
            castMember: {
                title: 'saved',
                message: 'Successfully saved'
            },
            sliderGraphic: {
                title: 'saved',
                message: 'Successfully saved',
                updateTitle: 'Updated',
                updateMessage: 'Successfully Updated',
                deleteSliderGraphic: {
                    title: 'Do you want do delete this SliderGraphic?',
                    message: 'SliderGraphic successfully deleted.',
                    confirmationMessage: 'This SliderGraphic will be deleted from Gluuoo application. Do you still want to continue?',
                    confirmButton: 'Yes'
                }
            },
            category: {
                title: 'saved',
                message: 'Successfully saved',
                updateTitle: 'Updated',
                updateMessage: 'Successfully Updated',
                deleteCategory: {
                    title: 'Do you want do delete this Category?',
                    message: 'Category successfully deleted.',
                    confirmationMessage: 'This Category will be deleted from Gluuoo application. Do you still want to continue?',
                    confirmButton: 'Yes'
                }
            },
            screen: {
                title: 'saved',
                message: 'Successfully saved',
                updateTitle: 'Update',
                updateMessage: 'Successfully Update'
            },
            season: {
                title: 'saved',
                message: 'Successfully saved'
            },
            notification: {
                title: 'saved',
                message: 'Successfully saved'
            },
            genresUpdate: {
                title: 'Success',
                message: 'Successfully updated'
            }
        },

        error: {
            movie: {
                metaData: {
                    title: 'error',
                    message: 'something went wrong'
                },
                video: {
                    title: 'error',
                    message: 'something went wrong'
                },
                poster: {
                    title: 'error',
                    message: 'something went wrong',
                    screenType: {
                        landscapeWithTitle: {
                            title: 'No Landscape with title',
                            message: 'add at least one Landscape with title',
                        },
                        landscapeWithoutTitle: {
                            title: 'No Landscape without title',
                            message: 'add at least one Landscape without title',
                        },
                        landscapeDetailWithoutTitle: {
                            title: 'No Landscape detail without title',
                            message: 'add at least one Landscape detail without title',
                        },
                        landscapeTitle: {
                            title: 'No Title',
                            message: 'add at least one Title',
                        },
                        portraitWithTitle: {
                            title: 'No Portrait with title',
                            message: 'add at least one Portrait with title',
                        },
                        portraitWithoutTitle: {
                            title: 'No Portrait without title',
                            message: 'add at least one Portrait without title',
                        }
                    }
                },
                monetization: {
                    title: 'error',
                    message: 'something went wrong',
                    premiumNotAvailable: 'Premium package should include in Free or Pay to watch',
                    bothPackagesShouldSame: 'Premium/Annual both packages should include in Free or Pay to watch',
                    annualPremiumNotAvailable: 'Annual Premium package should include in Free or Pay to watch'
                },
                content_rules: {
                    title: 'error',
                    message: 'something went wrong',
                    contentRulesCountry: {
                        title: 'error',
                        addCountry: 'countries should be add',
                        addRule: 'rule type type should be add',
                    }

                },
                tailersBouns: {
                    title: 'error',
                    message: 'something went wrong',
                    previewToggle: {
                        title: 'error',
                        message: 'One Preview should enable'
                    }
                },
                cuePoints: {
                    title: 'error',
                    message: 'something went wrong'
                }
            },
            tv_show: {
                metaData: {
                    title: 'error',
                    message: 'something went wrong'
                },
                poster: {
                    title: 'error',
                    message: 'something went wrong',
                    screenType: {
                        landscapeWithTitle: {
                            title: 'No Landscape with title',
                            message: 'add at least one Landscape with title',
                        },
                        landscapeWithoutTitle: {
                            title: 'No Landscape without title',
                            message: 'add at least one Landscape without title',
                        },
                        landscapeDetailWithoutTitle: {
                            title: 'No Landscape detail without title',
                            message: 'add at least one Landscape detail without title',
                        },
                        landscapeTitle: {
                            title: 'No Title',
                            message: 'add at least one Title',
                        },
                        portraitWithTitle: {
                            title: 'No Portrait with title',
                            message: 'add at least one Portrait with title',
                        },
                        portraitWithoutTitle: {
                            title: 'No Portrait without title',
                            message: 'add at least one Portrait without title',
                        }
                    }
                },
                monetization: {
                    title: 'error',
                    message: 'something went wrong',
                    premiumNotAvailable: 'Premium package should include in Free or Pay to watch',
                    bothPackagesShouldSame: 'Premium/Annual both packages should include in Free or Pay to watch',
                    annualPremiumNotAvailable: 'Annual Premium package should include in Free or Pay to watch'
                },
                content_rules: {
                    title: 'error',
                    message: 'something went wrong',
                    contentRulesCountry: {
                        title: 'error',
                        addCountry: 'countries should be add',
                        addRule: 'rule type type should be add',
                    }
                },
                tailersBouns: {
                    title: 'error',
                    message: 'something went wrong',
                    previewToggle: {
                        title: 'error',
                        message: 'One Preview should enable'
                    }
                },
                seasons: {
                    title: 'error',
                    message: 'something went wrong'
                }
            },
            tv_episode: {
                video: {
                    title: 'error',
                    message: 'something went wrong'
                },
                metaData: {
                    title: 'error',
                    message: 'something went wrong'
                },
                poster: {
                    title: 'error',
                    message: 'something went wrong',
                    screenType: {
                        landscapeWithTitle: {
                            title: 'No Landscape with title',
                            message: 'add at least one Landscape with title',
                        },
                        landscapeWithoutTitle: {
                            title: 'No Landscape without title',
                            message: 'add at least one Landscape without title',
                        },
                        landscapeDetailWithoutTitle: {
                            title: 'No Landscape detail without title',
                            message: 'add at least one Landscape detail without title',
                        },
                        landscapeTitle: {
                            title: 'No Title',
                            message: 'add at least one Title',
                        },
                        portraitWithTitle: {
                            title: 'No Portrait with title',
                            message: 'add at least one Portrait with title',
                        },
                        portraitWithoutTitle: {
                            title: 'No Portrait without title',
                            message: 'add at least one Portrait without title',
                        }
                    }
                },
                monetization: {
                    title: 'error',
                    message: 'something went wrong',
                    premiumNotAvailable: 'Premium package should include in Free or Pay to watch',
                    bothPackagesShouldSame: 'Premium/Annual both packages should include in Free or Pay to watch',
                    annualPremiumNotAvailable: 'Annual Premium package should include in Free or Pay to watch'
                },
                content_rules: {
                    title: 'error',
                    message: 'something went wrong',
                    contentRulesCountry: {
                        title: 'error',
                        addCountry: 'countries should be add',
                        addRule: 'rule type type should be add',
                    }
                },
            },
            video_channel: {
                metaData: {
                    title: 'error',
                    message: 'something went wrong'
                },
                video: {
                    title: 'error',
                    message: 'something went wrong'
                },
                poster: {
                    title: 'error',
                    message: 'something went wrong',
                    screenType: {
                        landscapeWithTitle: {
                            title: 'No Landscape with title',
                            message: 'add at least one Landscape with title',
                        },
                        landscapeWithoutTitle: {
                            title: 'No Landscape without title',
                            message: 'add at least one Landscape without title',
                        },
                        landscapeDetailWithoutTitle: {
                            title: 'No Landscape detail without title',
                            message: 'add at least one Landscape detail without title',
                        },
                        landscapeTitle: {
                            title: 'No Title',
                            message: 'add at least one Title',
                        },
                        portraitWithTitle: {
                            title: 'No Portrait with title',
                            message: 'add at least one Portrait with title',
                        },
                        portraitWithoutTitle: {
                            title: 'No Portrait without title',
                            message: 'add at least one Portrait without title',
                        }
                    }
                },
                content_rules: {
                    title: 'error',
                    message: 'something went wrong',
                    contentRulesCountry: {
                        title: 'error',
                        addCountry: 'countries should be add',
                        addRule: 'rule type type should be add',
                    }
                },
                tailersBouns: {
                    title: 'error',
                    message: 'something went wrong',
                    previewToggle: {
                        title: 'error',
                        message: 'One Preview should enable'
                    }
                },

                monetization: {
                    title: 'error',
                    message: 'something went wrong',
                    premiumNotAvailable: 'Premium package should include in Free or Pay to watch',
                    bothPackagesShouldSame: 'Premium/Annual both packages should include in Free or Pay to watch',
                    annualPremiumNotAvailable: 'Annual Premium package should include in Free or Pay to watch'

                }
            },
            live_channel: {
                metaData: {
                    title: 'error',
                    message: 'something went wrong'
                },
                video: {
                    title: 'error',
                    message: 'something went wrong'
                },
                poster: {
                    title: 'error',
                    message: 'something went wrong',
                    screenType: {
                        landscapeWithTitle: {
                            title: 'No Landscape with title',
                            message: 'add at least one Landscape with title',
                        },
                        landscapeWithoutTitle: {
                            title: 'No Landscape without title',
                            message: 'add at least one Landscape without title',
                        },
                        landscapeDetailWithoutTitle: {
                            title: 'No Landscape detail without title',
                            message: 'add at least one Landscape detail without title',
                        },
                        landscapeTitle: {
                            title: 'No Title',
                            message: 'add at least one Title',
                        },
                        portraitWithTitle: {
                            title: 'No Portrait with title',
                            message: 'add at least one Portrait with title',
                        },
                        portraitWithoutTitle: {
                            title: 'No Portrait without title',
                            message: 'add at least one Portrait without title',
                        }
                    }
                },
                content_rules: {
                    title: 'error',
                    message: 'something went wrong'
                },
                tailersBouns: {
                    title: 'error',
                    message: 'something went wrong',
                    previewToggle: {
                        title: 'error',
                        message: 'One Preview should enable'
                    }
                },

                monetization: {
                    title: 'error',
                    message: 'something went wrong'
                },
                programme: {
                    title: 'error',
                    message: 'something went wrong'
                },
                deleteMovie: {
                    title: 'Do you want do delete this programme schedule?',
                    message: 'Programme Schedule successfully deleted.',
                    confirmationMessage: 'The Schedule will be deleted from Gluuoo application. Do you still want to continue?',
                    confirmButton: 'Yes'
                }
            },
            video: {
                metaData: {
                    title: 'error',
                    message: 'something went wrong'
                },
                video: {
                    title: 'error',
                    message: 'something went wrong'
                },
                poster: {
                    title: 'error',
                    message: 'something went wrong',
                    screenType: {
                        landscapeWithTitle: {
                            title: 'No Landscape with title',
                            message: 'add at least one Landscape with title',
                        },
                        landscapeWithoutTitle: {
                            title: 'No Landscape without title',
                            message: 'add at least one Landscape without title',
                        },
                        landscapeDetailWithoutTitle: {
                            title: 'No Landscape detail without title',
                            message: 'add at least one Landscape detail without title',
                        },
                        landscapeTitle: {
                            title: 'No Title',
                            message: 'add at least one Title',
                        },
                        portraitWithTitle: {
                            title: 'No Portrait with title',
                            message: 'add at least one Portrait with title',
                        },
                        portraitWithoutTitle: {
                            title: 'No Portrait without title',
                            message: 'add at least one Portrait without title',
                        }
                    }
                },
                monetization: {
                    title: 'error',
                    message: 'something went wrong'
                },
                content_rules: {
                    title: 'error',
                    message: 'something went wrong',
                    contentRulesCountry: {
                        title: 'error',
                        addCountry: 'countries should be add',
                        addRule: 'rule type type should be add',
                    }
                },
                tailersBouns: {
                    title: 'error',
                    message: 'something went wrong',
                    previewToggle: {
                        title: 'error',
                        message: 'One Preview should enable'
                    }
                },
            },
            keyword: {
                title: 'error',
                message: 'something went wrong',
                alreadyExist: {
                    errorCode: 409,
                    message: 'Keyword already exist'
                }
            },
            genre: {
                title: 'error',
                message: 'something went wrong',
                alreadyExist: {
                    errorCode: 409,
                    message: 'Genre already exist'
                }
            },
            castMember: {
                title: 'error',
                message: 'something went wrong'
            },
            sliderGraphic: {
                title: 'error',
                message: 'something went wrong'
            },
            category: {
                title: 'error',
                message: 'something went wrong',
                updateTitle: 'error',
                updateMessage: 'something went wrong'
            },
            screen: {
                title: 'error',
                message: 'something went wrong',
                category: {
                    title: 'Category Not Found',
                    message: 'Please Remove category before update',
                    errorCode: 'C-001'
                }
            },
            season: {
                title: 'error',
                message: 'something went wrong'
            },
            notification: {
                title: 'error',
                message: 'something went wrong'
            },
            genresUpdate: {
                title: 'error',
                message: 'Genre name should not be empty!'
            }
        }
    },
    regexValidations: {
        slug: '^[a-z\\d](?:[a-z\\d_-]*[a-z\\d])?$'
    },

    validations: {
        emptyTitle: 'Please enter a Title',
        emptySlug: 'Please enter a valid slug',
        existingSlugErrorMessage: 'Slug is existing.. Please try different',
        emptyReleaseDate: 'Please choose a date',
        emptyRunTime: 'Please choose a time',
        invalidRuntime: 'Time cannot be negative',
        emptymaturityRating: 'Please choose a Maturity',
        emptyGenresMessage: 'Please choose a Genre',
        emptySubtileLanugateMessage: 'Please Select a Language',
        emptySubtitleMessage: 'Please Select a Subtitle',
        emptyResolutionValidatedMessage: 'select at least one resolution',
        emptySubValidateMessage: 'please upload image file',
        emptyCaptionValidateMessage: 'please enter a caption here',
        emptyScreenTypeValidateMessage: 'please choose a screen type',
        emptyPremiumLKRValidateMessage: 'please enter a value',
        emptyPremiumUSDValidateMessage: 'please enter a value',
        emptyPremiumDaysValidateMessage: 'please enter a value',
        emptyFreeLKRValidateMessage: 'please enter a value',
        emptyFreeUSDValidateMessage: 'please enter a value',
        emptyFreeDaysValidateMessage: 'please enter a value',
        emptyStandardLKRValidateMessage: 'please enter a value',
        emptyStandardUSDValidateMessage: 'please enter a value',
        emptyStandardDaysValidateMessage: 'please enter a value',
        emptyBlackWhiteListValidateMessage: 'please select an option',
        emptyVideoLocationValidateMessage: 'please add a video file',
        emptyThumbnailValidateMessage: 'please add a file',
        emptyTitleValidateMessage: 'please enter a title',
        emptyDescription: 'please enter a description',
        emptyGenres: 'please select genres',
        emptyKeyWords: 'please select key words',
        emptyCasts: 'please select cast members',
        emptyDirector: 'please select a director',
        emptyProducer: 'please select a producer',
        castMemberNameValidationMessage: 'Please enter name',
        castMemberImageValidationMessage: 'Please add a image',
        castMemberRoleValidationMessage: 'Please select a role',
        slugRegexValidationMessage: 'slug can only contain lower case letters, numbers & underscore only',
        emptyVideoFileValidationMessage: 'please add a video file here',
        sliderNameValidateMessage: 'please type a slider name',
        sliderMainTextValidateMessage: 'please add a main text',
        sliderSubTextValidateMessage: 'please add a main text',
        landscapeValidateMessage: 'please select a landscape position',
        portraitValidateMessage: 'please select a portrait position',
        portraitCaptionValidateMessage: 'please enter a caption',
        landscapeCaptionCaptionValidateMessage: 'please enter a caption',
        externalLinkValidateMessage: 'please enter a url',
        selectedContentValidateMessage: 'please select a option',
        selectedContentNotExistMessage: 'selected content not published',
        rulesValidateMessage: 'please select a rule',
        maturityValidateMessage: 'please select a maturity',
        countriesValidate: 'please select countries',
        ctaCusButtonTextMessage: 'please add a button text',
        customButtonValidate: 'please add a Button image',
        portraitImageValidate: 'please add a image for portrait option',
        landscapeImageValidate: 'please add a image for landscape',
        screenRulesValidate: 'please select a rule',
        screenCountryValidate: 'please select countries',
        screenMaturityValudate: 'please select a maturit rating',
        screenNameValidateMessage: 'please enter a screen name',
        categoryNameValidationMessage: 'please add category name',
        categoryTemplateValidationMessage: 'please add category template',
        contentTypeNotSelectedValidationMessage: 'please select a content type',
        contentStatusNotSelectedValidationMessage: 'please select a content status',
        seasonNumberValidateMessage: 'please add a Season Number',
        seasonLabelValidateMessage: 'Please add a Season Label',
        monetizationNotAvailableValidateMessage: 'Please enter meta data',
        contentsValidateMessage: 'Please add contents',
        tvShowValidateMessage: 'Please select a Tv show',
        seasonValidateMessage: 'Please select a season',
        tvEpisodeValidateMessage: 'Please enter a episode number',
        tvEpisodeAlreadyExistMessage: 'episode already exist',
        emptyTypeMessage : 'Please Select a cue type',
        emptyStartMessage : 'Please add a start cue',
        emptyEndMessage : 'Please add a end cue',
        cueTimeValidate: 'End time should be greater than out time',
        videoNumberValidateMessage: 'Please enter a video Number',
        playListValidateMessage: 'Please Select a playlist',
        videoChannelValidateMessage: 'Please select a Video Channel',
        playListNumberValidateMessage: 'Please Select a Playlist Number',
        posterValidationMessage: 'Poster size is not compatible for the selected Poster',
        startTimeFormatValidationMessage: 'Please check the start time format',
        endTimeFormatValidationMessage: 'Please check the end time format',
        notificationValidationMessage: 'Please enter template',
        liveChannelValidateMessage: 'Please select a live channel',
        templateValidateMessage: 'Please Upload a EPG',
        programmeNameValidateMessage: 'Please enter the name',
        programmeTypeValidateMessage: 'Please select the programme type',
        imageValidateMessage: 'Please add a image',
        startDateValidationMessage: 'Please enter start date',
        startTimeValidationMessage: 'Please enter start time',
        endTimeValidationMessage: 'Please enter end time',
        durationValidationMessage: 'Please enter duration',
        titleValidationMessage: 'Please enter title',
        descriptionValidationMessage: 'Please enter description',
        programmeScheduleImageValidationMessage: 'Please Upload a Image'

    },

    s3VideoBucket: {
        accessKeyId: 'AKIATCKAOATEEPV5IR5F',
        secretAccessKey: '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',
        region: 'ap-southeast-1',
        buckets: {
            bucketPath: 'dfix-vods/vod-content/',
            sourceFolder: 'source/staging/',
            destinationFolder: 'destination/staging/',
            content: {
                bucket: 'dfix-vods/vod-content/',
                sourceFolder: 'source/staging/',
                destinationFolder: 'destination/staging/',
            },
            tvEpisodeBucket: 'dfix-vods/vod-content/tv-episodes/',
            tvShowBucket: 'dfix-vods/vod-content/tv-shows/',
            videoChannelBucket: 'dfix-vods/vod-content/video-channels/',
            videoBucket: 'dfix-vods/vod-content/videos/',
            castMemberBucket: 'dfix-vods/vod-content/cast-members/',
            subtitle: 'dfix-vods/vod-content/test-movie-subs/subtitles/',
            poster: 'dfix-vods/vod-content/test-movie-posters/',
            video: 'dfix-vods/vod-content/test-movie-video/',
            trailer: 'dfix-vods/vod-content/test-movie-trailers/',
            tv_episode_meta_sub: 'dfix-vods/vod-content/test-tv-episode/meta-data-subtitle/',
            tv_episode_poster: 'dfix-vods/vod-content/test-tv-episode/posters/',
            video_channel_trailers: 'dfix-vods/vod-content/test-video-channel-tailers/',
        },

        contentTypeFolder: {
            movies: 'movie/',
            tvShows: 'tv_show/',
            videoChannels: 'video_channel/',
            liveChannels: 'live_channel/',
            videos: 'videos/',
            tvEpisodes: 'tv_episode/',
            programme: 'programme/',
            programmeSchedule: 'programme_schedule/'
        },

        metaDataTypeFolder: {
            castMembers: 'cast_members/'
        },

        path: {
            subs: '/sub',
            videos: '/video',
            posters: '/poster/source',
            trailers: '/trailer',
            castMembers: '/image_and_captions',
            sliderGraphic: 'slider_graphics/',
            sliderGraphicButtonFiles: 'slider_graphics_custom_btn/'
        },
        actualPath: 'https://dfix-vods.s3.ap-southeast-1.amazonaws.com/vod-content/destination',
        actualPathReplaceTo: 'https://cookie.dflix.com/destination',

        uploadError: {
            title: 'error',
            message: 'error uploading image'
        }
    },

    s3Bucket: {
        accessKeyId: 'AKIATCKAOATEEPV5IR5F',
        secretAccessKey: '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',
        region: 'ap-southeast-1',
        buckets: {
            bucketPath: 'dfix-vods/',
            sourceFolder: 'content/staging/gluooo-vod-content-destination/source/staging/',
            destinationFolder: 'content/staging/gluooo-vod-content-destination/destination/staging/',

            castMember: {
                bucket: 'dfix-vods/',
                sourceFolder: 'content/staging/gluooo-vod-content-destination/source/staging/',
                destinationFolder: 'content/staging/gluooo-vod-content-destination/destination/staging/'
            },
            content: {
                bucket: 'dfix-vods/',
                sourceFolder: 'content/staging/gluooo-vod-content-destination/source/staging/',
                destinationFolder: 'content/staging/gluooo-vod-content-destination/destination/staging/'
            },
            sliderGraphic: {
                bucket: 'dfix-vods/content/staging/gluuoo-vod-content-destination/destination/staging/',
                sourceFolder: 'destination/staging/',
                destinationFolder: 'destination/'
            },

            // TODO these bucket should be change in advance.
            tvEpisodeBucket: 'dfix-vods/vod-content/tv-episodes/',
            tvShowBucket: 'dfix-vods/vod-content/tv-shows/',
            videoChannelBucket: 'dfix-vods/vod-content/video-channels/',
            videoBucket: 'dfix-vods/vod-content/videos/',
            castMemberBucket: 'dfix-vods/vod-content/cast-members/',
            subtitle: 'dfix-vods/vod-content/test-movie-subs/subtitles/',
            poster: 'dfix-vods/vod-content/test-movie-posters/',
            video: 'dfix-vods/vod-content/test-movie-video/',
            trailer: 'dfix-vods/vod-content/test-movie-trailers/',
            tv_episode_meta_sub: 'dfix-vods/vod-content/test-tv-episode/meta-data-subtitle/',
            tv_episode_poster: 'dfix-vods/vod-content/test-tv-episode/posters/',
            video_channel_trailers: 'dfix-vods/vod-content/test-video-channel-tailers/',
        },

        contentTypeFolder: {
            movies: 'movie/',
            tvShows: 'tv_show/',
            videoChannels: 'video_channel/',
            liveChannels: 'live_channel/',
            videos: 'videos/',
            tvEpisodes: 'tv_episode/',
            programme: 'programme/',
            programmeSchedule: 'programme_schedule/'
        },

        metaDataTypeFolder: {
            castMembers: 'cast_members/'
        },

        path: {
            subs: '/sub',
            videos: '/video',
            posters: '/poster/source',
            trailers: '/trailer',
            castMembers: '/image_and_captions',
            sliderGraphic: 'slider_graphics/',
            sliderGraphicButtonFiles: 'slider_graphics_custom_btn/'
        },
        actualPath: 'https://dfix-vods.s3.ap-southeast-1.amazonaws.com/content/staging/gluooo-vod-content-destination/destination',
        actualPathReplaceTo: 'https://d13f3lhe3j1utx.cloudfront.net/staging/gluooo-vod-content-destination/destination',

        uploadError: {
            title: 'error',
            message: 'error uploading image'
        }
    },
    contentType: {
        movie: 'MOVIE',
        tvShow: 'TV_SHOW',
        videoChannel: 'VIDEO_CHANNEL',
        video: 'VIDEO',
        liveChannel: 'LIVE_CHANNEL',
        tvEpisode: 'TV_EPISODE'
    },
    subtitleLanguages: {
        language: {
            sinhala: 'SINHALA',
            english: 'ENGLISH',
            tamil: 'TAMIL'
        },
        code: {
            sinhala: 'SIN',
            english: 'ENG',
            tamil: 'TAM'
        }
    },
    publish: {
        error: {
            movie: {
                title: 'Error',
                message: 'Incomplete Tab data to publish',
                errorSavingMessage: 'Movie not published',
                publishDateAndTimeNotAvailable: 'Publish date and time Required',
                watchEnableDateAndTimeNotStandardAndFreeAvailable: 'Watch enable date and time Required',
                watchEnableDateAndTimePremiumNotAvailable: 'Watch enable date and time Required for premium users',
                countryNotAvailable: 'Country not available',
                monetizationNotFound: 'Monetization not found',
                contentWizadMetaData: 'Please complete Meta Data Tab',
                contentWizadVideo: 'Please complete Video Tab',
                contentWizadPoster: 'Please complete Poster Tab',
                contentWizadTrailer: 'Please complete Trailer & Bonus Tab',
                contentWizadMonetization: 'Please complete Monetization Tab',
            },
            tvShow: {
                title: 'Error',
                message: 'Incomplete Tab data to publish',
                errorSavingMessage: 'Tv show not published',
                publishDateAndTimeNotAvailable: 'Publish date and time Required',
                watchEnableDateAndTimeAllNotAvailable: 'Watch enable date and time Required',
                countryNotAvailable: 'Country not available',
                monetizationNotFound: 'Monetization not found'
            },
            videoChannel: {
                title: 'Error',
                message: 'Incomplete Tab data to publish',
                errorSavingMessage: 'Video channel not published',
                publishDateAndTimeNotAvailable: 'Publish date and time Required',
                watchEnableDateAndTimeAllNotAvailable: 'Watch enable date and time Required',
                countryNotAvailable: 'Country not available',
                monetizationNotFound: 'Monetization not found'
            },
            liveChannel: {
                title: 'Error',
                message: 'Incomplete Tab data to publish',
                errorSavingMessage: 'Live channel not published',
                publishDateAndTimeNotAvailable: 'Publish date and time Required',
                watchEnableDateAndTimeAllNotAvailable: 'Watch enable date and time Required',
                countryNotAvailable: 'Country not available',
                monetizationNotFound: 'Monetization not found'
            },
            tvEpisode: {
                title: 'Error',
                message: 'Incomplete Tab data to publish',
                errorSavingMessage: 'Tv Episode not published',
                publishDateAndTimeNotAvailable: 'Publish date and time Required',
                watchEnableDateAndTimeNotStandardAndFreeAvailable: 'Watch enable date and time Required',
                watchEnableDateAndTimePremiumNotAvailable: 'Watch enable date and time Required for premium users',
                countryNotAvailable: 'Country not available',
                monetizationNotFound: 'Monetization not found'
            },
            video: {
                title: 'Error',
                message: 'Incomplete Tab data to publish',
                errorSavingMessage: 'Video not published',
                publishDateAndTimeNotAvailable: 'Publish date and time Required',
                watchEnableDateAndTimeNotStandardAndFreeAvailable: 'Watch enable date and time Required',
                watchEnableDateAndTimePremiumNotAvailable: 'Watch enable date and time Required for premium users',
                countryNotAvailable: 'Country not available',
                monetizationNotFound: 'Monetization not found'
            },
            category: {
                title: 'Error',
                message: 'Category published unsuccessful',
            },
            screen: {
                title: 'Error',
                message: 'Screen published unsuccessful',
            },
            sliderGraphic: {
                title: 'Error',
                message: 'slider Graphic published unsuccessful',
            }
        },
        success: {
            movie: {
                title: 'Successfully Saved',
                message: 'Movie successfully published',
                unpublishTitle: 'Unpublished',
                unpublishMessage: 'Movie successfully Unpublished',
            },
            tvShow: {
                title: 'Successfully Saved',
                message: 'Tv show successfully published',
            },
            videoChannel: {
                title: 'Successfully Saved',
                message: 'Video channel successfully published',
            },
            tvEpisode: {
                title: 'Successfully Saved',
                message: 'Tv Episode successfully published',
            },
            video: {
                title: 'Successfully Saved',
                message: 'Video successfully published',
            },
            category: {
                title: 'Published',
                message: 'Category successfully published',
                unpublish: 'Unpublished',
                unpublishMessage: 'Category successfully Unpublished',
            },
            screen: {
                title: 'Published',
                message: 'Screen successfully published',
                unpublish: 'Unpublished',
                unpublishMessage: 'Screen successfully Unpublished',
            },
            sliderGraphic: {
                title: 'Published',
                message: 'Slider Graphic successfully published',
                unpublish: 'Unpublished',
                unpublishMessage: 'Slider Graphic successfully Unpublished',
            },
            problem: {
                title: 'Solved',
                message: 'Problem marked as solved',
            }
        }
    },
    packages: {
        free: 'FREE',
        standard: 'STANDARD',
        premium: 'PREMIUM',
        annualPremium: 'ANNUAL_PREMIUM'
    },

    imageConversionStatus: {
        pending: 'PENDING',
        complete: 'COMPLETE'
    },

    castMemberRoles: [
        {id: 1, role: 'ACTOR'},
        {id: 2, role: 'DIRECTOR'},
        {id: 3, role: 'PRODUCER'}],

    imageType: {
        icon: 'ICON',
        profile: 'PROFILE'
    },

    deviceType: {
        mobile: 'MOBILE',
        tablet: 'TABLET',
        tv: 'TV'
    },

    conversionStatus: {
        pending: 'PENDING',
        complete: 'COMPLETE'

    },

    orientationTypes: [
        {id: 'LANDSCAPE_IMAGE_WITH_TITLE', value: 'Landscape with title - 1920(W) X 1080 (H)'},
        {id: 'LANDSCAPE_IMAGE_WITHOUT_TITLE', value: 'Landscape with out title - 1920(W) X 1080 (H)'},

        {id: 'PORTRAIT_IMAGE_WITH_TITLE', value: 'Portrait with title - 1350(W) X 1800 (H)'},
        {id: 'PORTRAIT_IMAGE_WITHOUT_TITLE', value: 'Portrait with out title - 1350(W) X 1800 (H)'},
        {id: 'LANDSCAPE_TITLE', value: 'Title - 600(W) X 450 (H)'}
    ],

    orientationTypesTitle: {
        landscapeImageWithTitle: 'LANDSCAPE_IMAGE_WITH_TITLE',
        landscapeImageWithoutTitle: 'LANDSCAPE_IMAGE_WITHOUT_TITLE',

        portraitImageWithTitle: 'PORTRAIT_IMAGE_WITH_TITLE',
        portraitImageWithOutTitle: 'PORTRAIT_IMAGE_WITHOUT_TITLE',
        landscapeTitle: 'LANDSCAPE_TITLE'
    },

    orientationTypesSize: {
        LANDSCAPE_IMAGE_WITH_TITLE : {height: 1080, width: 1920},
        LANDSCAPE_IMAGE_WITHOUT_TITLE : {height: 1080, width: 1920},

        PORTRAIT_IMAGE_WITH_TITLE: {height: 1800, width: 1350},
        PORTRAIT_IMAGE_WITHOUT_TITLE: {height: 1800, width: 1350},
        LANDSCAPE_TITLE: {height: 450, width: 600},
    },

    contentDetailsOrientationTypes: [
        {id: 'LANDSCAPE_IMAGE_WITH_TITLE', value: 'Landscape with title - 1920(W) X 1080 (H)'},
        {id: 'PORTRAIT_IMAGE_WITH_TITLE', value: 'Portrait with title - 1350(W) X 1800 (H)'},
    ],

    contentDetailsOrientationTypesTitle: {
        landscapeImageWithTitle: 'LANDSCAPE_IMAGE_WITH_TITLE',
        portraitImageWithTitle: 'PORTRAIT_IMAGE_WITH_TITLE',
    },
    contentDetailsOrientationTypesSize: {
        LANDSCAPE_IMAGE_WITH_TITLE : {height: 1080, width: 1920},
        PORTRAIT_IMAGE_WITH_TITLE: {height: 1800, width: 1350},
    },

    orientations: {
        landscape: 'landscape',
        portrait: 'portrait'
    },

    cueType: {
        openingCredits: 'OPENING_CREDITS',
        endCredits: 'END_CREDITS',
        advertisement: 'ADVERTISEMENT',
        midBreak : 'MID_BREAK',
        nextEpisodePreview : 'NEXT_EPISODE_PREVIEW'
    },

    castMemberRqBody: {
        "bio": "string",
        "image_and_captions": [
            {
                "actor_name": "string",
                "alt_tag": "string",
                "cast_member_img_type": "ICON",
                "device_type": "MOBILE",
                "image_conversion_status": "PENDING",
                "image_name": "string",
                "orientation_type": "PORTRAIT",
                "source_url": "string"
            }
        ],
        "name": "string",
        "role": "ACTOR"
    },
    currency: {
        lkr: 'LKR',
        usd: 'USD'
    },

    packageActivationUnits: {
        days: 'DAYS'
    },

    contentStatus: {
        published: 'PUBLISHED',
        scheduled: 'SCHEDULED',
        processing: 'PROCESSING',
        processed: 'PROCESSED',
        unpublished: 'UNPUBLISHED',
        error: 'ERROR'
    },

    masterDataStatus: {
        published: 'PUBLISH',
        scheduled: 'SCHEDULE',
        processing: 'PROCESSING',
        processed: 'PROCESS',
        unpublished: 'UNPUBLISH',
        error: 'ERROR'
    },

    tableProperties: {
        size: 50
    },
    metaDataDateFormat: 'y-MM-dd',
    metaDataSubLanguages: {
        english: 'ENGLISH',
        sinhala: 'SINHALA',
        tamil: 'TAMIL',
        codes: {
            eng: 'ENG',
            sin: 'SIN',
            tam: 'TAM'
        }
    },


    unPublishedMovieColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'created_date', header: 'Created Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'published_date', header: 'Published Date', isFilterable: false, filterType: '', data: []},
        { field: 'uploadedBy', header: 'Uploaded', isFilterable: true, filterType: 'dropdown', data: []}
    ],

    processingMovieColumns: [
        { field: 'id', header: 'ID'},
        { field: 'poster', header: 'Poster'},
        { field: 'englishTitle', header: 'Title'},
        { field: 'createdBy', header: 'Created'},
        { field: 'uploadedBy', header: 'Uploaded'},
        { field: 'process_start_time', header: 'Process Start'},
        { field: 'progress', header: 'Progress'},
        { field: 'job_type', header: 'Job type'}
    ],

    processedMovieColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: false, filterType: '', data: []},
        { field: 'uploadedBy', header: 'Uploaded', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'process_start_time', header: 'Process Start', isFilterable: false, filterType: '', data: []},
        { field: 'process_end_time', header: 'Process End', isFilterable: false, filterType: '', data: []},
        { field: 'upload_process_status', header: 'Upload status', isFilterable: false, filterType: '', data: []}
    ],

    scheduledMovieColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'payToWatch', header: 'Pay for content', isFilterable: false, filterType: '', data: []},
        { field: 'freeToWatch', header: 'Free For content', isFilterable: false, filterType: '', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: false, filterType: '', data: []},
        { field: 'publishedBy', header: 'Published', isFilterable: false, filterType: '', data: []},
        { field: 'published_date', header: 'Published Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'release_date', header: 'Released Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'run_time', header: 'Run Time', isFilterable: false, filterType: '', data: []}
    ],

    publishedMovieColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'payToWatch', header: 'Pay for packages', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'freeToWatch', header: 'Free For packages', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'published_date', header: 'Published Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'release_date', header: 'Released Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'run_time', header: 'Run Time', isFilterable: false, filterType: '', data: []},
        { field: 'uploadedBy', header: 'Uploaded', isFilterable: true, filterType: 'dropdown', data: []}
    ],

    publishedVideoChannelColumns: [
        {field: 'id', header: 'ID'},
        {field: 'channelImage', header: 'Channel Image'},
        {field: 'channelName', header: 'Channel Name'},
        {field: 'primaryDestUrl', header: 'Primary Dest URL'},
        {field: 'secondaryDestUrl', header: 'Secondary Dest URL'},
        {field: 'inputType', header: 'Input Type'},
        {field: 'streamKey', header: 'Stream Key'},
        {field: 'timeShiftWatchPeriod', header: 'TimeShift Watch Period '},
        { field: 'published_date', header: 'Published Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'release_date', header: 'Released Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'payToWatch', header: 'Pay for packages', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'freeToWatch', header: 'Free For packages', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []}
    ],
    scheduledVideoChannelColumns: [
        {field: 'id', header: 'ID'},
        {field: 'channelImage', header: 'Channel Image', isFilterable: false, filterType: '', data: []},
        {field: 'channelName', header: 'Channel Name', isFilterable: false, filterType: '', data: []},
        {field: 'primaryDestUrl', header: 'Primary Dest URL', isFilterable: false, filterType: '', data: []},
        {field: 'secondaryDestUrl', header: 'Secondary Dest URL', isFilterable: false, filterType: '', data: []},
        {field: 'inputType', header: 'Input Type', isFilterable: false, filterType: '', data: []},
        {field: 'streamKey', header: 'Stream Key', isFilterable: false, filterType: '', data: []},
        {field: 'timeShiftWatchPeriod', header: 'TimeShift Watch Period ', isFilterable: false, filterType: '', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'release_date', header: 'Released Date', isFilterable: true, filterType: 'dateRange', data: []}
    ],

    unpublishedVideoChannelColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'channelImage', header: 'Channel Image', isFilterable: false, filterType: '', data: []},
        {field: 'channelName', header: 'Channel Name', isFilterable: false, filterType: '', data: []},
        {field: 'primaryDestUrl', header: 'Primary Dest URL', isFilterable: false, filterType: '', data: []},
        {field: 'secondaryDestUrl', header: 'Secondary Dest URL', isFilterable: false, filterType: '', data: []},
        {field: 'inputType', header: 'Input Type', isFilterable: false, filterType: '', data: []},
        {field: 'streamKey', header: 'Stream Key', isFilterable: false, filterType: '', data: []},
        {field: 'timeShiftWatchPeriod', header: 'TimeShift Watch Period ', isFilterable: false, filterType: '', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []}
    ],
    publishedVideoColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'videoPoster', header: 'Video Image', isFilterable: false, filterType: '', data: []},
        {field: 'videoTitle', header: 'Video Title', isFilterable: false, filterType: '', data: []},
        {field: 'convertedResolution', header: 'Converted Resolutions', isFilterable: false, filterType: '', data: []},
        {field: 'status', header: 'Status', isFilterable: false, filterType: '', data: []},
        {field: 'uploadedBy', header: 'Uploaded', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'processEndTime', header: 'Process End Time', isFilterable: false, filterType: '', data: []},
        { field: 'published_date', header: 'Published Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'release_date', header: 'Released Date', isFilterable: false, filterType: '', data: []},
        { field: 'payToWatch', header: 'Pay for packages', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'freeToWatch', header: 'Free For packages', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'videoChannel', header: 'Video Channel', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'playList', header: 'PlayList No.', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []}
    ],

    unpublishedVideoColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'videoPoster', header: 'Video Image', isFilterable: false, filterType: '', data: []},
        {field: 'videoTitle', header: 'Video Title', isFilterable: false, filterType: '', data: []},
        {field: 'convertedResolution', header: 'Converted Resolutions', isFilterable: false, filterType: '', data: []},
        {field: 'status', header: 'Status', isFilterable: false, filterType: '', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'processEndTime', header: 'Process End Time', isFilterable: false, filterType: '', data: []},
        {field: 'videoChannel', header: 'Video Channel', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'uploadedBy', header: 'Uploaded', isFilterable: true, filterType: 'dropdown', data: []}
    ],

    processedVideoColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'videoPoster', header: 'Video Image', isFilterable: false, filterType: '', data: []},
        {field: 'videoTitle', header: 'Video Title', isFilterable: false, filterType: '', data: []},
        {field: 'convertedResolution', header: 'Converted Resolutions', isFilterable: false, filterType: '', data: []},
        {field: 'status', header: 'Status', isFilterable: false, filterType: '', data: []},
        {field: 'uploadedBy', header: 'Uploaded', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'processEndTime', header: 'Process End Time', isFilterable: false, filterType: '', data: []},
        {field: 'videoChannel', header: 'Video Channel', isFilterable: true, filterType: 'dropdown', data: []},
    ],

    processingVideoColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'videoPoster', header: 'Video Image', isFilterable: false, filterType: '', data: []},
        {field: 'videoTitle', header: 'Video Title', isFilterable: false, filterType: '', data: []},
        {field: 'convertedResolution', header: 'Converted Resolutions', isFilterable: false, filterType: '', data: []},
        {field: 'status', header: 'Status', isFilterable: false, filterType: '', data: []},
        {field: 'uploader', header: 'Uploader', isFilterable: false, filterType: '', data: []},
        {field: 'processEndTime', header: 'Process End Time', isFilterable: false, filterType: '', data: []},
    ],


    publishedLiveChannelColumns: [
        {field: 'id', header: 'ID'},
        {field: 'videoPoster', header: 'Channel Image'},
        {field: 'videoTitle', header: 'Channel Name'},
        {field: 'primaryDestUrl', header: 'Primary Dest URL '},
        {field: 'secondaryDestUrl', header: 'Secondary Dest URL'},
        {field: 'inputType', header: 'Input Type'},
        {field: 'streamKey', header: 'Stream Key'},
        {field: 'timeShiftWatchPeriod', header: 'Time Shift Watch Period'},
    ],

    unPublishedLiveChannelColumns: [
        {field: 'id', header: 'ID'},
        {field: 'videoPoster', header: 'Channel Image'},
        {field: 'videoTitle', header: 'Channel Name'},
        {field: 'primaryDestUrl', header: 'Primary Dest URL '},
        {field: 'secondaryDestUrl', header: 'Secondary Dest URL'},
        {field: 'inputType', header: 'Input Type'},
        {field: 'streamKey', header: 'Stream Key'},
        {field: 'timeShiftWatchPeriod', header: 'Time Shift Watch Period'},
    ],

    scheduledLiveChannelColumns: [
        {field: 'id', header: 'ID'},
        {field: 'videoPoster', header: 'Channel Image'},
        {field: 'videoTitle', header: 'Channel Name'},
        {field: 'primaryDestUrl', header: 'Primary Dest URL '},
        {field: 'secondaryDestUrl', header: 'Secondary Dest URL'},
        {field: 'inputType', header: 'Input Type'},
        {field: 'streamKey', header: 'Stream Key'},
        {field: 'timeShiftWatchPeriod', header: 'Time Shift Watch Period'},
    ],

    scheduledVideoColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'videoPoster', header: 'Video Image', isFilterable: false, filterType: '', data: []},
        {field: 'videoTitle', header: 'Video Title', isFilterable: false, filterType: '', data: []},
        {field: 'convertedResolution', header: 'Converted Resolutions', isFilterable: false, filterType: '', data: []},
        {field: 'status', header: 'Status', isFilterable: false, filterType: '', data: []},
        {field: 'uploader', header: 'Uploader', isFilterable: false, filterType: '', data: []},
        {field: 'processEndTime', header: 'Process End Time', isFilterable: false, filterType: '', data: []},
    ],

    publishedSliderGraphicColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'sliderImage', header: 'Slider Image', isFilterable: false, filterType: '', data: []},
        {field: 'sliderName', header: 'Slider Name', isFilterable: false, filterType: '', data: []},
        {field: 'screen', header: 'Screen', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'createdBy', header: 'Created By', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'createdDate', header: 'created Date', isFilterable: true, filterType: 'dateRange', data: []}
    ],

    unPublishedSliderGraphicColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'sliderImage', header: 'Slider Image', isFilterable: false, filterType: '', data: []},
        {field: 'sliderName', header: 'Slider Name', isFilterable: false, filterType: '', data: []},
        {field: 'screen', header: 'Screen', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'createdBy', header: 'Created By', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'createdDate', header: 'created Date', isFilterable: true, filterType: 'dateRange', data: []}
    ],

    scheduledSliderGraphicColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'sliderImage', header: 'Slider Image', isFilterable: false, filterType: '', data: []},
        {field: 'sliderName', header: 'Slider Name', isFilterable: false, filterType: '', data: []},
        {field: 'screen', header: 'Screen', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'createdBy', header: 'Created By', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'createdDate', header: 'created Date', isFilterable: true, filterType: 'dateRange', data: []}
    ],
    publishedScreenColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'screenName', header: 'Screen Name', isFilterable: false, filterType: '', data: []},
        {field: 'createdBy', header: 'Created by', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'createdDate', header: 'created Date', isFilterable: true, filterType: 'dateRange', data: []}
    ],

    unPublishedScreenColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'screenName', header: 'Screen Name', isFilterable: false, filterType: '', data: []},
        {field: 'createdBy', header: 'Created by', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'createdDate', header: 'created Date', isFilterable: true, filterType: 'dateRange', data: []}
    ],

    scheduledScreenColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'screenName', header: 'Screen Name', isFilterable: false, filterType: '', data: []},
        {field: 'createdBy', header: 'Created by', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'createdDate', header: 'created Date', isFilterable: true, filterType: 'dateRange', data: []}
    ],
    kewWordsColumns: [
        {field: 'id', header: 'ID'},
        {field: 'name', header: 'Name'},
    ],
    castMemberColumns: [
        {field: 'id', header: 'ID'},
        {field: 'image', header: 'Image'},
        {field: 'name', header: 'Name'},
        {field: 'role', header: 'Role'},
        {field: 'bio', header: 'Bio'},
    ],
    genreColumns: [
        {field: 'id', header: 'ID'},
        {field: 'name', header: 'Name'},
    ],
    publishedCategoryColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'categoryName', header: 'Category Name', isFilterable: false, filterType: '', data: []},
        {field: 'createdBy', header: 'Created by', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'screen', header: 'Screens', isFilterable: true, filterType: 'dropdown', data: []},
    ],

    unpublishedCategoryColumns: [
        {field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        {field: 'categoryName', header: 'Category Name', isFilterable: false, filterType: '', data: []},
        {field: 'createdBy', header: 'Created by', isFilterable: true, filterType: 'dropdown', data: []},
        {field: 'screen', header: 'Screens', isFilterable: true, filterType: 'dropdown', data: []},
    ],

    sliderTextPositions: {
        tl: 'TOP_LEFT',
        tm: 'TOP_MIDDLE',
        tr: 'TOP_RIGHT',
        bl: 'BOTTOM_LEFT',
        bm: 'BOTTOM_MIDDLE',
        br: 'BOTTOM_RIGHT'
    },
    contentRules: {
        bl: 'Blacklist countries',
        wl: 'Whitelist countries',
        upperCaseBL: 'BLACK_LISTED',
        upperCaseWL: 'WHITE_LISTED'
    },
    sliderGraphic: {
        slider_type: 'STANDARD'
    },

    unPublishedTvShowColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'payToWatch', header: 'Pay for content', isFilterable: false, filterType: '', data: []},
        { field: 'freeToWatch', header: 'Free For content', isFilterable: false, filterType: '', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'created_date', header: 'Created Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'no_of_episodes', header: 'Episodes', isFilterable: false, filterType: '', data: []}
    ],

    scheduledTvShowColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'payToWatch', header: 'Pay for content', isFilterable: false, filterType: '', data: []},
        { field: 'freeToWatch', header: 'Free For content', isFilterable: false, filterType: '', data: []},
        { field: 'published_date', header: 'Published Date', isFilterable: false, filterType: '', data: []},
        { field: 'release_date', header: 'Released Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'no_of_episodes', header: 'Episodes', isFilterable: false, filterType: '', data: []}
    ],

    publishedTvShowColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'payToWatch', header: 'Pay for packages', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'freeToWatch', header: 'Free For packages', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'published_date', header: 'Published Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'release_date', header: 'Released Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'no_of_episodes', header: 'Episodes', isFilterable: false, filterType: '', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []}
    ],

    categoryTemplates: [
        { id: 'STANDARD', value: "Standard"},
        { id: 'ADVERTISEMENT', value: "Advertisement"},
        { id: 'FEATURED', value: "Featured"},
        { id: 'VIDEO', value: "Video"},
        { id: 'SPECIAL', value: "Special"}
    ],

    contentRulesArray: [
        { id: 'BLACK_LISTED', value: "Blacklist countries"},
        { id: 'WHITE_LISTED', value: "Whitelist countries"}
    ],

    addCategoryContentsColumns: [
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'release_date', header: 'Release Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'content_status', header: ' Select  Status', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'payToWatch', header: 'Pay for content', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'keywords', header: 'Select Keywords', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'genres', header: 'Select Genres', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'producers', header: 'Select Producer', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'directors', header: 'Select Director', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'actor', header: 'Select Actors', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'run_time', header: 'Run Time', isFilterable: true, filterType: 'dropdown', data: []}
    ],

    categoryContentType: [
        {id: 'MOVIE', value: 'Movies'},
        {id: 'TV_SHOW', value: 'Tv shows'},
        {id: 'VIDEO_CHANNEL', value: 'Video channels'},
        {id: 'LIVE_CHANNEL', value: 'Live channels'},
        {id: 'TV_EPISODE', value: 'Tv episodes'},
        {id: 'VIDEO', value: 'Videos'}
    ],

    contentStatusString: 'contentStatus=',

    selectedCategoryContentsColumns: [
        { "field": "poster", "header": ""},
        { "field": "englishTitle", "header": "Title"},
        {"field": "content_type", "header": "Content Classification"},
        { "field": "content_status", "header": "Status"}
    ],

    maturityRatingType: [
        {id: 'KIDS', value: 'Kids'},
        {id: 'MATURE', value: 'Mature'},
        {id: 'STANDARD', value: 'Standard'}
    ],

    unPublishedTvEpisodeColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'tvShowEnglishTitle', header: 'Tv show', isFilterable: false, filterType: '', data: []},
        { field: 'season_no', header: 'Season no.', isFilterable: false, filterType: '', data: []},
        { field: 'episode_no', header: 'Episode no.', isFilterable: false, filterType: '', data: []},
        { field: 'upload_process_status', header: 'Video uploaded', isFilterable: false, filterType: '', data: []},
        { field: 'conversion_resolutions', header: 'Converted resolutions', isFilterable: false, filterType: '', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'tvShow', header: 'Tv Show', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'uploadedBy', header: 'Uploaded', isFilterable: true, filterType: 'dropdown', data: []}
    ],

    processingTvEpisodeColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'tvShowEnglishTitle', header: 'Tv show', isFilterable: false, filterType: '', data: []},
        { field: 'conversion_resolutions', header: 'Converted resolutions', isFilterable: false, filterType: '', data: []},
        { field: 'uploadedBy', header: 'Uploaded', isFilterable: false, filterType: '', data: []},
        { field: 'process_start_time', header: 'Process Start', isFilterable: false, filterType: '', data: []},
        { field: 'progress', header: 'Progress', isFilterable: false, filterType: '', data: []},
        { field: 'job_type', header: 'Job type', isFilterable: false, filterType: '', data: []}
    ],

    processedTvEpisodeColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'tvShowEnglishTitle', header: 'Tv show', isFilterable: false, filterType: '', data: []},
        { field: 'conversion_resolutions', header: 'Converted resolutions', isFilterable: false, filterType: '', data: []},
        { field: 'uploadedBy', header: 'Uploaded', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'process_end_time', header: 'Process End', isFilterable: false, filterType: '', data: []},
        { field: 'upload_process_status', header: 'Upload status', isFilterable: false, filterType: '', data: []},
        { field: 'tvShow', header: 'Tv Show', isFilterable: true, filterType: 'dropdown', data: []}
    ],

    scheduledTvEpisodeColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'tvShowEnglishTitle', header: 'Tv show', isFilterable: false, filterType: '', data: []},
        { field: 'season_no', header: 'Season no.', isFilterable: false, filterType: '', data: []},
        { field: 'episode_no', header: 'Episode no.', isFilterable: false, filterType: '', data: []},
        { field: 'published_date', header: 'Published Date', isFilterable: false, filterType: '', data: []},
        { field: 'release_date', header: 'Released Date', isFilterable: true, filterType: 'dateRange', data: []},
    ],

    publishedTvEpisodeColumns: [
        { field: 'id', header: 'ID', isFilterable: false, filterType: '', data: []},
        { field: 'poster', header: 'Poster', isFilterable: false, filterType: '', data: []},
        { field: 'englishTitle', header: 'Title', isFilterable: false, filterType: '', data: []},
        { field: 'tvShowEnglishTitle', header: 'Tv show', isFilterable: false, filterType: '', data: []},
        { field: 'season_no', header: 'Season no.', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'episode_no', header: 'Episode no.', isFilterable: false, filterType: '', data: []},
        { field: 'published_date', header: 'Published Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'release_date', header: 'Released Date', isFilterable: true, filterType: 'dateRange', data: []},
        { field: 'tvShow', header: 'Tv Show', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'createdBy', header: 'Created', isFilterable: true, filterType: 'dropdown', data: []},
        { field: 'uploadedBy', header: 'Uploaded', isFilterable: true, filterType: 'dropdown', data: []}
    ],
    seasonTvShoEpisodeColumns: [
        { "field": "id", "header": "Season no."},
        { "field": "label", "header": "Season Label"},
        { "field": "tv_episode_list", "header": "Tv Episode"}
    ],

    playlistVideoColumns: [
        { "field": "id", "header": "Playlist no."},
        { "field": "label", "header": "Playlist Lable"},
        { "field": "video_list", "header": "Video"}
    ],

    tvEpisodeSeasonColumns: [
        { "field": "seasonId", "header": "Season no."},
        { "field": "seasonLable", "header": "Season Label"},
        { "field": "episode", "header": "Episode Number"},
        { "field": "title", "header": "Title"},
        { "field": "poster", "header": "Poster"},
        { "field": "release_date", "header": "Release Date"},
        { "field": "published_date_time", "header": "Publish Date"}
    ],
    videoChannelsVideoColumns: [
        { "field": "videoId", "header": "Play List"},
        { "field": "videoImage", "header": "Image"},
        { "field": "label", "header": "Video ID"},
        { "field": "title", "header": "Video Title"},
        { "field": "release_date", "header": "Release Date"},
        { "field": "published_date_time", "header": "Publish Date"}
    ],

    mediaConvertStatus: {
        pending: 'PENDING',
        converted: 'CONVERTED'
    },

    videoFormats: {
        dash: 'dash',
        hls: 'hls'
    },

    movieErrorsColumns: [
        { "field": "job_type", "header": "Job Type"},
        { "field": "created_time", "header": "Created Time"},
        { "field": "code", "header": "Error Code"},
        { "field": "message", "header": "Message"}
    ],

    resolutionImageType: {
        imageWithTitle: 'IMAGE_WITH_TITLE',
        imageWithoutTitle: 'IMAGE_WITHOUT_TITLE',
        detailImageWithoutTitle: 'DETAIL_IMAGE_WITHOUT_TITLE',
        title: 'TITLE'
    },

    resolutionTypes: {
        oneX: 'ONE_X',
        twoX: 'TWO_X',
        threeX: 'THREE_X',
        fourX: 'FOUR_X'
    },

    notificationsColumns: [
        { "field": "id", "header": "Id"},
        { "field": "name", "header": "Name"},
        { "field": "template", "header": "Template"},
        { "field": "content_type", "header": "Content Type"}
    ],

    screenReorderButtonText: {
        reorder: 'Re-order Screens',
        saveOrder: 'Save Order',
        buttonIcons: {
            reorder: 'fas fa-sort',
            save: 'far fa-check-square'
        }
    },

    paymentsColumns: [
        { field: 'transactionId', header: 'Transaction ID'},
        { field: 'transactionDate', header: 'Transaction Date'},
        { field: 'transactionType', header: 'Transaction Type'},
        { field: 'username', header: 'Username'},
        { field: 'paymentMethod', header: 'Payment method'},
        { field: 'status', header: 'Status'},
        { field: 'action', header: 'Action'}
    ],
    problemsColumns: [
        { field: 'id', header: 'Problem ID'},
        { field: 'problem', header: 'Problem'},
        { field: 'contents', header: 'Contents'},
        { field: 'contentDetails', header: 'Content Details'},
        { field: 'users', header: 'Users'}
    ],

    subscribersColumns: [
        { field: 'mobileNumber', header: 'Subscriber mobile number'},
        { field: 'email', header: 'Subscriber email'},
        { field: 'plan', header: 'Subscription plan'},
        { field: 'registeredDate', header: 'Registered date'},
        { field: 'status', header: 'Status'},
    ],

    cmsUserAction: {
        create: 'CREATE',
        upload: 'UPLOAD',
        publish: 'PUBLISH'
    },
    billingHistoryColumns: [
        { field: 'dateOfPayment', header: 'Date of payment'},
        { field: 'description', header: 'Description'},
        { field: 'amount', header: 'Payment amount'},
        { field: 'paymentMethod', header: 'Payment method'},
        { field: 'status', header: 'Payment status'},
    ],

    programmeType: [
        {id: 'NEWS', value: 'News'},
        {id: 'TV_SHOW', value: 'Tv Show'},
        {id: 'MOVIE', value: 'Movie'},
        {id: 'TALK_SHOW', value: 'Talk Show'},
        {id: 'POLITICAL_DISCUSSION', value: 'Political Discussion'},
        {id: 'SPORTS', value: 'Sports'},
        {id: 'RELIGIOUS', value: 'Religious'},
        {id: 'INFOTAINMENT', value: 'Infotainment'},
        {id: 'DRAMA', value: 'Drama'},
        {id: 'GAME_SHOW', value: 'Game Show'},
        {id: 'DISCUSSION', value: 'Discussion'},
        {id: 'KIDS', value: 'Kids'},
        {id: 'CARTOON', value: 'Cartoon'},
        {id: 'ENTERTAINMENT', value: 'Entertainment'},
        {id: 'ACADEMIC', value: 'Academic'},

    ],

    programmeColumns: [
        { field: 'id', header: 'ID'},
        { field: 'name', header: 'Name'},
        { field: 'poster', header: 'Poster'},
        { field: 'programme_type', header: 'Type'}
    ],

    viewProgrammeScheduleColumns: [
        { field: 'id', header: 'ID'},
        { field: 'name', header: 'Name'},
        { field: 'poster', header: 'Poster'},
        { field: 'duration', header: 'Duration'},
        { field: 'start_time', header: 'Start Time'},
        { field: 'end_time', header: 'End Time'}
    ],
    paymentStatus: [
        {value: 'ACCEPTED'},
        {value: 'FAILED'},
        {value: 'PENDING'}
    ],

    paymentMethod: [
        {value: 'IPG'},
        {value: 'DIALOG'}
    ],

    transactionType: [
        {value: 'SUBSCRIPTION'},
        {value: 'PREMIUM'}
    ],
    userStatus: [
        {value: 'ACTIVE'},
        {value: 'PENDING'}
    ],
    packageType:[
        {value: 'FREE'},
        {value:'PREMIUM'},
        {value:'ANNUAL PREMIUM'}
    ],

};


