import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MoviesComponent} from './movies/movies.component';
import {MediaVideoComponent} from './media-video/media-video.component';
import {TvEpisodeComponent} from './tv-episode/tv-episode.component';
import {VideoChannelComponent} from './video-channel/video-channel.component';
import {TvShowsComponent} from './tv-shows/tv-shows.component';
import {LiveChannelComponent} from './live-channel/live-channel.component';


const routes: Routes = [
  {path: 'movies', component: MoviesComponent},
  {path: 'videos', component: MediaVideoComponent},
  {path: 'tv-episodes', component: TvEpisodeComponent},
  {path: 'video-channels', component: VideoChannelComponent},
  {path: 'tv-shows', component: TvShowsComponent},
  {path: 'live-channels', component: LiveChannelComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MediaRoutingModule { }
