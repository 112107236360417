import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http: HttpService) { }

  findAllCounties() {
    return this.http.get<any>(environment.serverurl + '/countries');
  }
}
