import { Injectable } from '@angular/core';
import {NGXLogger, NGXLogInterface} from 'ngx-logger';
import {environment} from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor( private logger: NGXLogger ) { }

  info( message ) {
   if ( !environment.production) { this.logger.info( message ); }
  }

  error( message ) {
    if ( !environment.production) { this.logger.error( message ); }
  }
}
