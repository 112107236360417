import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CmsUserService {

  constructor(private http: HttpService) { }

  findAllScreenCmsUsers() {
    return this.http.get(environment.serverurl + '/cms-users/active');
  }

  findAllContentsCmsUsers(option) {
    return this.http.get(environment.serverurl + '/contents/cms-users/' + option.status);
  }
}
