import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  constructor(private  http: HttpService) { }

  save(body) {
    return this.http.post<any>(environment.serverurl + '/screens', body);
  }

  getAllSlidersByStatus(options) {
    return this.http.get(environment.serverurl + '/screens/status/' + options.status + '?page='
        + options.page + '&size='  +  options.size);
  }
  publish(id, body) {
    return this.http.put<any>(environment.serverurl + '/screens/publish/' + id , body);
  }

  findById(id) {
    return this.http.get<any>(environment.serverurl + '/screens/' + id);
  }

  update(id, body) {
    return this.http.put<any>(environment.serverurl + '/screens/' + id, body);
  }

  unPublish(id, body) {
    return this.http.put<any>(environment.serverurl + '/screens/un-publish/' + id, body);
  }

  previewByScreenId(id) {
    return this.http.get<any>(environment.serverurl + '/screens/' + id + '/preview');
  }

  reOrderScreen(reorderIds: number[]) {
    return this.http.post<any>(environment.serverurl + '/screen/reorder', reorderIds);
  }

  screensByFilters(options) {
    let queryString = '';
    if (options.createdBy) {
      queryString = '&created-by=' + options.createdBy;
    }

    if (options.greaterThanCreatedDateAndTime) {
      queryString = queryString + '&greater-than-create-date-time=' + options.greaterThanCreatedDateAndTime +
        '&less-than-create-date-time=' + options.lessThanCreatedDateAndTime;
    }

    return this.http.get<any>(environment.serverurl + '/screens/filters?' + 'page='
      + options.page + '&size='  +  options.size + '&status=' + options.contentStatus + queryString);
  }

  getAllScreens() {
    return this.http.get<any>(environment.serverurl + '/screens');
  }
}

