import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {CategoryService} from '../../../../../core/service/common_service/ http/category/category.service';
import {AddCategoryContentComponent} from '../add-category-content/add-category-content.component';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {PublishCategoryComponent} from '../publish-category/publish-category.component';

@Component({
  selector: 'app-category-detail-view',
  templateUrl: './category-detail-view.component.html',
  styleUrls: ['./category-detail-view.component.scss']
})
export class CategoryDetailViewComponent implements OnInit, OnDestroy {

  name: string;
  categoryNameValidationMessage: string;
  isCategoryNameValidated: boolean = true;
  description: string;
  NAME = 1;
  DESCRIPTION = 2;
  templates: any[] = [];
  selectedTemplate: any;
  TEMPLATE = 3;
  isCategoryTemplateValidated: boolean = true;
  categoryTemplateValidationMessage: string;
  rules: any[] = [];
  selectedRule: any;
  isRulesValidated: boolean = true;
  isCountriesValidated: boolean = true;
  countries: any[] = [];
  selectedCountries: any[] = [];
  rulesValidateMessage: string;
  countriesValidateMessage: string;
  isSaveDisable ;
  env = environment;
  isFormValid: boolean = true;
  RULE = 4;
  COUNTRIES = 5;
  isAddContentDisabled = false;
  requestBody: any = {};
  isRuleDisabled: boolean = false;
  isCountryDisabled: boolean = false;
  selectedContents: any[] = [];
  isContentsValidated: boolean = true;
  contentsValidateMessage: string;
  destroy$ = new Subject();
  selectedMaturityRating: any;
  MATURITY_RATING = 6;
  isMaturityRatingValidated: boolean = true;
  maturityRatingValidateMessage: string;
  isMaturityRatingDisabled: boolean = false;
  maturityRating: any[] = [];
  editBtnDisabled = false;


  isNameDisable;
  isDescriptionDisable;
  isSelectedContentsDisable;

  categoryId;
  categoryData: any;
  // isHiddenUnPublishButton = false;
  publishedStatus;
  buttonStatus;
  isTableDraggable = false;
  isDeletable = false;
  publishBtnDisabled;


  constructor(private model: MatDialogRef<CategoryDetailViewComponent>,
              private matModel: MatDialog,
              private commonService: CommonService,
              private alert: Alerts,
              private categoryService: CategoryService,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.categoryData = data.selectedCategoryData;
    this.categoryId = data.categoryId;
    this.publishedStatus = data.publishStatus;

  }

  ngOnInit() {

    if (this.publishedStatus === environment.masterDataStatus.published) {
      this.buttonStatus = environment.masterDataStatus.unpublished;
    }

    if (this.publishedStatus === environment.masterDataStatus.unpublished) {
      this.buttonStatus = environment.masterDataStatus.published;
    }

    this.disableFields();
    this.templates = this.env.categoryTemplates;
    this.rules = this.env.contentRulesArray;
    this.maturityRating = this.env.maturityRatingType;
    this.loadAllCountries();
    this.loadFormData();
    this.loadContent();

  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeWindow() {
    this.model.close();
  }

  editCategory() {

    // Published Tab Click
    if (this.publishedStatus === environment.masterDataStatus.published) {
      if (this.buttonStatus === environment.masterDataStatus.published) {
        if (this.publishedStatus === environment.masterDataStatus.published) {
          this.isNameDisable = false;
          this.isDescriptionDisable = false;
          this.isMaturityRatingDisabled = false;
          this.isRuleDisabled = false;
          this.isCountryDisabled = false;
          this.isSelectedContentsDisable = false;
          this.isAddContentDisabled = false;
          this.isTableDraggable = true;
          this.isDeletable = true;
          this.isSaveDisable = false;
          this.publishBtnDisabled = true;
          return;
        }
      }

      if (this.publishedStatus === environment.masterDataStatus.published) {
        this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
            environment.alerts.success.movie.unPublish.confirmButton).then(value => {
          if (value.isConfirmed) {

            this.commonService.unPublish(Service.CATEGORY, this.categoryId, {country_id: 0, un_publish_general_rule: true})
                .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

              this.buttonStatus = environment.contentStatus.published;
              this.publishBtnDisabled = true;




              this.isNameDisable = false;
              this.isDescriptionDisable = false;
              this.isMaturityRatingDisabled = false;
              this.isRuleDisabled = false;
              this.isCountryDisabled = false;
              this.isSelectedContentsDisable = false;
              this.isAddContentDisabled = false;
              this.isTableDraggable = true;
              this.isDeletable = true;
              this.isSaveDisable = false;


              this.editBtnDisabled = true;
            });
          }
        });
      } else {
        if (this.publishedStatus === environment.contentStatus.published) {

          this.isSaveDisable = false;

          // this.isHiddenUnPublishButton = true;
          this.isNameDisable = false;
          this.isDescriptionDisable = false;
          this.isMaturityRatingDisabled = false;
          this.isRuleDisabled = false;
          this.isCountryDisabled = false;
          this.isSelectedContentsDisable = false;
          this.isAddContentDisabled = false;
          this.isTableDraggable = true;
          this.isDeletable = true;
          this.isSaveDisable = true;
          return;
        }
      }
    }


    // Unpublish Tab Click

    if (this.publishedStatus === environment.masterDataStatus.unpublished) {
      if (this.buttonStatus === environment.masterDataStatus.published) {
        if (this.buttonStatus === environment.masterDataStatus.published) {
          this.isNameDisable = false;
          this.isDescriptionDisable = false;
          this.isMaturityRatingDisabled = false;
          this.isRuleDisabled = false;
          this.isCountryDisabled = false;
          this.isSelectedContentsDisable = false;
          this.isAddContentDisabled = false;
          this.isTableDraggable = true;
          this.isDeletable = true;
          this.isSaveDisable = false;
          this.publishBtnDisabled = true;
          return;
        }
      }
      if (this.buttonStatus === environment.masterDataStatus.unpublished) {
        this.alert.confirm(null, environment.alerts.success.movie.unPublish.confirmationMessage,
            environment.alerts.success.movie.unPublish.confirmButton).then(value => {
          if (value.isConfirmed) {

            this.commonService.unPublish(Service.CATEGORY, this.categoryId, {country_id: 0, un_publish_general_rule: true})
                .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {

              this.buttonStatus = environment.masterDataStatus.published;
              this.publishBtnDisabled = true;

              this.isNameDisable = false;
              this.isDescriptionDisable = false;
              this.isMaturityRatingDisabled = false;
              this.isRuleDisabled = false;
              this.isCountryDisabled = false;
              this.isSelectedContentsDisable = false;
              this.isAddContentDisabled = false;
              this.isTableDraggable = true;
              this.isDeletable = true;
              this.isSaveDisable = false;

              this.editBtnDisabled = true;
            });
          }
        });
      }
    }
  }

  disableFields() {
    this.isNameDisable = true;
    this.isDescriptionDisable = true;
    this.isMaturityRatingDisabled = true;
    this.isRuleDisabled = true;
    this.isCountryDisabled = true;
    this.isSelectedContentsDisable = true;
    this.isAddContentDisabled = true;
    this.isTableDraggable = false;
    this.isDeletable = false;
    this.isSaveDisable = true;
  }


  resetValidation(field: number) {

    this.isFormValid = true;
    switch (field) {
      case this.NAME:
        this.isCategoryNameValidated = true;
        break;
      case this.TEMPLATE:
        this.isCategoryTemplateValidated = true;
        break;
      case this.RULE:
        this.isRulesValidated = true;
        break;
      case this.MATURITY_RATING:
        this.isMaturityRatingValidated = true;
        break;
      default:
        this.isCountriesValidated = true;
    }
  }

  addContent() {
    if (this.categoryData) {
      const matDialogRef = this.matModel.open(AddCategoryContentComponent, {
        disableClose: true,
        autoFocus: false,
        height: 'auto',
        minWidth: '100%',
        minHeight: '100vh',
        data: {
          data : this.selectedContents,
          rating : this.selectedMaturityRating
        }
      });

      matDialogRef.afterClosed().subscribe(value => {
        if (value) {
          for (const content of this.selectedContents) {
            value = value.filter(selectedValue => selectedValue.id !== content.id);
          }
          this.selectedContents = [...this.selectedContents, ...value];
        }
      });
    } else {
      const matDialogRef = this.matModel.open(AddCategoryContentComponent, {
        disableClose: true,
        autoFocus: false,
        height: 'auto',
        minWidth: '100%',
        minHeight: '100vh',
        data: {
          rating : this.selectedMaturityRating
        }
      });

      matDialogRef.afterClosed().subscribe(value => {
        if (value) {
          for (const content of this.selectedContents) {
            value = value.filter(selectedValue => selectedValue.id !== content.id);
          }
          this.selectedContents = [...this.selectedContents, ...value];
        }
      });
    }
  }

  save() {
    this.publishBtnDisabled = true;


    this.disableFields();

    this.editBtnDisabled = false;

    this.validateRequiredFields();
    this.createRequestBody();

    if (!this.isFormValid) {
      return;
    }
    this.commonService.update(Service.CATEGORY, this.categoryId, this.requestBody).pipe(takeUntil(this.destroy$))
        .subscribe((result: any) => {
          this.publishBtnDisabled = false;

          this.alert.successAlert( environment.alerts.success.category.updateTitle, environment.alerts.success.category.updateMessage);
      }, error => {
        this.alert.errorAlert(environment.alerts.error.category.updateTitle, environment.alerts.error.category.updateMessage);
      });
  }

  validateRequiredFields() {

    if (!this.name) {
      this.isFormValid = false;
      this.isCategoryNameValidated = false;
      this.categoryNameValidationMessage = this.env.validations.categoryNameValidationMessage;
    }

    if (!this.selectedTemplate) {
      this.isFormValid = false;
      this.isCategoryTemplateValidated = false;
      this.categoryTemplateValidationMessage = this.env.validations.categoryTemplateValidationMessage;
    }

    if (this.selectedRule && this.selectedCountries.length <= 0) {
      this.isFormValid = false;
      this.isCountriesValidated = false;
      this.countriesValidateMessage = this.env.validations.countriesValidate;
    }

    if (!this.selectedRule && this.selectedCountries.length > 0) {
      this.isFormValid = false;
      this.isRulesValidated = false;
      this.rulesValidateMessage = this.env.validations.rulesValidateMessage;
    }


    if (this.selectedTemplate !== this.env.categoryTemplates[1].id) {
      if (this.selectedContents.length <= 0) {
        this.isFormValid = false;
        this.isContentsValidated = false;
        this.contentsValidateMessage = this.env.validations.contentsValidateMessage;
      }

      if (!this.selectedMaturityRating) {
        this.isFormValid = false;
        this.isMaturityRatingValidated = false;
        this.maturityRatingValidateMessage = this.env.validations.maturityValidateMessage;
      }
    }
  }

  selectTemplate(selectedTemplate: any) {
    if (selectedTemplate === this.env.categoryTemplates[1].id) {
      this.isAddContentDisabled = true;
      this.isRuleDisabled = true;
      this.isCountryDisabled = true;
      this.isMaturityRatingDisabled = true;
      return;
    }
    this.isAddContentDisabled = false;
    this.isRuleDisabled = false;
    this.isCountryDisabled = false;
  }

  createRequestBody() {
    this.requestBody = {};
    this.requestBody.name = this.name;
    this.requestBody.description = this.description;
    this.requestBody.display_type = this.selectedTemplate;

    if (this.selectedTemplate !== this.env.categoryTemplates[1].id) {
      this.requestBody.category_template = this.env.categoryTemplates[0].id;
      this.requestBody.contents = this.selectedContents.map(value => {
        return {
          content_type: value.content_type,
          id: value.id
        };
      });
      this.requestBody.country_ids = this.selectedCountries;
      this.requestBody.rule_type = this.selectedRule;
      this.requestBody.maturity_rating = this.selectedMaturityRating;
    } else {
      this.requestBody.category_template = this.selectedTemplate;
    }
  }

  loadAllCountries() {
    this.commonService.findAll(Service.COUNTRY)
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      this.countries = response;
    });
  }

  updateSelectedContents(event) {
    this.selectedContents.splice(event.allData.indexOf(event.selectedData), 1);

  }

  publishCategory() {
    this.matModel.open(PublishCategoryComponent, {
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '90%',
      width: '70%',
      data: {
        id: this.categoryId,
        title: this.name
      }
    });
  }




  loadFormData() {
    if (this.categoryData) {

      if (this.categoryData.name) {
        this.name = this.categoryData.name;
      }
      if (this.categoryData.description) {
        this.description = this.categoryData.description;
      }

      if (this.categoryData.display_type) {
        this.selectedTemplate = this.categoryData.display_type;
      }

      if (this.categoryData.maturity_rating) {
        this.selectedMaturityRating = this.categoryData.maturity_rating;
      }

      if (this.categoryData.rule_type) {
        this.selectedRule = this.categoryData.rule_type;
      }

      if (this.categoryData.country_ids) {
        this.selectedCountries = this.categoryData.country_ids;
      }
    }
  }

  loadContent() {
    this.selectedContents = this.categoryData.contents.map((content, index) => {

      if (content.title) {
        content.englishTitle = content.title.english;
      }
      if (content.poster) {
        content.poster = {
          isPoster: true,
          url: content.poster.source_url
        };
      }
      return content;
    });
  }

  unPublishedCategory() {

    if (this.buttonStatus === environment.masterDataStatus.unpublished) {
      this.commonService.unPublish(Service.CATEGORY, this.categoryId, {country_id: 0, un_publish_general_rule: true})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.buttonStatus = environment.masterDataStatus.published;
        // this.isHiddenUnPublishButton = true;
        this.alert.successAlert(environment.publish.success.category.unpublish, environment.publish.success.category.unpublishMessage);
      });
    } else {
      let matDialogRef = this.matModel.open(PublishCategoryComponent, {
        disableClose: true,
        autoFocus: false,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '90%',
        width: '70%',
        data: {
          id: this.categoryId,
          title: this.name
        }
      });
      matDialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(value => {
        if (value) {
          this.closeWindow();
        }
      });
    }



  }
}
