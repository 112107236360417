import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpService, private httpBackend: HttpBackend) {}

  save(body: any) {
    return this.http.post<any>(environment.serverurl + '/categories', body);
  }

   getAllCategory(status, page, size) {
    return this.http.get(environment.serverurl + '/categories?categoryStatus=' + status + '&page=' + page + '&size=' + size);
  }

  getAllRealityCategory(page, size) {
    const newHttpClient = new HttpClient(this.httpBackend);
    return newHttpClient.get<any>(environment.realityCmsServiceApi + `category?page=${page}&size=${size}`);
  }

    publish(id, body) {
        return this.http.put<any>(environment.serverurl + '/categories/publish/' + id , body);
    }
    findById(id) {
      return this.http.get<any>(environment.serverurl + '/categories/' + id);
    }
    update(id, body) {
      return this.http.put<any>(environment.serverurl + '/categories/' + id, body);
    }

    unPublish(id, body) {
      return this.http.put<any>(environment.serverurl + '/categories/un-publish/' + id, body);
    }

  categoryByFilters(options) {
    let queryString = '';
    if (options.createdBy) {
      queryString = 'created-by=' + options.createdBy;
    }

    if (options.screen) {
      queryString = queryString + '&screen-id=' + options.screen;
    }


    return this.http.get<any>(environment.serverurl + '/categories/filters?' + queryString + '&page='
        + options.page + '&size='  +  options.size + '&status=' + options.contentStatus);
  }


  deleteCategory(id) {
    return this.http.delete<any>(environment.serverurl + '/categories/' + id);
  }
}
