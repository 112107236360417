import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Alerts} from '../../../../../alert/Alert';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {EventService} from '../../../../../core/service/event.service';
import {AddNewSeasonComponent} from '../../../tv-episode/tabs/add-new-season/add-new-season.component';
import {TvShowService} from '../../../../../core/service/common_service/ http/contents/tv-shows/tv-show.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-seasons',
  templateUrl: './seasons.component.html',
  styleUrls: ['./seasons.component.scss']
})
export class SeasonsComponent implements OnInit {
  private tvShowId: number;

  constructor( private service: CommonService, private alert: Alerts,
               private eventService: EventService, private tvShowService: TvShowService,
               @Inject(MAT_DIALOG_DATA) private data: any) {
    this.seasonNumber = this.data.tvShowID;
    this.seasons = this.data.seasons;
  }
  env = environment;
  seasonNumber: number;
  isSeasonNumberValidate = true;
  seasonNumberValidateMessage;
  seasons = [];
  seasonLabel: string;
  isSeasonLabelValidate = true;
  seasonLabelValidateMessage;
  requestBody: any = {};
  isFormValidate = false;
  destroy$ = new Subject();
  savedSeason: any;
  isDisable = false;
  editSeasonOn = false;
  selectedSeason;
  tvEpisode = [];


  SEASON_NUMBER = 1;
  SEASON_LABEL = 2;
  tableData: any[] = [];
  getContentIdSubscription;
  pageCount: any;
  isClickFieldDisable: any = true;
  isFieldDisable: any;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // this.closeWindow(this.savedSeason);
  }

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.tvShowId = response.id;
        this.tvShowService.seasonsById(this.tvShowId)
            .pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          this.seasons = res.data;
          this.seasons.sort((a, b) => a.sequence_number - b.sequence_number);
          this.findTvEpisodeBySeason();
        });
      }
    });
  }

  createRequestBody() {
    for (let i = 0 ; i < this.seasons.length ; i++) {
      this.seasons[i].sequence_number = i + 1;
    }
    this.requestBody.id = this.tvShowId;
    this.requestBody.season_rqs = this.seasons;
  }

  validatingRequiredFields() {

    if (!this.seasonLabel) {
      this.isSeasonLabelValidate = false;
      this.seasonLabelValidateMessage = environment.validations.seasonLabelValidateMessage;
    }
  }

  findTvEpisodeBySeason() {
    this.tableData = this.seasons.map((season) => {
      this.pageCount = (season.total) / this.env.tableProperties.size * 10;
      console.log(this.pageCount);
      season.tv_episode_list = season.tv_episode_list.map((id) => id.id);
      return season;
    });

  }

  resetValidation(field) {
    switch (field) {
      case this.SEASON_NUMBER:
        this.isSeasonNumberValidate = true;
        break;
      case this.SEASON_LABEL:
        this.isSeasonLabelValidate = true;
        break;
    }
  }
  formValidation() {
    if (this.isSeasonLabelValidate) {
      this.isFormValidate = true;
    } else {
      this.isFormValidate = false;
    }
  }

  save() {
    this.isDisable = true;
    this.validatingRequiredFields();
    this.formValidation();
    this.seasons.push({
      label: this.seasonLabel,
      sequence_number: this.seasons.length
    });
    this.isDisable = false;
    this.seasonLabel = '';
  }

  onDropSliders(event: CdkDragDrop<string[]>) {
    // this.seasons[event.previousIndex].sequence_number = event.currentIndex + 1;
    moveItemInArray(this.seasons, event.previousIndex, event.currentIndex);
  }


  editSeason(slider) {
    this.selectedSeason = slider;
    this.seasonLabel = slider.label;
    this.editSeasonOn = true;
  }

  edit() {
    this.selectedSeason.label = this.seasonLabel;
    this.seasons.find(value => value.id == this.selectedSeason.id).label = this.seasonLabel;
    this.editSeasonOn = false;
    this.seasonLabel = '';
  }

  updateAll() {
    this.createRequestBody();
    this.tvShowService.saveAllSeasons(this.requestBody)
        .subscribe((response: any) => {
          this.seasons = response.data;
          this.alert.successAlert(environment.alerts.success.tv_show.seasons.title,
              environment.alerts.success.tv_show.seasons.message);
        }, error => {
          this.alert.errorAlert(environment.alerts.error.tv_show.seasons.title,
              error.error.error);
        });
  }

  deleteSeason(slider: any) {
    this.alert.confirm(null, 'This Season will be deleted from Gluuoo application. Do you still want to continue?',
        'Yes').then(val => {
      if (val.isConfirmed) {
        const index = this.seasons.findIndex(value => value.id == slider.id);
        console.log(slider.id);
        console.log(index);
        if (slider.id != undefined) {
          this.tvShowService.deleteSeason(slider.id).subscribe(
              value => {
                this.seasons.splice(index, 1);
                this.alert.successAlert('Season Deleted', 'Season Deleted');
              }, error => {
                this.alert.errorAlert('Unable to delete', 'episode exists.');
              }
          );
        } else {
          this.seasons.splice(index, 1);
        }
      }
    });
  }
}
