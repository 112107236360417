import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ScreenService} from '../../../../core/service/common_service/ http/screen/screen.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-preview-screen',
  templateUrl: './preview-screen.component.html',
  styleUrls: ['./preview-screen.component.scss']
})
export class PreviewScreenComponent implements OnInit {

  sliders: any = [];
  categories: any = [];

  screenId;
  httpDestroy = new Subject();
  loading = false;

  responsiveOptions: any;


  constructor(private model: MatDialogRef<PreviewScreenComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
              private screenService: ScreenService) {

     this.screenId = data.screenId;
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit() {
    this.loadScreenById();
  }

  onNoClick() {
    this.closeWindow();
  }

  closeWindow() {
    this.model.close();
  }

  loadScreenById() {
    this.loading = true;
    this.screenService.previewByScreenId(this.screenId)
        .pipe(takeUntil(this.httpDestroy)).subscribe((response: any) => {
        this.sliders = response.slider_graphic_img_urls;
        this.categories = response.categories;
      this.loading = false;
    });
  }
}
