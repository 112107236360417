import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Subject} from 'rxjs';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {EventService} from '../../../../../core/service/event.service';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {UpperCasePipe} from '@angular/common';

@Component({
    selector: 'app-trailer-bonus-detail-view',
    templateUrl: './trailer-bonus-detail-view.component.html',
    styleUrls: ['./trailer-bonus-detail-view.component.scss']
})
export class TrailerBonusDetailViewComponent implements OnInit, OnDestroy {

    posters: any[] = [];
    loading = false;
    movieId;
    trailers: any[] = [];
    videoFileLocation: string[] = [];
    thumbnailLocation: string[] = [];
    videoFiles: File[] = [];
    thumbnailFiles: File[] = [];
    titles: string[] = [];
    runTimes: string[] = [];
    previewToggle: boolean[] = [];
    emptyVideoLocations: number[] = [];
    emptyThumbnailLocations: number[] = [];
    emptyTitles: number[] = [];
    videoLocationValidateMessage = environment.validations.emptyVideoLocationValidateMessage;
    thumbnailValidateMessage = environment.validations.emptyThumbnailValidateMessage;
    titleValidateMessage = environment.validations.emptyTitleValidateMessage;
    requestBody: any = {};
    destroy$ = new Subject();
    getContentIdSubscription;
    videoPromiseArray: any[] = [];
    thumbnailPromiseArray: any[] = [];
    saveBtnDisabled = false;
    isFormValid = true;
    file: File[] = [];
    subtitles: any[] = [];
    toggleLayer = true;
    editViewerEvent;
    isRunTimeHasColon = [];
    invalidRunTimes: number[] = [];
    runTimeValidateMessage = 'run time not correct';
    savedVideoIndexes = [];
    savedThumbnailIndexes = [];
    videoFileName: string[] = [];
    @ViewChild('trailer', null) formReference: any;


    constructor(private service: CommonService, private s3Bucket: S3BucketService,
                private alert: Alerts, private eventService: EventService,
                private upperCase: UpperCasePipe) {
    }

    ngOnInit() {
        this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
            if (response) {
                this.movieId = response.id;
            }
        });

        this.editViewerEvent = this.eventService.contentViewSender.pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.toggleLayer = value.viewEnable;

        });

        this.loadAllTrailerBonus();
    }

    ngOnDestroy(): void {
        /**
         * Unsubscribe all http request
         */
        this.destroy$.next();
        this.destroy$.complete();
        this.getContentIdSubscription.unsubscribe();
    }

    onSelectOnVideo(event, index) {
        this.videoFileName[index] = '';
        this.trailers[index].progressprogress2 = 0;
        this.savedVideoIndexes.splice(this.savedVideoIndexes.indexOf(index), 1);
        this.trailers.forEach(() => {
            for (const file of event.addedFiles) {
                this.videoFiles[index] = file;
                const reader = new FileReader();
                reader.addEventListener('load', () => {

                    const video = document.createElement('video') as HTMLVideoElement;
                    video.onloadeddata = () => {
                        this.runTimes[index] = this.displayTime(video.duration);
                        this.trailers[index].runTime = video.duration / 60;
                        this.isRunTimeHasColon[index] = true;
                        this.runTimes = [...this.runTimes];
                    };
                    video.src = reader.result as string;

                }, false);
                reader.readAsDataURL(file);
            }
        });
    }

    upload() {
        this.validatingRequiredFields();
        if (!this.isFormValid) {
            return;
        }
        if (!this.movieId) {
            this.service.save(Service.MOVIE.CONTENT, {type: environment.contentType.movie})
                .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
                this.movieId = response.id;
                this.eventService.getContentIdEventEmitter({id: response.id});
                this.videoS3Upload();
            });
        } else {
            this.videoS3Upload();
        }
        this.saveBtnDisabled = true;
    }

    videoS3Upload() {
        let index = 0;
        let videoUploadPromise;
        for (const trailer of this.trailers) {
            if (this.videoFiles[index] && (this.videoFiles[index].size > 0) && (this.savedVideoIndexes.indexOf(index) === -1)) {
                videoUploadPromise = new Promise((resolve, reject) => {
                    this.s3Bucket.uploadFile(this.movieId, this.videoFiles[index],
                        environment.s3Bucket.path.trailers,
                        environment.s3Bucket.buckets.bucketPath +
                        environment.s3Bucket.buckets.destinationFolder +
                        environment.s3Bucket.contentTypeFolder.movies, (error, response) => {
                            if (error) {
                                reject({status: false, response: error});
                            }
                            trailer.videoFileLocation = response.Location;
                            resolve({status: true, response});
                        }).on('httpUploadProgress', (progress) => {
                        trailer.progressprogress2 = Math.round(progress.loaded / progress.total * 100);
                    });
                });
                this.videoPromiseArray.push(videoUploadPromise);
            }
            ++index;
        }
        Promise.all(this.videoPromiseArray).then(value => {
            this.savedVideoIndexes = value.map((value1, index1) => {
              if (value1) {
                return index1;
              }
            });
            if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
                this.thumbnailS3Upload();
                return;
            }
            this.alert.errorAlert(environment.s3Bucket.uploadError.title,
                environment.s3Bucket.uploadError.message);
        });
    }

    onSelectOnThumbnail(event, index) {
        this.trailers[index].progressprogress1 = 0;

        this.savedThumbnailIndexes.splice(this.savedThumbnailIndexes.indexOf(index), 1);
        this.trailers.forEach(() => {
            for (const file of event.addedFiles) {
                this.thumbnailFiles[index] = file;
            }
        });
    }

    thumbnailS3Upload() {
        let index = 0;
        let thumbnailUploadPromise;
        for (const trailer of this.trailers) {
            if (this.thumbnailFiles[index] && (this.thumbnailFiles[index].size > 0) && (this.savedThumbnailIndexes.indexOf(index) === -1)) {
                thumbnailUploadPromise = new Promise((resolve, reject) => {
                    this.s3Bucket.uploadFile(this.movieId, this.thumbnailFiles[index],
                        environment.s3Bucket.path.trailers,
                        environment.s3Bucket.buckets.bucketPath +
                        environment.s3Bucket.buckets.destinationFolder +
                        environment.s3Bucket.contentTypeFolder.movies, (error, response) => {
                            if (error) {
                                reject({status: false, response: error});
                            }
                            trailer.thumbnailLocation = response.Location.replace(environment.s3Bucket.actualPath,
                                environment.s3Bucket.actualPathReplaceTo);
                            resolve({status: true, response});
                        }).on('httpUploadProgress', (progress) => {
                        trailer.progressprogress1 = Math.round(progress.loaded / progress.total * 100);
                    });
                });
                this.thumbnailPromiseArray.push(thumbnailUploadPromise);
            }
            ++index;
        }
        Promise.all(this.thumbnailPromiseArray).then(value => {
            this.savedThumbnailIndexes = value.map((value1, index1) => {
                if (value1) {
                return index1;
            }
            });
            if (value.filter(filteredValue => !filteredValue.status).length <= 0) {
                this.save();
                return;
            }
            this.alert.errorAlert(environment.s3Bucket.uploadError.title,
                environment.s3Bucket.uploadError.message);
        });
    }

    addAnotherTrailer() {
        this.trailers.push({});
        this.initializeToggle(this.trailers.length);
    }

    deleteTrailer(index) {

        this.trailers.splice(index, 1);
        this.thumbnailFiles.splice(index, 1);
        this.videoFiles.splice(index, 1);
        this.titles.splice(index, 1);
        this.runTimes.splice(index, 1);
        this.invalidRunTimes.splice(this.invalidRunTimes.indexOf(index), 1);
        this.resetValidations();
    }


    toggleChange(index) {
        this.previewToggle.forEach((value, i, array) => {
            this.previewToggle[i] = index === i;
        });
    }

    save() {
        this.createRequestBody();
        this.service.update(Service.MOVIE.TRAILER, this.movieId, this.requestBody)
            .pipe(takeUntil(this.destroy$)).subscribe((result) => {
            this.formReference.form.pristine = true;
            this.alert.successAlert(environment.alerts.success.movie.tailersBouns.title,
                environment.alerts.success.movie.tailersBouns.message);
            this.saveBtnDisabled = false;
        }, error => {
            this.alert.errorAlert(environment.alerts.error.movie.tailersBouns.title,
                error.error.error);
            this.saveBtnDisabled = false;
        });
    }

    validatingRequiredFields() {
        if (!this.previewToggle.includes(true)) {
            this.alert.errorAlert(environment.alerts.error.movie.tailersBouns.previewToggle.title,
                environment.alerts.error.movie.tailersBouns.previewToggle.message);
        }

        this.trailers.forEach((value, index, array) => {
            if (!this.videoFileLocation[index]) {
                this.emptyVideoLocations.push(index);
            }
            if (!this.thumbnailLocation[index]) {
                this.emptyThumbnailLocations.push(index);
            }
            if (!this.titles[index]) {
                this.emptyTitles.push(index);
            }
            if (!this.isRunTimeHasColon[index]) {
                this.isFormValid = false;
                this.invalidRunTimes.push(index);
            }
        });

        if (!this.previewToggle.includes(true)) {
            this.isFormValid = false;
        }

        if (this.emptyVideoLocations.length > 0 ||
            this.emptyThumbnailLocations.length > 0 ||
            this.emptyTitles.length > 0) {
            this.isFormValid = false;
        }
    }

    resetValidations() {
        this.isFormValid = true;
        this.emptyTitles = [];
        this.emptyVideoLocations = [];
        this.emptyThumbnailLocations = [];
    }

    initializeToggle(index) {
        this.previewToggle[index] = false;
    }

    displayTime(seconds) {
        const format = val => `0${Math.floor(val)}`.slice(-2);
        const hours = seconds / 3600;
        const minutes = (seconds % 3600) / 60;
        return [hours, minutes, seconds % 60].map(format).join(':');
    }


    hoursToSeconds(time) {
        const a = time.split(':'); // split it at the colons
        const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds;
    }


    createRequestBody() {
        this.requestBody.content_type = environment.contentType.movie;
        this.requestBody.bonus_contents = [];
        for (let index = 0; index < this.trailers.length; index++) {
            let runTime;
            let runTimeInSeconds;
            if (this.trailers[index].runTime) {
                runTime = this.runTimes[index];
                runTimeInSeconds = this.hoursToSeconds(runTime);
            }
            if (this.previewToggle[index] === undefined) {
                this.previewToggle[index] = false;
            }
            this.requestBody.bonus_contents.push({
                posters: [{
                    alt_tag: this.thumbnailFiles[index].name,
                    converted_url: null,
                    device_type: environment.deviceType.mobile,
                    image_conversion_status: environment.conversionStatus.pending,
                    image_name: this.thumbnailFiles[index].name,
                    orientation_type: this.upperCase.transform(environment.orientations.landscape),
                    source_url: (this.trailers[index].thumbnailLocation) ?
                      this.trailers[index].thumbnailLocation : this.thumbnailLocation[index],
                    resolution_type: environment.resolutionTypes.fourX,
                    image_type: environment.resolutionImageType.imageWithTitle
                }],
                preview_enabled: this.previewToggle[index],
                run_time: runTimeInSeconds,
                source_url: (this.trailers[index].videoFileLocation) ?
                    this.trailers[index].videoFileLocation : this.videoFileLocation[index],
                title: {
                    english: this.titles[index],
                    sinhala: null,
                    tamil: null
                },
                video_conversion_status: (this.trailers[index].videoFileLocation) ?
                  environment.mediaConvertStatus.pending : this.trailers[index].videoConversionStatus
            });
        }

    }

    loadAllTrailerBonus() {
        this.service.findById(Service.MOVIE.TRAILER, this.movieId, environment.contentType.movie)
            .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
                if (result.bonus_contents) {
                    for (let j = 0; j < result.bonus_contents.length; j++) {
                        this.trailers.push({});
                        this.previewToggle[j] = result.bonus_contents[j].preview_enabled;
                        this.runTimes[j] = this.displayTime(result.bonus_contents[j].run_time);
                        this.isRunTimeHasColon[j] = true;
                        this.trailers[j].runTime = result.bonus_contents[j].run_time;
                        this.videoFileLocation[j] = result.bonus_contents[j].source_url;
                        if (result.bonus_contents[j].source_url) {
                            this.videoFileName[j] = this.getNameFromSourceUrl(result.bonus_contents[j].source_url);
                            this.trailers[j].progressprogress2 = 100;
                        }
                        this.titles[j] = result.bonus_contents[j].title.english;
                        this.trailers[j].videoConversionStatus = result.bonus_contents[j].video_conversion_status;
                        for (let i = 0; i < result.bonus_contents[j].posters.length; i++) {
                            if (result.bonus_contents[j].posters[i].source_url !== null) {
                                this.thumbnailLocation[j] = result.bonus_contents[j].posters[i].source_url;
                                this.thumbnailFiles[j] = new File([], result.bonus_contents[j].posters[i].image_name);
                                this.trailers[j].progressprogress1 = 100;
                            }
                        }
                    }
                }
        });
    }

    runTimeValidator(index) {
      this.invalidRunTimes.splice(this.invalidRunTimes.indexOf(index), 1);
        this.isRunTimeHasColon[index] = false;
        const regex = RegExp('^(1[0-2]|0[1-9]):([0-5][0-9]):([0-5][0-9])$');
        if (regex.test(this.runTimes[index])) {
            this.isRunTimeHasColon[index] = true;
            this.trailers[index].runTime = this.runTimes[index];
        }
    }

    getNameFromSourceUrl(url) {
        const index = url.lastIndexOf('_');
        return url.substr(index + 1);
    }
}
