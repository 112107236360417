import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {S3BucketService} from '../../../../../core/service/s3-bucket.service';
import {Alerts} from '../../../../../alert/Alert';
import {Service} from '../../../../../core/service/common_service/services';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {EventService} from '../../../../../core/service/event.service';

@Component({
  selector: 'app-tv-episode-video',
  templateUrl: './tv-episode-video.component.html',
  styleUrls: ['./tv-episode-video.component.scss']
})
export class TvEpisodeVideoComponent implements OnInit, OnDestroy {
  video: string[] = [];
  files: File[] = [];
  selectValue: string[] = ['240p', '360p', '720p', '1080p'];
  addedFile: File [] = [];
  tvShowId: string;
  videoLocation: string;
  requestBody: any = {};
  destroy$ = new Subject();
  progress: number;
  selectedResolutions: string[] = [];
  isVideoFileUploaded = false;
  isSelectedResolutionValidated = true;
  selectedResolutionValidatedMessage;
  getContentIdSubscription;
  saveBtnDisabled = false;
  isFileSelected = true;
  isFormValid = true;
  videoFileUploadValidationMessage;
  isDropZoneDisable = false;

  constructor(private service: CommonService, private s3Bucket: S3BucketService,
              private alert: Alerts, private eventService: EventService) { }


  ngOnInit() {
    this.getContentIdSubscription = this.eventService.returnContentIdEvent.subscribe(response => {
      if (response) {
        this.tvShowId = response.id;
      }
    });
  }

  ngOnDestroy(): void {
    /**
     * Unsubscribe all http request
     */
    this.destroy$.next();
    this.destroy$.complete();
    this.getContentIdSubscription.unsubscribe();
  }

  onSelect(event) {
    this.progress = 0;
    const file: File = event.addedFiles[0];
    this.addedFile[0] = event.addedFiles[0];


    const reader =   new FileReader();
    reader.addEventListener('load', () => {

      const video = document.createElement('video') as HTMLVideoElement;
      video.onloadeddata = () => {
        /*   this.generateResolutions(video.videoWidth, video.videoHeight);*/
      };
      video.src = reader.result as string;
    }, false );
    reader.readAsDataURL(file);
    this.isFileSelected = true;
  }


  validatingRequiredFields() {
    if (this.selectedResolutions.length <= 0) {
      this.isFormValid =  false;
      this.isSelectedResolutionValidated = false;
      this.selectedResolutionValidatedMessage = environment.validations.emptyResolutionValidatedMessage;
    }
    if (!this.addedFile[0]) {
      this.isFileSelected = false;
      this.videoFileUploadValidationMessage = environment.validations.emptyVideoFileValidationMessage;
    }
  }

  createRequestBody() {

    this.requestBody.content_type = environment.contentType.tvEpisode;
    this.requestBody.conversion_resolutions = this.selectedResolutions;
    this.requestBody.source_url = this.videoLocation;
  }


  uploadVideo() {
    this.validatingRequiredFields();
    if (!this.isFormValid) {
      return;
    }
    if (!this.tvShowId) {
      this.service.save(Service.TV_EPISODE.CONTENT, {type: environment.contentType.tvEpisode})
          .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.tvShowId = response.id;
        this.eventService.getContentIdEventEmitter({id: response.id});
        this.s3upload();
      });
    } else {
      this.s3upload();
    }
  }

  s3upload() {
    this.isDropZoneDisable = true;
    this.saveBtnDisabled = true;
    if (this.addedFile[0]) {
      new Promise((resolve, reject) => {

        this.s3Bucket.uploadFile(this.tvShowId, this.addedFile[0], environment.s3VideoBucket.path.videos,
            environment.s3VideoBucket.buckets.bucketPath +
            environment.s3VideoBucket.buckets.sourceFolder +
            environment.s3VideoBucket.contentTypeFolder.tvEpisodes, (error, response, pr) => {
              this.isVideoFileUploaded = true;
              this.videoLocation = response.Location;
              if (error) {
                reject(false);
              }
              resolve(true);
            }).on('httpUploadProgress', (progress) => {
          this.progress = Math.round(progress.loaded / progress.total * 100);
        });
      }).then(value => {
        if (value) {
          this.saveVideo();
        }
      });
    }

  }

  saveVideo() {
    this.createRequestBody();
    this.service.update(Service.TV_EPISODE.VIDEO, this.tvShowId, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.alert.successAlert(environment.alerts.success.tv_episode.video.title,
          environment.alerts.success.tv_episode.video.message);
    }, error2 => {
      this.alert.errorAlert(environment.alerts.error.tv_episode.video.title,
          error2.error.error);
    });
    this.saveBtnDisabled = false;
    this.isDropZoneDisable = false;
  }

  onRemove() {
    this.addedFile = [];
    this.selectedResolutions = [];
  }

  resetValidation() {
    this.isFormValid = true;
    if (this.selectedResolutions.length > 0) {
      this.isSelectedResolutionValidated = true;
    }
  }


}
