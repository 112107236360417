import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        label: 'Navigation',
        isTitle: true
    },
    {
        label: 'Dashboard',
        icon: 'home',
        link: '/',
    },
    {
        label: 'Media Library',
        icon: 'youtube',
        subItems: [
            {
                label: 'Movies',
                link: '/media/movies',
            },
            {
                label: 'Tv Shows',
                link: '/media/tv-shows'
            },
            {
                label: 'Tv Episodes',
                link: '/media/tv-episodes'
            },
            {
                label: 'Video Channels',
                link: '/media/video-channels'
            },
            {
                label: 'Videos',
                link: '/media/videos'
            },
            {
                label: 'Live Channels',
                link: '/media/live-channels'
            }
        ]
    },
    {
      label: 'Master Data',
      icon: 'database',
      subItems: [
          {
              label: 'Screens',
              link: '/meta-data/screens',
          },
          {
              label: 'Categories',
              link: '/meta-data/categories'
          },
          {
              label: 'Slider Graphics',
              link: '/meta-data/slider-graphics'
          },
          {
              label: 'Cast',
              link: '/meta-data/cast'
          },
          {
              label: 'Keywords',
              link: '/meta-data/keywords'
          },
          {
              label: 'Genres',
              link: '/meta-data/genres'
          }
      ]
    },
    {
      label: 'Subscribers',
      icon: 'users',
      link: '/subscribers',
    },
    {
        label: 'Promos',
        icon: 'arrow-up-circle',
        link: '/subscribers',
    },
    {
        label: 'Payments',
        icon: 'credit-card',
        link: '/payments',
    },
    {
        label: 'Reports',
        icon: 'trello',
        link: '/subscribers',
    },
    {
      label: 'Notifications',
      icon: 'send',
      link: '/notifications',
    },
    {
        label: 'Analytics',
        icon: 'map',
        link: '/analytics',
    },
    {
        label: 'Problems',
        icon: 'arrow-down-circle',
        link: '/problems',
    }
];
