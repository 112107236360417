export const  Service = {
    MOVIE: {
        CUE_POINT: 1,
        CONTENT_RULE: 2,
        META_DATA: 3,
        MONETIZATION: 4,
        POSTER: 5,
        TRAILER: 6,
        VIDEO: 7,
        CONTENT: 8,
        PUBLISH: 9,
        STATUS: 41,
        UNPUBLISH: 59,

    },
    TV_SHOW: {
        CUE_POINT: 10,
        CONTENT_RULE: 11,
        META_DATA: 12,
        MONETIZATION: 13,
        POSTER: 14,
        TRAILER: 15,
        VIDEO: 16,
        CONTENT: 17,
        PUBLISH: 40,
        STATUS: 42,
        UNPUBLISH: 61,

    },
    VIDEO_CHANNEL: {
        CONTENT_RULE: 18,
        META_DATA: 19,
        MONETIZATION: 20,
        POSTER: 21,
        TRAILER: 22,
        VIDEO: 23,
        CONTENT: 24,
        PUBLISH: 39,
        STATUS: 46,
        UNPUBLISH: 63,

    },
    LIVE_CHANNEL: {
        CONTENT: 43,
        POSTER: 44,
        STATUS: 51,
        UNPUBLISH: 76,
        MATADATA: 77,
    },
    CAST_MEMBER: 25,
    GENRE: 26,
    KEY_WORD: 27,
    COUNTRY: 28,
    PACKAGE_TIER: 29,
    VIDEO: {
        META_DATA: 30,
        VIDEO: 31,
        CONTENT: 32,
        POSTER: 55,
        STATUS: 56,
        CONTENT_RULE: 64,
        MONETIZATION: 65,
        CUE_POINT: 66,
        PUBLISH: 67,
        UNPUBLISH: 68,
        PLAY_LIST: 69
    },
    TV_EPISODE: {
        VIDEO: 33,
        CONTENT_RULE: 34,
        MONETIZATION: 35,
        POSTER: 36,
        CONTENT: 37,
        META_DATA: 38,
        SEASON: 47,
        PUBLISH: 48,
        STATUS: 58,
        UNPUBLISH: 60,
        CUE_POINT: 62
    },
    SLIDER_GRAPHIC: 51,
    SLIDER_GRAPHIC_REALITY: 52,
    SLIDER_GRAPHIC_PUBLISH: 57,
    REALITY_CATEGORY: 49,
    CATEGORY: 45,
    SCREEN: 50,
    NOTIFICATION: 70,
    PAYMENT: 71,
    SUBSCRIBER: 73,
    CMS_USER: 72,
    PROGRAMME: 74,
    PROGRAMME_SCHEDULE: 75
};
