import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeService {

  constructor(private http: HttpService) { }

  create(body) {
    return  this.http.post<any>(environment.serverurl + '/programmes', body);
  }

  update(id, body) {
    return  this.http.put<any>(environment.serverurl + '/programmes/' + id, body);
  }

  findAllByLiveChannel(id, options) {
    return  this.http.get<any>(environment.serverurl + '/programmes/live-channels/' + id + '?page='
      + options.page + '&size=' +  options.size);
  }


  searchByName(liveChannelId, searchTerm, page, size) {
    return this.http.get<any>(environment.serverurl + '/programmes/search?live-channel-id=' + liveChannelId +
        '&page=' + page + '&searchTerm=' + searchTerm + '&size=' + size );
  }

}
