import { Injectable } from '@angular/core';
import {HttpService} from "../http.service";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VideoChannelContentRuleService {

  constructor(private http: HttpService) { }

  save(body) {
    return this.http.post<any>(environment.serverurl + '/contents/content-rules', body);
  }
  findById(id) {
    return this.http.get<any>(environment.serverurl + '/contents/'+ id +'/content-rules');
  }
  update(id, body) {
    return this.http.put<any>(environment.serverurl + '/contents/content-rules/' + id, body);
  }
}
