import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../../../../core/service/common_service/common.service';
import {Service} from '../../../../../core/service/common_service/services';
import {environment} from '../../../../../../environments/environment';
import {EventService} from '../../../../../core/service/event.service';
import {takeUntil} from 'rxjs/operators';
import {Alerts} from '../../../../../alert/Alert';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-live-channel-metadata',
  templateUrl: './live-channel-metadata.component.html',
  styleUrls: ['./live-channel-metadata.component.scss']
})
export class LiveChannelMetadataComponent implements OnInit {
  channelUrl: any;
  saveBtnDisabled: any;
  liveChannelId;
  private getContentIdSubscription;
  requestBody: any = {};
  destroy$ = new Subject();

  constructor(private commonService: CommonService,
              private eventService: EventService,
              private alert: Alerts) { }

  ngOnInit() {
    this.getContentIdSubscription = this.eventService.contentIdSender.subscribe(response => {
      if (response) {
        this.liveChannelId = response.id;
        this.loadLiveChannelData();
      }
    });
  }

  updateUrl() {
    this.createRequestBody();
    this.commonService.update(Service.LIVE_CHANNEL.MATADATA, null, this.requestBody)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
      this.alert.successAlert(environment.alerts.success.live_channel.poster.title,
          environment.alerts.success.live_channel.poster.message);
      this.saveBtnDisabled = false;
    }, error2 => {
      this.saveBtnDisabled = false;
      this.alert.errorAlert(environment.alerts.error.live_channel.poster.title,  error2.error.error);
    });
  }

  createRequestBody() {
    this.requestBody.id = this.liveChannelId;
    this.requestBody.url = this.channelUrl;
  }

  loadLiveChannelData() {
    this.commonService.findById( Service.LIVE_CHANNEL.MATADATA, this.liveChannelId, environment.contentType.liveChannel)
        .pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          this.channelUrl = result.cdn_url;
    });
  }
}
