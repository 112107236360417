import { Injectable } from '@angular/core';
import {HttpService} from '../http.service';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpService) { }

  getAllPayments(options: any) {
    return this.http.get(environment.paymentUrl + '/payments?page=' + options.page + '&size=' + options.size);
  }

  searchPayments(options: any) {

    /*return this.http.get(
        environment.paymentUrl + '/payments/search?greater-than-date-time=' + options.startDate + '&less-than-date-time=' + options.endDate + '&search-term=' + options.searchTerm
        + '&payment-method=' + options.paymentMethod + '&payment-status=' + options.paymentStatus + '&transaction-type=' + options.transactionType
        + '&page=' + options.page + '&size=' + options.size);*/

    if (options.startDate != null && options.endDate != null && options.searchTerm != null) {
      return this.http.get(
          environment.paymentUrl + '/payments/search?greater-than-date-time=' + options.startDate + '&less-than-date-time=' + options.endDate + '&search-term=' + options.searchTerm
          + '&payment-method=' + options.paymentMethod + '&payment-status=' + options.paymentStatus + '&transaction-type=' + options.transactionType
          + '&page=' + options.page + '&size=' + options.size);
    }else if(options.startDate != null && options.endDate != null && options.searchTerm == null){
      return this.http.get(
          environment.paymentUrl + '/payments/search?greater-than-date-time=' + options.startDate + '&less-than-date-time=' + options.endDate + '&payment-method=' + options.paymentMethod + '&payment-status=' + options.paymentStatus + '&transaction-type=' + options.transactionType
          + '&page=' + options.page + '&size=' + options.size);
    }else if(options.startDate == null && options.endDate == null && options.searchTerm == null){
      return this.http.get(
          environment.paymentUrl + '/payments/search?payment-method=' + options.paymentMethod + '&payment-status=' + options.paymentStatus + '&transaction-type=' + options.transactionType
          + '&page=' + options.page + '&size=' + options.size);
    }
    return this.http.get(
        environment.paymentUrl + '/payments/search?search-term=' + options.searchTerm
        + '&payment-method=' + options.paymentMethod + '&payment-status=' + options.paymentStatus + '&transaction-type=' + options.transactionType
        + '&page=' + options.page + '&size=' + options.size);
  }

  getPaymentsByUser(userId: number, page, size) {
    return this.http.get(environment.paymentUrl + '/payments/' + userId + '?page=' + page + '&size=' + size );
  }

}
